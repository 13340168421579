import { Helmet } from 'react-helmet';
import { Page, Panel, Panels, AutomationTiles } from 'components_old';
import { useTranslation } from 'hooks';

export const AutomationPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} | {t('routes.automation')}
        </title>
      </Helmet>

      <Page>
        <Panels>
          <Panel
            header={<>{t('routes.automation')}</>}
            body={
              <AutomationTiles />
            }
          />
        </Panels >
      </Page >
    </>
  );
};

