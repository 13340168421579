import 'styles/base.scss';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { NylasProvider } from '@nylas/nylas-react';
import { persistor, store } from 'store';
import { router } from 'routing/router';
import { Tooltip } from 'react-tooltip'
import 'i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.scss';
import setUpInterceptor from 'services/http/interceptor';
import { TOOLTIP_ID, API_URL, MIXPANEL_API_KEY, ENV } from 'const';
import { API_ROUTES } from 'services/api/constants';
import * as Sentry from "@sentry/react";
import { isProd } from 'utils/misc';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: "https://0399d6e377d983ce6c684f3e8fa11200@o4505308253585408.ingest.sentry.io/4505981150101504",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: isProd() ? ENV.PROD : ENV.DEV,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

setUpInterceptor(store)

mixpanel.init(MIXPANEL_API_KEY, { api_host: API_URL + API_ROUTES.mixpanel, debug: !isProd(), ignore_dnt: true });

serviceWorkerRegistration.register();

root.render(
  // <React.StrictMode>
  <NylasProvider serverBaseUrl={API_URL!}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
        <ToastContainer
          icon={false}
        />
        <Tooltip id={TOOLTIP_ID} />
      </PersistGate>
    </Provider>
  </NylasProvider>
  // </React.StrictMode>,
);
