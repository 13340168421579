import styles from './AutomationDescription.module.scss';
import { ReactNode } from 'react';
import classnames from 'classnames';

interface IProps {
  children?: ReactNode;
  className?: string
}
export const AutomationDescription = ({
  children,
  className
}: IProps) => {
  return (
    <div className={classnames(styles.AutomationDescription, className)}>
      {children}
    </div>
  );
};
