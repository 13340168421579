import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";

export const createDataExtractorRequest = createAsyncThunk(
  ACTION.DATA_EXTRACTOR.CREATE_REQUEST,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.dataExtractor.createDataExtractorRequest(
        payload
      );

      const blob = new Blob([response], { type: "application/octet-stream" });
      const blobObjectUrl = window.URL.createObjectURL(blob);

      return blobObjectUrl;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
