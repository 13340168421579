import { Helmet } from 'react-helmet';
import { ComparisonWizard, Page, Panel, Panels, } from 'components_old';
import { useTranslation } from 'hooks';

export const PortfolioComparisonPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} | {t('routes.portfolioComparison')}
        </title>
      </Helmet>

      <Page>
        <Panels>
          <Panel
            header={<>{t('routes.portfolioComparison')}</>}
            body={
              <ComparisonWizard />
            }
          />
        </Panels >
      </Page >
    </>
  );
};

