import { WizardRecord } from "store/analysis";

export interface IStepProps {
  handleGoToNextStep: (arg: WizardRecord) => void;
}

export interface IStepMandatoryProps extends IStepProps {
  nextStep: string;
}

export const STEP_TYPE = {
  GENERAL: "general",
  EXTRACTION: "extraction",
  ENTRY: "data-entry",
  REPORT: "report",
};

export const STEP = {
  S1: `${STEP_TYPE.GENERAL}_step1_getting-started`,
  S2_EXTRACTION: `${STEP_TYPE.EXTRACTION}_step2_select-format`,
  S3_EXTRACTION: `${STEP_TYPE.EXTRACTION}_step3_how-to-share-file`,
  S4A_EXTRACTION: `${STEP_TYPE.EXTRACTION}_step4a_file-upload`,
  S4B_EXTRACTION: `${STEP_TYPE.EXTRACTION}_step4a_file-link`,
  S5_EXTRACTION: `${STEP_TYPE.EXTRACTION}_step5_congrats`,
  S2_ENTRY: `${STEP_TYPE.ENTRY}_step2_select-format`,
  S3_ENTRY: `${STEP_TYPE.ENTRY}_step3_share-access`,
  S4_ENTRY: `${STEP_TYPE.ENTRY}_step4_how-to-share-file`,
  S5A_ENTRY: `${STEP_TYPE.ENTRY}_step5a_file-upload`,
  S5B_ENTRY: `${STEP_TYPE.ENTRY}_step5a_file-link`,
  S6_ENTRY: `${STEP_TYPE.ENTRY}_step6_congrats`,
  S2_REPORT: `${STEP_TYPE.REPORT}_step2_select-format`,
  S3_REPORT: `${STEP_TYPE.REPORT}_step3_how-to-share-file`,
  S4_REPORT_OH_NO: `${STEP_TYPE.REPORT}_step4_oh-no`,
  S4A_REPORT: `${STEP_TYPE.REPORT}_step4a_file-upload`,
  S4B_REPORT: `${STEP_TYPE.REPORT}_step4b_file-link`,
  S5_REPORT: `${STEP_TYPE.REPORT}_step5_congrats`,
};

export interface IShareAccessPayload {
  // tool: string;
  username: string;
  password: string;
}

export interface IFileSecureLinkPayload {
  url: string;
}

export interface IUploadedFile {
  content_disposition?: string;
  content_id?: string;
  content_type?: string;
  filename: string;
  id: string;
  size?: number;
}
