import { ReactNode } from 'react';
import { cn } from 'lib/utils';
import { TooltipProvider } from 'components/ui/tooltip';
import { MetaContent } from './MetaContent';
import { useBodyClass } from 'hooks';
import { AppMenu } from 'components/layout-atoms/AppMenu';

interface IProps {
  children: ReactNode,
  className?: string;
  contentClassName?: string;
  hideMenu?: boolean;
  isOneScreenOnly?: boolean;
}

export const GeneralLayout = ({ children, className, contentClassName, hideMenu, isOneScreenOnly }: IProps) => {
  useBodyClass("muninai");
  
  return (
    <TooltipProvider delayDuration={100}>
      <MetaContent />
      <div className={cn("flex min-h-screen w-full flex-col", { "h-screen": isOneScreenOnly }, className)}>
        {!hideMenu && <AppMenu />}
        <main className={cn("flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10", contentClassName)}>
          {isOneScreenOnly ? (
            <div className="flex flex-col justify-between h-full w-full relative gap-6">
              {children}
            </div>
          ) : (
            <>{children}</>
          )}
        </main>
      </div>
    </TooltipProvider>
  )
}



