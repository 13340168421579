import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { getAllTemplates, selectActiveAdminTemplate, selectAllAdminTemplates } from 'store/templates-management';
import { DataTable } from './data-table';
import { columns } from './columns';
import { Fieldset } from 'components/ui/fieldset';
import { EditTemplatePrompts } from './EditTemplatePrompts';

export const AdminTemplatesManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const templatesList = useAppSelector(selectAllAdminTemplates);
  const activeAdminTemplate = useAppSelector(selectActiveAdminTemplate);

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);


  return (
    <Fieldset legend={t('component.AdminTemplatesManagement.listHeader')}>
      <DataTable columns={columns} data={templatesList} />
      {!!activeAdminTemplate && (
        <EditTemplatePrompts
          data={activeAdminTemplate}
        />
      )}
    </Fieldset >
  );
};