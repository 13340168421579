import { authentication } from "./authentication";
import { chat } from "./chat";
import { users } from "./users";
import { emails } from "./emails";
import { mailbox } from "./mailbox";
import { myProfile } from "./profile";
import { analysis } from "./analysis";
import { credentials } from "./credentials";
import { portfolioModel } from "./portfolioModel";
import { notifications } from "./notifications";
import { dataExtractor } from "./dataExtractor";
import { automations } from "./automations";
import { chatAssistant } from "./chatAssistant";
import { clientManagement } from "./clientManagement";
import { templatesManagement } from "./templates-management";
import { headsUpCards } from "./heads-up-cards";
import { calendar } from "./calendar";
import { redtail } from "./redtail";
import { organizations } from "./organizations";
import { dataPoints } from "./data-points";

export const api = {
  authentication,
  chat,
  users,
  emails,
  mailbox,
  myProfile,
  analysis,
  credentials,
  portfolioModel,
  notifications,
  dataExtractor,
  automations,
  chatAssistant,
  clientManagement,
  templatesManagement,
  headsUpCards,
  calendar,
  redtail,
  organizations,
  dataPoints
};
