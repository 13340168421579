
import { GeneralLayout } from "components/layout/GeneralLayout";
import { Card, CardHeader, CardTitle, CardDescription } from "components/ui/card";
import { useTranslation } from "hooks";
import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

export const LoginSignUpSubLayout = ({ children }: IProps) => {
  const { t } = useTranslation();

  return (
    <GeneralLayout isOneScreenOnly={true} hideMenu={true} contentClassName="!p-0">
      <div className="w-full h-full flex flex-col justify-center lg:grid lg:grid-cols-2">

        {/* Left side */}
        <div className="hidden bg-primary lg:flex p-8 flex-col justify-between">
          <Card className="bg-transparent shadow-none border-none">
            <CardHeader className="p-0">
              <CardTitle className="text-3xl text-primary-foreground">
                {t('general.website')}
              </CardTitle>
            </CardHeader>
          </Card>

          <Card className="bg-transparent shadow-none border-none">
            <CardHeader className="p-0">
              <CardTitle className="text-2xl text-primary-foreground">
                {t('general.heroTitle')}
              </CardTitle>
              <CardDescription className="text-base font-light text-primary-foreground">
                {t('general.heroDescription')}
              </CardDescription>
            </CardHeader>
          </Card>
        </div>

        {/* Right side */}
        <div className="flex items-center justify-center p-8">
          {children}
        </div>

      </div>
    </GeneralLayout>
  )
}
