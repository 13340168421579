
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { useTranslation, useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { } from 'hooks';
import { getAuthenticationLoading, login } from 'store/authentication';
import { ILoginPayload } from 'services/api/authentication/types';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Checkbox } from 'components/ui/checkbox';
import { LINKS, LOCAL_STORAGE } from 'const';

export interface IFormPayload
  extends Pick<
    ILoginPayload,
    "email" | "password"
  > { betaConsent?: boolean; }

export const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAuthenticationLoading);

  const consentGiven = localStorage.getItem(LOCAL_STORAGE.BETA_TESTING) === '1';

  const formSchema = z.object({
    email: z.string().min(1, t('errors.required') as string),
    password: z.string().min(1, t('errors.required') as string),
    ...(!consentGiven ? { betaConsent: z.boolean().refine((value) => value === true, t('errors.youMustAgree') as string) } : {}),
  });

  const form = useForm<IFormPayload>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: { email: '', password: '', betaConsent: false }
  });

  function onSubmit(values: IFormPayload) {
    if (values.betaConsent) {
      localStorage.setItem(LOCAL_STORAGE.BETA_TESTING, '1');
    }

    dispatch(login({ ...values, muninai: true }));
  }

  useEffect(() => {
    form.setFocus('email');
  }, [form]);

  return (
    <div className="mx-auto grid w-[400px] gap-6">
      <div className="grid gap-2">
        <h1 className="text-3xl font-bold">
          {t('component.LoginForm.title')}
        </h1>
        <p className="text-balance text-muted-foreground">
          {t('component.LoginForm.description')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('component.LoginForm.email')}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('component.LoginForm.password')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {!consentGiven && (
            <FormField
              control={form.control}
              name="betaConsent"
              render={({ field }) => (
                <FormItem>
                  <div className='flex items-center'>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pl-2">
                      {t('component.LoginForm.iAccept')} &nbsp;
                      <a href={LINKS.BETA_AGREEMENT} target='_blank' rel="noreferrer" className='underline'>
                        {t('component.LoginForm.betaTestAgreement')}
                      </a>
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <Button loading={loading} type="submit" className='w-full'>
            {t('component.LoginForm.btn')}
          </Button>

          <div className="mt-4 text-center text-sm">
            <p>{t('component.LoginForm.doNotHaveAccount')}</p>
            <p>
              <a href={LINKS.BOOK_A_CALL} target='_blank' rel="noreferrer" className='underline'>
                {t('component.LoginForm.bookMeeting')}
              </a>
              {" "}
              {t('component.LoginForm.withTheTeam')}
            </p>
          </div>
        </form>
      </Form>
    </div>
  )
}
