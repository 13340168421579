import { Icon, IIconProps } from './Icon';

export const Refresh = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_403_2901)">
        <path d="M12.0002 1.99997C13.3267 2.00432 14.6392 2.27171 15.8617 2.78666C17.0842 3.30161 18.1924 4.05389 19.1222 4.99997H16.0002C15.735 4.99997 15.4807 5.10533 15.2931 5.29286C15.1056 5.4804 15.0002 5.73476 15.0002 5.99997C15.0002 6.26519 15.1056 6.51954 15.2931 6.70708C15.4807 6.89461 15.735 6.99997 16.0002 6.99997H20.1432C20.6357 6.99971 21.1078 6.80397 21.456 6.45578C21.8042 6.10758 22 5.6354 22.0002 5.14297V0.999971C22.0002 0.734754 21.8949 0.4804 21.7073 0.292864C21.5198 0.105328 21.2654 -2.92115e-05 21.0002 -2.92115e-05C20.735 -2.92115e-05 20.4807 0.105328 20.2931 0.292864C20.1056 0.4804 20.0002 0.734754 20.0002 0.999971V3.07797C18.3474 1.58942 16.3128 0.590381 14.1243 0.19272C11.9358 -0.204941 9.67986 0.0144827 7.60902 0.826418C5.53818 1.63835 3.73425 3.01074 2.39924 4.7899C1.06424 6.56905 0.250875 8.68471 0.050233 10.9C0.0373164 11.0392 0.0535488 11.1797 0.097896 11.3123C0.142243 11.4449 0.213733 11.5669 0.307809 11.6704C0.401886 11.7738 0.516487 11.8566 0.644311 11.9133C0.772135 11.9701 0.910378 11.9996 1.05023 12C1.29482 12.0031 1.53177 11.9148 1.71469 11.7524C1.89762 11.59 2.01335 11.3652 2.03923 11.122C2.26185 8.63272 3.40721 6.31667 5.25031 4.62881C7.09342 2.94094 9.50105 2.00326 12.0002 1.99997Z" fill="#374957" />
        <path d="M22.9512 12.0002C22.7066 11.9971 22.4696 12.0854 22.2867 12.2478C22.1038 12.4102 21.988 12.635 21.9622 12.8782C21.7968 14.7814 21.0891 16.5973 19.9228 18.1104C18.7565 19.6235 17.1807 20.7703 15.3823 21.4148C13.5839 22.0592 11.6383 22.1743 9.7765 21.7463C7.91466 21.3183 6.21465 20.3653 4.87815 19.0002H8.00015C8.26537 19.0002 8.51972 18.8949 8.70726 18.7073C8.8948 18.5198 9.00015 18.2654 9.00015 18.0002C9.00015 17.735 8.8948 17.4806 8.70726 17.2931C8.51972 17.1056 8.26537 17.0002 8.00015 17.0002H3.85715C3.61325 17.0001 3.37172 17.048 3.14635 17.1413C2.92099 17.2346 2.71623 17.3714 2.54376 17.5438C2.3713 17.7163 2.23452 17.9211 2.14124 18.1464C2.04796 18.3718 2.00002 18.6133 2.00015 18.8572V23.0002C2.00015 23.2654 2.10551 23.5198 2.29305 23.7073C2.48058 23.8949 2.73494 24.0002 3.00015 24.0002C3.26537 24.0002 3.51972 23.8949 3.70726 23.7073C3.8948 23.5198 4.00015 23.2654 4.00015 23.0002V20.9222C5.65298 22.4108 7.68756 23.4098 9.87605 23.8075C12.0645 24.2051 14.3205 23.9857 16.3914 23.1738C18.4622 22.3618 20.2661 20.9895 21.6011 19.2103C22.9361 17.4311 23.7495 15.3155 23.9502 13.1002C23.9631 12.9609 23.9468 12.8205 23.9025 12.6879C23.8581 12.5552 23.7867 12.4333 23.6926 12.3298C23.5985 12.2263 23.4839 12.1436 23.3561 12.0868C23.2283 12.0301 23.09 12.0006 22.9502 12.0002H22.9512Z" fill="#374957" />
      </g>
      <defs>
        <clipPath id="clip0_403_2901">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
