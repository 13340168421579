import classnames from 'classnames';
import styles from './MailboxSelector.module.scss';
import { Avatar } from 'components_old';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAdvisorsMailbox, selectActiveMailbox, selectAdvisorMailboxes, updateActiveAdvisorMailbox } from 'store/users';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routing/constants';


export const MailboxSelector = () => {
  const dispatch = useAppDispatch();

  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);
  const activeMailbox = useAppSelector(selectActiveMailbox);

  const onSelectMailbox = (mailbox: IAdvisorsMailbox | null) => {
    dispatch(updateActiveAdvisorMailbox({ payload: mailbox }))
  }

  return (
    <div className={classnames(styles.Wrapper)}>
      <div className={classnames(styles.Title)}>
        Inboxes
      </div>

      {advisorMailboxes?.map(mailbox => {
        return (
          <Link
            key={mailbox?.id}
            className={classnames(styles.Item, { [styles['Item--selected']]: activeMailbox?.id === mailbox.id })}
            onClick={(e) => onSelectMailbox(mailbox)}
            to={ROUTES.PARAPLANNER_MAILBOX_INBOX}
          >
            <Avatar size={30} letter={mailbox.firstName} wrapperClassName={styles.AvatarWrapper} />
            <div className={styles.ItemInfo}>
              <div className={styles['ItemInfo__name']}>{mailbox.firstName} {mailbox.lastName}</div>
              <div className={styles['ItemInfo__email']}>{mailbox.email}</div>
            </div>
          </Link>
        )
      })}
    </div>
  );
};
