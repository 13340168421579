
import styles from './ISDataEntryCongrats.module.scss'
import { useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, ContactUs, ParaplannerAI, StepperTitle } from 'components_old';
import { IStepperComponentProps } from '../../types';


export const ISDataEntryCongrats = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Wrapper}>
      <>
        <StepperTitle>
          <ParaplannerAI />
        </StepperTitle>

        <StepperTitle>
          <div>{t('common.congrats')}</div>
          <div>{t('automations.tiles.investmentStatementDataEntry.congrats.titleA')}</div>
        </StepperTitle>

        <StepperTitle className={styles.ContactUs}>
          <div>{t('common.somethingUrgent')}</div>
          <ContactUs
            email={t('automations.tiles.investmentStatementDataEntry.contact.email')}
            subject={t('automations.tiles.investmentStatementDataEntry.contact.subject')}
            body={t('automations.tiles.investmentStatementDataEntry.contact.body')}
          /> {t('common.forSupport')}
        </StepperTitle>
      </>

      <div className={styles.Buttons}>
        <Button
          look={BUTTON_LOOK.TERNARY}
          onClick={async () => {
            // mixpanel.track(EVENT.ANOTHER_REQUEST);
            handleGoToNextStep({ action: nextStep });
          }}
        >
          {t('dataExtractor.congrats.newRequest')}
        </Button>
      </div>
    </div >
  );
}
