import { Helmet } from 'react-helmet';
import { useTranslation } from 'hooks';
import { FIXED_PANEL_WIDTH } from 'const/misc';
import { Panel, Panels } from 'components_old';
import { ThreadsListHeader } from 'components_old/features/Mailbox/ThreadsListHeader';
import { ThreadDetailsHeader } from 'components_old/features/Mailbox/ThreadDetailsHeader';

import styles from './MailboxPage.module.scss';
import { ThreadDetailsView } from 'components_old/features/Mailbox/ThreadDetailsView';
import { Outlet } from 'react-router-dom';

export const ParaplannerMailbox = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} - {t('common.appCredo')}
        </title>
      </Helmet>
      <Panels>
        <Panel header={<ThreadsListHeader />} body={<Outlet />} width={FIXED_PANEL_WIDTH} />
        <Panel header={<ThreadDetailsHeader />} body={<ThreadDetailsView />} bodyClassName={styles.ThreadDetailsBody} />
      </Panels>
    </>
  );
};
