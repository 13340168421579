import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getCredentialsState = (state: RootState) => state.credentials;

export const selectCredentialsProcessing = createSelector(
  [getCredentialsState],
  ({ processing }) => processing
);

export const selectSaveCredentialsResponse = createSelector(
  [getCredentialsState],
  ({ saveCredentialsResponse }) => saveCredentialsResponse
);