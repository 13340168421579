import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserEmail } from "store/authentication";

export interface IUseIsDev {
  isDevEnv: boolean;
  isDenisK: boolean;
}

export const useDevFlag = (): IUseIsDev => {
  const [isDevEnv, setIsDevEnv] = useState(false);
  const [isDenisK, setIsDenisK] = useState(false);

  const userEmail = useSelector(getUserEmail);

  useEffect(() => {
    const url = window.location.href;
    const isDev =
      url.includes("localhost") ||
      url.includes("dev.") ||
      url.includes("ngrok-free.app");

    setIsDevEnv(isDev);
  }, []);

  useEffect(() => {
    setIsDenisK(userEmail === "denis@konoplev.co.uk");
  }, [userEmail]);

  return {
    isDevEnv: false,
    isDenisK,
  };
};
