import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";

export const createInvestmentStatementExtractionWithHumanCheckRequest =
  createAsyncThunk(
    ACTION.AUTOMATION.CREATE_IS_EXTRACTION_HUMAN_CHECK_REQUEST,
    async (payload: FormData, { rejectWithValue }) => {
      try {
        const response =
          await api.automations.createInvestmentStatementExtractionWithHumanCheckRequest(
            payload
          );

        return response;
      } catch (error) {
        const msg = processApiError(error);
        return rejectWithValue(msg);
      }
    }
  );
