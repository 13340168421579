import { Icon, IIconProps } from './Icon';

export const ThreeDots = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_406_113)">
        <path d="M21.517 14.5664C22.8977 14.5664 24.017 13.4471 24.017 12.0664C24.017 10.6857 22.8977 9.56641 21.517 9.56641C20.1363 9.56641 19.017 10.6857 19.017 12.0664C19.017 13.4471 20.1363 14.5664 21.517 14.5664Z" fill="#374957" />
        <path d="M11.9999 14.4997C13.3806 14.4997 14.4999 13.3804 14.4999 11.9997C14.4999 10.619 13.3806 9.49969 11.9999 9.49969C10.6192 9.49969 9.49994 10.619 9.49994 11.9997C9.49994 13.3804 10.6192 14.4997 11.9999 14.4997Z" fill="#374957" />
        <path d="M2.5 14.4997C3.88071 14.4997 5 13.3804 5 11.9997C5 10.619 3.88071 9.49969 2.5 9.49969C1.11929 9.49969 0 10.619 0 11.9997C0 13.3804 1.11929 14.4997 2.5 14.4997Z" fill="#374957" />
      </g>
      <defs>
        <clipPath id="clip0_406_113">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
