import { i18n } from 'i18n';

import { IMenuLink } from 'components_old/layout';
import { ArrowLeft, Envelope, Settings, Document, Compare, ArrowGrow, Form, MagicWand } from 'icons';
import { ROUTES } from 'routing/constants';
import { router } from 'routing/router';
import { RoleEnum } from "const";

export const MENU_ID = {
  USERS: 'users',
  PORTFOLIO_COMPARISON: 'portfolioComparison',
  PORTFOLIO_ANALYSIS: 'portfolioAnalysis',
  CREDENTIALS_MANAGEMENT: 'credentialsManagement',
  SETTINGS: {
    MAIN: 'settings',
    CONNECT: 'settings_connect',
    PORTFOLIO_MODELS_MANAGEMENT: 'settings_portfolioModelsManagement',
  },
  MAILBOX: {
    MAIN: 'mailbox',
    INBOX: 'mailbox_inbox',
    DRAFTS: 'mailbox_drafts',
    ARCHIVED: 'mailbox_archived',
    ALL: 'mailbox_all',
    WITH_CONVERSATION: 'mailbox_with_conversation',
  },
  SECURELY_UPLOAD_FILES: 'securely_upload_files',
  PLANNING_SUBMISSION_FORM: 'planning_submission_form',
  DATA_EXTRACTOR: 'dataExtractor',
  AUTOMATION: 'automation',
  CLIENTS: 'clients',
  START_MEETING: 'startMeeting',
  NEW_LOOK: 'newLook',
}

export const getMenuItems = (role: RoleEnum, pathname: string): IMenuLink[] => {

  const menu: IMenuLink[] = [
    {
      id: MENU_ID.MAILBOX.MAIN,
      label: i18n.t('routes.mailbox.mailbox'),
      to: ROUTES.ADVISOR_MAILBOX,
      icon: <Envelope hasHover={false} size={16} />,
      availableTo: [RoleEnum.Advisor],
      isActive: [ROUTES.ADVISOR_MAILBOX, ROUTES.ADVISOR_MAILBOX_ALL, ROUTES.ADVISOR_MAILBOX_WITH_CONVERSATION].includes(pathname)
    },
    {
      id: MENU_ID.PORTFOLIO_COMPARISON,
      label: i18n.t('routes.portfolioComparison'),
      to: ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
      icon: <Compare size={16} />,
      availableTo: [RoleEnum.Advisor],
      isActive: [ROUTES.ADVISOR, ROUTES.ADVISOR_PORTFOLIO_COMPARISON].includes(pathname)
    },
    {
      id: MENU_ID.PORTFOLIO_ANALYSIS,
      label: i18n.t('routes.portfolioAnalysis'),
      to: ROUTES.ADVISOR_PORTFOLIO_ANALYSIS,
      icon: <Document size={16} />,
      availableTo: [RoleEnum.Advisor],
      isActive: [ROUTES.ADVISOR_PORTFOLIO_ANALYSIS].includes(pathname)
    },
    {
      id: MENU_ID.PLANNING_SUBMISSION_FORM,
      label: i18n.t('routes.planningSubmissionForm'),
      to: ROUTES.PLANNING_SUBMISSION_FORM,
      icon: <Form />,
      availableTo: [RoleEnum.Advisor],
      isActive: [ROUTES.PLANNING_SUBMISSION_FORM].includes(pathname)
    },
    {
      id: MENU_ID.SETTINGS.MAIN,
      label: i18n.t('routes.settings'),
      to: ROUTES.ADVISOR_SETTINGS,
      icon: <Settings />,
      availableTo: [RoleEnum.Advisor],
      isActive: [ROUTES.ADVISOR_SETTINGS, ROUTES.ADVISOR_SETTINGS_CONNECT, ROUTES.ADVISOR_SETTINGS_MODELS].includes(pathname)
    },
    {
      id: MENU_ID.NEW_LOOK,
      label: 'New UI',
      to: ROUTES.APP.DEFAULT,
      icon: <MagicWand />,
      availableTo: [RoleEnum.Advisor],
    },

  ];

  return menu.filter(entry => entry.availableTo?.includes(role));
}


export const goBackMenuLinks: IMenuLink[] = [
  {
    id: 'back',
    label: i18n.t('common.back'),
    onClick: () => router.navigate(-1),
    icon: <ArrowLeft />,
  },
];


interface IGetMailboxMenu {
  pathname: string,
  profileRole: string
}
export const getMailboxMenu = ({ pathname, profileRole }: IGetMailboxMenu): IMenuLink[] => {
  const menuParaplanner: IMenuLink[] = [
    {
      id: MENU_ID.MAILBOX.INBOX,
      label: i18n.t('routes.mailbox.inbox'),
      to: ROUTES.PARAPLANNER_MAILBOX_INBOX,
      isActive: [ROUTES.PARAPLANNER_MAILBOX, ROUTES.PARAPLANNER_MAILBOX_INBOX].includes(pathname)
    },
    {
      id: MENU_ID.MAILBOX.DRAFTS,
      label: i18n.t('routes.mailbox.drafts'),
      to: ROUTES.PARAPLANNER_MAILBOX_DRAFTS,
      isActive: [ROUTES.PARAPLANNER_MAILBOX_DRAFTS].includes(pathname)
    },
    {
      id: MENU_ID.MAILBOX.ARCHIVED,
      label: i18n.t('routes.mailbox.archived'),
      to: ROUTES.PARAPLANNER_MAILBOX_ARCHIVED,
      isActive: [ROUTES.PARAPLANNER_MAILBOX_ARCHIVED].includes(pathname)
    },
  ];

  const menuAdvisor: IMenuLink[] = [
    {
      id: MENU_ID.MAILBOX.ALL,
      label: i18n.t('routes.mailbox.all'),
      to: ROUTES.ADVISOR_MAILBOX_ALL,
      isActive: [ROUTES.ADVISOR_MAILBOX, ROUTES.ADVISOR_MAILBOX_ALL].includes(pathname)
    },
  ];

  return profileRole === RoleEnum.Advisor ? menuAdvisor : menuParaplanner;
}

export const getSettingsMenu = (pathname: string): IMenuLink[] => {
  const menu: IMenuLink[] = [
    {
      id: MENU_ID.SETTINGS.PORTFOLIO_MODELS_MANAGEMENT,
      icon: <ArrowGrow hasHover={false} size={16} />,
      label: i18n.t('routes.portfolioModels'),
      isActive: [ROUTES.ADVISOR_SETTINGS_MODELS, ROUTES.ADVISOR_SETTINGS].includes(pathname),
      to: ROUTES.ADVISOR_SETTINGS_MODELS
    },
    {
      id: MENU_ID.SETTINGS.CONNECT,
      icon: <Envelope hasHover={false} size={16} />,
      label: i18n.t('routes.connectEmail'),
      isActive: [ROUTES.ADVISOR_SETTINGS_CONNECT].includes(pathname),
      to: ROUTES.ADVISOR_SETTINGS_CONNECT
    },
  ];

  return menu;
}
