import { Mail } from "./components/mail";
import { accounts, mails } from "./data";

export default function MailPage() {

  return (
    <div className="hidden flex-col md:flex">
      <Mail
        accounts={accounts}
        mails={mails}
        navCollapsedSize={4}
        defaultLayout={[265, 440, 655]}
      />
    </div>
  )
}
