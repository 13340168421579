import { useTranslation, useDynamicChecklist } from "hooks";
import { cn } from "lib/utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { Card, CardDescription, CardHeader, CardTitle } from "components/ui/card";
import { ItemSelectedIcon } from "components/common/_atoms/ItemSelectedIcon";

interface IProps {
  botId: string,
}

export const DynamicChecklist = ({ botId }: IProps) => {
  const { t } = useTranslation();

  const { items: dataPoints, itemIdsInPendingState: dataPointIdsInPendingState } = useDynamicChecklist({ botId, type: 'dataPoints' });
  const { items: questions, itemIdsInPendingState: questionsInPendingState } = useDynamicChecklist({ botId, type: 'questions' });

  const inProgressIndicator = (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="absolute top-1/2 transform -translate-y-1/2 right-1 flex size-3">
          <span className="animate-ping duration-2000 absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
          <span className="relative inline-flex rounded-full size-3 bg-primary"></span>
        </span>
      </TooltipTrigger>
      <TooltipContent>
        {t('component.DynamicChecklist.captureInProgress')}
      </TooltipContent>
    </Tooltip>
  )

  return (
    <div className="flex flex-col gap-3">
      {
        [...questions, ...dataPoints]?.map(item => (
          <Card
            key={item.id}
            variant="item"
            className={cn({ 'animate-item-completed': item.value })}
          >
            <CardHeader className="p-3 pr-4">
              <CardDescription>{item.text}</CardDescription>
              {item?.value && <CardTitle className="text-sm">{item.value}</CardTitle>}
            </CardHeader>

            {item?.value && (
              <ItemSelectedIcon />
            )}

            {!item?.value && [...dataPointIdsInPendingState, ...questionsInPendingState]?.includes(item?.id) && (
              inProgressIndicator
            )}
          </Card>
        ))
      }
    </div>
  )
}
