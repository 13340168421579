interface IProps {
  className?: string
}
export const SignOut = ({ className }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_636_212)">
        <path
          d="M12.6 -0.000352796H3.4C2.50735 -0.00835197 1.648 0.338145 1.01056 0.963086C0.373121 1.58803 0.00967683 2.44035 0 3.33298L0 5.33298C0 5.50979 0.0702379 5.67936 0.195262 5.80439C0.320286 5.92941 0.489856 5.99965 0.666667 5.99965C0.843478 5.99965 1.01305 5.92941 1.13807 5.80439C1.2631 5.67936 1.33333 5.50979 1.33333 5.33298V3.33298C1.3428 2.79391 1.56571 2.28061 1.95314 1.90567C2.34058 1.53073 2.86091 1.32478 3.4 1.33298H12.6C13.1391 1.32478 13.6594 1.53073 14.0469 1.90567C14.4343 2.28061 14.6572 2.79391 14.6667 3.33298V12.6663C14.6572 13.2054 14.4343 13.7187 14.0469 14.0936C13.6594 14.4686 13.1391 14.6745 12.6 14.6663H3.4C2.86091 14.6745 2.34058 14.4686 1.95314 14.0936C1.56571 13.7187 1.3428 13.2054 1.33333 12.6663V10.6663C1.33333 10.4895 1.2631 10.3199 1.13807 10.1949C1.01305 10.0699 0.843478 9.99965 0.666667 9.99965C0.489856 9.99965 0.320286 10.0699 0.195262 10.1949C0.0702379 10.3199 0 10.4895 0 10.6663L0 12.6663C0.00967683 13.5589 0.373121 14.4113 1.01056 15.0362C1.648 15.6612 2.50735 16.0076 3.4 15.9996H12.6C13.4926 16.0076 14.352 15.6612 14.9894 15.0362C15.6269 14.4113 15.9903 13.5589 16 12.6663V3.33298C15.9903 2.44035 15.6269 1.58803 14.9894 0.963086C14.352 0.338145 13.4926 -0.00835197 12.6 -0.000352796Z"
          fill="#696969"
        />
        <path
          d="M2 7.99929C2 8.17611 2.07024 8.34567 2.19526 8.4707C2.32029 8.59572 2.48986 8.66596 2.66667 8.66596L11.4587 8.64596L8.57667 11.528C8.51299 11.5895 8.46221 11.663 8.42727 11.7444C8.39233 11.8257 8.37394 11.9132 8.37317 12.0017C8.3724 12.0902 8.38927 12.178 8.42279 12.2599C8.45631 12.3419 8.50581 12.4163 8.5684 12.4789C8.631 12.5415 8.70543 12.591 8.78736 12.6245C8.86929 12.658 8.95708 12.6749 9.0456 12.6741C9.13412 12.6734 9.2216 12.655 9.30294 12.62C9.38427 12.5851 9.45784 12.5343 9.51933 12.4706L12.5767 9.41329C12.9516 9.03824 13.1622 8.52962 13.1622 7.99929C13.1622 7.46897 12.9516 6.96035 12.5767 6.58529L9.52067 3.52796C9.39493 3.40652 9.22653 3.33933 9.05173 3.34085C8.87693 3.34236 8.70973 3.41248 8.58612 3.53608C8.46252 3.65969 8.3924 3.8269 8.39088 4.00169C8.38937 4.17649 8.45656 4.34489 8.578 4.47063L11.4193 7.31263L2.66667 7.33263C2.48986 7.33263 2.32029 7.40287 2.19526 7.52789C2.07024 7.65291 2 7.82248 2 7.99929Z"
          fill="#696969"
        />
      </g>
      <defs>
        <clipPath id="clip0_636_212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
