import { Icon, IIconProps } from './Icon';


export const Archive = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_1729_164)">
        <path d="M6.58565 12.0813C6.77138 12.2672 6.99192 12.4146 7.23465 12.5152C7.47738 12.6158 7.73756 12.6676 8.00031 12.6676C8.26306 12.6676 8.52324 12.6158 8.76598 12.5152C9.00871 12.4146 9.22925 12.2672 9.41498 12.0813L11.5556 9.94067C11.6704 9.81373 11.732 9.64752 11.7276 9.47644C11.7232 9.30536 11.6532 9.14253 11.532 9.02165C11.4109 8.90077 11.2479 8.8311 11.0768 8.82707C10.9058 8.82304 10.7397 8.88496 10.613 9L8.66231 10.9513L8.66698 0.666667C8.66698 0.489856 8.59674 0.320286 8.47172 0.195262C8.34669 0.0702379 8.17712 0 8.00031 0V0C7.8235 0 7.65393 0.0702379 7.52891 0.195262C7.40388 0.320286 7.33365 0.489856 7.33365 0.666667L7.32765 10.9387L5.38765 9C5.26255 8.875 5.09292 8.8048 4.91608 8.80486C4.73923 8.80493 4.56965 8.87524 4.44465 9.00033C4.31964 9.12543 4.24945 9.29506 4.24951 9.4719C4.24957 9.64875 4.31989 9.81833 4.44498 9.94333L6.58565 12.0813Z" fill="#660AD6" />
        <path d="M15.3333 10.667C15.1565 10.667 14.987 10.7372 14.8619 10.8623C14.7369 10.9873 14.6667 11.1568 14.6667 11.3337V14.0003C14.6667 14.1771 14.5964 14.3467 14.4714 14.4717C14.3464 14.5967 14.1768 14.667 14 14.667H2C1.82319 14.667 1.65362 14.5967 1.5286 14.4717C1.40357 14.3467 1.33333 14.1771 1.33333 14.0003V11.3337C1.33333 11.1568 1.2631 10.9873 1.13807 10.8623C1.01305 10.7372 0.843478 10.667 0.666667 10.667C0.489856 10.667 0.320286 10.7372 0.195262 10.8623C0.0702379 10.9873 0 11.1568 0 11.3337L0 14.0003C0 14.5308 0.210714 15.0395 0.585786 15.4145C0.960859 15.7896 1.46957 16.0003 2 16.0003H14C14.5304 16.0003 15.0391 15.7896 15.4142 15.4145C15.7893 15.0395 16 14.5308 16 14.0003V11.3337C16 11.1568 15.9298 10.9873 15.8047 10.8623C15.6797 10.7372 15.5101 10.667 15.3333 10.667Z" fill="#660AD6" />
      </g>
      <defs>
        <clipPath id="clip0_1729_164">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
