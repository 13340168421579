export const API_URL = process.env.REACT_APP_API_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;
export const CONTACT_US_LINK =
  process.env.REACT_APP_CONTACT_US_LINK || 'https://www.paraplanner.ai/';

export const NYLAS_SUCCESS_URL = process.env.REACT_APP_NYLAS_SUCCESS_URL || '';
export const CALENDAR_CONNECT_SUCCESS_URL = process.env.REACT_APP_CALENDAR_CONNECT_SUCCESS_URL || '';
export const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY || '';
export const INTERCOM_APP = process.env.REACT_APP_INTERCOM_APP || '';
export const GDRIVE_PERMISSION_EMAIL = process.env.REACT_APP_GDRIVE_PERMISSION_EMAIL || '';
