import styles from './ConversationInfoLayout.module.scss';
import { ReactNode } from 'react';

interface IProps {
  label: ReactNode | string;
  content: ReactNode | string,
}

export const ConversationInfoLayout = ({
  label,
  content
}: IProps) => {
  return (
    <div className={styles.Container}>
      <label className={styles.Label}>
        {label}
      </label>
      <div className={styles.Content}>
        {content}
      </div>
    </div>
  );
}
