// export enum RoleEnum {
//   Admin = 'admin',
//   Advisor = 'advisor',
//   Paraplanner = 'paraplanner'
// }

import { RoleEnum } from "const";

export enum SignOutReason {
  USER_ACTION = "userAction",
  SESSION_EXPIRED = "sessionExpired"
}

export interface ICurrentUser {
  email: string;
  id: string;
  role: RoleEnum | null;
  muninai?: boolean;
}

export interface IAuthenticationState {
  user: ICurrentUser;
  accessToken: string;
  refreshToken: string;
  loading: boolean;
}

export interface IAccessTokenPayload {
  email: string;
  sub: string;
  role: RoleEnum;
}

export interface ILoginSuccessPayload {
  user: ICurrentUser;
  accessToken: string;
  refreshToken: string;
}

export interface IRefreshSuccessPayload {
  accessToken: string;
  refreshToken: string;
}

export interface ISignUpPayload {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  linkedin?: string;
  passwordConfirmation: string;
  source: string;
  is_terms_accepted: boolean;
}

export interface ISignUpResponse {
  accessToken: string;
  refreshToken: string;
}

export interface ISignOutPayload {
  reason: SignOutReason;
}
