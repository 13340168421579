import { Info } from 'lucide-react';
import { Card, CardHeader, cardVariants } from 'components/ui/card';
import { CardDescription, CardTitle } from 'components/ui/card';
import { type VariantProps } from "class-variance-authority"
import { cn } from 'lib/utils';

export interface IProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof cardVariants> {
  title: string,
  description?: string | undefined,
  headerClassName?: string
}

export const InfoCard = ({ title, description, variant, className, headerClassName }: IProps) => {
  return (
    <Card className={cn('w-full', className)} variant={variant}  >
      <CardHeader className={cn('flex flex-row gap-3 items-center p-6 space-y-0', headerClassName)}>
        <Info className='inline size-4 shrink-0' />

        <span className='flex flex-col gap-0.5'>
          <CardTitle className='flex items-center gap-3 text-sm'>
            {title}
          </CardTitle>
          {description && (
            <CardDescription className="">
              {description}
            </CardDescription>
          )}
        </span>
      </CardHeader>
    </Card>
  )
}