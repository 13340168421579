import { useAppSelector } from 'hooks';
import { ThreadWithMessagesDetails } from '../ThreadWithMessagesDetails';
import { selectSelectedThread } from 'store/mailbox';
import { ThreadWithDraftCompose } from '../ThreadWithDraftCompose';
import { Feedback } from 'components_old';

export const ThreadDetailsView = () => {
  const activeThread = useAppSelector(selectSelectedThread);
  const messages = activeThread?.messages || [];
  const drafts = activeThread?.drafts || [];

  if (messages?.length > 0) return <ThreadWithMessagesDetails />

  if (drafts?.length > 0) return <ThreadWithDraftCompose />

  return (<div style={{marginTop: 20}}>
    <Feedback>Select a thread to view its content</Feedback>
  </div>);
}