import { useEffect, useState } from 'react';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { useParams } from 'react-router-dom';
import { ClientDataRecordType, IClientDataRecord, selectClientDataRecordsList } from 'store/clientManagement';
import { v4 as uuidv4 } from 'uuid';
import { MeetingBreakDown, MeetingBreakDownContext } from 'components/common/app/_general/MeetingBreakDown';
import { useSelector } from 'react-redux';

export const MeetingDataRecordPage = () => {
  const { id } = useParams();

  const [dataRecord, setDataRecord] = useState<IClientDataRecord>();
  const clientDataRecordsList = useSelector(selectClientDataRecordsList);


  useEffect(() => {
    const theDataRecord = clientDataRecordsList?.find(record => record.recallBotId === id)

    setDataRecord(
      theDataRecord || {
        recallBotId: id || '',
        clientId: '',
        id: uuidv4(),
        type: ClientDataRecordType.CLIENT_MEETING
      })
  }, [id, clientDataRecordsList]);

  return (
    <GeneralLayout isOneScreenOnly={true}>
      <MeetingBreakDown context={MeetingBreakDownContext.DATA_RECORD} dataRecord={dataRecord} />
    </GeneralLayout>
  );
};

