import { useTranslation } from 'hooks';
import { StepperBox, StepperTitle } from 'components_old';

interface IProps<T> {
  handleGoToNextStep: (arg0: T) => void;
  nextSteps: T[],
}

export function HowToAddModel<T>({ handleGoToNextStep, nextSteps }: IProps<T>) {
  const { t } = useTranslation();

  return (
    <>
      <StepperTitle>
        <div>{t('portfolioModels.howToAddModel.titleA')}</div>
        <div>{t('portfolioModels.howToAddModel.titleB')}</div>
      </StepperTitle>
      <StepperBox
        title={t('portfolioModels.howToAddModel.builder.title')}
        content={t('portfolioModels.howToAddModel.builder.content')}
        onClick={() => {
          // mixpanel.track(EVENT.PORTFOLIO_MODELS.ADD_MODEL_VIA_BUILDER);
          handleGoToNextStep(nextSteps[0])
        }}
      />
      <StepperBox
        title={t('portfolioModels.howToAddModel.upload.title')}
        content={t('portfolioModels.howToAddModel.upload.content')}
        onClick={() => {
          // mixpanel.track(EVENT.PORTFOLIO_MODELS.ADD_MODEL_VIA_UPLOAD);
          handleGoToNextStep(nextSteps[1])
        }}
      />
    </>
  );
}
