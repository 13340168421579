import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { ITemplatesManagementApi } from "./types";

export const templatesManagement: ITemplatesManagementApi = {
  getTemplates: (_) =>
    http
      .get(API_ROUTES.templatesManagement.getTemplates)
      .then((response) => response.data),
  getAllTemplates: (_) =>
    http
      .get(API_ROUTES.templatesManagement.getAllTemplates)
      .then((response) => response.data),
  createPersonalTemplate: (payload) =>
    http
      .post(API_ROUTES.templatesManagement.createPersonalTemplate, payload)
      .then((response) => response.data),
  updatePersonalTemplate: (payload) =>
    http
      .put(
        API_ROUTES.templatesManagement.updatePersonalTemplate(payload.id),
        payload
      )
      .then((response) => response.data),
  deletePersonalTemplate: (payload) =>
    http
      .delete(API_ROUTES.templatesManagement.deletePersonalTemplate(payload.id))
      .then((response) => response.data),
  createOrgTemplate: (payload) =>
    http
      .post(API_ROUTES.templatesManagement.createOrgTemplate, payload)
      .then((response) => response.data),
  updateOrgTemplate: (payload) =>
    http
      .put(
        API_ROUTES.templatesManagement.updateOrgTemplate(payload.id),
        payload
      )
      .then((response) => response.data),
  deleteOrgTemplate: (payload) =>
    http
      .delete(API_ROUTES.templatesManagement.deleteOrgTemplate(payload.id))
      .then((response) => response.data),
  updateTemplatePrompt: (payload) =>
    http
      .put(
        API_ROUTES.templatesManagement.updateTemplatePrompt(payload.id),
        payload
      )
      .then((response) => response.data),
};
