import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useMixpanel, useTranslation } from 'hooks';
import { Button } from 'components/ui/button';
import { CALENDAR_CONNECT_SUCCESS_URL, MIXPANEL } from 'const';
import { Label } from 'components/ui/label';
import { clearNylasAuthUrlValue, deleteCalendarAccount, generateCalendarAuthUrl, selectCalendarAuthUrl, selectIsNylasProcessing } from 'store/nylas';
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import { toast } from 'react-toastify';

export const ConnectCalendar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isCalendarRequestProcessing = useAppSelector(selectIsNylasProcessing);
  const profileDetails = useAppSelector(selectProfileDetails);
  const calendarAuthUrl = useAppSelector(selectCalendarAuthUrl)

  const [hasConnectedCalendar, setHasConnectedCalendar] = useState(false)
  const [calendarDisconnectCallInit, setCalendarDisconnectCallInit] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    return () => {
      setIsRedirecting(false)
    }
  }, [])

  useEffect(() => {
    if (!isCalendarRequestProcessing && calendarDisconnectCallInit) {
      dispatch(getProfileDetails())
      setCalendarDisconnectCallInit(false);
    }
  }, [isCalendarRequestProcessing, calendarDisconnectCallInit, dispatch])

  useEffect(() => {
    const isCalendarConnected = !!profileDetails?.googleCalendar || !!profileDetails?.microsoftCalendar
    setHasConnectedCalendar(isCalendarConnected)
  }, [profileDetails])

  useEffect(() => {
    if (calendarAuthUrl) {
      const redirectUrl = calendarAuthUrl;
      setIsRedirecting(true)
      dispatch(clearNylasAuthUrlValue());
      window.location.href = redirectUrl;
    }
  }, [calendarAuthUrl, dispatch])


  const handleCalendarDisconnect = async () => {
    setCalendarDisconnectCallInit(true);
    const response = await dispatch(deleteCalendarAccount())

    if (!response?.hasOwnProperty('error')) {
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.DISCONNECT_CALENDAR });
      toast(t('component.ConnectCalendar.deleteSuccess'))
    }
  }

  const handleCalendarConnect = () => {
    trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.CONNECT_CALENDAR });
    dispatch(generateCalendarAuthUrl({
      successUrl: CALENDAR_CONNECT_SUCCESS_URL
    }));
  }

  return (
    <div className="flex flex-row items-center justify-between gap-6">
      <div className="space-y-1.5">
        <Label>
          {t('component.ConnectCalendar.title')}
        </Label>
        <Label variant="description">
          {!hasConnectedCalendar ? (
            t('component.ConnectCalendar.descriptionConnect')
          ) : (
            t('component.ConnectCalendar.descriptionDisconnect')
          )}
        </Label>
      </div>

      {!hasConnectedCalendar ? (
        <Button
          type='button'
          onClick={handleCalendarConnect}
          loading={isCalendarRequestProcessing || isRedirecting}
        >
          {t('general.connect')}
        </Button>
      ) : (
        <Button
          type='button'
          variant="destructive"
          onClick={handleCalendarDisconnect}
          loading={isCalendarRequestProcessing}
        >
          {t('general.disconnect')}
        </Button>
      )}
    </div>
  );
};
