import React, { useState, useEffect } from 'react';
import styles from './ThreadPreview.module.scss';
import classnames from 'classnames';
import { Archive, BackToInbox, BrainAvatar, Comment, ThreeDots } from 'icons';
import { ConversationInfo } from 'components_old';
import { MAILBOX_TYPE, RoleEnum, THREAD_LABEL, TOOLTIP_ID } from 'const';
import { useAppDispatch, useTranslation } from 'hooks';
import { IEmailThread, mailboxActions, selectExecutingActionOnThreadId } from 'store/mailbox';
import { useSelector } from 'react-redux';

export interface IThreadPreviewProps {
  thread: IEmailThread;
  isActive: boolean;
  type: MAILBOX_TYPE;
  profileRole: RoleEnum;
};

export const ThreadPreview = ({ thread, isActive, type, profileRole }: IThreadPreviewProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const executingActionOnThreadId = useSelector(selectExecutingActionOnThreadId);

  const [hasDrafts, setHasDrafts] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);

  useEffect(() => {
    if (thread?.messages?.length) {
      setHasDrafts(thread?.drafts?.length > 0);
    }
  }, [thread]);

  const handleArchive = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event?.stopPropagation();
    await dispatch(
      mailboxActions.updateThreadLabel({ threadId: thread.thread_id, label: THREAD_LABEL.SKIPPED })
    );
  }

  const handleBackToInbox = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event?.stopPropagation();
    await dispatch(
      mailboxActions.deleteThreadLabel({ threadId: thread.thread_id, label: THREAD_LABEL.SKIPPED })
    );
  }

  return (
    <div className={classnames(
      styles.Wrapper,
      { [styles['Wrapper--active']]: isActive }
    )}>
      <div className={styles.Info}>
        {/* {<BrainAvatar size={12} hasHover={false} />} */}
        {/* <div className={styles.Circle}></div>
        <div className={classnames(styles.Circle,styles.CircleActive)}></div> */}
      </div>

      <ConversationInfo thread={thread} className={styles.Details} />

      <div
        className={styles.Info}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        {profileRole !== RoleEnum.Advisor && type !== MAILBOX_TYPE.DRAFTS && (
          <>
            <ThreeDots size={12} />
            {showActions && (
              <div className={styles.DrawerMenuWrapper}>
                <div className={styles.DrawerMenu}>
                  {type === MAILBOX_TYPE.INBOX && (
                    <div
                      className={styles.DrawerItem}
                      data-tooltip-id={TOOLTIP_ID}
                      data-tooltip-content='Archive'
                    >
                      <Archive
                        size={16}
                        isLoading={executingActionOnThreadId === thread?.thread_id}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleArchive(e)}
                      />
                    </div>
                  )}
                  {type === MAILBOX_TYPE.ARCHIVED && (
                    <div
                      className={styles.DrawerItem}
                      data-tooltip-id={TOOLTIP_ID}
                      data-tooltip-content={t('common.backToInbox')}
                    >
                      <BackToInbox
                        size={16}
                        isLoading={executingActionOnThreadId === thread?.thread_id}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleBackToInbox(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {profileRole === RoleEnum.Advisor && (
          <>
            {thread.has_ai_chat && !thread.has_ai_chat_with_unread && (
              <div data-tooltip-id={TOOLTIP_ID} data-tooltip-content={t('common.aiConversation')}>
                <div className={styles.Conversation} />
              </div>
            )}
            {thread.has_ai_chat_with_unread && (
              <div data-tooltip-id={TOOLTIP_ID} data-tooltip-content={t('common.aiConversationWithMessages')}>
                <div className={classnames(styles.Conversation, styles.ConversationWithMessages)} />
              </div>
            )}
          </>
        )}
      </div>
    </div >
  );
}



