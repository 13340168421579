import classnames from 'classnames';
import styles from './StepperTitle.module.scss';
import { ReactNode } from 'react';

interface IPageProps {
  children?: ReactNode | undefined;
  className?: string;
}

export const StepperTitle = ({ children, className }: IPageProps) => {
  return <div className={classnames(styles.Title, className)}>{children}</div>
};
