import { useAppDispatch, useTranslation } from 'hooks';
import { FileUploader, StepperTitle } from 'components_old';
import { IFileWithMeta } from 'react-dropzone-uploader'
import { IStepperComponentProps } from '../../types';
import { createInvestmentStatementExtractionWithHumanCheckRequest, selectAutomationsIsProcessing, selectCreateInvestmentStatementExtractionWithHumanCheckRequestSuccess } from 'store/automations';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';


export const ISDEIEHCDataExtractorUploader = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isRequestSuccessful = useSelector(selectCreateInvestmentStatementExtractionWithHumanCheckRequestSuccess);
  const isProcessing = useSelector(selectAutomationsIsProcessing);
  const [apiCalled, setApiCalled] = useState(false)

  useEffect(() => {
    if (apiCalled && isRequestSuccessful) {
      handleGoToNextStep({ action: nextStep })
    }
  }, [apiCalled, isRequestSuccessful, handleGoToNextStep, nextStep])

  const doCreateDataExtractorRequest = async (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const formData = new FormData();
    formData.append('file', allFiles[0].file);

    dispatch(createInvestmentStatementExtractionWithHumanCheckRequest(formData));
    setApiCalled(true);
  }

  return (
    <>
      <StepperTitle>
        <div>{t('dataExtractor.uploader.titleA')}</div>
        <div>{t('dataExtractor.uploader.titleB')}</div>
      </StepperTitle>

      <FileUploader
        submitButtonContent={t('dataExtractor.uploader.extractData')}
        onSubmit={doCreateDataExtractorRequest}
        maxFiles={1}
        multiple={false}
        isLoading={isProcessing}
      />
    </>
  );
}

