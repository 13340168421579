import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getPortfolioModelState = (state: RootState) => state.portfolioModel;

export const selectGettingModels = createSelector(
  [getPortfolioModelState],
  ({ isGettingModels }) => isGettingModels
);

export const selectIsCreatingModels = createSelector(
  [getPortfolioModelState],
  ({ isCreatingModels }) => isCreatingModels
);

export const selectIsUpdating = createSelector(
  [getPortfolioModelState],
  ({ isUpdatingModels: isUpdating }) => isUpdating
);

export const selectIsUploadingModels = createSelector(
  [getPortfolioModelState],
  ({ isUploadingModels }) => isUploadingModels
);

export const selectPortfolioModels = createSelector(
  [getPortfolioModelState],
  ({ portfolioModels }) => portfolioModels
);

export const selectIsUploadingComparisonFiles = createSelector(
  [getPortfolioModelState],
  ({ isUploadingComparisonFiles }) => isUploadingComparisonFiles
);

export const selectComparisonRequestPayload = createSelector(
  [getPortfolioModelState],
  ({ comparisonRequestPayload }) => comparisonRequestPayload
);

export const selectIsCreatingRequest = createSelector(
  [getPortfolioModelState],
  ({ isCreatingRequest }) => isCreatingRequest
);

export const selectCreatingRequestSuccess = createSelector(
  [getPortfolioModelState],
  ({ creatingRequestSuccess }) => creatingRequestSuccess
);

