import { Icon, IIconProps } from './Icon';

export const Clip = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_383_1405)">
        <path
          d="M15.2995 6.39996C15.1745 6.27498 15.005 6.20477 14.8282 6.20477C14.6514 6.20477 14.4819 6.27498 14.3569 6.39996L7.09553 13.6926C6.786 14.0022 6.41853 14.2478 6.0141 14.4153C5.60967 14.5829 5.1762 14.6691 4.73843 14.6692C3.85432 14.6692 3.0064 14.3181 2.38119 13.693C1.75599 13.0678 1.40472 12.22 1.40466 11.3359C1.40459 10.4518 1.75575 9.60383 2.38086 8.97863L9.41353 1.91729C9.78959 1.54722 10.2966 1.34074 10.8243 1.34283C11.3519 1.34491 11.8573 1.5554 12.2304 1.92843C12.6035 2.30147 12.8141 2.80683 12.8163 3.33443C12.8185 3.86204 12.6122 4.36915 12.2422 4.74529L5.20953 11.8066C5.08269 11.9281 4.91384 11.996 4.73819 11.996C4.56255 11.996 4.39369 11.9281 4.26686 11.8066C4.14188 11.6816 4.07167 11.5121 4.07167 11.3353C4.07167 11.1585 4.14188 10.989 4.26686 10.864L10.5282 4.57396C10.6496 4.44823 10.7168 4.27982 10.7153 4.10503C10.7138 3.93023 10.6437 3.76302 10.5201 3.63942C10.3965 3.51581 10.2293 3.4457 10.0545 3.44418C9.87966 3.44266 9.71126 3.50986 9.58553 3.63129L3.32419 9.92129C3.13844 10.107 2.99109 10.3275 2.89056 10.5702C2.79003 10.8129 2.73829 11.073 2.73829 11.3356C2.73829 11.5983 2.79003 11.8584 2.89056 12.1011C2.99109 12.3437 3.13844 12.5642 3.32419 12.75C3.70527 13.1136 4.21178 13.3165 4.73853 13.3165C5.26527 13.3165 5.77178 13.1136 6.15286 12.75L13.1849 5.68796C13.7969 5.06016 14.1369 4.21653 14.1313 3.33981C14.1256 2.46309 13.7748 1.62388 13.1548 1.00398C12.5349 0.384072 11.6956 0.0333909 10.8189 0.0278975C9.94216 0.0224042 9.09857 0.362542 8.47086 0.974627L1.43819 8.03596C0.562979 8.91117 0.071289 10.0982 0.0712891 11.336C0.0712891 12.5737 0.562979 13.7607 1.43819 14.636C2.31341 15.5112 3.50045 16.0029 4.73819 16.0029C5.97593 16.0029 7.16298 15.5112 8.03819 14.636L15.2995 7.34529C15.3618 7.28334 15.4112 7.20969 15.445 7.12858C15.4787 7.04746 15.4961 6.96048 15.4961 6.87263C15.4961 6.78478 15.4787 6.6978 15.445 6.61668C15.4112 6.53556 15.3618 6.46191 15.2995 6.39996Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_383_1405">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

