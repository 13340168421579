import { Card, CardContent } from "components/ui/card"
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "components/ui/carousel";
import { useAppDispatch } from "hooks";
import { cn } from "lib/utils";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getHeadsUpDisplay, selectHeadsUpDisplayItems, selectHeadsUpDisplayItemsCount } from "store/chat-assistant";

interface IProps {
  botId: string
}

export const HeadsUpDisplay = ({ botId }: IProps) => {
  const dispatch = useAppDispatch();
  const headsUpDisplayItems = useSelector(selectHeadsUpDisplayItems);
  const headsUpDisplayItemsCount = useSelector(selectHeadsUpDisplayItemsCount);
  const [api, setApi] = useState<CarouselApi>()


  useEffect(() => {
    if (!botId) return;

    const intervalLogic = setInterval(() => {
      dispatch(getHeadsUpDisplay(botId))
    }, 5000);

    return () => {
      clearInterval(intervalLogic);
    };
  }, [botId, dispatch]);

  useEffect(() => {
    if (api && headsUpDisplayItemsCount) {
      setTimeout(() => {
        api.scrollTo(headsUpDisplayItemsCount);
      }, 1000);
    }
  }, [headsUpDisplayItemsCount, api])

  if ((headsUpDisplayItems || []).length === 0) return <Fragment></Fragment>

  return (
    <Carousel
      setApi={setApi}
      opts={{ align: "start" }}
      className="mx-12"
    >
      <CarouselContent className="">
        {headsUpDisplayItems?.map((item, index) => (
          <CarouselItem key={item.id} className="basis-full md:basis-1/2 lg:basis-1/3">
            <Card className={cn("shadow-none border", { 'delay-1000 animate-item-completed': index === headsUpDisplayItems?.length - 1 })}>
              <CardContent className="flex p-3">
                <div className="h-[60px] overflow-auto px-0.5 text-sm">
                  <p className='font-medium'>
                    {item.title}
                  </p>
                  <p className='text-muted-foreground'>
                    {item.description}
                  </p>
                </div>
              </CardContent>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  )
}
