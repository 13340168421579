import MailPage from 'components/common/app/inbox/page';
import { GeneralLayout } from 'components/layout/GeneralLayout';

export const InboxPage = () => {

  return (
    <GeneralLayout isOneScreenOnly={true} contentClassName="!p-0">
      <div className="flex flex-1 overflow-auto gap-6">
        <MailPage />
      </div>
    </GeneralLayout >
  );
};