import { Column, ColumnDef } from "@tanstack/react-table"
import { Button } from "components/ui/button"
import { t } from "i18next"
import { ChevronDownIcon, ChevronsUpDownIcon, ChevronUpIcon } from "lucide-react"
import { IAdminMeetingTemplate, setActiveAdminTemplate } from "store/templates-management"
import { useAppDispatch } from "hooks"


export const columns: ColumnDef<IAdminMeetingTemplate>[] = [
  {
    accessorKey: "name",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.templateName')}
      />
  },
  {
    accessorKey: "promptsLeftToFill",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.promptsLeftToFill')}
      />,
    cell: ({ row }) => <div className="text-center">{row.getValue("promptsLeftToFill")}</div>
  },
  {
    accessorKey: "organizationName",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.organizationName')}
      />
  },
  {
    accessorKey: "userEmail",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.userEmail')}
      />
  },
  {
    accessorKey: "templateType",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.templateType')}
      />
  },
  {
    accessorKey: "totalNoQuestions",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.totalNoQuestions')}
      />,
    cell: ({ row }) => <div className="text-center">{row.getValue("totalNoQuestions")}</div>
  },
  {
    accessorKey: "totalNoPrompts",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.totalNoPrompts')}
      />,
    cell: ({ row }) => <div className="text-center">{row.getValue("totalNoPrompts")}</div>
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.createdAt')}
      />,
    cell: ({ row }) => {
      const dateString = row.getValue("createdAt") as string;
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split('.')[0].replace('T', ' ');
      return <div>{formattedDate}</div>
    }
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) =>
      <HeaderSortable
        column={column}
        label={t('component.AdminTemplatesManagement.column.updatedAt')}
      />,
    cell: ({ row }) => {
      const dateString = row.getValue("updatedAt") as string;
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split('.')[0].replace('T', ' ');
      return <div>{formattedDate}</div>
    }
  },
  {
    id: "actions",
    cell: ({ row }) => <EditButton template={row.original} />,

  },
]

const EditButton = ({ template }: { template: IAdminMeetingTemplate }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setActiveAdminTemplate(template))
  }

  return (
    <Button variant="outline" onClick={handleClick}>
      {t('component.AdminTemplatesManagement.action.editPrompts')}
    </Button>
  )
}

interface IHeaderSortable {
  column: Column<IAdminMeetingTemplate, unknown>,
  label: string,
}

const HeaderSortable = ({ column, label }: IHeaderSortable) => {
  const isSorted = column.getIsSorted();
  let SortIcon;

  if (isSorted === "asc") {
    SortIcon = ChevronUpIcon;
  } else if (isSorted === "desc") {
    SortIcon = ChevronDownIcon;
  } else {
    SortIcon = ChevronsUpDownIcon;
  }

  
  return (
    <Button
      variant="ghost"
      onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    >
      {label}
      <SortIcon className="ml-2 size-4 shrink-0" />
    </Button>
  )
}
