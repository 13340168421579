import styles from './ViewEditPortfolioModels.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { BUTTON_LOOK, Button, PortfolioModelForm } from 'components_old';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { IPortfolioModelFormValues, listPortfolioModels, selectIsUpdating, selectPortfolioModels, updatePortfolioModels } from 'store/portfolioModel';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  onAddModel: Function;
}

export const ViewEditPortfolioModels = ({ onAddModel }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const portfolioModels = useSelector(selectPortfolioModels);
  const isUpdating = useSelector(selectIsUpdating);

  const [isEditing, setIsEditing] = useState(false)

  const {
    control,
    getValues,
    watch,
    trigger,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    ...useFormProps
  } = useForm<IPortfolioModelFormValues>({
    defaultValues: { models: portfolioModels },
    mode: 'onBlur'
  });
  const { fields, remove, insert } = useFieldArray({
    name: "models",
    control
  });

  useEffect(() => {
    dispatch(listPortfolioModels())
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      trigger();
    });
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, trigger]);


  useEffect(() => {
    trigger();
    reset({ models: portfolioModels });
  }, [portfolioModels, trigger, reset]);

  const onEdit = () => {
    setIsEditing(true);
    trigger();
    // mixpanel.track(EVENT.PORTFOLIO_MODELS.EDIT_MODELS);
  }

  const onSave = async (values: IPortfolioModelFormValues) => {
    const response = await dispatch(updatePortfolioModels(values.models));
    if (!response.hasOwnProperty('error')) {
      setIsEditing(false);
    }
    // mixpanel.track(EVENT.PORTFOLIO_MODELS.SAVE_MODELS);
  };

  const onCancel = () => {
    reset({ models: portfolioModels });
    setIsEditing(false)
    // mixpanel.track(EVENT.PORTFOLIO_MODELS.CANCEL_EDIT_MODELS);
  }

  return (
    <form
      className={styles.Wrapper}
      onSubmit={handleSubmit(onSave)}
    >
      <div className={classnames(styles.Column, styles.ColumnLeft)}></div>

      <div className={classnames(styles.Column, styles.ColumnCenter)}>
        {
          fields?.map((field, index) => {
            return (
              <PortfolioModelForm
                key={field.id}
                control={control}
                getValues={getValues}
                watch={watch}
                trigger={trigger}
                errors={errors}
                isValid={isValid}
                modelIndex={index}
                model={field}
                isEditing={isEditing}
                isProcessing={isUpdating}
                onDelete={() => {
                  // mixpanel.track(EVENT.PORTFOLIO_MODELS.DELETE_MODEL);
                  remove(index);
                }}
                onDuplicate={() => {
                  // mixpanel.track(EVENT.PORTFOLIO_MODELS.DUPLICATE_MODEL);
                  insert(index+1, { ...field, id: uuidv4(), name: `COPY OF ${field.name}` });
                }}
                {...useFormProps}
              />
            )
          })
        }
      </div>

      <div className={classnames(styles.Column, styles.ColumnRight)}>
        {!isEditing && (
          <>
            <Button
              disabled={false}
              look={BUTTON_LOOK.SECONDARY}
              onClick={() => onAddModel()}
            >
              {t('portfolioModels.addModel')}
            </Button>
            <Button
              look={BUTTON_LOOK.SECONDARY}
              onClick={onEdit}
            >
              {t('portfolioModels.editModels')}
            </Button>
          </>
        )}

        {isEditing && (
          <>
            <Button
              disabled={!isValid || !isDirty}
              look={BUTTON_LOOK.SECONDARY}
              type="submit"
              loading={isUpdating}
            >
              {t('common.save')}
            </Button>
            <Button
              look={BUTTON_LOOK.TERNARY}
              onClick={onCancel}
              loading={isUpdating}
            >
              {t('common.cancel')}
            </Button>
          </>
        )}
      </div>

    </form>
  );
}
