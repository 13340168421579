
import { LoginSignUpSubLayout } from "components/layout/LoginSignUpSubLayout";
import { LoginForm } from "components/common/login/LoginForm";
import { useSearchParams } from "react-router-dom";
import { URL_PARAM } from "routing/constants";
import { SignOutReason } from "store/authentication";
import { toast } from "react-toastify";
import { useMixpanel, useTranslation } from "hooks";
import { useEffect } from "react";

export const LoginPage = () => {
  const { t } = useTranslation();
  useMixpanel();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const reason = params.get(URL_PARAM.REASON);

    if (reason) {
      params.delete(URL_PARAM.REASON);
      setSearchParams(params);

      if (reason === SignOutReason.USER_ACTION) {
        toast(t("page.LoginPage.signOutUserAction"));
      }

      if (reason === SignOutReason.SESSION_EXPIRED) {
        toast(t("page.LoginPage.signOutSessionExpired"), { type: "warning" });
      }
    }
  }, []);


  return (
    <LoginSignUpSubLayout>
      <LoginForm />
    </LoginSignUpSubLayout>
  )
}
