import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import {
  IUploadSecurelyFilesResponse, createUploadSecurelyFilesRequest, resetIsSecureUploadSuccessful, selectIsSecureUploadRequestProcessing, selectIsSecureUploadRequestSuccessful, selectIsUploadingSecurely, uploadSecurelyFiles
} from 'store/chat';
import { Button } from 'components/ui/button';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import { FileUploader } from 'components/common/_atoms/FileUploader';
import { Textarea } from 'components/ui/textarea';
import { Label } from 'components/ui/label';
import { WIZARD_HOW_TO_SHARE_FILE } from 'store/analysis';
import { MIXPANEL } from 'const';

export const ShareFilesViaUpload = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const isUploadingSecurely = useSelector(selectIsUploadingSecurely);
  const isSecureUploadRequestProcessing = useSelector(selectIsSecureUploadRequestProcessing);
  const isSecureUploadRequestSuccessful = useSelector(selectIsSecureUploadRequestSuccessful);

  const [filesToUpload, setFilesToUpload] = useState<Array<IFileWithMeta>>([]);
  const [isLocalUploadIncomplete, setIsLocalUploadIncomplete] = useState(true);

  const [uploadLink, setUploadLink] = useState('');
  const [note, setNote] = useState('');

  useEffect(() => {
    return () => {
      resetComponent();
    }
  }, []);

  useEffect(() => {
    if (uploadLink) {
      dispatch(createUploadSecurelyFilesRequest({
        access_type: WIZARD_HOW_TO_SHARE_FILE.upload,
        google_drive_link: uploadLink,
        note
      }));
      trackEvent({ action: MIXPANEL.ACTION.PARAPLANNER_AI.UPLOAD_FILE })
    }
  }, [uploadLink, dispatch, note]);

  const handleLocalUpload = (file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]) => {

    const isIncomplete =
      allFiles.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
      !allFiles.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))
    setIsLocalUploadIncomplete(isIncomplete)

    setFilesToUpload(allFiles);
  }

  const doFileUploadAndCreateRequest = async () => {
    const formData = new FormData();
    filesToUpload?.forEach(item => {
      formData.append('files', item.file);
    })

    try {
      const uploadResponse = await dispatch(uploadSecurelyFiles(formData));

      if (!uploadResponse.hasOwnProperty('error')) {
        const responseLink = (uploadResponse?.payload as IUploadSecurelyFilesResponse)?.link;
        setUploadLink(responseLink)
      }
    } catch (error) { }
  }

  const resetComponent = () => {
    setUploadLink('')
    setNote('');
    setFilesToUpload([]);
    setIsLocalUploadIncomplete(true)
    dispatch(resetIsSecureUploadSuccessful())
  }

  return (
    <Card>
      {!isSecureUploadRequestSuccessful ? (
        <>
          <CardHeader>
            <CardTitle>
              {t('component.ShareFilesViaUpload.title')}
            </CardTitle>
            <CardDescription>
              {t('component.ShareFilesViaUpload.description')}
            </CardDescription>
          </CardHeader>

          <CardContent className="flex flex-col gap-6">
            <FileUploader
              onChangeStatus={handleLocalUpload}
              showSubmitButton={false}
            />

            <div className='space-y-1.5'>
              <Label>
                {t('component.ShareFilesViaUpload.note')}
              </Label>

              <Label variant="description" className='relative pr-6'>
                {t('component.ShareFilesViaUpload.noteDescription')}
              </Label>

              <Textarea
                rows={5}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </CardContent>

          <CardFooter>
            <Button
              disabled={isLocalUploadIncomplete}
              loading={isUploadingSecurely || isSecureUploadRequestProcessing}
              onClick={() => doFileUploadAndCreateRequest()}
            >
              {t('component.ShareFilesViaUpload.btn')}
            </Button>
          </CardFooter>
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>{t('component.ShareFilesViaUpload.successState.title')}</CardTitle>
            <CardDescription>{t('component.ShareFilesViaUpload.successState.description')}
            </CardDescription>
          </CardHeader>

          <CardFooter className="flex-col gap-4">
            <Button
              onClick={() => resetComponent()}
            >
              {t('component.ShareFilesViaUpload.successState.newRequest')}
            </Button>
          </CardFooter>
        </>
      )}
    </Card>

  );
};
