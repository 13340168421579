import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "lib/utils"

const labelVariants = cva(
  "block text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      variant: {
        default: "",
        description: "text-xs font-extralight leading-tight",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants>
>(({ className, variant, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ variant, className }))}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName

// const LabelDiv = React.forwardRef<
//   React.ElementRef<typeof LabelPrimitive.Root>,
//   React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
//   VariantProps<typeof labelVariants>
// >(({ className, variant, ...props }, ref) => (
//   <div
//     className={cn(labelVariants({ variant, className }))}
//     {...props}
//   />
// ))

export interface LabelDivProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
  VariantProps<typeof labelVariants> {
  iconClassName?: string;
}

const LabelDiv = React.forwardRef<HTMLDivElement, LabelDivProps>(
  ({ className, variant, disabled, children, iconClassName, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(labelVariants({ variant, className }))} {...props}>
        {children}
      </div>
    )
  }
)

export { Label, LabelDiv }
