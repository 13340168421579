import { processApiError } from "services";
import { api } from "services/api";
import {
  IAdvisorsMailbox,
  IGetUserDetailsPayload,
  ICreateUserPayload,
  IUser,
  IUpdateUserPayload,
} from "./types";
import {
  PrepareAction,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { ACTION } from "const/actions";

export const getAdvisorMailboxes = createAsyncThunk(
  "getAdvisorMailboxes",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.users.getAdvisorMailboxes(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateActiveAdvisorMailbox = createAction<
  PrepareAction<IAdvisorsMailbox | null>
>(ACTION.USERS.UPDATE_ACTIVE_ADVISOR_MAILBOX, (value) => {
  return value;
});

export const getUsersList = createAsyncThunk(
  ACTION.USERS.GET_USERS_LIST,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.users.getUsersList();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  ACTION.USERS.GET_USER_DETAILS,
  async (payload: IGetUserDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await api.users.getUserDetails(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createUser = createAsyncThunk(
  ACTION.USERS.CREATE_USER,
  async (payload: ICreateUserPayload, { rejectWithValue }) => {
    try {
      const response = await api.users.createUser(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateUser = createAsyncThunk(
  ACTION.USERS.UPDATE_USER,
  async (payload: IUpdateUserPayload, { rejectWithValue }) => {
    try {
      const response = await api.users.updateUser(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteUser = createAsyncThunk(
  ACTION.USERS.DELETE_USER,
  async (payload: IUser, { rejectWithValue }) => {
    try {
      const response = await api.users.deleteUser(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
