import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getNotificationsState = (state: RootState) => state.notifications;

export const selectGeneralConversationRecord = createSelector(
  [getNotificationsState],
  ({ generalConversationRecord }) => generalConversationRecord
);

export const selectGeneralConversationRecordHasUnread = createSelector(
  [getNotificationsState],
  ({ generalConversationRecord }) => generalConversationRecord?.isUnread
);
