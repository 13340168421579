import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { INewAddedUser, ICreateUserPayload, IUser, UserRoleInOrgEnum, createUser, selectIsUserOperationProcessing, updateUser } from 'store/users';
import { toast } from 'react-toastify';
import { DetailsLine } from 'components/common/_atoms/DetailsLine';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IOrganization, getOrganizations, selectOrganizationsList } from 'store/organizations';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { OrganizationModal } from '../organization-management/OrganizationModal';


interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  data?: IUser | null;
  onComplete?: (user: INewAddedUser) => void
}

export const UserModal = ({ isVisible, setIsVisible, data, onComplete }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [apiResponse, setApiResponse] = useState<INewAddedUser | null>(null)
  const isUserOperationProcessing = useAppSelector(selectIsUserOperationProcessing);

  const organizationsList = useAppSelector(selectOrganizationsList);
  const [isOrgModalVisible, setIsOrgModalVisible] = useState(false)

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const formSchema = z.object({
    email: z.string().min(1, t('errors.required') as string).email(),
    firstName: z.string().min(1, t('errors.required') as string),
    lastName: z.string().min(1, t('errors.required') as string),
    orgId: z.string().min(1, t('errors.required') as string),
    userRole: z.string().min(1, t('errors.required') as string),
  });

  const form = useForm<ICreateUserPayload>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: data?.email || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      orgId: data?.organisations?.[0]?.organisationId || '',
      userRole: data?.organisations?.[0]?.role || undefined,
    }
  });

  useEffect(() => {
    form.setFocus('firstName');
  }, [form]);

  useEffect(() => {
    form.reset({
      email: data?.email || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      orgId: data?.organisations?.[0]?.organisationId || '',
      userRole: data?.organisations?.[0]?.role || undefined,
    })
  }, [data, form]);

  const onSubmit = async (values: ICreateUserPayload) => {
    if (!data) {
      const response = await dispatch(createUser(values));

      if (!response?.hasOwnProperty('error')) {
        const payload = response?.payload as INewAddedUser;
        setApiResponse(payload);
        toast(t(`component.UserModal.createSuccess`));
        onComplete && onComplete(payload)
      }
    } else {
      const response = await dispatch(updateUser({
        ...values,
        id: data.id
      }));

      if (!response?.hasOwnProperty('error')) {
        const payload = response?.payload as INewAddedUser;
        resetForm();
        toast(t(`component.UserModal.updateSuccess`));
        onComplete && onComplete(payload)
      }
    }
  }

  const resetForm = () => {
    form.reset();
    setApiResponse(null)
    setIsVisible(false);
  }

  return (
    <>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!data && !apiResponse && t(`component.UserModal.createTitle`)}
              {!data && apiResponse && t(`component.UserModal.createOutcomeTitle`)}
              {data && !apiResponse && t(`component.UserModal.editTitle`)}
            </DialogTitle>

            <DialogDescription>
              {!data && !apiResponse && t(`component.UserModal.createDescription`)}
              {data && !apiResponse && t(`component.UserModal.editDescription`)}
            </DialogDescription>
          </DialogHeader>

          {!apiResponse && (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">

                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UserModal.firstName`)}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore disabled={isUserOperationProcessing} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UserModal.lastName`)}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore disabled={isUserOperationProcessing} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UserModal.email`)}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore disabled={isUserOperationProcessing} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="orgId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UserModal.orgId`)}
                      </FormLabel>

                      <div className='flex flex-col items-center gap-1'>
                        <div className='w-full'>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                            value={field.value}
                            disabled={isUserOperationProcessing}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder={t('component.UserModal.selectOrg')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {organizationsList?.map(organization => {
                                return (
                                  <SelectItem
                                    key={organization.id}
                                    value={organization.id}
                                  >
                                    {organization.name}
                                  </SelectItem>
                                )
                              })}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </div>

                        <div>OR</div>

                        <Button className='w-full' onClick={() => setIsOrgModalVisible(true)}>
                          {t('component.UserModal.createOrganization')}
                        </Button>
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="userRole"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UserModal.role`)}
                      </FormLabel>

                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        disabled={isUserOperationProcessing}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={t('component.UserModal.selectOrgRole')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {Object.keys(UserRoleInOrgEnum)?.map(key => {
                            return (
                              <SelectItem
                                key={key}
                                value={UserRoleInOrgEnum[key as keyof typeof UserRoleInOrgEnum]}
                              >
                                {key}
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <DialogFooter>
                  <Button
                    type="submit"
                    loading={isUserOperationProcessing}
                  >
                    {!data ? t(`component.UserModal.createBtn`) : t(`component.UserModal.updateBtn`)}
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          )}

          {!data && apiResponse && (
            <>
              <div className='grid gap-2'>
                <DetailsLine label={t('component.UserModal.email')} value={apiResponse?.email} copyValueEnabled={true} />
                <DetailsLine label={t('component.UserModal.password')} value={apiResponse?.password} copyValueEnabled={true} />
              </div>

              <DialogFooter>
                <Button onClick={() => resetForm()}>
                  {t(`general.close`)}
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
      <OrganizationModal
        isVisible={isOrgModalVisible}
        setIsVisible={setIsOrgModalVisible}
        onCreateCompleted={async (org: IOrganization) => {
          await dispatch(getOrganizations())
          form.setValue('orgId', org.id);
          form.trigger('orgId')
        }}
      />
    </>
  );
};

