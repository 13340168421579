import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRecordingManagementState, UploadStatusEnum } from "./types";
import { AxiosProgressEvent } from "axios";

const initialState: IRecordingManagementState = {
  uploadStatus: UploadStatusEnum.IDLE,
  uploadProgressEvent: null,
};

const uploadManagementSlice = createSlice({
  name: "uploadManagement",
  initialState,
  reducers: {
    resetUploadManagementSlice: () => {
      return { ...initialState };
    },
    setUploadProgressEvent: (
      state,
      action: PayloadAction<AxiosProgressEvent | null>
    ) => {
      state.uploadProgressEvent = action.payload;
    },
    setUploadStatus: (state, action: PayloadAction<UploadStatusEnum>) => {
      state.uploadStatus = action.payload;
    },
  },
});

export const {
  resetUploadManagementSlice,
  setUploadProgressEvent,
  setUploadStatus,
} = uploadManagementSlice.actions;

export default uploadManagementSlice.reducer;
