import { ICrmTask } from "store/chat-assistant";

export interface IClientManagementState {
  isGetClientsListProcessing: boolean;
  clientsList: IClientData[];
  isCreateClientProcessing: boolean;
  isGetClientProcessing: boolean;
  isDeleteClientProcessing: boolean;
  activeClient: IClientData | null;
  isAskAiProcessing: boolean;
  isGetDataRecordsListProcessing: boolean;
  clientDataRecordsList: IClientDataRecord[];
  isCreateDataRecordProcessing: boolean;
  isDeleteDataRecordProcessing: boolean;
  isGetAiDataSearchAnswerProcessing: boolean;
  aiDataSearchAnswers: IGetAiDataSearchAnswerResponse[];
  isSyncingSalesforce: boolean;
  isSyncingRedtail: boolean;
  isImportingFromToRedtail: boolean;
  importResult: IImportRedtailResponse | null;
}

export interface ICreateClientPayload {
  email?: string;
  first_name: string;
  last_name: string;
}

export interface IGetClientPayload {
  id: string;
}

export interface IDeleteClientPayload {
  id: string;
}
export interface IDeleteClientResponse {}

export interface IClientData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileSummary: string;
  redtailClientId: string;
  salesforceClientId: string;
}

export interface IClientAskAiPayload {}
export interface IClientAskAiResponse {}

export interface IClientTextResource {
  description: string;
  content: string;
}

export interface ICreateDataRecordPayload {
  client_id: string;
  title: string;
  value: string;
}

export interface ILinkGoogleDriveFolderPayload {
  client_id: string;
  link: string;
}

export interface IClientDataRecord {
  id: string;
  type: ClientDataRecordType;
  clientId: string;
  recallBotId: string;
  title?: string;
  value?: string;
  advisorId?: string;
  updatedAt?: string;
  createdAt?: string;
  deletedAt?: string;
}

export interface IDeleteDataRecordPayload {
  clientId: string;
  recordId: string;
}

export interface IGetAiDataSearchAnswerPayload {
  botId: string;
  clientId: string;
  question: string;
  aiAnswerType?: AiAnswerType;
}

export interface IGetAiDataSearchExtraInfoPayload {
  clientId: string;
}

export interface IGetAiDataSearchAnswerResponse
  extends IGetAiDataSearchAnswerPayload {
  answer: string;
}

export interface IGetAiDataSearchWhoIsClientResponse {
  summary: string;
}

export interface IGetAiDataSearchDoAgendaResponse {
  agenda: string;
}

export interface ISalesforceItem {
  title: string;
  description: string;
}

export interface ISalesforceMeetingNotes {
  data_points: ISalesforceItem;
  questions: ISalesforceItem;
  summary: ISalesforceItem;
  action_items: ISalesforceItem;
}

export interface ISyncSalesforcePayload {
  client_id: string;
  meeting_notes: ISalesforceMeetingNotes;
  tasks: ICrmTask[];
}

export interface ISyncRedtailPayload {
  client_id: string;
  meeting_notes: ISalesforceMeetingNotes;
  tasks: ICrmTask[];
}

export enum ClientDataRecordType {
  TEXT = "text",
  G_DRIVE = "gdrive_folder",
  CLIENT_MEETING = "client_meeting",
  IN_PERSON_MEETING = "in_person_meeting",
}

export enum AiAnswerType {
  PHRASE = "phrase",
  MARKUP = "markup",
}

export interface IImportRedtailResponse {
  message: string;
  status: string;
}
