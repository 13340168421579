import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { omit, trim } from "lodash";
import { IUser, IUsersState } from "./types";
import {
  getUsersList,
  getUserDetails,
  createUser,
  updateUser,
  deleteUser,
  getAdvisorMailboxes,
  updateActiveAdvisorMailbox,
} from "./actions";

const initialState: IUsersState = {
  advisorsMailboxLoading: false,
  advisorMailboxes: [],
  activeAdvisorMailbox: null,
  isGetUsersListProcessing: false,
  usersList: [],
  isGetUserDetailsProcessing: false,
  activeUser: null,
  isUserOperationProcessing: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<IUser>) => {
      state.activeUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.isGetUsersListProcessing = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isGetUsersListProcessing = false;
        state.usersList = action.payload?.sort((a, b) =>
          a?.firstName?.toLowerCase().localeCompare(b?.firstName?.toLowerCase())
        );
        if (currentState?.activeUser?.id) {
          const updatedCurrentUser = action.payload?.find(
            (curr) => curr.id === currentState?.activeUser?.id
          );
          state.activeUser = updatedCurrentUser || null;
        }
      })
      .addCase(getUsersList.rejected, (state) => {
        state.isGetUsersListProcessing = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.isGetUserDetailsProcessing = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isGetUserDetailsProcessing = false;
        state.activeUser = action.payload;
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.isGetUserDetailsProcessing = false;
      })
      .addCase(createUser.pending, (state) => {
        state.isUserOperationProcessing = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isUserOperationProcessing = false;
        state.activeUser = action.payload;
        state.usersList = [action.payload, ...currentState.usersList];
      })
      .addCase(createUser.rejected, (state) => {
        state.isUserOperationProcessing = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isUserOperationProcessing = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isUserOperationProcessing = false;

        const updatedUser: IUser = omit(action.payload, "password");
        state.usersList = currentState?.usersList?.map((user) =>
          user?.id !== updatedUser?.id ? user : updatedUser
        );
        state.activeUser = updatedUser;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isUserOperationProcessing = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isUserOperationProcessing = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const currentState = current(state);
        const deletedUserId = action.meta.arg.id;

        state.isUserOperationProcessing = false;

        state.usersList = currentState?.usersList?.filter(
          (user) => user?.id !== deletedUserId
        );
        state.activeUser = null;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isUserOperationProcessing = false;
      })
      .addCase(getAdvisorMailboxes.pending, (state) => {
        state.advisorsMailboxLoading = true;
      })
      .addCase(getAdvisorMailboxes.fulfilled, (state, action) => {
        state.advisorsMailboxLoading = false;
        const filteredData = action.payload.filter(
          (data) => data.connected_emails.length
        );
        state.advisorMailboxes = filteredData;
      })
      .addCase(getAdvisorMailboxes.rejected, (state) => {
        state.advisorsMailboxLoading = false;
      })
      .addCase(updateActiveAdvisorMailbox, (state, action) => {
        state.activeAdvisorMailbox = action.payload;
      });
  },
});

export const { setActiveUser } = usersSlice.actions;

export default usersSlice.reducer;
