import { useEffect } from "react"
import { getUser } from "store/authentication"
import { getProfileDetails, selectProfileDetails } from "store/profile"
import { useSearchParams } from "react-router-dom"
import { cn } from "lib/utils"
import { RoleEnum } from "const"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "components/ui/select"
import { useAppDispatch, useAppSelector } from "hooks"
import { getAdvisorMailboxes, selectActiveMailbox, selectActiveMailboxConnectedEmail, selectAdvisorMailboxes, updateActiveAdvisorMailbox } from "store/users"
import { Gmail } from "icons/Gmail"

interface AccountSwitcherProps {
  isCollapsed: boolean
}

export const AccountSwitcher = ({ isCollapsed }: AccountSwitcherProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getUser);
  const myProfileData = useAppSelector(selectProfileDetails)

  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);
  const activeMailbox = useAppSelector(selectActiveMailbox);
  const activeMailboxEmail = useAppSelector(selectActiveMailboxConnectedEmail);


  useEffect(() => {
    if (currentUser.id) {
      dispatch(getProfileDetails())
    }
  }, [currentUser?.id, dispatch]);

  /* Mailbox logic */
  // I have the profile, get the mailboxes list
  useEffect(() => {
    if (myProfileData.id && myProfileData.role !== RoleEnum.Admin) {
      dispatch(getAdvisorMailboxes(null));
    }
  }, [dispatch, myProfileData]);

  // After I get the data set the first mailbox as active
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authId = params.get('authId');

    if (advisorMailboxes?.length) {
      if (authId) {
        const mailboxFromParam = authId ? advisorMailboxes?.find(mailbox => mailbox?.connected_emails[0]?.authId === authId) : undefined

        if (authId) {
          params.delete('authId');
          setSearchParams(params);
        }

        dispatch(updateActiveAdvisorMailbox({ payload: mailboxFromParam || advisorMailboxes[0] }))
      } else if (!activeMailbox) {
        dispatch(updateActiveAdvisorMailbox({ payload: advisorMailboxes[0] }))
      }
    }
  }, [dispatch, advisorMailboxes, activeMailbox])

  const onChangeMailbox = (email: string) => {
    const selectedMailbox = advisorMailboxes.find(mailbox => mailbox?.connected_emails?.[0].email === email)
    dispatch(updateActiveAdvisorMailbox({ payload: selectedMailbox }))
  }

  return (
    <Select defaultValue={activeMailboxEmail?.email} onValueChange={value => onChangeMailbox(value)}>
      <SelectTrigger
        className={cn(
          "flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
          isCollapsed &&
          "flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden"
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select an account">
          <Gmail />
          <span className={cn("ml-2", isCollapsed && "hidden")}>
            {activeMailbox?.firstName}
            &nbsp;
            {activeMailbox?.lastName}
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {advisorMailboxes.map((mailbox) => (
          <SelectItem key={mailbox?.connected_emails?.[0].email} value={mailbox?.connected_emails?.[0].email}>
            <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              <Gmail />
              {mailbox?.connected_emails?.[0].email}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
