import styles from './SelectTool.module.scss';
import { useTranslation } from 'hooks';
import { Button, Input, Modal, StepperBox, StepperTitle } from 'components_old';
import { WIZARD_TOOL } from 'store/analysis';
import { ReactNode, useEffect, useState } from 'react';
import { IStepMandatoryProps } from 'components_old/features/PortfolioAnalysis/types';
import { useForm } from 'react-hook-form';

interface IProps extends IStepMandatoryProps {
  title: ReactNode,
}

interface FormData {
  otherToolName: string;
}

export const SelectTool = ({ handleGoToNextStep, title, nextStep }: IProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { isValid },
  } = useForm<FormData>({ mode: 'onChange'});

  useEffect(() => {
    setFocus('otherToolName');
  }, [setFocus, isModalOpen]);

  const onOtherToolOk = (values: FormData) => {
    setModalIsOpen(false);
    handleGoToNextStep({ action: nextStep, payload: { tool: values?.otherToolName } })
    reset();
  }

  const onOtherToolCancel = () => {
    reset();
    setModalIsOpen(false);
  }

  return (
    <>
      <StepperTitle>
        {title}
      </StepperTitle>
      <StepperBox
        title={t('analysis.selectTool.morningstar')}
        onClick={() => handleGoToNextStep({ action: nextStep, payload: { tool: WIZARD_TOOL.MORNING_STAR } })}
      />
      <StepperBox
        title={t('analysis.selectTool.kwanti')}
        onClick={() => handleGoToNextStep({ action: nextStep, payload: { tool: WIZARD_TOOL.KWANTI } })}
      />
      {/* <StepperBox
        title={t('analysis.selectTool.nitrogen')}
        onClick={() => handleGoToNextStep({ action: nextStep, payload: { tool: WIZARD_TOOL.NITROGEN } })}
      /> */}
      <StepperBox
        title={t('analysis.selectTool.other')}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          setModalIsOpen(true)
        }}
      />

      <Modal open={isModalOpen} onClose={() => { }}>
        <div className={styles.Modal}>
          <form className={styles.Content} onSubmit={handleSubmit(onOtherToolOk)}>
            <div className={styles.FormTitle}>{t('analysis.selectTool.toolNameTitle')}</div>
            <Input
              formed
              placeholder={t('analysis.selectTool.toolName') as string}
              {...register('otherToolName', {
                required: { value: true, message: t('errors.required') },
              })}
            />

            <div className={styles.Buttons}>
              <Button disabled={!isValid} type='submit'>
                {t('common.continue')}
              </Button>
              <Button onClick={onOtherToolCancel} className={styles.CloseBtn}>
                {t('common.close')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
