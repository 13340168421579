import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from 'components/ui/card';
import { IMeetingQuestionsSection, IMeetingTemplate, IMeetingTemplateFormData, TemplateManagementTypeEnum, createOrgTemplate, deleteOrgTemplate, templateFormSchema, updateOrgTemplate, updatePersonalTemplate } from 'store/templates-management';
import {
  createPersonalTemplate, deletePersonalTemplate, getTemplates, selectActiveTemplate, selectIsCreatingTemplate, selectIsDeletingTemplate, selectIsGettingTemplates, selectIsUpdatingTemplate, selectMeetingTemplates, setActiveTemplate
} from 'store/templates-management';
import { useSelector } from 'react-redux';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from 'components/ui/select';
import { Loading } from 'components/layout-atoms/Loading';
import { MeetingTemplate } from './MeetingTemplate';
import { v4 as uuidv4 } from 'uuid';
import { MIXPANEL } from 'const';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { toast } from 'react-toastify';
import ReactRouterPrompt from 'react-router-prompt';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from "zod"
import { getDataPointsList } from 'store/data-points';


interface IProps {
  type: TemplateManagementTypeEnum;
}

export const TemplatesManagement = ({ type }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const meetingTemplates = useSelector(selectMeetingTemplates);
  const activeTemplate = useSelector(selectActiveTemplate);

  const isGettingTemplates = useSelector(selectIsGettingTemplates);
  const isCreatingTemplate = useSelector(selectIsCreatingTemplate);
  const isUpdatingTemplate = useSelector(selectIsUpdatingTemplate);
  const isDeletingTemplate = useSelector(selectIsDeletingTemplate);

  const [isInEditMode, setIsInEditMode] = useState<boolean>(false)
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState<boolean>(false)
  const [hasTemplates, setHasTemplates] = useState(false)

  const form = useForm<IMeetingTemplateFormData>({
    resolver: zodResolver(templateFormSchema),
    mode: 'onSubmit',
    defaultValues: { ...activeTemplate },
  });

  useEffect(() => {
    dispatch(getTemplates())
    dispatch(getDataPointsList())

    return () => {
      dispatch(setActiveTemplate())
    }
  }, [])

  useEffect(() => {
    form.reset({ 'formSelectedTemplateId': activeTemplate?.id, ...activeTemplate });
  }, [activeTemplate])

  useEffect(() => {
    setHasTemplates(
      (meetingTemplates?.orgTemplates?.length > 0 && type === TemplateManagementTypeEnum.ORGANIZATION) ||
      (meetingTemplates?.myTemplates?.length > 0 && type === TemplateManagementTypeEnum.PERSONAL))
  }, [meetingTemplates, type])


  const onTemplateSelectorValueChange = (value: string) => {
    const chosenTemplate = [...meetingTemplates?.myTemplates || [], ...meetingTemplates?.orgTemplates || []]?.find(template => template.id === value);
    dispatch(setActiveTemplate(chosenTemplate));
  }

  const handleCreateTemplate = async () => {
    const payload: IMeetingTemplate = {
      id: uuidv4(),
      name: "New template",
      dataPoints: [],
      dataPointIds: [],
      sections: []
    }

    const response = type === TemplateManagementTypeEnum.PERSONAL ? await dispatch(createPersonalTemplate(payload)) : await dispatch(createOrgTemplate(payload));

    if (createPersonalTemplate.fulfilled.match(response) || createOrgTemplate.fulfilled.match(response)) {
      dispatch(getTemplates());
      setIsInEditMode(true)

      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.TEMPLATES.CREATE_TEMPLATE })
      toast(t('component.TemplatesManagement.toast.create'));
    }
  }

  const handleDuplicateTemplate = async () => {
    if (activeTemplate) {
      const payload: IMeetingTemplate = {
        ...activeTemplate,
        dataPoints: activeTemplate?.dataPoints || [],
        dataPointIds: (activeTemplate?.dataPoints || [])?.map(dataPoint => dataPoint.id),
        sections: (activeTemplate?.sections || [])?.map(section => {
          return { ...section, id: uuidv4(), questions: section?.questions?.map(question => ({ ...question, id: uuidv4() })) }
        }),
        id: uuidv4(),
        name: `Copy of ${activeTemplate?.name}`,
      }

      const response = type === TemplateManagementTypeEnum.PERSONAL ? await dispatch(createPersonalTemplate(payload)) : await dispatch(createOrgTemplate(payload));

      if (createPersonalTemplate.fulfilled.match(response) || createOrgTemplate.fulfilled.match(response)) {
        dispatch(getTemplates());
        setIsInEditMode(true)

        trackEvent({ action: MIXPANEL.ACTION.SETTINGS.TEMPLATES.CREATE_TEMPLATE })
        toast(t('component.TemplatesManagement.toast.create'));
      }
    }
  }

  const handleOnUpdateTemplate = async (values: z.infer<typeof templateFormSchema>) => {
    if (activeTemplate) {
      const formValues = form.getValues();
      const newSections = JSON.parse(JSON.stringify(formValues?.sections)) as IMeetingQuestionsSection[]; // Deep copy

      newSections?.forEach(section => {
        delete section.id;
        section.questions.forEach(question => {
          delete question.id;
        });
      });

      const payload = { ...formValues, sections: newSections, dataPointIds: formValues?.formSelectedDataPointOptions?.map(option => option.value) };

      const response = type === TemplateManagementTypeEnum.PERSONAL ? await dispatch(updatePersonalTemplate(payload)) : await dispatch(updateOrgTemplate(payload));

      if (updatePersonalTemplate.fulfilled.match(response) || updateOrgTemplate.fulfilled.match(response)) {
        dispatch(getTemplates());
        setIsInEditMode(false)
        form.reset(payload);

        trackEvent({ action: MIXPANEL.ACTION.SETTINGS.TEMPLATES.UPDATE_TEMPLATE })
        toast(t('component.TemplatesManagement.toast.update'));
      }
    }
  }

  const handleDeleteTemplate = async () => {
    if (activeTemplate) {
      const response = type === TemplateManagementTypeEnum.PERSONAL ? await dispatch(deletePersonalTemplate(activeTemplate)) : await dispatch(deleteOrgTemplate(activeTemplate));

      if (deletePersonalTemplate.fulfilled.match(response) || deleteOrgTemplate.fulfilled.match(response)) {
        dispatch(getTemplates());
        setIsDeleteConfirmVisible(false);
        form.reset({ formSelectedTemplateId: '' });

        trackEvent({ action: MIXPANEL.ACTION.SETTINGS.TEMPLATES.DELETE_TEMPLATE })
        toast(t('component.TemplatesManagement.toast.delete'));
      }
    }
  }

  const handleOnCancelEditing = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsInEditMode(false)
    form.reset({
      formSelectedTemplateId: activeTemplate?.id,
      ...activeTemplate
    });
  }

  return (
    <div className="flex flex-col flex-1 overflow-auto gap-6">
      <Card>
        <CardHeader className=''>
          <CardTitle>
            {type === TemplateManagementTypeEnum.PERSONAL ? t('component.TemplatesManagement.personal.title') : t('component.TemplatesManagement.org.title')}
          </CardTitle>
          <CardDescription>
            {type === TemplateManagementTypeEnum.PERSONAL ? t('component.TemplatesManagement.personal.description') : t('component.TemplatesManagement.org.description')}
          </CardDescription>
        </CardHeader>

        <CardContent className='flex flex-col gap-6'>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnUpdateTemplate)} className='flex flex-col gap-6'>
              <FormField
                control={form.control}
                name="formSelectedTemplateId"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('component.TemplatesManagement.templateSelectorLabel')}
                      </FormLabel>

                      <div className='flex gap-3 items-center'>
                        {isGettingTemplates && <Loading variant="item" className='basis-2/3 p-[9px] rounded-md' />}

                        {!isGettingTemplates && hasTemplates && (
                          <div className='basis-2/3'>
                            <Select
                              onValueChange={
                                (value) => {
                                  field.onChange(value)
                                  onTemplateSelectorValueChange(value)
                                }
                              }
                              defaultValue={field.value}
                              value={field.value}
                              disabled={activeTemplate && isInEditMode}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder={t('component.TemplatesManagement.templateSelectorPlaceholder')} />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {type === TemplateManagementTypeEnum.ORGANIZATION && meetingTemplates?.orgTemplates?.length > 0 && (
                                  <SelectGroup>
                                    <SelectLabel>
                                      {t('general.orgTemplates')}
                                    </SelectLabel>
                                    {meetingTemplates?.orgTemplates?.map(template => (
                                      <SelectItem
                                        key={template.id}
                                        value={template.id}
                                      >
                                        {template.name}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                )}
                                {type === TemplateManagementTypeEnum.PERSONAL && meetingTemplates?.myTemplates?.length > 0 && (
                                  <SelectGroup>
                                    <SelectLabel>
                                      {t('general.myTemplates')}
                                    </SelectLabel>
                                    {meetingTemplates?.myTemplates?.map(template => (
                                      <SelectItem
                                        key={template.id}
                                        value={template.id}
                                      >
                                        {template.name}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                )}
                              </SelectContent>
                            </Select>
                          </div>
                        )}

                        {(isGettingTemplates || hasTemplates) && <span className='text-sm'>OR</span>}

                        <Button
                          type='button'
                          className='basis-1/3 min-w-48'
                          loading={isCreatingTemplate}
                          onClick={handleCreateTemplate}
                          disabled={activeTemplate && isInEditMode}
                        >
                          {t('component.TemplatesManagement.button.add')}
                        </Button>
                      </div>

                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              {activeTemplate && (
                <>
                  {!isInEditMode ? (
                    <div className='flex justify-between'>
                      <div className='flex gap-3'>
                        <Button type='button' onClick={() => setIsInEditMode(true)}>
                          {t('component.TemplatesManagement.button.edit')}
                        </Button>

                        <Button
                          type='button'
                          variant='outline'
                          onClick={handleDuplicateTemplate}
                        >
                          {t('component.TemplatesManagement.button.duplicate')}
                        </Button>
                      </div>
                      <div>

                        <Button
                          type='button'
                          variant='destructive'
                          onClick={() => setIsDeleteConfirmVisible(true)}
                        >
                          {t('component.TemplatesManagement.button.delete')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className='flex gap-3'>
                      <Button
                        type='submit'
                        loading={isUpdatingTemplate}
                      >
                        {t('component.TemplatesManagement.button.save')}
                      </Button>
                      <Button
                        type='button'
                        variant='outline'
                        onClick={handleOnCancelEditing}
                      >
                        {t('component.TemplatesManagement.button.cancel')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </form >
          </Form >

        </CardContent>
      </Card>

      <div className="grow overflow-auto">
        {activeTemplate && (
          <MeetingTemplate
            form={form}
            template={activeTemplate}
            inEditMode={isInEditMode}
          />
        )}
      </div>

      <ConfirmActionDialog
        onOk={handleDeleteTemplate}
        isVisible={isDeleteConfirmVisible}
        setIsVisible={setIsDeleteConfirmVisible}
        isProcessing={isDeletingTemplate}
        title={t('component.TemplatesManagement.deleteConfirmTitle')?.replace('{{templateName}}', activeTemplate?.name || '')}
        description={t('component.TemplatesManagement.deleteConfirmDescription')}
      />

      <ReactRouterPrompt when={isInEditMode}>
        {({ isActive, onConfirm, onCancel }) => (
          <>
            <ConfirmActionDialog
              title={t('component.TemplatesManagement.navigateAwayTitle')}
              description={t('component.TemplatesManagement.navigateAwayDescription')}
              isVisible={isActive}
              setIsVisible={() => { }}
              onOk={onConfirm}
              onCancel={onCancel}
            />
          </>
        )}
      </ReactRouterPrompt>
    </div>
  );
}
