import { http } from "services/http";

import { IChatAssistantApi } from "./types";
import {
  ICreateBotPayload,
  IDownloadMediaPayload,
  IGetAIAnswerPayload,
  IGetDataPointValuePayload,
  IGetDataPointsStatusPayload,
  IGetChatAssistantCommonPayload,
  IGetQuestionValuePayload,
} from "store/chat-assistant/types";
import { API_ROUTES } from "services/api/constants";

export const chatAssistant: IChatAssistantApi = {
  createBot: (payload: ICreateBotPayload) =>
    http
      .post(API_ROUTES.chatAssistant.createBot, payload)
      .then((response) => response.data),
  getTranscript: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.chatAssistant.getTranscript(payload.botId))
      .then((response) => response.data),
  getTranscriptAIAnswer: (payload: IGetAIAnswerPayload) =>
    http
      .post(API_ROUTES.chatAssistant.getTranscriptAIAnswer, {
        ...payload,
        bot_id: payload.botId,
      })
      .then((response) => response.data),
  getPastDataAIAnswer: (payload: IGetAIAnswerPayload) =>
    http
      .post(API_ROUTES.chatAssistant.getPastDataAIAnswer, {
        ...payload,
        bot_id: payload.botId,
      })
      .then((response) => response.data),
  getDataPointValue: (payload: IGetDataPointValuePayload) =>
    http
      .post(API_ROUTES.chatAssistant.getDataPointValue(payload.botId), payload)
      .then((response) => response.data),
  getDataPointsStatus: (payload: IGetDataPointsStatusPayload) =>
    http
      .get(API_ROUTES.chatAssistant.getDataPointsStatus(payload.botId))
      .then((response) => response.data),
  getQuestionValue: (payload: IGetQuestionValuePayload) =>
    http
      .post(API_ROUTES.chatAssistant.getQuestionValue(payload.botId), payload)
      .then((response) => response.data),
  getQuestionsStatus: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.chatAssistant.getQuestionsStatus(payload.botId))
      .then((response) => response.data),
  downloadVideo: (payload: IDownloadMediaPayload) =>
    http
      .get(API_ROUTES.chatAssistant.downloadVideo(payload.botId))
      .then((response) => response.data),
  downloadAudio: (payload: IDownloadMediaPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.downloadAudio(payload.botId))
      .then((response) => response.data),
  downloadTranscript: (payload: IDownloadMediaPayload) =>
    http
      .get(API_ROUTES.chatAssistant.downloadTranscript(payload.botId), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      })
      .then((response) => response.data),
  getPostCallSummary: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.chatAssistant.getPostCallSummary(payload.botId))
      .then((response) => response.data),
  getHeadsUpDisplay: (botId: string) =>
    http
      .get(API_ROUTES.chatAssistant.getHeadsUpDisplay(botId))
      .then((response) => response.data),
  createInPersonMeeting: (payload: FormData) =>
    http
      .post(API_ROUTES.inPersonMeeting.createMeeting, payload)
      .then((response) => response.data),
  getInPersonTranscript: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.getTranscript(payload.botId))
      .then((response) => response.data),
  downloadInPersonTranscript: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.downloadTranscript(payload.botId), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      })
      .then((response) => response.data),
  getInPersonDataPointValue: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.getDataPointValue(payload.botId))
      .then((response) => response.data),
  getInPersonQuestionValue: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.getQuestionAnswers(payload.botId))
      .then((response) => response.data),
  getInPersonPostCallSummary: (payload: IGetChatAssistantCommonPayload) =>
    http
      .get(API_ROUTES.inPersonMeeting.getPostCallSummary(payload.botId))
      .then((response) => response.data),
  getInPersonTranscriptAIAnswer: (payload: IGetAIAnswerPayload) =>
    http
      .post(API_ROUTES.inPersonMeeting.getTranscriptAIAnswer, {
        ...payload,
        bot_id: payload.botId,
      })
      .then((response) => response.data),
};
