import { cn } from 'lib/utils';
import { IMenuItem, MenuItem } from './MenuItem';

interface IProps {
  menuItems: IMenuItem[],
  className?: string
}

export const SideMenu = ({ menuItems, className }: IProps) => {
  return (
    <nav className={cn("flex flex-col h-full overflow-auto gap-4 pr-2 text-sm", className)}>
      {
        menuItems?.map(item => (
          <MenuItem key={item.label} item={item} variant="side" />
        ))
      }
    </nav>
  )
}


