import styles from './RequireAuth.module.scss';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import { useAppSelector } from 'hooks';
import { getAccessToken } from 'store/authentication';
import { ErrorHandler, Menu, Panel, Panels } from 'components_old';
import { ErrorBoundary } from 'react-error-boundary'

export const RequireAuth = () => {
  const accessToken = useAppSelector(getAccessToken);  

  if (!accessToken) {
    const redirectPath = `${window.location.pathname}${window.location.search}`;
    sessionStorage.setItem('redirectPath', redirectPath);

    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Panels>
        <Panel bodyClassName={styles.PanelBody} body={<Menu />} width={246} />
        <Panel bodyClassName={styles.PanelBody} body={<Outlet />} />
      </Panels>
    </ErrorBoundary>
  )
};
