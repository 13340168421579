import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";
import { IHeadsUpCard } from "./types";

export const getCards = createAsyncThunk(
  ACTION.HEADS_UP_CARDS.GET_CARDS,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.headsUpCards.getCards();
      return response;
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const createCard = createAsyncThunk(
  ACTION.HEADS_UP_CARDS.CREATE_CARD,
  async (payload: IHeadsUpCard, { rejectWithValue }) => {
    try {
      // TODO: call BE
      // const response = await api.headsUpCards.createCard(payload);
      const response = payload;
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateCard = createAsyncThunk(
  ACTION.HEADS_UP_CARDS.UPDATE_CARD,
  async (payload: IHeadsUpCard, { rejectWithValue }) => {
    try {
      // TODO: call BE
      // const response = await api.headsUpCards.updateCard(payload);
      const response = payload;
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteCard = createAsyncThunk(
  ACTION.HEADS_UP_CARDS.DELETE_CARD,
  async (payload: IHeadsUpCard, { rejectWithValue }) => {
    try {
      // TODO: call BE
      // const response = await api.headsUpCards.deleteCard(payload);
      const response = payload;
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
