import { createSlice } from "@reduxjs/toolkit";
import { IAutomationsState } from "./types";
import { createInvestmentStatementExtractionWithHumanCheckRequest } from "./actions";

const initialState: IAutomationsState = {
  isProcessing: false,
  isProcessingError: false,
  createInvestmentStatementExtractionWithHumanCheckRequestSuccess: false,
};

const automationsSlice = createSlice({
  name: "automations",
  initialState,
  reducers: {
    resetDataExtractorState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvestmentStatementExtractionWithHumanCheckRequest.pending, (state, action) => {
        return { ...initialState, isProcessing: true };
      })
      .addCase(createInvestmentStatementExtractionWithHumanCheckRequest.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.createInvestmentStatementExtractionWithHumanCheckRequestSuccess = true;
      })
      .addCase(createInvestmentStatementExtractionWithHumanCheckRequest.rejected, (state) => {
        state.isProcessing = false;
        state.isProcessingError = true;
      });
  },
});

export const { resetDataExtractorState } = automationsSlice.actions;

export default automationsSlice.reducer;
