import { Icon, IIconProps } from './Icon';


export const Pencil = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_403_3224)">
        <path d="M22.8532 1.14801C22.1733 0.469212 21.2519 0.0879517 20.2912 0.0879517C19.3304 0.0879517 18.409 0.469212 17.7292 1.14801L1.46515 17.412C0.999404 17.8751 0.630115 18.426 0.37865 19.0328C0.127186 19.6396 -0.00146065 20.2902 0.00015231 20.947V23C0.00015231 23.2652 0.105509 23.5196 0.293046 23.7071C0.480582 23.8947 0.734936 24 1.00015 24H3.05315C3.70991 24.0019 4.36052 23.8734 4.96731 23.6221C5.57409 23.3708 6.12501 23.0017 6.58815 22.536L22.8532 6.27101C23.5317 5.59121 23.9127 4.66998 23.9127 3.70951C23.9127 2.74905 23.5317 1.82782 22.8532 1.14801ZM5.17415 21.122C4.61016 21.6823 3.84812 21.9977 3.05315 22H2.00015V20.947C1.99914 20.5529 2.07631 20.1625 2.22719 19.7985C2.37807 19.4344 2.59967 19.1039 2.87915 18.826L15.2222 6.48301L17.5222 8.78302L5.17415 21.122ZM21.4382 4.85701L18.9322 7.36401L16.6322 5.06901L19.1392 2.56201C19.2902 2.41132 19.4694 2.29185 19.6666 2.21042C19.8638 2.129 20.0751 2.0872 20.2884 2.08744C20.5018 2.08767 20.713 2.12992 20.91 2.21178C21.107 2.29363 21.286 2.41349 21.4367 2.56451C21.5873 2.71553 21.7068 2.89476 21.7882 3.09195C21.8697 3.28914 21.9115 3.50044 21.9112 3.71378C21.911 3.92713 21.8687 4.13833 21.7869 4.33535C21.705 4.53236 21.5852 4.71132 21.4342 4.86201L21.4382 4.85701Z" fill="#374957" />
      </g>
      <defs>
        <clipPath id="clip0_403_3224">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
