import { useTranslation } from 'hooks';
import { StepperBox, StepperTitle } from 'components_old';
import { IStepProps } from 'components_old/features/PortfolioAnalysis/types';
import { WIZARD_HOW_TO_SHARE_FILE } from 'store/analysis';

interface IProps extends IStepProps {
  nextSteps: string[],
}

export const HowToShareFile = ({ handleGoToNextStep ,nextSteps}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepperTitle>
        <div>{t('analysis.howToShareFile.titleA')}</div>
        <div>{t('analysis.howToShareFile.titleB')}</div>
      </StepperTitle>
      <StepperBox
        title={t('analysis.howToShareFile.upload.title')}
        content={t('analysis.howToShareFile.upload.content')}
        onClick={() => handleGoToNextStep(
          { action: nextSteps[0], payload: { access_type: WIZARD_HOW_TO_SHARE_FILE.upload } }
        )}
      />
      <StepperBox
        title={t('analysis.howToShareFile.link.title')}
        content={t('analysis.howToShareFile.link.content')}
        onClick={() => handleGoToNextStep(
          { action: nextSteps[1], payload: { access_type: WIZARD_HOW_TO_SHARE_FILE.link } }
        )}
      />
    </>
  );
}
