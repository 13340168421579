import { useState } from 'react';
import { Stepper, GenericPage, AutomationStartScreen, STEPPER_LOOK, PortfolioAnalysisUploader, PortfolioAnalysisCongrats } from 'components_old';
import { IAutomationWizardRecord, PortfolioAnalysis_STEP, TAutomationStep } from '../../types';
import { INTRO_VIDEO_ID } from 'const';
import { useTranslation } from 'react-i18next';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { useAppDispatch } from 'hooks';
import { omit } from 'lodash';


export const PortfolioAnalysisWizard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [stepsArray, setStepsArray] = useState<Array<TAutomationStep>>([PortfolioAnalysis_STEP.S1]);

  const handleGoToNextStep = ({ action, payload }: IAutomationWizardRecord) => {
    const isAnotherRequest = action?.includes('step1');

    setStepsArray(isAnotherRequest ? [action] : [...stepsArray, action]);

    // mixpanel.track(action, omit(payload, "password"));

    if (payload) {
      // dispatch(updateComparisonRequestPayload(payload))
    }
  }

  const handleBackAction = () => {
    const currentStep = stepsArray[stepsArray.length - 1];

    if (currentStep === PortfolioAnalysis_STEP.S1) {
      router.navigate(ROUTES.ADVISOR_AUTOMATIONS)
    } else {
      setStepsArray(stepsArray.slice(0, -1));
    }

    // mixpanel.track(EVENT.BACK);
  }

  return (
    <GenericPage title={t('automations.tiles.portfolioAnalysis.title')}>
      <Stepper
        activeStep={0}
        totalSteps={0}
        showSteps={false}
        showBackBtn={true}
        handleBackAction={handleBackAction}
        look={STEPPER_LOOK.AUTOMATIONS}
      >
        {stepsArray[stepsArray.length - 1] === PortfolioAnalysis_STEP.S1 && (
          <AutomationStartScreen
            description={
              <>
                <div>{t('automations.tiles.portfolioAnalysis.descriptionA')}</div>
                {/* <div>{t('automations.tiles.portfolioAnalysis.descriptionB')}</div> */}
              </>
            }
            videoId={INTRO_VIDEO_ID}
          >
            <PortfolioAnalysisUploader handleGoToNextStep={handleGoToNextStep} nextStep={PortfolioAnalysis_STEP.S2} />
          </AutomationStartScreen>
        )}

        {stepsArray[stepsArray.length - 1] === PortfolioAnalysis_STEP.S2 && (
          <PortfolioAnalysisCongrats handleGoToNextStep={handleGoToNextStep} nextStep={PortfolioAnalysis_STEP.S1} />
        )}
      </Stepper>
    </GenericPage>
  );
}
