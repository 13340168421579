import classnames from 'classnames';
import { Circle } from './Circle';

import styles from './LoadingRow.module.scss';

interface IProps {
  className?: string;
}

export const LoadingRow = ({ className }: IProps) => {
  return (
    <div className={classnames(styles.LoadingRow, className)}>
      <Circle />
      <Circle />
      <Circle />
    </div>
  );
};
