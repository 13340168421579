import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  IChatState,
  ICreateUploadSecurelyFilesRequest,
  IGetConversationIdPayload,
  IIntercomChatState,
} from "./types";
import {
  createUploadSecurelyFilesRequest,
  getConversationId,
  uploadSecurelyFiles,
} from "./actions";

const initialIntercomChatState = {
  isOpen: false,
  conversationId: null,
  threadId: null,
};

const initialState: IChatState = {
  isGettingIntercomChatInfo: false,
  intercomChatState: initialIntercomChatState,
  intercomThreadConversationPairs: {},
  isUploadingSecurely: false,
  uploadSecurelyFilesPayload: {},
  isSecureUploadRequestProcessing: false,
  isSecureUploadRequestSuccessful: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setIntercomChatVisibility: (state, action: PayloadAction<boolean>) => {
      if (action.payload && !!state.intercomChatState?.conversationId) {
        // @ts-ignore
        window.Intercom(
          "showConversation",
          state.intercomChatState?.conversationId
        );
      } else {
        // @ts-ignore
        window.Intercom("hide");
      }

        state.intercomChatState = {
          ...state.intercomChatState,
          isOpen: action.payload,
        };
    },
    setIntercomChatState: (
      state,
      action: PayloadAction<IIntercomChatState>
    ) => {
      const newState = action.payload;

      if (newState?.isOpen && !!newState?.conversationId) {
        // @ts-ignore
        window.Intercom("showConversation", newState?.conversationId);
      } else {
        // @ts-ignore
        window.Intercom("hide");
      }

      state.intercomChatState = newState;
    },
    resetIntercomChatState: (state) => {
      state.intercomChatState = initialIntercomChatState;

      // @ts-ignore
      window.Intercom("hide");
    },
    updateUploadSecurelyFilesPayload: (
      state,
      action: PayloadAction<ICreateUploadSecurelyFilesRequest>
    ) => {
      state.uploadSecurelyFilesPayload = {
        ...state.uploadSecurelyFilesPayload,
        ...action.payload,
      };
    },
    resetUploadSecurelyFilesPayload: (state) => {
      state.uploadSecurelyFilesPayload = {};
    },
    resetIsSecureUploadSuccessful: (state) => {
      state.isSecureUploadRequestSuccessful = false;
    },
    resetChatSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversationId.pending, (state, action) => {
        state.isGettingIntercomChatInfo = true;

        // @ts-ignore
        window.Intercom("hide");
      })
      .addCase(getConversationId.fulfilled, (state, action) => {
        state.isGettingIntercomChatInfo = false;

        const requestBody: IGetConversationIdPayload = action.meta.arg;
        const threadId = requestBody?.threadId;
        const threadData = requestBody?.threadData;
        const newConversationId = action.payload?.conversationId;

        state.intercomThreadConversationPairs = {
          ...state.intercomThreadConversationPairs,
          [threadId]: newConversationId,
        };

        chatSlice.caseReducers.setIntercomChatState(state, {
          ...action,
          payload: {
            threadId,
            conversationId: newConversationId,
            isOpen: requestBody.hasOwnProperty("forceIsOpen")
              ? !!requestBody.forceIsOpen
              : state.intercomChatState.isOpen ||
                !!threadData?.has_ai_chat_with_unread,
          },
        });
      })
      .addCase(getConversationId.rejected, (state) => {
        state.isGettingIntercomChatInfo = false;
        chatSlice.caseReducers.resetIntercomChatState(state);
      })
      .addCase(uploadSecurelyFiles.pending, (state, action) => {
        state.isUploadingSecurely = true;
      })
      .addCase(uploadSecurelyFiles.fulfilled, (state, action) => {
        state.isUploadingSecurely = false;
      })
      .addCase(uploadSecurelyFiles.rejected, (state) => {
        state.isUploadingSecurely = false;
      })
      .addCase(createUploadSecurelyFilesRequest.pending, (state, action) => {
        state.isSecureUploadRequestProcessing = true;
      })
      .addCase(createUploadSecurelyFilesRequest.fulfilled, (state, action) => {
        state.isSecureUploadRequestProcessing = false;
        state.isSecureUploadRequestSuccessful = true;
        chatSlice.caseReducers.resetUploadSecurelyFilesPayload(state);
      })
      .addCase(createUploadSecurelyFilesRequest.rejected, (state) => {
        state.isSecureUploadRequestProcessing = false;
        state.isSecureUploadRequestSuccessful = false;
      });
  },
});

export const {
  setIntercomChatVisibility,
  setIntercomChatState,
  resetIntercomChatState,
  resetChatSlice,
  updateUploadSecurelyFilesPayload,
  resetUploadSecurelyFilesPayload,
  resetIsSecureUploadSuccessful,
} = chatSlice.actions;

export default chatSlice.reducer;
