import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const dataPointsState = (state: RootState) => state.dataPoints;

export const selectIsDataPointProcessing = createSelector(
  [dataPointsState],
  ({ isDataPointProcessing }) => isDataPointProcessing
);

export const selectActiveDataPoint = createSelector(
  [dataPointsState],
  ({ activeDataPoint }) => activeDataPoint
);

export const selectDataPointsList = createSelector(
  [dataPointsState],
  ({ dataPointsList }) => dataPointsList
);

export const selectDataPointActionUUID = createSelector(
  [dataPointsState],
  ({ dataPointActionUUID }) => dataPointActionUUID
);
