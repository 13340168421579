import classnames from "classnames";
import { useFetchDataAtInterval } from "hooks";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IProcessedTranscriptItem, IProcessedWord, ITranscriptItem, selectIsTranscriptTruncated, selectTranscript } from "store/chat-assistant";
import { SpeakerBlock } from "../../_general/SpeakerBlock";
import { Phrase } from "../../_general/Phrase";

interface IIntervalData {
  start: number,
  end: number,
}

interface IProps {
  botId: string
}

const COUNTER_UPDATE_FREQUENCY_MS = 200;

export const LiveTranscript = ({ botId }: IProps) => {
  const inputTranscript = useSelector(selectTranscript);
  const isTranscriptTruncated = useSelector(selectIsTranscriptTruncated);
  const [transcript, setTranscript] = useState<ITranscriptItem[]>([]);
  const [lastDisplayedWordTS, setLastDisplayedWordTS] = useState<number>(0);
  const [processedTranscript, setProcessedTranscript] = useState<IProcessedTranscriptItem[]>([]);

  const counterInterval = useRef<ReturnType<typeof setInterval> | undefined>();
  const [intervalData, setIntervalData] = useState<IIntervalData>({ start: 0, end: 0 })
  const [counter, setCounter] = useState(0);

  const { refetchInterval } = useFetchDataAtInterval({ botId, type: 'transcript' });

  useEffect(() => {
    return () => {
      counterInterval.current && clearInterval(counterInterval.current);
    }
  }, []);

  useEffect(() => {
    setTranscript(inputTranscript)
  }, [inputTranscript]);

  useEffect(() => {
    processTranscript(transcript);
  }, [transcript]);

  // Set interval for updating counter
  useEffect(() => {
    if (counterInterval.current) {
      clearInterval(counterInterval.current);
      counterInterval.current = undefined;
    }

    const intervalLengthSeconds = Math.max(intervalData.end - intervalData.start, refetchInterval / 1000);
    const intervalIterationsBetweenFetches = refetchInterval / COUNTER_UPDATE_FREQUENCY_MS

    counterInterval.current = setInterval(
      () => setCounter(counter => counter + intervalLengthSeconds / intervalIterationsBetweenFetches),
      COUNTER_UPDATE_FREQUENCY_MS
    );
  }, [intervalData]);

  // Clear interval when counter passes the end of the interval data
  useEffect(() => {
    if (!counterInterval.current) return;

    if (counter > intervalData?.end) {
      clearInterval(counterInterval.current);
      counterInterval.current = undefined;
    }
  }, [counter, intervalData]);


  const processTranscript = useCallback((input: ITranscriptItem[]) => {
    const result: IProcessedTranscriptItem[] = [];

    let currentSpeaker: string = '';
    let newChunkFirstWordTS = Infinity;
    let newChunkLastWordTS = lastDisplayedWordTS;


    input.forEach(originalTranscript => {
      // The speaker has changed - add boilerplate
      if (originalTranscript.speaker !== result[result.length - 1]?.speaker) {
        currentSpeaker = originalTranscript.speaker;

        result.push({
          speaker: currentSpeaker,
          phrases: [[]],
          startTime: originalTranscript.words[0].start_time
        });
      }

      const currentProcessedItem = result[result.length - 1];

      originalTranscript.words.forEach(word => {
        newChunkLastWordTS = Math.max(newChunkLastWordTS, word.start_time);
        const diff = word.start_time - lastDisplayedWordTS
        if (diff > 0 && diff < newChunkFirstWordTS - lastDisplayedWordTS) {
          newChunkFirstWordTS = word.start_time
        }

        const currentPhrase = currentProcessedItem.phrases[currentProcessedItem.phrases.length - 1]
        const newWord: IProcessedWord = {
          ...word,
          is_final: originalTranscript.is_final,
          original_transcript_id: originalTranscript.original_transcript_id
        }

        currentPhrase.push(newWord);

        // Start new phrase if word ends in dot
        if (newWord?.text?.endsWith('.') || newWord?.text?.endsWith('!') || newWord?.text?.endsWith('?')) {
          currentProcessedItem.phrases.push([])
        }
      })
    });

    let intervalStart = newChunkFirstWordTS;
    let intervalEnd = newChunkLastWordTS;
    const maxDeltaInSec = refetchInterval * 2 / 1000;

    if (newChunkLastWordTS > newChunkFirstWordTS && newChunkLastWordTS - newChunkFirstWordTS > maxDeltaInSec) {
      intervalStart = intervalEnd - maxDeltaInSec;
    }

    setLastDisplayedWordTS(intervalEnd);
    setCounter(intervalStart)

    setIntervalData({
      start: intervalStart,
      end: intervalEnd,
    })
    setProcessedTranscript(result)
  }, [lastDisplayedWordTS, refetchInterval])

  return (
    <div className="flex flex-1 overflow-auto flex-col-reverse scroll-smooth [overflow-anchor:auto] border rounded-lg p-3">
      <div className="flex flex-col space-y-3">
        <SpeakerBlock
          speaker="...content skipped..."
          hidden={!isTranscriptTruncated}
        />
        {
          processedTranscript?.map((paragraph, paragraphIndex) => {
            const paragraphStartTS = paragraph.phrases?.[0]?.[0]?.start_time;
            const nextParagraph = processedTranscript[paragraphIndex + 1];
            const nextParagraphStartTS = nextParagraph?.phrases?.[0]?.[0]?.start_time;

            return (
              <SpeakerBlock
                key={`item${paragraph.startTime}`}
                speaker={paragraph.speaker}
                hidden={paragraphStartTS > counter}
              >
                {
                  paragraph.phrases?.filter(phrase => phrase.length).map((phrase, phraseIndex) => {
                    const phraseStartTS = phrase[0]?.start_time;
                    const phraseEndTS = phrase[phrase.length - 1]?.end_time;

                    const nextPhrase = paragraph.phrases[phraseIndex + 1];
                    const nextPhraseStartTS = nextPhrase?.[0]?.start_time;

                    const blinkUntil = nextPhraseStartTS || nextParagraphStartTS || Infinity;

                    return (
                      <Phrase
                        key={`phrase-${phraseStartTS}`}
                        hidden={phraseStartTS > counter}
                        blinker={counter <= blinkUntil}
                      >
                        {phraseEndTS <= counter ? (
                          <Fragment>
                            {phrase?.map(word => word.text)?.join(" ")}
                          </Fragment>
                        ) : (
                          phrase.map(word => (
                            <span key={`word-${word.start_time}`} className={classnames('break-words', { hidden: word.start_time > counter })}>
                              {word.text}{" "}
                            </span>
                          ))
                        )}
                      </Phrase>
                    )
                  })
                }
              </SpeakerBlock>
            )
          })
        }
      </div>
    </div>
  )
}
