import { selectIsGetTranscriptProcessing, ITranscriptItem, IProcessedTranscriptItem, selectOngoingCallBotId, selectFullTranscript } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Loading } from 'components/layout-atoms/Loading';
import { SpeakerBlock } from 'components/common/app/_general/SpeakerBlock';
import { Phrase } from 'components/common/app/_general/Phrase';


// The call to get transcript is done in parent component
export const DisplayMeetingTranscript = () => {
  const [processedTranscript, setProcessedTranscript] = useState<IProcessedTranscriptItem[]>([])

  const ongoingCallBotId = useSelector(selectOngoingCallBotId);
  const fullTranscript = useSelector(selectFullTranscript);
  const isGetTranscriptProcessing = useSelector(selectIsGetTranscriptProcessing);

  useEffect(() => {
    if (!fullTranscript) return;

    const result = processTranscript(fullTranscript);
    setProcessedTranscript(result);
  }, [fullTranscript])

  const processTranscript = (input: ITranscriptItem[]) => {
    const result: IProcessedTranscriptItem[] = [];

    let currentSpeaker: string = "";

    input.forEach((originalTranscript) => {
      // The speaker has changed - add boilerplate
      if (originalTranscript.speaker !== result[result.length - 1]?.speaker) {
        currentSpeaker = originalTranscript.speaker;

        result.push({
          speaker: currentSpeaker,
          phrases: [[]],
          startTime: originalTranscript.words[0].start_time,
        });
      }

      const currentProcessedItem = result[result.length - 1];

      originalTranscript.words.forEach((word) => {
        const currentPhrase =
          currentProcessedItem.phrases[currentProcessedItem.phrases.length - 1];
        const newWord = {
          ...word,
          is_final: originalTranscript.is_final,
          original_transcript_id: originalTranscript.original_transcript_id,
        };

        currentPhrase.push(newWord);

        // Start new phrase if word ends in dot
        if (
          newWord?.text?.endsWith(".") ||
          newWord?.text?.endsWith("!") ||
          newWord?.text?.endsWith("?")
        ) {
          currentProcessedItem.phrases.push([]);
        }
      });
    });

    return result;
  };

  return (
    <div className="flex flex-1 overflow-auto flex-col-reverse scroll-smooth [overflow-anchor:auto] border rounded-lg p-3">
      {(isGetTranscriptProcessing && !fullTranscript) || (isGetTranscriptProcessing && !ongoingCallBotId) ? (
        <Loading variant='centered' />
      ) : (
        <div className="flex flex-col space-y-3">
          {
            processedTranscript?.map((paragraph) => {
              return (
                <SpeakerBlock
                  key={`item${paragraph.startTime}`}
                  speaker={paragraph.speaker}
                >
                  {
                    paragraph.phrases?.filter(phrase => phrase.length).map((phrase, phraseIndex) => {
                      const phraseStartTS = phrase[0]?.start_time;

                      return (
                        <Phrase key={`phrase-${phraseStartTS}`}>
                          {phrase?.map(word => word.text)?.join(" ")}
                        </Phrase>
                      )
                    })
                  }
                </SpeakerBlock>
              )
            })
          }
        </div>
      )}
    </div>
  );
};
