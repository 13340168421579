import { selectClientId } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { useLocation } from 'react-router-dom';
import { ClientDataRecordType, IClientDataRecord, selectClientDataRecordsList } from 'store/clientManagement';
import { v4 as uuidv4 } from 'uuid';
import { MeetingBreakDown, MeetingBreakDownContext } from 'components/common/app/_general/MeetingBreakDown';
import { useMixpanel } from 'hooks';

export const CopilotMeetingSummaryPage = () => {
  const { search } = useLocation();
  useMixpanel();
  const params = new URLSearchParams(search);

  const meetingId = params.get('meetingId');

  const clientId = useSelector(selectClientId);
  const clientDataRecordsList = useSelector(selectClientDataRecordsList);

  const [dataRecord, setDataRecord] = useState<IClientDataRecord>();

  useEffect(() => {
    const theDataRecord = clientDataRecordsList?.find(record => record.recallBotId === meetingId)
    setDataRecord(
      theDataRecord || {
        recallBotId: meetingId || '',
        clientId: clientId,
        id: uuidv4(),
        type: ClientDataRecordType.CLIENT_MEETING
      })
  }, [meetingId, clientId, clientDataRecordsList]);

  return (
    <GeneralLayout isOneScreenOnly={true}>
      <MeetingBreakDown context={MeetingBreakDownContext.SUMMARY} dataRecord={dataRecord} />
    </GeneralLayout>
  );
};

