import { Icon, IIconProps } from './Icon';


export const Envelope = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 14 14"
    >
      <path
        d="M11.0833 0.583008H2.91667C2.1434 0.583934 1.40208 0.891522 0.855295 1.4383C0.308514 1.98508 0.00092625 2.72641 0 3.49967L0 10.4997C0.00092625 11.2729 0.308514 12.0143 0.855295 12.561C1.40208 13.1078 2.1434 13.4154 2.91667 13.4163H11.0833C11.8566 13.4154 12.5979 13.1078 13.1447 12.561C13.6915 12.0143 13.9991 11.2729 14 10.4997V3.49967C13.9991 2.72641 13.6915 1.98508 13.1447 1.4383C12.5979 0.891522 11.8566 0.583934 11.0833 0.583008ZM2.91667 1.74967H11.0833C11.4326 1.75036 11.7737 1.85556 12.0627 2.05173C12.3517 2.2479 12.5754 2.52606 12.705 2.85042L8.23783 7.31817C7.90908 7.64561 7.46399 7.82945 7 7.82945C6.53601 7.82945 6.09092 7.64561 5.76217 7.31817L1.295 2.85042C1.42459 2.52606 1.64827 2.2479 1.93728 2.05173C2.22628 1.85556 2.56738 1.75036 2.91667 1.74967ZM11.0833 12.2497H2.91667C2.45254 12.2497 2.00742 12.0653 1.67923 11.7371C1.35104 11.4089 1.16667 10.9638 1.16667 10.4997V4.37467L4.93733 8.14301C5.48487 8.68916 6.22665 8.99586 7 8.99586C7.77335 8.99586 8.51513 8.68916 9.06267 8.14301L12.8333 4.37467V10.4997C12.8333 10.9638 12.649 11.4089 12.3208 11.7371C11.9926 12.0653 11.5475 12.2497 11.0833 12.2497Z"
      />
    </Icon>
  );
};
