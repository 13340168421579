export const ACTION = {
  MAILBOX: {
    GET_THREADS_LIST: "getThreadsList",
    SEARCH_THREADS_LIST: "searchThreadsList",
    GET_DRAFTS_LIST: "getDraftsList",
    GET_ARCHIVED_THREADS_LIST: "getArchivedThreadsList",
    GET_MESSAGE: "getMessage",
    GET_DRAFT: "getDraft",
    UPDATE_THREAD_LABEL: "updateThreadLabel",
    DELETE_THREAD_LABEL: "deleteThreadLabel",
  },
  NOTIFICATIONS: {
    MARK_AS_READ: "markAsRead",
    GET_GENERAL_CONVERSATION: "getGeneralConversation",
  },
  NYLAS: {
    GENERATE_AUTH_URL: "generateAuthUrl",
    GENERATE_CALENDAR_AUTH_URL: "generateCalendarAuthUrl",
    EXCHANGE_CODE_FROM_URL_FOR_TOKEN: "exchangeCodeFromUrlForToken",
    EXCHANGE_CALENDAR_CODE_FROM_URL_FOR_TOKEN:
      "exchangeCalendarCodeFromUrlForToken",
    DELETE_CALENDAR_ACCOUNT: "deleteCalendarAccount",
  },
  MAILBOX_MIDDLEWARE: {
    TRIGGER_SET_SELECTED_THREAD: "triggerSetSelectedThread",
  },
  USERS: {
    GET_USERS_LIST: "getUsersList",
    GET_USER_DETAILS: "getUserDetails",
    CREATE_USER: "createUser",
    UPDATE_USER: "updateUser",
    DELETE_USER: "deleteUser",
    SIGN_UP: "signUp",
    ADD_USER: "addUser",
    UPDATE_ACTIVE_ADVISOR_MAILBOX: "updateActiveAdvisorMailbox",
  },
  CALENDAR: {
    GET_CALENDAR_EVENTS: "getCalendarEvents",
  },
  REDTAIL: {
    CONNECT: "connectRedtail",
    DISCONNECT: "disconnectRedtail",
  },
  ANALYSIS: {
    CREATE_REQUEST: "createRequest",
    UPLOAD_ANALYSIS_FILES: "uploadAnalysisFiles",
    OH_NO: "ohNo",
  },
  CREDENTIALS: {
    SAVE_CREDENTIALS: "saveCredentials",
  },
  PORTFOLIO_MODEL: {
    UPDATE_MODELS: "updatePortfolioModels",
    CREATE_MODELS: "createPortfolioModels",
    LIST_MODELS: "listPortfolioModels",
    UPLOAD_PORTFOLIO_MODEL: "uploadPortfolioModel",
    UPLOAD_COMPARISON_FILES: "uploadComparisonFiles",
    CREATE_COMPARISON_REQUEST: "createComparisonRequest",
  },
  AUTH: {
    SIGN_UP: "signUp",
    RESET_REDUX: 'resetRedux'
  },
  CHAT: {
    GET_CONVERSATION_ID: "getConversationId",
    UPLOAD_SECURELY_FILES: "uploadSecurelyFiles",
    CREATE_UPLOAD_SECURELY_FILES_REQUEST: "createUploadSecurelyFilesRequest",
  },
  PROFILE: {
    UPDATE_PREFERENCES: "updatePreferences",
    GET_PROFILE_DETAILS: "getProfileDetails",
  },
  DATA_EXTRACTOR: {
    CREATE_REQUEST: "dataExtractorCreateRequest",
  },
  AUTOMATION: {
    CREATE_IS_EXTRACTION_HUMAN_CHECK_REQUEST:
      "createInvestmentStatementWithHumanCheckRequest",
  },
  CHAT_ASSISTANT: {
    CREATE_BOT: "createBot",
    GET_TRANSCRIPT: "getTranscript",
    GET_TRANSCRIPT_AI_ANSWER: "getTranscriptAIAnswer",
    GET_PAST_DATA_AI_ANSWER: "getPastDataAIAnswer",
    GET_DATA_POINTS: "getDataPoints",
    GET_DATA_POINT_VALUE: "getDataPointValue",
    GET_DATA_POINTS_STATUS: "getDataPointsStatus",
    GET_QUESTION_VALUE: "getQuestionValue",
    GET_QUESTIONS_STATUS: "getQuestionsStatus",
    DOWNLOAD_VIDEO: "downloadVideo",
    DOWNLOAD_AUDIO: "downloadAudio",
    GET_TRANSCRIPT_VIDEO_URL: "getTranscriptVideoUrl",
    GET_TRANSCRIPT_AUDIO_URL: "getTranscriptAudioUrl",
    DOWNLOAD_TRANSCRIPT: "downloadTranscript",
    GET_POST_CALL_SUMMARY: "getPostCallSummary",
    GET_HEADS_UP_DISPLAY: "getHeadsUpDisplay",
    UPLOAD_IN_PERSON_RECORDING: "uploadInPersonRecording",
  },
  CLIENT_MANAGEMENT: {
    GET_CLIENTS_LIST: "getClientsList",
    CREATE_CLIENT: "createClient",
    GET_CLIENT: "getClient",
    DELETE_CLIENT: "deleteClient",
    CREATE_DATA_RECORD: "createDataRecord",
    LINK_GOOGLE_DRIVE_FOLDER: "linkGoogleDriveFolder",
    GET_DATA_RECORDS_LIST: "getDataRecordsList",
    DELETE_DATA_RECORD: "deleteDataRecord",
    GET_AI_DATA_SEARCH_ANSWER: "getAiDataSearchAnswer",
    GET_AI_DATA_SEARCH_WHO_IS_CLIENT: "getAiDataSearchWhoIsClient",
    GET_AI_DATA_SEARCH_DO_AGENDA: "getAiDataSearchDoAgenda",
    SYNC_SALESFORCE: "syncSalesforce",
    IMPORT_REDTAIL: "importRedtail",
    SYNC_REDTAIL: "syncRedtail",
  },
  TEMPLATES_MANAGEMENT: {
    GET_TEMPLATES: "getTemplates",
    GET_ALL_TEMPLATES: "getAllTemplates",
    CREATE_PERSONAL_TEMPLATE: "createPersonalTemplate",
    UPDATE_PERSONAL_TEMPLATE: "updatePersonalTemplate",
    DELETE_PERSONAL_TEMPLATE: "deletePersonalTemplate",
    CREATE_ORG_TEMPLATE: "createOrgTemplate",
    UPDATE_ORG_TEMPLATE: "updateOrgTemplate",
    DELETE_ORG_TEMPLATE: "deleteOrgTemplate",
    UPDATE_TEMPLATE_PROMPT: "updateTemplatePrompt",
  },
  HEADS_UP_CARDS: {
    GET_CARDS: "getCards",
    CREATE_CARD: "createCard",
    UPDATE_CARD: "updateCard",
    DELETE_CARD: "deleteCard",
  },
  DATA_POINTS: {
    GET_DATA_POINTS: "getDataPoints",
    CREATE_DATA_POINT: "createDataPoint",
    UPDATE_DATA_POINT: "updateDataPoint",
    DELETE_DATA_POINT: "deleteDataPoint",
  },
  ORGANIZATIONS: {
    GET_ORGANIZATIONS: "getOrganizations",
    GET_ORGANIZATION: "getOrganization",
    CREATE_ORGANIZATION: "createOrganization",
    UPDATE_ORGANIZATION: "updateOrganization",
    DELETE_ORGANIZATION: "deleteOrganization",
  },
  IN_PERSON_MEETING: {
    CREATE_MEETING: "createInPersonMeeting",
    GET_TRANSCRIPT: "getInPersonMeetingTranscript",
    DOWNLOAD_TRANSCRIPT: "downloadInPersonMeetingTranscript",
    GET_DATA_POINT_VALUE: "getInPersonMeetingDataPointValue",
    GET_QUESTION_ANSWERS: "getInPersonMeetingQuestionAnswers",
    GET_POST_CALL_SUMMARY: "getInPersonMeetingPostCallSummary",
  },
};
