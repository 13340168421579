import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../hooks";
import { getAccessToken } from "../../../../../store/authentication";

const Integrations = () => {
  const accessToken = useAppSelector(getAccessToken);
  const [jwtToken, setJwtToken] = useState('');
  useEffect(() => {
    const fetchJwtToken = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/authentication/sign-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const data = await response.json();
      setJwtToken(data.token);
    };

    fetchJwtToken();
  }, [accessToken]);

  return (
    <div style={{ width: '100%', height: '100vh', border: 'none' }}>
      {jwtToken && (
        <iframe
          src={`https://tray-marketplace-app-delta.vercel.app/?jwt=${jwtToken}`}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Integrations"
        />
      )}
    </div>
  );
};

export default Integrations;
