import { useTranslation } from 'hooks';
import { LinkText } from 'components_old';

interface IProps {
  text?: string | null;
  email: string,
  subject: string;
  body?: string | null;
}

export const ContactUs = ({
  email, subject, body = "", text
}: IProps) => {
  const { t } = useTranslation();

  return (
    <LinkText
      href={`mailto:${email}?subject=${subject}&body=${body}`}
      title={text || t('common.contactUs')}
    >
      {text || t('common.contactUs')}
    </LinkText>
  );
};
