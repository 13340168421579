import { useTranslation } from 'hooks';
import { StepperBox, StepperTitle } from 'components_old';
import { IStepProps, STEP } from 'components_old/features/PortfolioAnalysis/types';
import { WIZARD_OPERATION } from 'store/analysis';

export const GettingStarted = ({ handleGoToNextStep }: IStepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepperTitle>
        <div>{t('analysis.gettingStarted.titleA')}</div>
        <div>{t('analysis.gettingStarted.titleB')}</div>
      </StepperTitle>
      <StepperBox
        title={t('analysis.gettingStarted.extraction.title')}
        content={t('analysis.gettingStarted.extraction.content')}
        onClick={() => handleGoToNextStep({ action: STEP.S2_EXTRACTION, payload: { type: WIZARD_OPERATION.EXTRACTION } })}
      />
      <StepperBox
        title={t('analysis.gettingStarted.entry.title')}
        content={t('analysis.gettingStarted.entry.content')}
        onClick={() => handleGoToNextStep({ action: STEP.S2_ENTRY, payload: { type: WIZARD_OPERATION.ENTRY } })}
      />
      <StepperBox
        title={t('analysis.gettingStarted.report.title')}
        content={t('analysis.gettingStarted.report.content')}
        onClick={() => handleGoToNextStep({ action: STEP.S2_REPORT, payload: { type: WIZARD_OPERATION.REPORT } })}
      />
    </>
  );
}
