/* eslint-disable no-useless-escape */
import { EmailType, REPLY_TYPE } from "const";
import DOMPurify from "dompurify";
import { IConnectedEmail } from "store/users";
import { formatPreviewDate } from "./date";
import { IDraftComposer, IEmail, IEmailThread } from "store/mailbox";

interface IBuildDraft {
  email?: IEmail;
  type: keyof typeof REPLY_TYPE;
  connectedEmail: IConnectedEmail | undefined;
}

export const buildInitialDraft = ({
  email,
  type,
  connectedEmail,
}: IBuildDraft): IDraftComposer => {
  const provider = connectedEmail?.provider;

  const { to, cc } = buildRecipientsList({ email, type, connectedEmail });

  if (!email) {
    return {
      to: [],
      cc: [],
      subject: "",
      body: "",
    } as unknown as IDraftComposer;
  }

  const draft = {
    ...email,
    to,
    cc,
    subject: generateSubject(email),
    body: generateDraftBody(email, provider),
    replyToMessageId: email?.id,
  };

  return draft as IDraftComposer;
};

const generateSubject = (email?: IEmail) => {
  if (!email) return "";

  const subject = email?.subject?.toLowerCase()?.startsWith("re:")
    ? email?.subject
    : `Re: ${email?.subject}`;

  return subject;
};

const generateDraftBody = (email?: IEmail, provider?: string) => {
  if (provider === EmailType.gmail) {
    return buildGmailReplyFrame(email);
  } else if (provider === EmailType.outlook) {
    return buildOutlookReplyFrame(email);
  }
  return email?.body;
};

const buildOutlookReplyFrame = (email?: IEmail): string => {
  const from = email?.from?.length ? email?.from[0].email : "";

  return `<html><head><body dir=\"ltr\">
  <div class=\"elementToProof\">&nbsp;</div>
  <div id=\"appendonsend\"></div>
  <hr style=\"display:inline-block;width:98%\" tabindex=\"-1\">
  <div id=\"divRplyFwdMsg\" dir=\"ltr\">
  <font>
  <b>From:</b> ${from} <br>
  <b>Sent:</b> ${
    email?.date
      ? formatPreviewDate(new Date(Math.floor(email?.date * 1000)))
      : "-"
  }<br>
  <b>To:</b> ${email?.to?.map((item) => item.email)?.join(", ")} <br>
  ${
    email?.cc?.length
      ? `<b>Cc:</b> ${email?.cc?.map((item) => item.email)?.join(", ")} <br>`
      : ""
  }
  <b>Subject:</b> ${email?.subject}
  </font>
  <div>&nbsp;</div>\n</div>\n</div><div dir=\"ltr\">${
    email?.body
      ?.replaceAll("appendonsend", "x_appendonsend")
      .replaceAll("divRplyFwdMsg", "x_divRplyFwdMsg")
      .replaceAll("elementToProof", "x_elementToProof") || ""
  }</div></body></html>`;
};

const buildGmailReplyFrame = (email?: IEmail): string => {
  return `<div dir=\"ltr\">&nbsp;</div><div class=\"gmail_quote\">${
    email?.body || ""
  }</div>`;
};

interface IRecipientsList extends Pick<IEmail, "to" | "cc"> {}

export const buildRecipientsList = ({
  email,
  type,
  connectedEmail,
}: IBuildDraft): IRecipientsList => {
  const from = email?.from || [];
  const to =
    email?.to?.filter((contact) => contact.email !== connectedEmail?.email) ||
    [];
  const cc =
    email?.cc?.filter((contact) => contact.email !== connectedEmail?.email) ||
    [];

  return {
    to: type === REPLY_TYPE.REPLY ? from : [...from, ...to],
    cc: type === REPLY_TYPE.REPLY ? [] : cc,
  };
};

export const cleanEmailBody = (body: string | Node) => {
  if (!body) return "";

  let cleanedBody = DOMPurify.sanitize(body, { USE_PROFILES: { html: true } });
  return cleanedBody;
};

export const filterRelevantMessageAttachments = (
  message: IEmail | undefined
) => {
  if (!message) return [];
  return message.files?.filter(
    (file) =>
      file.content_disposition === "attachment" &&
      !file?.content_type?.includes("calendar")
  );
};

export const messageHasAttachments = (message: IEmail | undefined) => {
  return filterRelevantMessageAttachments(message).length > 0;
};

export interface IEmailMeta {
  hasAttachments: boolean;
  isGmailWithHistory: boolean;
  isOutlookWithHistory: boolean;
}

export const getMessageMeta = (message: IEmail | undefined): IEmailMeta => {
  const hasAttachments = filterRelevantMessageAttachments(message).length > 0;
  const isGmailWithHistory =
    !!message?.body && message?.body?.includes('"gmail_quote"');
  const isOutlookWithHistory =
    !!message?.body &&
    message?.body?.includes('"elementToProof"') &&
    message?.body?.includes('"appendonsend"');

  return {
    hasAttachments,
    isGmailWithHistory,
    isOutlookWithHistory,
  };
};

export const convertFileSize = (bytes: number | undefined) => {
  if (!bytes) return "-";

  if (bytes < Math.pow(10, 3)) {
    return `${bytes} bytes`;
  } else if (bytes < Math.pow(10, 6)) {
    return `${Math.floor(bytes / Math.pow(10, 3))} KB`;
  } else if (bytes < Math.pow(10, 9)) {
    return `${Math.floor(bytes / Math.pow(10, 6))} MB`;
  } else {
    return `${Math.floor(bytes / Math.pow(10, 9))} GB`;
  }
};

export const hasArchivedLabel = (thread: IEmailThread) => {
  const { labels } = thread;
  const archivedLabel = labels?.find(
    (label) =>
      label?.display_name?.includes("paraplanner.ai") &&
      label?.display_name?.includes("Skipped")
  );

  return !!archivedLabel;
};
