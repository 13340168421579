import styles from './ComparisonGettingStarted.module.scss'
import { useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, LinkText, StepperTitle } from 'components_old';
import { useSelector } from 'react-redux';
import { listPortfolioModels, selectGettingModels } from 'store/portfolioModel';
import { useEffect } from 'react';
import { IStepperComponentProps } from '../types';
import SampleComparison from 'documents/Side-by-Side Comparison.pdf'


export const ComparisonGettingStarted = ({ nextStep, handleGoToNextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const isGettingModels = useSelector(selectGettingModels);

  useEffect(() => {
    listPortfolioModels();
  }, []);

  const handleGetStarted = () => {
    // mixpanel.track(EVENT.PORTFOLIO_COMPARISON.GETTING_STARTED);
    handleGoToNextStep(nextStep);
  }

  return (
    <>
      <StepperTitle className={styles.Title}>
        <div>{t('portfolioComparison.comparisonGettingStarted.titleA')}</div>
        <div>{t('portfolioComparison.comparisonGettingStarted.titleB')}</div>
      </StepperTitle>

      <Button
        look={BUTTON_LOOK.SECONDARY}
        loading={isGettingModels}
        onClick={handleGetStarted}
      >
        {t('portfolioComparison.comparisonGettingStarted.getStarted')}
      </Button>

      <div className={styles.SampleBtnWrapper}>
        <LinkText title={t('portfolioComparison.comparisonGettingStarted.viewSample')} href={SampleComparison} target='_blank'>
          {t('portfolioComparison.comparisonGettingStarted.viewSample')}
        </LinkText>
      </div>
    </>
  );
}
