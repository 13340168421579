import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CONVERSATION_RECORD_TYPE,
  IConversationRecord,
  INotificationsState,
} from "./types";
import { getGeneralConversation, markAsRead } from "./actions";

const initialState: INotificationsState = {
  markingAsRead: false,
  gettingGeneralConversation: false,
  generalConversationRecord: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateGeneralConversationRecord: (
      state,
      action: PayloadAction<IConversationRecord>
    ) => {
      const payload = action.payload;

      if (payload.type === CONVERSATION_RECORD_TYPE.GENERAL) {
        state.generalConversationRecord = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markAsRead.pending, (state, action) => {
        state.markingAsRead = true;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.markingAsRead = false;
      })
      .addCase(markAsRead.rejected, (state) => {
        state.markingAsRead = false;
      })
      .addCase(getGeneralConversation.pending, (state, action) => {
        state.gettingGeneralConversation = true;
      })
      .addCase(getGeneralConversation.fulfilled, (state, action) => {
        state.gettingGeneralConversation = false;
        state.generalConversationRecord = action.payload;
      })
      .addCase(getGeneralConversation.rejected, (state) => {
        state.gettingGeneralConversation = false;
      });
  },
});

export const { updateGeneralConversationRecord } = notificationsSlice.actions;

export default notificationsSlice.reducer;
