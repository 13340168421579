import { useTranslation } from 'hooks';
import { Button, Input, StepperTitle } from 'components_old';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { IISDataEntryRequest, IStepperComponentProps } from '../../types';

export const ISDataEntryShareCredentials = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IISDataEntryRequest>({ mode: 'onBlur' });

  const onSubmit = (values: IISDataEntryRequest) => {
    handleGoToNextStep(
      {
        action: nextStep,
        payload: values
      }
    )
  };

  useEffect(() => {
    setFocus('tool');
  }, [setFocus]);

  return (
    <>
      <StepperTitle>
        <div>{t('credentialsManagement.titleA')}</div>
        <div>{t('credentialsManagement.titleB')}</div>
      </StepperTitle>
      <form
        style={{ marginBottom: 10 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          formed
          placeholder={t('placeholder.tool') as string}
          error={errors?.tool?.message}
          {...register('tool', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Input
          formed
          placeholder={t('placeholder.username') as string}
          error={errors?.username?.message}
          {...register('username', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Input
          formed
          type="password"
          placeholder={t('placeholder.password') as string}
          error={errors?.password?.message}
          {...register('password', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Button disabled={!isValid} type="submit">
          {t('common.submit')}
        </Button>
      </form>
    </>
  );
}
