import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IAnalysisApi } from "./types";

export const analysis: IAnalysisApi = {
  uploadFiles: (payload) =>
    http
      .post(API_ROUTES.analysis.uploadFiles, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data),
  createRequest: (payload) =>
    http
      .post(API_ROUTES.analysis.createRequest, payload)
      .then((response) => response.data),
  ohNo: () =>
    http.post(API_ROUTES.analysis.ohNo).then((response) => response.data),
};
