import { Envelope } from 'icons';
import { IField } from 'components_old/shared/DataField';
import { i18n } from 'i18n';
import { IUser } from 'store/users';

const firstNamePlaceholder = i18n.t('common.firstNamePlaceholder');
const lastNamePlaceholder = i18n.t('common.lastNamePlaceholder');
const emailPlaceholder = i18n.t('common.emailPlaceholder');

export const userDetailFields: IField<IUser>[] = [
  {
    key: 'firstName',
    label: i18n.t('common.firstName'),
    placeholder: firstNamePlaceholder,
  },
  {
    key: 'lastName',
    label: i18n.t('common.lastName'),
    placeholder: lastNamePlaceholder,
  },
  {
    key: 'email',
    label: i18n.t('common.email'),
    placeholder: emailPlaceholder,
  },
];
