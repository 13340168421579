import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "lib/utils"
import { Loader } from "lucide-react"

const loadingVariants = cva(
  "",
  {
    variants: {
      variant: {
        default:
          "",
        centered:
          "w-full h-full flex justify-center items-center",
        item:
          "flex items-center justify-center rounded-md p-3 w-full border shadow-none rounded-lg ",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface LoadingProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
  VariantProps<typeof loadingVariants> {
  iconClassName?: string;
}

const Loading = React.forwardRef<HTMLDivElement, LoadingProps>(
  ({ className, variant, disabled, children, iconClassName, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(loadingVariants({ variant, className }))}      >
        {children || (<Loader className={cn("text-primary size-4 animate-spin duration-2000", iconClassName)} />)}
      </div>
    )
  }
)
Loading.displayName = "Loading"

export { Loading, loadingVariants as buttonVariants }
