import axios from "axios";
import { toast } from "react-toastify";

import { i18n } from "i18n";
import { isArray } from "lodash";

export const processApiError = (error: unknown, showToast: boolean = true) => {
  let message = i18n.t("errors.somethingWentWrong");
  if (axios.isAxiosError(error)) {
    if (error?.response?.data?.message) {
      message = error.response.data.message;
    } else if (error.message) {
      message = error.message;
    }
  }

  if (showToast) {
    isArray(message) && message.length
      ? toast.error(message[0])
      : toast.error(message);
  }

  return message;
};
