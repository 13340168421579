import styles from './MailboxPage.module.scss';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { FIXED_PANEL_WIDTH, GENERAL_CONVERSATION_ID } from 'const';
import { Panel, Panels } from 'components_old';
import { ThreadsListHeader } from 'components_old/features/Mailbox/ThreadsListHeader';
import { ThreadDetailsHeader } from 'components_old/features/Mailbox/ThreadDetailsHeader';
import { ThreadDetailsView } from 'components_old/features/Mailbox/ThreadDetailsView';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { selectProfileDetails } from 'store/profile';
import { NylasConnect } from 'components_old/features/Mailbox/NylasConnect';
import { getConversationId, selectIntercomThreadConversationPairs } from 'store/chat';
import { mailboxActions } from 'store/mailbox';

export const AdvisorMailboxPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profileDetails = useAppSelector(selectProfileDetails);
  const intercomConversationIds = useAppSelector(selectIntercomThreadConversationPairs);

  useEffect(() => {
    return () => {
      dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, intercomConversationIds }));
      dispatch(mailboxActions.updateSelectedThread(null));
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} - {t('common.appCredo')}
        </title>
      </Helmet>
      <Panels>
        {profileDetails?.connectedEmail ? (
          <>
            <Panel header={<ThreadsListHeader />} body={<Outlet />} width={FIXED_PANEL_WIDTH} />
            <Panel header={<ThreadDetailsHeader />} body={<ThreadDetailsView />} bodyClassName={styles.ThreadDetailsBody} />
          </>
        ) : (
          <Panel body={<NylasConnect />} />
        )}
      </Panels>
    </>
  );
};
