import { useEffect } from 'react';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { useSelector } from 'react-redux';
import { updateSavedCopilotConfigurationFormValues, selectOngoingCallBotId } from 'store/chat-assistant';
import { useAppDispatch, useDevFlag, useTranslation } from 'hooks';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card } from 'components/ui/card';
import { ConfigureCopilotForm } from 'components/common/app/copilot/configure/ConfigureCopilotForm';
import { CalendarEventsToday } from 'components/common/app/copilot/configure/CalendarEventsToday';
import { getClientsList } from 'store/clientManagement';
import { getProfileDetails } from 'store/profile';

export const ConfigureCopilotPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDevEnv } = useDevFlag()

  const ongoingCallBotId = useSelector(selectOngoingCallBotId);

  useEffect(() => {
    dispatch(getProfileDetails())
    dispatch(getClientsList())
  }, [dispatch])

  useEffect(() => {
    if (ongoingCallBotId) {
      router.navigate(ROUTES.APP.COPILOT.MEETING);
      dispatch(updateSavedCopilotConfigurationFormValues(null))
    }
  }, [ongoingCallBotId, dispatch])

  return (
    <GeneralLayout isOneScreenOnly={true}>
      <div className={isDevEnv ? "mx-auto grid w-full h-full max-w-6xl items-start gap-6 grid-cols-[1fr_2fr]" : "mx-auto grid w-full h-full max-w-3xl items-center"}>
        {isDevEnv && (
          <CalendarEventsToday />
        )}


        <Card className='flex flex-col h-full overflow-auto'>
          <CardHeader>
            <CardTitle>{t('page.ConfigureCopilotPage.title')}</CardTitle>
            <CardDescription>
              {t('page.ConfigureCopilotPage.description')}
            </CardDescription>
          </CardHeader>

          <CardContent className='flex flex-col grow'>
            <ConfigureCopilotForm />
          </CardContent>

        </Card>
      </div>
    </GeneralLayout>
  );
};
