import { useTranslation } from 'hooks';
import { Button, Input, StepperTitle } from 'components_old';
import { IFileSecureLinkPayload, IStepMandatoryProps } from 'components_old/features/PortfolioAnalysis/types';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

export const FileSecureLink = ({ handleGoToNextStep, nextStep }: IStepMandatoryProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IFileSecureLinkPayload>({ mode: 'onBlur' });

  useEffect(() => {
    setFocus('url');
  }, [setFocus]);

  const onSubmit = (values: IFileSecureLinkPayload) => {
    handleGoToNextStep({ action: nextStep, payload: { google_drive_link: values.url } })
  };

  return (
    <>
      <StepperTitle>
        <div>{t('analysis.fileSecureLink.titleA')}</div>
        <div>{t('analysis.fileSecureLink.titleB')}</div>
        <div>{t('analysis.fileSecureLink.titleC')}</div>
      </StepperTitle>
      <form
        style={{ marginBottom: 10 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          formed
          placeholder={t('placeholder.url') as string}
          error={errors?.url?.message}
          {...register('url', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Button disabled={!isValid} type="submit">
          {t('common.submit')}
        </Button>
      </form>
    </>
  );
}
