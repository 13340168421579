import { useAppDispatch, useTranslation } from 'hooks';
import { getTranscriptAudioUrl, resetAudioUrl, selectAudioUrl, selectIsGetTranscriptAudioUrlProcessing, selectOngoingCallBotId } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Card, CardHeader } from 'components/ui/card';
import { Info } from 'lucide-react';
import { CardDescription } from 'components/ui/card';
import { Loading } from 'components/layout-atoms/Loading';
import { IClientDataRecord } from 'store/clientManagement';
import { cn } from 'lib/utils';
import { LabelDiv } from 'components/ui/label';

interface IProps {
  botId: string;
  dataRecord: IClientDataRecord;
}

export const MeetingAudio = ({ botId, dataRecord }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const audioRef = useRef<HTMLAudioElement>(null);
  const [durationFixed, setDurationFixed] = useState(false)

  const audioUrl = useSelector(selectAudioUrl);
  const isGetTranscriptAudioUrlProcessing = useSelector(selectIsGetTranscriptAudioUrlProcessing);
  const ongoingCallBotId = useSelector(selectOngoingCallBotId);


  useEffect(() => {
    return () => {
      dispatch(resetAudioUrl())
    }
  }, [dispatch])


  useEffect(() => {
    if (botId) {
      dispatch(getTranscriptAudioUrl({ botId, dataRecord }))
    }
  }, [dispatch, botId, dataRecord])

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", () => {
        if (audioRef.current && audioRef.current?.duration === Infinity) {
          audioRef.current.currentTime = 1e101;

          audioRef.current.addEventListener("timeupdate", () => {
            if (audioRef.current) {
              audioRef.current.currentTime = 0;
            }
          }, { once: true });
        }
      })
      audioRef.current.addEventListener("canplaythrough", () => {
        if (audioRef.current && isFinite(audioRef.current?.duration)) {
          setDurationFixed(true);
        }
      }, { once: true })
    }
  }, [audioUrl])


  if (!!ongoingCallBotId && ongoingCallBotId === botId) {
    return (
      <Card className='shadow-none border'>
        <CardHeader>
          <CardDescription className='flex items-center gap-3'>
            <Info className='min-w-4 size-4' />
            <span>
              {t('general.audioAvailableAfterMeeting')}
            </span>
          </CardDescription>
        </CardHeader>
      </Card>
    )
  }

  return (
    <Fragment>
      {audioUrl ? (
        <div className='h-full flex flex-col items-start justify-center min-h-14'>
          {!durationFixed && (
            <div className={cn('flex w-full justify-start items-center gap-4')}>
              <LabelDiv variant='description'>
                {t('general.audioInPreparation')}
              </LabelDiv>
              <Loading />
            </div>
          )}
          <audio
            ref={audioRef}
            src={audioUrl}
            className={cn('min-h-14 self-start', { 'hidden': !durationFixed })}
            controls controlsList="nodownload" preload='auto'
          />
        </div>
      ) : (
        isGetTranscriptAudioUrlProcessing ?
          <Loading variant="centered" className='min-h-14' /> :
          <Card className='shadow-none border'>
            <CardHeader>
              <CardDescription className='flex items-center gap-3'>
                <Info className='min-w-4 size-4' />
                <span>
                  {t('general.audioNotAvailable')}
                </span>
              </CardDescription>
            </CardHeader>
          </Card>
      )}
    </Fragment>
  );
};
