import { Middleware } from "redux";
import { IEmailThread, IGetThreadsListPayload, IMailboxState } from "./mailbox";
import { mailboxActions } from "store/mailbox";
import { getThreadsList } from "store/mailbox/actions";
import { RoleEnum, THREAD_LIST_LIMIT } from "const";
import { ACTION } from "const/actions";
import { AnyAction, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit";
import { IProfileState } from "./profile";
import {
  IChatState,
  getConversationId,
} from "store/chat";

const mailboxMiddleware: Middleware = (store) => (next) => async (action) => {
  // This executes the flow when a new inbox is selected
  if (action?.type === ACTION.USERS.UPDATE_ACTIVE_ADVISOR_MAILBOX) {
    next(action);

    await store.dispatch(mailboxActions.resetThreadsAndSelectedThread());

    let mailboxState: IMailboxState = store.getState().mailbox;
    // Should reset thread list

    const payload: IGetThreadsListPayload = {
      offset: mailboxState?.threadsList?.offset,
      limit: THREAD_LIST_LIMIT,
    };

    // @ts-ignore
    await store.dispatch(getThreadsList(payload));

    mailboxState = store.getState().mailbox;
    //  I should have thread list

    // const firstThread = mailboxState?.threadsList?.data?.[0];

    // if (!mailboxState?.selectedThread && firstThread) {
    //   await doSetSelectedThread(store, firstThread);
    // }
    return;
  } else if (
    action.type === ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD
  ) {
    next(action);

    const emailThread: IEmailThread = action.payload;
    let mailboxState: IMailboxState = store.getState().mailbox;
    const selectedThreadId = mailboxState?.selectedThread?.thread_id;

    if (selectedThreadId !== emailThread?.thread_id) {
      await doSetSelectedThread(store, emailThread);
    }
    return;
  }

  return next(action);
};

const doSetSelectedThread = async (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  emailThread: IEmailThread
) => {
  if (!emailThread) return;

  store.dispatch(mailboxActions.updateSelectedThread(emailThread));

  let mailboxState: IMailboxState = store.getState().mailbox;
  let myProfileState: IProfileState = store.getState().myProfile;
  let chatState: IChatState = store.getState().chat;

  // I should have selected thread
  const selectedThreadData = mailboxState?.selectedThread;
  const selectedThreadId = mailboxState?.selectedThread?.thread_id;
  if (selectedThreadId) {
    if (myProfileState?.profileDetails?.role === RoleEnum.Advisor) {
      await store.dispatch(
        // @ts-ignore
        getConversationId({
          threadId: selectedThreadId,
          threadData: selectedThreadData,
          intercomConversationIds: chatState.intercomThreadConversationPairs,
        })
      );
    }

    await store.dispatch(
      // @ts-ignore
      mailboxActions.getThreadDetails({ id: selectedThreadId })
    );
  }

  mailboxState = store.getState().mailbox;
  // I should have selected thread details aka drafts

  const selectedThread = mailboxState?.selectedThread;

  // It is a thread with messages: get each message
  if (selectedThread?.messages?.length) {
    const expandedMsgIds = selectedThread?.drafts?.map(
      (draft) => draft.reply_to_message_id
    );
    const lastMessage =
      selectedThread?.messages[selectedThread?.messages?.length - 1];

    if (!expandedMsgIds.includes(lastMessage.id)) {
      expandedMsgIds.push(lastMessage.id);
    }

    expandedMsgIds
      ?.filter((id) => !!id)
      .forEach(async (id) => {
        await store.dispatch(
          // @ts-ignore
          mailboxActions.getMessage({ id })
        );
      });
  }

  // The thread has no messages but drafts: it means it is a lone draft case
  if (!selectedThread?.messages?.length && selectedThread?.drafts?.length) {
    const lastDraft =
      selectedThread?.drafts[selectedThread?.drafts?.length - 1];

    await store.dispatch(
      // @ts-ignore
      mailboxActions.getDraft({ id: lastDraft.id })
    );
  }
};

export default mailboxMiddleware;
