import { useTranslation } from 'hooks';
import { InfoCard } from './InfoCard';
import { GeneralLayout } from 'components/layout/GeneralLayout';


export const ErrorHandler = () => {
  const { t } = useTranslation();

  return (
    <GeneralLayout isOneScreenOnly={true}>
      <InfoCard
        variant="item"
        title={t('general.errorOccurred')}
        className='text-destructive border-destructive'
      />
    </GeneralLayout>
  );
};
