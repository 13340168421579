import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { CirclePlus } from 'lucide-react';
import { cn } from 'lib/utils';
import { Card, CardHeader } from 'components/ui/card';
import { CardTitle } from 'components/ui/card';
import { Loading } from 'components/layout-atoms/Loading';
import { Fieldset } from 'components/ui/fieldset';
import { ItemSelectedIcon } from 'components/common/_atoms/ItemSelectedIcon';
import { OrganizationModal } from './OrganizationModal';
import { DetailsLine } from 'components/common/_atoms/DetailsLine';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { toast } from 'react-toastify';
import { ScrollArea } from 'components/ui/scroll-area';
import { SearchInput } from 'components/common/_atoms/SearchInput';
import { IOrganization, deleteOrganization, getOrganizations, selectActiveOrganization, selectIsGettingOrganizationsList, selectIsOrganizationActionProcessing, selectOrganizationsList, setActiveOrganization } from 'store/organizations';


export const OrganizationManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isOrganizationModalVisible, setIsOrganizationModalVisible] = useState(false);
  const [organizationModalData, setOrganizationModalData] = useState<IOrganization | null>()
  const [triggerClear, setTriggerClear] = useState<number>(0)

  const isGettingOrganizationsList = useAppSelector(selectIsGettingOrganizationsList);
  const isOrganizationOperationProcessing = useAppSelector(selectIsOrganizationActionProcessing);

  const organizationsList = useAppSelector(selectOrganizationsList);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [displayList, setDisplayList] = useState(organizationsList)

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  useEffect(() => {
    setDisplayList(organizationsList)
  }, [organizationsList]);

  const handleDeleteOrganization = async () => {
    if (!activeOrganization) return;

    const response = await dispatch(deleteOrganization(activeOrganization));

    if (!response?.hasOwnProperty('error')) {
      toast(t(`component.OrganizationManagement.deleteSuccess`))
      setIsDeleteConfirmVisible(false)
    }

  }

  return (
    <Fragment>
      <div className="flex flex-1 overflow-auto gap-6">
        <Fieldset
          className='basis-2/4'
          legend={t('component.OrganizationManagement.listHeader')}
          fieldsetActions={[
            {
              onClick: () => {
                setIsOrganizationModalVisible(true)
                setOrganizationModalData(null)
              },
              tooltip: t('component.OrganizationManagement.addOrganization'),
              content: <CirclePlus className='size-4' />
            }
          ]}
        >
          <SearchInput
            inputPlaceholder={t('component.OrganizationManagement.searchPlaceholder') as string}
            onClearSearch={() => {
              setDisplayList(organizationsList)
            }}
            onChange={(value) => {
              if (!value) {
                setDisplayList(organizationsList)
              } else {
                setDisplayList(organizationsList.filter(item => {
                  return item?.name?.toLowerCase()?.includes(value?.toLowerCase())
                }))
              }
            }}
            triggerClear={triggerClear}
            containerClassName="pr-3"
          />

          <ScrollArea className="grow overflow-auto">
            <div className='flex flex-col gap-3 pr-3'>
              {
                !isGettingOrganizationsList ?
                  displayList?.map(organization => {
                    const isActive = organization?.id === activeOrganization?.id;

                    return (
                      <Card
                        key={organization.id}
                        variant="item"
                        className={cn('cursor-pointer', { 'border-primary': isActive })}
                        onClick={() => dispatch(setActiveOrganization(organization))}
                      >
                        <CardHeader className="p-3">
                          <CardTitle className="text-sm">{organization?.name}</CardTitle>
                        </CardHeader>

                        {isActive && <ItemSelectedIcon />}
                      </Card>
                    )
                  })
                  : (
                    <Loading variant="item" />
                  )
              }
            </div>
          </ScrollArea>
        </Fieldset>

        <Fieldset
          className='basis-2/4'
          legend={t('component.OrganizationManagement.organizationDetails')}
        >
          {
            activeOrganization ? (
              <div className='flex flex-col gap-4'>
                <div className="grid gap-2">
                  <DetailsLine label={t('component.OrganizationManagement.name')} value={activeOrganization?.name} />
                  <DetailsLine label={t('component.OrganizationManagement.domain')} value={activeOrganization?.domain} />
                  <DetailsLine label={t('component.OrganizationManagement.industryType')} value={activeOrganization?.industryType} />
                </div>
                <Separator />
                <div className="flex gap-3 justify-end">
                  <Button variant="destructive" onClick={() => setIsDeleteConfirmVisible(true)}>
                    {t('component.OrganizationManagement.deleteBtn')}
                  </Button>
                  <Button onClick={() => {
                    setIsOrganizationModalVisible(true)
                    setOrganizationModalData(activeOrganization)
                  }}>
                    {t('component.OrganizationManagement.editBtn')}
                  </Button>
                </div>
              </div>
            ) : (
              <InfoCard
                variant="item"
                title={t('component.OrganizationManagement.noActiveOrganization')}
                description={t('component.OrganizationManagement.noActiveOrganizationDesc') as string}
              />
            )
          }
        </Fieldset>
      </div >

      <OrganizationModal
        isVisible={isOrganizationModalVisible}
        setIsVisible={setIsOrganizationModalVisible}
        data={organizationModalData}
        onCreateCompleted={async (org: IOrganization) => {
          setTriggerClear(1)
          setTimeout(() => {
            setTriggerClear(0)
          }, 10);
          await dispatch(getOrganizations());
          dispatch(setActiveOrganization(org));
        }}
        onUpdateCompleted={async (org: IOrganization) => {
          setTriggerClear(1)
          setTimeout(() => {
            setTriggerClear(0)
          }, 10);
          await dispatch(getOrganizations());
          dispatch(setActiveOrganization(org));
        }}
      />

      {activeOrganization &&
        <ConfirmActionDialog
          onOk={handleDeleteOrganization}
          isVisible={isDeleteConfirmVisible}
          setIsVisible={setIsDeleteConfirmVisible}
          isProcessing={isOrganizationOperationProcessing}
          title={t('component.OrganizationManagement.deleteConfirmTitle')?.replace('{{name}}', activeOrganization?.name)}
          description={t('component.OrganizationManagement.deleteConfirmDescription')}
        />
      }
    </Fragment >
  );
};