import { useTranslation } from 'hooks';
import { Fragment, ReactNode } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  title?: ReactNode;
  description?: ReactNode;
  isProcessing?: boolean;
  okText?: ReactNode;
  cancelText?: ReactNode;
  onOk: Function;
  onCancel?: Function;
}

export const ConfirmActionDialog = ({
  title, description, isVisible, setIsVisible, isProcessing, okText, cancelText, onOk, onCancel
}: IProps) => {
  const { t } = useTranslation();

  const handleOk = () => {
    onOk && onOk();
  }

  const handleCancel = () => {
    setIsVisible(false)
    onCancel && onCancel();
  }

  return (
    <Fragment>
      {isVisible && (
        <AlertDialog open={isVisible}>
          <AlertDialogContent>
            <AlertDialogHeader>
              {
                title && (
                  <AlertDialogTitle>{title}</AlertDialogTitle>
                )
              }
              {
                description && (
                  <AlertDialogDescription>{description}</AlertDialogDescription>
                )
              }
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button
                onClick={() => handleCancel()}
                variant="outline"
              >
                {cancelText || t('general.cancel')}
              </Button>

              <Button
                onClick={() => handleOk()}
                loading={isProcessing}
              >
                {okText || t('general.continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </Fragment>
  );
};

