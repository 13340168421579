import { useEffect } from 'react';
import { useNylas } from '@nylas/nylas-react';
import { Button, Input } from 'components_old/shared';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import {
  clearNylasAuthUrlValue, exchangeCodeFromUrlForToken, generateAuthUrl, getNylasAuthUrl, selectIsNylasProcessing, selectNylasConnectionEstablished
} from 'store/nylas';
import { useSearchParams } from 'react-router-dom';
import { NYLAS_SUCCESS_URL } from 'const';
import { LoginLayout } from 'components_old';

import styles from './NylasConnect.module.scss'
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import { LoadingRow } from 'components_old';
import { useForm } from 'react-hook-form';

interface INylasForm {
  email: string;
}

export const NylasConnect = () => {
  const { t } = useTranslation();
  const nylas = useNylas();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const profileDetails = useAppSelector(selectProfileDetails)
  const isNylasProcessing = useAppSelector(selectIsNylasProcessing);
  const nylasAuthUrl = useAppSelector(getNylasAuthUrl);
  const nylasConnectionEstablished = useAppSelector(selectNylasConnectionEstablished);

  const { connectedEmail: emailIsConnected } = profileDetails;

  const isActionProcessing = isNylasProcessing || !!nylasAuthUrl;
  const isNylasSetupDone = emailIsConnected || nylasConnectionEstablished;
  const isProfileFetched = profileDetails?.email?.length > 0;

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<INylasForm>({ mode: 'onTouched' });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const generateNylasAuthUrl = (values: INylasForm) => {
    dispatch(generateAuthUrl({
      emailAddress: values.email,
      successUrl: NYLAS_SUCCESS_URL
    }));
  };

  // Redirect to Nylas to connect email on their side
  useEffect(() => {
    if (nylasAuthUrl) {
      const redirectUrl = nylasAuthUrl;
      dispatch(clearNylasAuthUrlValue());
      window.location.href = redirectUrl;
    }
  }, [nylasAuthUrl, dispatch])


  // Handle the code that is passed in the query params from Nylas after a successful login
  useEffect(() => {
    if (!nylas) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      params.delete('code');
      setSearchParams(params);

      dispatch(exchangeCodeFromUrlForToken({ token: code }));
    }
  }, [nylas]);

  useEffect(() => {
    if (nylasConnectionEstablished) {
      dispatch(getProfileDetails())
    }
  }, [nylasConnectionEstablished, dispatch]);

  if (!isProfileFetched) {
    return (
      <LoginLayout
        info={
          <LoadingRow />
        }
      />
    );
  }

  return (
    <LoginLayout
      info={
        !isNylasSetupDone ? (
          <>
            <div>{t("nylasConnect.connect.titleA")}</div>
            <div>{t("nylasConnect.connect.titleB")}</div>
          </>
        ) : (
          <>
            <div>{t("nylasConnect.connected.titleA")}</div>
            <div>{t("nylasConnect.connected.titleB")}</div>
          </>
        )
      }
      content={
        !isNylasSetupDone ? (
          <div className={styles.ConnectForm}>
            <form className={styles.ConnectForm} onSubmit={handleSubmit(generateNylasAuthUrl)}>
              <Input
                wrapperClassName={styles.Input}
                formed
                placeholder={t('placeholder.emailAddress') as string}
                error={errors?.email?.message}
                {...register('email', {
                  required: { value: true, message: t('errors.required') },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('errors.wrongEmailFormat'),
                  },
                })}
              />

              <Button className={styles.button} type="submit" loading={isActionProcessing}>
                {t('common.continue')}
              </Button>
            </form>
          </div >
        ) : (<></>)}
      containerClassName={styles.Wrapper}
    />
  );
};

