import { ReactNode } from 'react';
import { CopyValue } from './CopyValue';
import { cn } from 'lib/utils';

interface IProps {
  label?: ReactNode,
  value?: string,
  copyValueEnabled?: boolean,
  displayOnSeparateLines?: boolean,
  className?: string,
  labelClassName?: string,
  descriptionClassName?: string,
}

export const DetailsLine = ({ label, value, copyValueEnabled, displayOnSeparateLines, className, labelClassName, descriptionClassName }: IProps) => {
  return (
    <div className={cn("flex items-center justify-between text-sm", { 'flex-col items-start': displayOnSeparateLines }, className)}>
      <span className={cn("text-muted-foreground", labelClassName)}>
        {label}
      </span>

      <span className={cn('flex gap-3 items-center', descriptionClassName)}>
        <span>{value}</span>

        {copyValueEnabled && <CopyValue value={value} />}
      </span>
    </div>
  );
};

