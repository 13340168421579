import { ScrollArea } from "components/ui/scroll-area"
import { useAppDispatch, useAppSelector, useIsInViewport, useTranslation } from 'hooks';
import { selectSelectedThread, selectThreadsListHasMoreData, selectThreadsListOffset, selectThreadListLoading, getThreadsList, selectThreadsListData, getArchivedThreadsList, getDraftsList, mailboxActions, getThreadsWithConversationList, searchThreadsList, selectIsRefreshingMailbox, IEmailThread } from 'store/mailbox';
import { useEffect, useRef, useState } from 'react';
import { ACTION } from 'const/actions';
import { MAILBOX_TYPE, THREAD_LIST_LIMIT } from 'const';
import { selectConnectedEmailAuthId } from 'store/users';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { InfoCard } from "components/layout-atoms/InfoCard"
import { Loading } from "components/layout-atoms/Loading"
import { MailThreadPreview } from "./mail-thread-preview"
import { SearchInput } from "components/common/_atoms/SearchInput";

interface IProps {
  type?: MAILBOX_TYPE
}

export function MailList({ type = MAILBOX_TYPE.INBOX }: IProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const threadsList = useAppSelector(selectThreadsListData) || [];

  const activeThread = useAppSelector(selectSelectedThread);
  const threadsListOffset = useAppSelector(selectThreadsListOffset);
  const threadsListHasMoreData = useAppSelector(selectThreadsListHasMoreData);
  const threadsListIsLoading = useAppSelector(selectThreadListLoading);
  const connectedEmailAuthId = useAppSelector(selectConnectedEmailAuthId);
  const isRefreshingMailbox = useAppSelector(selectIsRefreshingMailbox);

  const [searchValue, setSearchValue] = useState("");
  const [threadCallType, setThreadCallType] = useState("");

  const loaderRef = useRef(null)
  const isInViewport = useIsInViewport(loaderRef);

  useEffect(() => {
    setThreadCallType(type);
  }, [type])

  useEffect(() => {
    if (isRefreshingMailbox || !([MAILBOX_TYPE.INBOX, MAILBOX_TYPE.SEARCH] as string[]).includes(threadCallType)) {
      setSearchValue('')
    }
  }, [threadCallType, isRefreshingMailbox])


  useEffect(() => {
    const initNewTab = async () => {
      if (!connectedEmailAuthId) return;
      if (threadCallType === MAILBOX_TYPE.SEARCH) return;

      const payload = { limit: THREAD_LIST_LIMIT, offset: 0 };

      await dispatch(mailboxActions.resetThreadsAndSelectedThread());

      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.CONVERSATIONS) {
        await dispatch(getThreadsWithConversationList(payload));
      }
    }

    initNewTab();
  }, [threadCallType, connectedEmailAuthId, dispatch])


  const debouncedGetData = debounce(async () => {
    if (searchValue) return;

    if (isInViewport && !threadsListIsLoading && threadsListOffset && threadsListHasMoreData) {
      const payload = { limit: THREAD_LIST_LIMIT, offset: threadsListOffset };


      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }

    }
  }, 1000);

  useEffect(() => {
    if (!connectedEmailAuthId) return;

    const getData = async () => {
      debouncedGetData();
    };

    getData();
  }, [isInViewport, threadsListOffset, threadsListHasMoreData, threadsListIsLoading, connectedEmailAuthId, dispatch, type, searchValue])

  useEffect(() => {
    return () => {
      debouncedGetData.cancel();
    };
  }, []);


  useEffect(() => {
    if (threadsList?.length && !activeThread) {

      const params = new URLSearchParams(window.location.search);
      const threadId = params.get('threadId');
      const threadFromParam = threadId ? threadsList?.find(thread => thread.thread_id === threadId) : undefined

      if (threadId) {
        params.delete('threadId');
        setSearchParams(params);
      }

      dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: threadFromParam })
    }
  }, [threadsList, activeThread, dispatch])

  const handleThreadSelect = (thread: IEmailThread) => {
    dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: thread })
  };

  const handleEnterPressed = (value: string) => {
    if (value) {
      setThreadCallType(MAILBOX_TYPE.SEARCH)

      const payload = { limit: THREAD_LIST_LIMIT, q: searchValue };
      dispatch(searchThreadsList(payload));
    } else {
      setThreadCallType(MAILBOX_TYPE.INBOX)
    }
  }

  const handleClearSearch = async () => {
    setSearchValue('');
    setThreadCallType(MAILBOX_TYPE.INBOX)
  }

  return (
    <div className="h-full flex flex-col">
      {type === MAILBOX_TYPE.INBOX && (
        <SearchInput
          inputPlaceholder={t('common.searchInEmails') as string}
          onClearSearch={handleClearSearch}
          onEnterPressed={handleEnterPressed}
          containerClassName="p-4"
        />
      )}

      <ScrollArea className="grow overflow-auto">
        <div className="flex flex-col gap-2 p-4 pt-0">


          {threadsList?.filter(thread => {
            if (!thread.messages?.length && !thread.drafts?.length) return false;

            if (type === MAILBOX_TYPE.INBOX && thread?.labels?.length > 0) return false;
            if (type === MAILBOX_TYPE.ARCHIVED && thread?.labels?.length > 0) return false;

            return true;
          })?.map((thread) => (
            <div key={thread.thread_id} onClick={() => handleThreadSelect(thread)} className="yy">
              <MailThreadPreview
                thread={thread}
                isActive={activeThread?.thread_id === thread.thread_id}
              />
            </div>
          ))}

          {threadsListIsLoading && <Loading variant="item" className="p-6 bg-muted" />}
          {!threadsListIsLoading && threadsList.length === 0 && (
            <InfoCard
              variant="item"
              title="There are no email messages"
              className="bg-muted"
              headerClassName="px-3"
            />
          )}


          {/* <div ref={loaderRef} className="mt-6 text-xs">&nbsp;</div> */}
        </div>
      </ScrollArea>
    </div>
  )
}
