import styles from './PortfolioModelsManagement.module.scss'
import { useAppDispatch } from 'hooks';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { listPortfolioModels, selectGettingModels, selectPortfolioModels } from 'store/portfolioModel';
import { AddPortfolioModel, HowToAddModel, LoadingRow, Stepper, UploadPortfolioModel, ViewEditPortfolioModels } from 'components_old';
import { MODELS_MANAGEMENT_STEP, TModelsManagementStep } from '../types';
import { useSelector } from 'react-redux';


export const PortfolioModelsManagement = () => {
  const dispatch = useAppDispatch();

  const isGettingModels = useSelector(selectGettingModels);
  const portfolioModels = useSelector(selectPortfolioModels);

  const [stepsArray, setStepsArray] = useState<Array<TModelsManagementStep>>(portfolioModels?.length ? [MODELS_MANAGEMENT_STEP.S1] : [MODELS_MANAGEMENT_STEP.S2]);

  useEffect(() => {
    if (stepsArray[stepsArray.length - 1] === MODELS_MANAGEMENT_STEP.S1) {
      dispatch(listPortfolioModels())
    }
  }, [stepsArray, dispatch])


  if (isGettingModels && !portfolioModels?.length) {
    return <LoadingRow />
  }

  const handleGoToNextStep = (whereToStep: TModelsManagementStep) => {
    setStepsArray([...stepsArray, whereToStep]);
    // mixpanel.track(whereToStep);
  }

  const handleBackAction = () => {
    setStepsArray(stepsArray.slice(0, -1));
    // mixpanel.track(EVENT.PORTFOLIO_MODELS.BACK);
  }

  return (
    <Stepper
      activeStep={0}
      totalSteps={0}
      showSteps={false}
      showBackBtn={stepsArray?.length > 1}
      handleBackAction={handleBackAction}
      stepperClassName={
        classnames(
          { [styles.StepperNarrow]: MODELS_MANAGEMENT_STEP.S1 !== stepsArray[stepsArray.length - 1] }
        )
      }
    >
      {stepsArray[stepsArray.length - 1] === MODELS_MANAGEMENT_STEP.S1 && (
        <ViewEditPortfolioModels onAddModel={() => handleGoToNextStep(MODELS_MANAGEMENT_STEP.S2)} />
      )}
      {stepsArray[stepsArray.length - 1] === MODELS_MANAGEMENT_STEP.S2 && (
        <HowToAddModel<TModelsManagementStep> handleGoToNextStep={handleGoToNextStep} nextSteps={[MODELS_MANAGEMENT_STEP.S3A, MODELS_MANAGEMENT_STEP.S3B]} />
      )}
      {stepsArray[stepsArray.length - 1] === MODELS_MANAGEMENT_STEP.S3A && (
        <AddPortfolioModel handleExit={() => handleGoToNextStep(MODELS_MANAGEMENT_STEP.S1)} />
      )}
      {stepsArray[stepsArray.length - 1] === MODELS_MANAGEMENT_STEP.S3B && (
        <UploadPortfolioModel handleExit={() => handleGoToNextStep(MODELS_MANAGEMENT_STEP.S1)} />
      )}
    </Stepper>
  );
}
