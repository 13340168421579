import { http } from "services/http";
import { API_ROUTES } from "services/api/constants";

import { IChatApi } from "./types";

export const chat: IChatApi = {
  getConversationId: (payload) =>
    http
      .get(API_ROUTES.chat.getConversationId(payload.threadId))
      .then((response) => response.data),
  uploadSecurelyFiles: (payload) =>
    http
      .post(API_ROUTES.chat.uploadSecurelyFiles, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data),
  createUploadSecurelyFilesRequest: (payload) =>
    http
      .post(API_ROUTES.chat.createUploadSecurelyFilesRequest, payload)
      .then((response) => response.data),
};
