import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { ICalendarApi } from "./types";

export const calendar: ICalendarApi = {
  getCalendarEvents: (payload: void) =>
    http
      .get(API_ROUTES.calendar.getCalendarEvents)
      .then((response) => response.data),
};
