import styles from './AnalysisWizard.module.scss'
import { useSelector } from 'react-redux';
import { Stepper } from 'components_old';
import { WIZARD_TOOL, WizardRecord, addToWizardHistory, popLastWizardHistory, selectCurrentStep, selectCurrentWizardRecord, selectRequestPayload, selectTotalSteps, selectWizardHistory } from 'store/analysis';
import { useEffect } from 'react';
import { useAppDispatch, useTranslation } from 'hooks';
import { STEP } from '../types';
import { Congrats, FileSecureLink, FileUpload, GettingStarted, HowToShareFile, OhNo, SelectTool, ShareAccess } from './fragments';

export const AnalysisWizard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const totalSteps = useSelector(selectTotalSteps);
  const currentWizardRecord = useSelector(selectCurrentWizardRecord);
  const wizardHistory = useSelector(selectWizardHistory);
  const requestPayload = useSelector(selectRequestPayload);

  useEffect(() => {
    // mixpanel.track(STEP.S1)
    dispatch(addToWizardHistory({ action: STEP.S1 }));
  }, [dispatch])


  const handleGoToNextStep = (newRecord: WizardRecord) => {
    // mixpanel.track(newRecord?.action, omit(newRecord?.payload, ['password', 'username']))
    dispatch(addToWizardHistory(newRecord));
  }

  const handleBackAction = () => {
    // mixpanel.track(EVENT.ANALYTICS.BACK);
    dispatch(popLastWizardHistory());
  }

  if (!currentWizardRecord) return <></>

  const isLastStep = [STEP.S5_EXTRACTION, STEP.S6_ENTRY, STEP.S4_REPORT_OH_NO,].includes(currentWizardRecord?.action);

  return (
    <Stepper
      activeStep={currentStep}
      totalSteps={totalSteps}
      showBackBtn={wizardHistory?.length > 1 && !isLastStep}
      handleBackAction={handleBackAction}
      showSteps={!isLastStep}
      stepperClassName={styles.Stepper}
    >
      {currentWizardRecord.action === STEP.S1 && (
        <GettingStarted handleGoToNextStep={handleGoToNextStep} />
      )}
      {currentWizardRecord.action === STEP.S2_EXTRACTION && (
        <SelectTool handleGoToNextStep={handleGoToNextStep} title={<>
          <div>{t('analysis.selectTool.title.extractionA')}</div>
          <div>{t('analysis.selectTool.title.extractionB')}</div>
        </>} nextStep={STEP.S3_EXTRACTION} />
      )}
      {currentWizardRecord.action === STEP.S3_EXTRACTION && (
        <HowToShareFile handleGoToNextStep={handleGoToNextStep} nextSteps={[STEP.S4A_EXTRACTION, STEP.S4B_EXTRACTION]} />
      )}
      {currentWizardRecord.action === STEP.S4A_EXTRACTION && (
        <FileUpload handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S5_EXTRACTION} />
      )}
      {currentWizardRecord.action === STEP.S4B_EXTRACTION && (
        <FileSecureLink handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S5_EXTRACTION} />
      )}
      {currentWizardRecord.action === STEP.S5_EXTRACTION && (
        <Congrats handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S1} />
      )}
      {currentWizardRecord.action === STEP.S2_ENTRY && (
        <SelectTool handleGoToNextStep={handleGoToNextStep} title={t('analysis.selectTool.title.entry')} nextStep={STEP.S3_ENTRY} />
      )}
      {currentWizardRecord.action === STEP.S3_ENTRY && (
        <ShareAccess handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S4_ENTRY} />
      )}
      {currentWizardRecord.action === STEP.S4_ENTRY && (
        <HowToShareFile handleGoToNextStep={handleGoToNextStep} nextSteps={[STEP.S5A_ENTRY, STEP.S5B_ENTRY]} />
      )}
      {currentWizardRecord.action === STEP.S5A_ENTRY && (
        <FileUpload handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S6_ENTRY} />
      )}
      {currentWizardRecord.action === STEP.S5B_ENTRY && (
        <FileSecureLink handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S6_ENTRY} />
      )}
      {currentWizardRecord.action === STEP.S6_ENTRY && (
        <Congrats handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S1} />
      )}
      {currentWizardRecord.action === STEP.S2_REPORT && (
        <SelectTool handleGoToNextStep={handleGoToNextStep} title={t('analysis.selectTool.title.report')} nextStep={STEP.S3_REPORT} />
      )}
      {(currentWizardRecord.action === STEP.S3_REPORT && requestPayload?.tool) && (
        <HowToShareFile
          handleGoToNextStep={handleGoToNextStep}
          nextSteps={
            [WIZARD_TOOL.MORNING_STAR, WIZARD_TOOL.KWANTI].includes(requestPayload.tool as WIZARD_TOOL) ? [STEP.S4A_REPORT, STEP.S4B_REPORT] : [STEP.S4_REPORT_OH_NO, STEP.S4_REPORT_OH_NO]
          }
        />
      )}
      {currentWizardRecord.action === STEP.S4_REPORT_OH_NO && (
        <OhNo handleGoToNextStep={handleGoToNextStep} />
      )}
      {currentWizardRecord.action === STEP.S4A_REPORT && (
        <FileUpload handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S5_REPORT} />
      )}
      {currentWizardRecord.action === STEP.S4B_REPORT && (
        <FileSecureLink handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S5_REPORT} />
      )}
      {currentWizardRecord.action === STEP.S5_REPORT && (
        <Congrats handleGoToNextStep={handleGoToNextStep} nextStep={STEP.S1} />
      )}
    </Stepper>
  );
}
