import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";
import { ICreateComparisonRequest, IPortfolioModel } from "./types";

export const listPortfolioModels = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.LIST_MODELS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.listPortfolioModels();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updatePortfolioModels = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.UPDATE_MODELS,
  async (payload: IPortfolioModel[], { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.updatePortfolioModels(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createPortfolioModels = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.CREATE_MODELS,
  async (payload: IPortfolioModel[], { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.createPortfolioModels(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const uploadPortfolioModels = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.UPLOAD_PORTFOLIO_MODEL,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.uploadPortfolioModels(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const uploadComparisonFiles = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.UPLOAD_COMPARISON_FILES,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.uploadPortfolioModels(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createComparisonRequest = createAsyncThunk(
  ACTION.PORTFOLIO_MODEL.CREATE_COMPARISON_REQUEST,
  async (payload: ICreateComparisonRequest, { rejectWithValue }) => {
    try {
      const response = await api.portfolioModel.createComparisonRequest(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
