import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IAnalysisState, WizardRecord } from "./types";
import { callOhNo, createRequest, uploadAnalysisFiles } from "./actions";

const initialState: IAnalysisState = {
  processing: false,
  uploadingFiles: false,
  wizardHistory: [],
  totalSteps: 0,
  requestPayload: {},
  requestSuccessful: false,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    addToWizardHistory: (state, action: PayloadAction<WizardRecord>) => {
      state.wizardHistory = [...state.wizardHistory, action.payload];
      state.requestPayload = {
        ...state.requestPayload,
        ...action.payload.payload,
      };
    },
    popLastWizardHistory: (state) => {
      const lastEntry = state.wizardHistory[state.wizardHistory.length - 1];
      if (lastEntry && lastEntry.payload) {
        const keys = Object.keys(lastEntry?.payload);
        const resetPayload = keys?.reduce((acc, curr) => {
          return { ...acc, [curr]: null };
        }, {});
        state.requestPayload = { ...state.requestPayload, ...resetPayload };
      }
      state.wizardHistory = [...state.wizardHistory].slice(0, -1);
    },
    resetWizardHistory: (state) => {
      state.wizardHistory = [];
    },
    resetRequestPayload: (state) => {
      state.requestPayload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadAnalysisFiles.pending, (state, action) => {
        state.uploadingFiles = true;
      })
      .addCase(uploadAnalysisFiles.fulfilled, (state, action) => {
        state.uploadingFiles = false;
      })
      .addCase(uploadAnalysisFiles.rejected, (state) => {
        state.uploadingFiles = false;
        toast("Error uploading file", { type: "error" });
      })
      .addCase(createRequest.pending, (state, action) => {
        state.processing = true;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        state.processing = false;
        state.requestSuccessful = true;
        analysisSlice.caseReducers.resetRequestPayload(state);
      })
      .addCase(createRequest.rejected, (state) => {
        state.processing = false;
        state.requestSuccessful = false;
        toast("Error submitting request", { type: "error" });
      })
      .addCase(callOhNo.pending, (state, action) => {
        state.processing = true;
      })
      .addCase(callOhNo.fulfilled, (state, action) => {
        state.processing = false;
        analysisSlice.caseReducers.resetRequestPayload(state);
      })
      .addCase(callOhNo.rejected, (state) => {
        state.processing = false;
      })
  },
});

export const {
  addToWizardHistory,
  popLastWizardHistory,
  resetWizardHistory,
  resetRequestPayload,
} = analysisSlice.actions;

export default analysisSlice.reducer;
