import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IPortfolioModelApi } from "./types";

export const portfolioModel: IPortfolioModelApi = {
  updatePortfolioModels: (payload) =>
    http
      .put(API_ROUTES.portfolioModel.updatePortfolioModels, payload)
      .then((response) => response.data),
  createPortfolioModels: (payload) =>
    http
      .post(API_ROUTES.portfolioModel.createPortfolioModels, payload)
      .then((response) => response.data),
  listPortfolioModels: () =>
    http
      .get(API_ROUTES.portfolioModel.listPortfolioModels)
      .then((response) => response.data),
  uploadPortfolioModels: (payload) =>
    http
      .post(API_ROUTES.portfolioModel.uploadPortfolioModels, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data),
  uploadComparisonFiles: (payload) =>
    http
      .post(API_ROUTES.portfolioModel.uploadComparisonFiles, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data),
  createComparisonRequest: (payload) =>
    http
      .post(API_ROUTES.portfolioModel.createComparisonRequest, payload)
      .then((response) => response.data),
};
