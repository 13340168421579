
import styles from './PortfolioAnalysisCongrats.module.scss'
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, ContactUs, ParaplannerAI, StepperTitle } from 'components_old';
import { IStepperComponentProps } from '../../types';
import { useEffect } from 'react';
import { selectIntercomChatState, setIntercomChatVisibility } from 'store/chat';


export const PortfolioAnalysisCongrats = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const intercomChatState = useAppSelector(selectIntercomChatState);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!intercomChatState?.isOpen) {
  //       dispatch(setIntercomChatVisibility(true))
  //     }
  //   }, 2000);
  // }, [])


  return (
    <div className={styles.Wrapper}>
      <>
        <StepperTitle>
          <ParaplannerAI />
        </StepperTitle>

        <StepperTitle>
          <div>{t('common.congrats')}</div>
          <div>{t('automations.tiles.investmentStatementDataEntry.congrats.titleA')}</div>
        </StepperTitle>

        <StepperTitle className={styles.ContactUs}>
          <div>{t('common.somethingUrgent')}</div>
          <ContactUs
            email={t('automations.tiles.investmentStatementDataEntry.contact.email')}
            subject={t('automations.tiles.investmentStatementDataEntry.contact.subject')}
            body={t('automations.tiles.investmentStatementDataEntry.contact.body')}
          /> {t('common.forSupport')}
        </StepperTitle>
      </>

      <div className={styles.Buttons}>
        <Button
          look={BUTTON_LOOK.TERNARY}
          onClick={async () => {
            // mixpanel.track(EVENT.ANOTHER_REQUEST);
            handleGoToNextStep({ action: nextStep });
          }}
        >
          {t('dataExtractor.congrats.newRequest')}
        </Button>
      </div>
    </div >
  );
}
