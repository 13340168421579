import { API_ROUTES } from "services/api/constants";
import { http } from "services/http";

import { IMyProfileApi } from "./types";
import { IProfilePreferences } from "store/profile";

export const myProfile: IMyProfileApi = {
  getProfileDetails: () =>
    http.get(API_ROUTES.profile.getProfileDetails).then((response) => response.data),
  updatePreferences: (payload: IProfilePreferences) =>
    http
      .post(API_ROUTES.profile.updatePreferences, payload)
      .then((response) => response.data),
};
