import { useTranslation } from 'hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { Search, X } from "lucide-react"
import { Input } from "components/ui/input"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { cn } from "lib/utils";

interface IProps {
  inputPlaceholder?: string
  clearSearchTooltip?: string
  onChange?: (value: string) => void
  onEnterPressed?: (value: string) => void
  onClearSearch?: () => void
  containerClassName?: string
  triggerClear?: number
}

export function SearchInput({ inputPlaceholder, clearSearchTooltip, onChange, onEnterPressed, onClearSearch, containerClassName, triggerClear }: IProps) {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (triggerClear) {
      setSearchValue('');
      onClearSearch && onClearSearch()
    }
  }, [triggerClear, onClearSearch])


  const handleSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnterPressed && onEnterPressed(searchValue)
    }

    if (e.key === 'Escape') {
      handleClearSearch()
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChange && onChange(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchValue('');
    onClearSearch && onClearSearch()
  }

  return (
    <div className={cn("bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60", containerClassName)}>
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder={inputPlaceholder || t('general.search') as string}
          className="px-8"
          value={searchValue}
          onChange={handleOnChange}
          onKeyDown={handleSearch}
        />
        {searchValue && (
          <Tooltip >
            <TooltipTrigger className="absolute right-2 top-2.5">
              <X
                size={14}
                className="h-4 w-4 text-muted-foreground cursor-pointer"
                onClick={handleClearSearch}
              />
            </TooltipTrigger>
            <TooltipContent className="-mb-1 -ml-10">
              {clearSearchTooltip || t('common.clearSearch')}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
