import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { toast } from 'react-toastify';
import { MIXPANEL } from 'const';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import {
  createDataExtractorRequest, resetDataExtractorState, selectDataExtractorBlobObjectUrl, selectDataExtractorIsProcessing, selectDataExtractorIsProcessingError
} from 'store/dataExtractor';
import { Button } from 'components/ui/button';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { FileUploader } from 'components/common/_atoms/FileUploader';
import { Progress } from 'components/ui/progress';

export const StatementExtraction = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const isProcessing = useSelector(selectDataExtractorIsProcessing);
  const blobObjectUrl = useSelector(selectDataExtractorBlobObjectUrl);
  const isProcessingError = useSelector(selectDataExtractorIsProcessingError);

  const [filesToUpload, setFilesToUpload] = useState<Array<IFileWithMeta>>([]);
  const [isLocalUploadIncomplete, setIsLocalUploadIncomplete] = useState(true);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<ReturnType<typeof setInterval> | undefined>();


  useEffect(() => {
    return () => {
      resetComponent();
    }
  }, []);

  useEffect(() => {
    if (isProcessing) {
      setProgress(1);
      progressInterval.current = setInterval(
        () => {
          setProgress(progress => Math.min(progress + 2.5, 95))
        },
        1500
      );
    } else {
      clearInterval(progressInterval.current);
    }
  }, [isProcessing]);

  useEffect(() => {
    if (!!blobObjectUrl) {
      setProgress(99.9);
      setTimeout(() => {
        setProgress(100);
      }, 1000);
    }
  }, [blobObjectUrl]);

  useEffect(() => {
    if (isProcessingError) {
      toast(t('general.errorOccurred'))
      resetComponent();
    }
  }, [isProcessingError]);

  const handleLocalUpload = (file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]) => {

    const isIncomplete =
      allFiles.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
      !allFiles.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))
    setIsLocalUploadIncomplete(isIncomplete)

    setFilesToUpload(allFiles);
  }

  const doExtractionRequest = async () => {
    const formData = new FormData();
    formData.append('file', filesToUpload[0].file);

    trackEvent({ action: MIXPANEL.ACTION.PARAPLANNER_AI.STATEMENT_EXTRACTION_REQUEST })
    dispatch(createDataExtractorRequest(formData));
  }

  const downloadResult = () => {
    const fullFileName = filesToUpload?.[0]?.file?.name
    let fileName = fullFileName;
    var lastIndex = fullFileName?.lastIndexOf(".");

    if (lastIndex !== -1) {
      fileName = fullFileName?.substring(0, lastIndex);;
    }

    const a = document.createElement("a");
    a.href = blobObjectUrl;
    a.download = `${fileName}_result.csv`;
    a.target = "_blank";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    trackEvent({ action: MIXPANEL.ACTION.PARAPLANNER_AI.STATEMENT_EXTRACTION_DOWNLOAD })
  }

  const resetComponent = () => {
    setProgress(0);
    setFilesToUpload([]);
    setIsLocalUploadIncomplete(true);
    dispatch(resetDataExtractorState())
  }

  return (
    <>
      {progress < 100 ? (
        <Card>
          <CardHeader>
            <CardTitle>
              {t('component.StatementExtraction.title')}
            </CardTitle>
            <CardDescription>
              {t('component.StatementExtraction.description')}
            </CardDescription>
          </CardHeader>

          <CardContent className="flex flex-col gap-6">
            {progress === 0 ? (
              <FileUploader
                onChangeStatus={handleLocalUpload}
                showSubmitButton={false}
                maxFiles={1}
                accept='application/pdf'
              />
            ) : (
              <Progress value={progress} />
            )}
          </CardContent>

          <CardFooter>
            <Button
              disabled={isLocalUploadIncomplete}
              loading={isProcessing}
              onClick={() => doExtractionRequest()}
            >
              {t('component.StatementExtraction.btn')}
            </Button>
          </CardFooter>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>
              {t('component.StatementExtraction.successState.title')}
            </CardTitle>
            <CardDescription>
              {t('component.StatementExtraction.successState.description')}
            </CardDescription>
          </CardHeader>

          <CardFooter className="gap-4">
            <Button
              type='button'
              onClick={downloadResult}
            >
              {t('component.StatementExtraction.successState.downloadResult')}
            </Button>
            <Button
              type='button'
              variant='outline'
              onClick={resetComponent}
            >
              {t('component.StatementExtraction.successState.newRequest')}
            </Button>
          </CardFooter>
        </Card>
      )}
    </>
  );
};
