import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { IMeetingNotesSyncPicker } from 'store/chat-assistant';
import { SectionContainer } from 'components/common/_atoms/SectionContainer';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';

interface IProps {
  onSyncObjectChange: (payload: IMeetingNotesSyncPicker) => void
}

export const MeetingNotesTable = ({ onSyncObjectChange }: IProps) => {
  const { t } = useTranslation();

  const [syncObject, setSyncObject] = useState<IMeetingNotesSyncPicker>({
    dataPoints: true,
    questions: true,
    summary: true,
    actionItems: true,
  });

  useEffect(() => {
    onSyncObjectChange(syncObject)
  }, [syncObject, onSyncObjectChange])

  return (
    <>
      <SectionContainer
        title={t('component.MeetingNotesTable.title') as string}
        description={t('component.MeetingNotesTable.description') as string}
      >
        <div className="flex px-2 py-3 gap-3 justify-between rounded-md border min-h-14">
          <div className="flex flex-1 border-r last:border-r-0 items-center gap-2">
            <Switch
              id="dataPoints"
              checked={syncObject.dataPoints}
              onCheckedChange={(checked) => {
                setSyncObject({ ...syncObject, dataPoints: checked })
              }}
            />
            <Label htmlFor="dataPoints">
              {t('component.MeetingNotesTable.item.dataPoints')}
            </Label>
          </div>

          <div className="flex flex-1 border-r last:border-r-0 items-center gap-2">
            <Switch
              id="questions"
              checked={syncObject.questions}
              onCheckedChange={(checked) => {
                setSyncObject({ ...syncObject, questions: checked })
              }}
            />
            <Label htmlFor="questions">
              {t('component.MeetingNotesTable.item.questions')}
            </Label>
          </div>

          <div className="flex flex-1 border-r last:border-r-0 items-center gap-2">
            <Switch
              id="summary"
              checked={syncObject.summary}
              onCheckedChange={(checked) => {
                setSyncObject({ ...syncObject, summary: checked })
              }}
            />
            <Label htmlFor="summary">
              {t('component.MeetingNotesTable.item.summary')}
            </Label>
          </div>

          <div className="flex flex-1 border-r last:border-r-0 items-center gap-2">
            <Switch
              id="actionItems"
              checked={syncObject.actionItems}
              onCheckedChange={(checked) => {
                setSyncObject({ ...syncObject, actionItems: checked })
              }}
            />
            <Label htmlFor="actionItems">
              {t('component.MeetingNotesTable.item.actionItems')}
            </Label>
          </div>

        </div>
      </SectionContainer>
    </>
  );
};

