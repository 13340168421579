import { useTranslation } from "react-i18next";
import styles from "./AutomationTiles.module.scss"
import { Tile } from "components_old";
import { ROUTES } from "routing/constants";
import { AUTOMATION } from "../types";


export const AutomationTiles = () => {
  const { t } = useTranslation();

  const operationRouteMap: { [key: string]: string } = {
    [AUTOMATION.INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL]: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL,
    [AUTOMATION.INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL_HUMAN_CHECK]: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL_HUMAN_CHECK,
    [AUTOMATION.INVESTMENT_STATEMENT_DATA_ENTRY]: ROUTES.ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_DATA_ENTRY,
    [AUTOMATION.PORTFOLIO_ANALYSIS]: ROUTES.ADVISOR_AUTOMATIONS__PORTFOLIO_ANALYSIS,
    // "investmentDocumentsExtraction": ROUTES.ADVISOR_DATA_EXTRACTOR,
    // "leads": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "meetingRecordings": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "dataExtraction": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "nurturePotential": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "portfolioComparison": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "generateContent": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "createInitialPlan": ROUTES.ADVISOR_PORTFOLIO_COMPARISON,
    // "createComprehensivePlan": ROUTES.ADVISOR_PORTFOLIO_COMPARISON
  }

  return (
    <>
      <div className={styles.Title}>{t(`automations.title`)}</div>
      <div className={styles.Title}>{t(`automations.subtitle`)}</div>

      <div className={styles.TileContainer}>
        {Object.keys(operationRouteMap)?.map(key => {
          const redirectUrl = operationRouteMap[key];

          return (
            <Tile
              className={styles.Tile}
              title={t(`automations.tiles.${key}.title`)}
              subtitle={t(`automations.tiles.${key}.subtitle`)}
              priceA={t(`automations.tiles.${key}.priceA`)}
              priceB={t(`automations.tiles.${key}.priceB`)}
              redirectUrl={redirectUrl}
            />
          )
        })}
      </div>
    </>
  );
}
