import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getDataExtractorState = (state: RootState) => state.dataExtractor;

export const selectDataExtractorIsProcessing = createSelector(
  [getDataExtractorState],
  ({ isProcessing }) => isProcessing
);

export const selectDataExtractorIsProcessingError = createSelector(
  [getDataExtractorState],
  ({ isProcessingError }) => isProcessingError
);

export const selectDataExtractorBlobObjectUrl = createSelector(
  [getDataExtractorState],
  ({ dataExtractorBlobObjectUrl }) => dataExtractorBlobObjectUrl
);
