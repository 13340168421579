import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { AgendaQuestionsSet } from './AgendaQuestionsSet';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from 'components/ui/form';
import { IMeetingTemplateFormData } from 'store/templates-management';
import { useTranslation } from 'hooks';
import { PlusIcon } from 'lucide-react';

interface IProps {
  form: UseFormReturn<IMeetingTemplateFormData>;
}

export const AgendaSections = ({ form }: IProps) => {
  const { t } = useTranslation();

  const { fields, remove, insert } = useFieldArray({
    control: form.control,
    name: "sections"
  });


  return (
    <>
      {fields.map((item, index) => {
        return (
          <Card key={item.id} className=''>
            <CardContent className="flex flex-col gap-6 pt-6">
              {/* <FormField
                control={form.control}
                name={`sections.${index}.id`}
                render={({ field }) => (
                  <FormItem className='hidden'>
                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore className='hidden' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}

              <FormField
                control={form.control}
                name={`sections.${index}.name`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.AgendaSections.name')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.AgendaSections.nameDescription')}
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <AgendaQuestionsSet sectionIndex={index} form={form} />
            </CardContent>

            <CardFooter className='gap-4 justify-between'>
              <Button
                type="button"
                onClick={() => {
                  insert(index + 1, {
                    name: 'New section',
                    questions: [{ text: '' }]
                  });
                }}
              >
                <PlusIcon className='size-4' />
                {t('component.AgendaSections.button.add')}
              </Button>

              <Button
                type="button"
                onClick={() => remove(index)}
                variant="destructive"
              >
                {t('component.AgendaSections.button.delete')}
              </Button>
            </CardFooter>
          </Card>
        );
      })}

      {fields?.length === 0 && (
        <Button
          type="button"
          className='w-fit'
          onClick={() => {
            insert(fields?.length + 1, {
              name: 'New section',
              questions: [{ text: '' }]
            });
          }}
        >
          <PlusIcon className='size-4' />
          {t('component.AgendaSections.button.add')}
        </Button>
      )}
    </>
  );
}
