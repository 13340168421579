export const AUTOMATION = {
  INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL:
    "investmentStatementExtractionIntoExcel",
  INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL_HUMAN_CHECK:
    "investmentStatementExtractionIntoExcelHumanCheck",
  INVESTMENT_STATEMENT_DATA_ENTRY: "investmentStatementDataEntry",
  PORTFOLIO_ANALYSIS: "portfolioAnalysis",
} as const;

export const ISDEIE_STEP = {
  S1: `ISDataExtractionIntoExcel_step1_file-upload`,
  S2: `ISDataExtractionIntoExcel_step2_processing`,
  S3: `ISDataExtractionIntoExcel_step3_congrats`,
} as const;

export type TISDEIEStep = (typeof ISDEIE_STEP)[keyof typeof ISDEIE_STEP];

// ==============================================================================

export const ISDEIEHC_STEP = {
  S1: `ISDataExtractionIntoExcelHumanCheck_step1_file-upload`,
  S2: `ISDataExtractionIntoExcelHumanCheck_step2_congrats`,
} as const;

export type TISDEIEHCStep = (typeof ISDEIEHC_STEP)[keyof typeof ISDEIEHC_STEP];

// ==============================================================================

export const ISDataEntry_STEP = {
  S1: `ISDataEntry_step1_file-upload`,
  S2: `ISDataEntry_step2_share-credentials`,
  S3: `ISDataEntry_step3_congrats`,
} as const;

export interface IISDataEntryRequest {
  google_drive_link?: string;
  username?: string;
  password?: string;
  tool?: string;
}

export type TISDataEntryStep =
  (typeof ISDataEntry_STEP)[keyof typeof ISDataEntry_STEP];

// ==============================================================================

export const PortfolioAnalysis_STEP = {
  S1: `PortfolioAnalysis_step1_file-upload`,
  S2: `PortfolioAnalysis_step2_congrats`,
} as const;

export interface IPortfolioAnalysisRequest {
  google_drive_link?: string;
  tool?: string;
}

export type TPortfolioAnalysisStep =
  (typeof PortfolioAnalysis_STEP)[keyof typeof PortfolioAnalysis_STEP];

// ==============================================================================

export type TAutomationStep =
  | TISDEIEStep
  | TISDEIEHCStep
  | TISDataEntryStep
  | TPortfolioAnalysisStep;
export type TAutomationPayload =
  | IISDataEntryRequest
  | IPortfolioAnalysisRequest;

export interface IStepperComponentProps {
  // handleGoToNextStep(
  //   action?: TAutomationStep,
  //   payload?: TAutomationPayload
  // ): void;
  handleGoToNextStep(record: IAutomationWizardRecord): void;
  nextStep: TAutomationStep;
  nextSteps?: TAutomationStep[];
}

export interface IAutomationWizardRecord {
  action: TAutomationStep;
  payload?: Partial<TAutomationPayload>;
}
