import { ReactNode } from 'react';
import { Label } from 'components/ui/label';

interface IProps {
  title?: string,
  description?: string,
  children: ReactNode | undefined;
}

export const SectionContainer = ({ title, description, children }: IProps) => {
  return (
    <div className='space-y-1.5'>
      {title && (
        <Label>
          {title}
        </Label>
      )}

      {description && (
        <Label variant="description">
          {description}
        </Label>
      )}

      <>
        {children}
      </>
    </div>
  );
};

