import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getAutomationsState = (state: RootState) => state.automations;

export const selectAutomationsIsProcessing = createSelector(
  [getAutomationsState],
  ({ isProcessing }) => isProcessing
);

export const selectAutomationsIsProcessingError = createSelector(
  [getAutomationsState],
  ({ isProcessingError }) => isProcessingError
);

export const selectCreateInvestmentStatementExtractionWithHumanCheckRequestSuccess = createSelector(
  [getAutomationsState],
  ({ createInvestmentStatementExtractionWithHumanCheckRequestSuccess }) => createInvestmentStatementExtractionWithHumanCheckRequestSuccess
);

