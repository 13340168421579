import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IHeadsUpCardsApi } from "./types";

export const headsUpCards: IHeadsUpCardsApi = {
  getCards: (_) =>
    http
      .get(API_ROUTES.headsUpCards.getCards)
      .then((response) => response.data),
  createCard: (payload) =>
    http
      .post(API_ROUTES.headsUpCards.createCard, payload)
      .then((response) => response.data),
  updateCard: (payload) =>
    http
      .put(API_ROUTES.headsUpCards.updateCard(payload.id), payload)
      .then((response) => response.data),
  deleteCard: (payload) =>
    http
      .delete(API_ROUTES.headsUpCards.deleteCard(payload.id))
      .then((response) => response.data),
};
