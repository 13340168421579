import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const headsUpCardsState = (state: RootState) => state.headsUpCards;

export const selectIsGettingCards = createSelector(
  [headsUpCardsState],
  ({ isGettingCards }) => isGettingCards
);

export const selectIsCreatingCard = createSelector(
  [headsUpCardsState],
  ({ isCreatingCard }) => isCreatingCard
);

export const selectIsUpdatingCard = createSelector(
  [headsUpCardsState],
  ({ isUpdatingCard }) => isUpdatingCard
);

export const selectIsDeletingCard = createSelector(
  [headsUpCardsState],
  ({ isDeletingCard }) => isDeletingCard
);

export const selectHeadsUpCards = createSelector(
  [headsUpCardsState],
  ({ headsUpCards }) => headsUpCards
);

export const selectActiveCard = createSelector(
  [headsUpCardsState],
  ({ activeCard }) => activeCard
);
