import { UseFieldArrayRemove, UseFieldArrayInsert } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { PlusIcon, X } from 'lucide-react';
import { cn } from 'lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { IMeetingTemplateFormData } from 'store/templates-management';
import { useTranslation } from 'hooks';

interface IProps {
  index: number;
  remove: UseFieldArrayRemove;
  insert: UseFieldArrayInsert<IMeetingTemplateFormData, `sections.${number}.questions`>;
}

export const QuestionActions = ({ remove, insert, index }: IProps) => {
  const { t } = useTranslation();
  const btnActionClassNames = 'h-full rounded-none min-w-9 px-0'

  const insertAfter = (index: number) => {
    insert(index + 1, { text: '' });
  };

  return (
    <div className='h-auto hidden group-hover:flex gap-0' >
      <Button
        type='button'
        className={cn(btnActionClassNames)}
        onClick={() => insertAfter(index)}
      >
        <Tooltip>
          <TooltipTrigger asChild>
            <PlusIcon className='size-4 text-initial' />
          </TooltipTrigger>
          <TooltipContent>
            {t('component.QuestionActions.insert')}
          </TooltipContent>
        </Tooltip>
      </Button>


      <Button
        type='button'
        variant="destructive"
        className={cn(btnActionClassNames, "rounded-r-md")}
        onClick={() => remove(index)}
      >
        <Tooltip>
          <TooltipTrigger asChild>
            <X className='size-4' />
          </TooltipTrigger>
          <TooltipContent>
            {t('component.QuestionActions.delete')}
          </TooltipContent>
        </Tooltip>
      </Button>
    </div>
  )
}
