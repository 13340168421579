import { useAppDispatch, useMixpanel, useTranslation } from "hooks";
import { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getTranscriptAIAnswer, selectIsGetTranscriptAIAnswerProcessing, selectTranscriptAIAnswers
} from "store/chat-assistant";
import { AiAnswerType, IClientDataRecord, IGetAiDataSearchAnswerResponse, getAiDataSearchAnswer, getAiDataSearchDoAgenda, getAiDataSearchWhoIsClient, selectAiDataSearchAnswers, selectIsGetAiDataSearchAnswerProcessing } from "store/clientManagement";
import AskAITextArea from "./AskAITextArea";
import { SpeakerBlock } from "./SpeakerBlock";
import { Phrase } from "./Phrase";
import { cn } from "lib/utils";
import { MIXPANEL } from "const";
import { Button } from "components/ui/button";
import { NotebookTextIcon, UserSearchIcon } from "lucide-react";
import { MarkdownRenderer } from "components/common/_atoms/MarkdownRenderer";
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';


export enum AiConversationType {
  TRANSCRIPT = 'transcript',
  CLIENT_DATA = 'clientData',
  // MEETING_PAST_DATA = 'meetingPastData',
}

const isProcessingSelector: Record<AiConversationType, any> = {
  transcript: selectIsGetTranscriptAIAnswerProcessing,
  // meetingPastData: selectIsGetPastDataAIAnswerProcessing,
  clientData: selectIsGetAiDataSearchAnswerProcessing,
};

const answersSelector: Record<AiConversationType, any> = {
  transcript: selectTranscriptAIAnswers,
  // meetingPastData: selectPastDataAIAnswers,
  clientData: selectAiDataSearchAnswers,
};

const dispatchAction: Record<AiConversationType, any> = {
  transcript: getTranscriptAIAnswer,
  // meetingPastData: getPastDataAIAnswer,
  clientData: getAiDataSearchAnswer,
};

interface IPanelItemClassNames {
  className?: string;
  contentClassName?: string;
}

interface IProps {
  botId: string;
  clientId: string;
  placeholder: string;
  type: AiConversationType;
  preContent?: ReactNode;
  conversationClassNames?: IPanelItemClassNames;
  conversationHiddenUntilContent?: boolean;
  hasExtraActions?: boolean;
  dataRecord?: IClientDataRecord
}

const COUNTER_UPDATE_FREQUENCY_MS = 100;

export const AskAIConversation = ({ botId, clientId, placeholder, type, preContent, conversationClassNames, conversationHiddenUntilContent, hasExtraActions, dataRecord }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const rawAnswers = useSelector(answersSelector[type]) as IGetAiDataSearchAnswerResponse[];
  const isProcessing = useSelector(isProcessingSelector[type]) as boolean;

  const [counter, setCounter] = useState(0)
  const counterInterval = useRef<ReturnType<typeof setInterval> | undefined>();
  const [lastAnswerFrags, setLastAnswerFrags] = useState<Array<string>>([]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [question, setQuestion] = useState('');
  const [lastSentQuestion, setLastSentQuestion] = useState('');
  const [answers, setAnswers] = useState<IGetAiDataSearchAnswerResponse[]>([]);

  useEffect(() => {
    return () => {
      counterInterval.current && clearInterval(counterInterval.current);
    }
  }, []);

  useEffect(() => {
    if (type === AiConversationType.CLIENT_DATA) {
      setAnswers(rawAnswers?.filter(item => item?.clientId === clientId))
    }
    if (type === AiConversationType.TRANSCRIPT) {
      setAnswers(rawAnswers?.filter(item => item?.botId === botId))
    }
    // if (type === AiConversationType.MEETING_PAST_DATA) {
    //   setAnswers(rawAnswers)
    // }
  }, [rawAnswers, clientId, type, botId]);

  // Set interval for updating counter
  useEffect(() => {
    if (!answers?.length) return;

    if (counterInterval.current) {
      clearInterval(counterInterval.current);
      counterInterval.current = undefined;
    }

    const lastAnswer = answers[answers?.length - 1]?.answer?.toString();
    setLastAnswerFrags(lastAnswer?.split(" "));

    setCounter(0)
    counterInterval.current = setInterval(
      () => {
        setCounter(counter => counter + 1)
      },
      COUNTER_UPDATE_FREQUENCY_MS
    );
  }, [answers]);

  // Clear interval when counter passes the end of the interval data
  useEffect(() => {
    if (!counterInterval.current) return;

    if (counter > lastAnswerFrags?.length) {
      clearInterval(counterInterval.current);
      counterInterval.current = undefined;
    }
  }, [counter, lastAnswerFrags]);

  useEffect(() => {
    if (!question) {
      return;
    }

    dispatch(dispatchAction[type]({ botId, question, clientId, dataRecord }))
    trackEvent({ action: type, type: MIXPANEL.TYPE.ASK_AI })

    setLastSentQuestion(question);
    setQuestion('')
  }, [botId, question, dispatch, type, clientId, dataRecord]);

  useEffect(() => {
    if (answers && answers?.length && !isProcessing && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [isProcessing, answers])

  return (
    <Fragment>
      <div
        className={
          cn(
            "flex flex-1 overflow-auto flex-col-reverse scroll-smooth [overflow-anchor:auto] border rounded-lg p-3",
            conversationClassNames?.className,
            { 'hidden': conversationHiddenUntilContent && !answers?.length && !lastSentQuestion }
          )
        }
      >
        <div className={cn('flex flex-col space-y-3 rounded-lg ', conversationClassNames?.contentClassName)}>
          {preContent}

          {answers?.map((item, index) => {
            return (
              <Fragment key={index}>
                <SpeakerBlock speaker={t('general.you')}>
                  <Phrase>{item.question}</Phrase>
                </SpeakerBlock>
                <SpeakerBlock speaker={t('general.copilot')}>
                  {
                    index !== answers.length - 1 ? (
                      <>
                        {
                          item?.aiAnswerType === AiAnswerType.MARKUP ? (
                            <MarkdownRenderer
                              markdown={item.answer}
                              className="text-muted-foreground text-sm"
                            />
                          ) : (
                            <Phrase>{item.answer}</Phrase>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          item?.aiAnswerType === AiAnswerType.MARKUP ? (
                            <Phrase>
                              <MarkdownRenderer
                                markdown={item.answer}
                                className="text-muted-foreground text-sm"
                              />
                            </Phrase>
                          ) : (
                            <Phrase
                              blinker={counter <= lastAnswerFrags?.length}
                            >
                              {
                                lastAnswerFrags?.map((word, wordIndex) => (
                                  <span key={`word-${wordIndex}`} className={cn('break-words', { hidden: wordIndex > counter })}>
                                    {word}{" "}
                                  </span>
                                ))
                              }
                            </Phrase>
                          )
                        }
                      </>
                    )
                  }
                </SpeakerBlock>
              </Fragment>
            )
          })}

          {lastSentQuestion && isProcessing && (
            <Fragment>
              <SpeakerBlock speaker={t('general.you')}>
                <Phrase>{lastSentQuestion}</Phrase>
              </SpeakerBlock>
              <SpeakerBlock speaker={t('general.copilot')}>
                <Phrase blinker={true} />
              </SpeakerBlock>
            </Fragment>
          )}
        </div>
      </div>

      {hasExtraActions && type === AiConversationType.CLIENT_DATA && (
        <div className="flex flex-row gap-4">
          <Button
            type='button' variant='secondary' className="flex-1"
            onClick={() => {
              setLastSentQuestion(t('component.AskAIConversation.whoMyClientIs') as string)
              dispatch(getAiDataSearchWhoIsClient({ clientId }))
            }}
          >
            <UserSearchIcon className='size-5' />
            {t('component.AskAIConversation.whoMyClientIs')}
          </Button>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="inline-flex flex-1">
                <Button
                  type='button' variant='secondary' className="flex-1"
                  disabled
                  onClick={() => {
                    setLastSentQuestion(t('component.AskAIConversation.agendaHelp') as string)
                    dispatch(getAiDataSearchDoAgenda({ clientId }))
                  }}
                >
                  <NotebookTextIcon className='size-6' />
                  {t('component.AskAIConversation.agendaHelp')}
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {t('general.comingSoon')}
            </TooltipContent>
          </Tooltip>

        </div>
      )}

      <AskAITextArea
        ref={textareaRef}
        placeholder={placeholder}
        disabled={isProcessing}
        value={textareaValue}
        onChange={(e) => {
          setTextareaValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            setQuestion(textareaValue);
            setTextareaValue('')
          }
        }}
      />
    </Fragment>
  )
}
