import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'routing/constants';
import { useAppSelector } from 'hooks';
import { getUserRole } from 'store/authentication';
import { RoleEnum } from "const";

export interface IRequireRole {
  requiredRole: RoleEnum
}

export const RequireRole = ({ requiredRole }: IRequireRole) => {
  const role = useAppSelector(getUserRole);

  if (role !== requiredRole) {
    return <Navigate to={ROUTES.APP.DEFAULT} />;
  }

  return <Outlet />;
};
