import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import { useAppSelector, useBodyClass } from 'hooks';
import { getAccessToken, getUserRole } from 'store/authentication';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorHandler } from 'components/layout-atoms/ErrorHandler';
import { Intercom } from 'components/layout-atoms/Intercom';
import { RoleEnum } from 'const';

interface IRequireAuthMuninAI {
  requiredRole: RoleEnum
}

export const RequireAuthMuninAI = ({ requiredRole }: IRequireAuthMuninAI) => {
  const accessToken = useAppSelector(getAccessToken);
  const role = useAppSelector(getUserRole);
  const { pathname } = useLocation();

  useBodyClass("muninai");

  // If the user has no access token, redirect to login
  if (!accessToken) {
    sessionStorage.setItem('redirectPath', `${window.location.pathname}${window.location.search}`);
    return <Navigate to={ROUTES.LOGIN} />;
  }

  // If the user does not have the role for the path, redirect to /app where the AppGateway will send him to default for role
  if (role !== requiredRole && pathname !== ROUTES.APP.DEFAULT) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Intercom />
      <Outlet />
    </ErrorBoundary>
  )
};
