import { useAppDispatch, useTranslation } from 'hooks';
import { FileUploader, StepperTitle } from 'components_old';
import { IFileWithMeta } from 'react-dropzone-uploader'
import { createDataExtractorRequest } from 'store/dataExtractor';
import { IStepperComponentProps } from '../../types';


export const ISDEIEDataExtractorUploader = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();


  const doCreateDataExtractorRequest = async (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const formData = new FormData();
    formData.append('file', allFiles[0].file);

    dispatch(createDataExtractorRequest(formData));
    handleGoToNextStep({ action: nextStep })
  }

  return (
    <>
      <StepperTitle>
        <div>{t('dataExtractor.uploader.titleA')}</div>
        <div>{t('dataExtractor.uploader.titleB')}</div>
      </StepperTitle>

      <FileUploader
        submitButtonContent={t('dataExtractor.uploader.extractData')}
        onSubmit={doCreateDataExtractorRequest}
        maxFiles={1}
        multiple={false}
      />
    </>
  );
}

