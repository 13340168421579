export const ArrowLeft = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.33337H6L8.19333 5.14004C8.25582 5.07806 8.30541 5.00433 8.33926 4.92309C8.37311 4.84185 8.39053 4.75471 8.39053 4.66671C8.39053 4.5787 8.37311 4.49156 8.33926 4.41032C8.30541 4.32908 8.25582 4.25535 8.19333 4.19337C8.06842 4.06921 7.89946 3.99951 7.72333 3.99951C7.54721 3.99951 7.37824 4.06921 7.25333 4.19337L4.39333 7.06004C4.14294 7.30894 4.00149 7.64698 4 8.00004C4.00324 8.35077 4.14456 8.6861 4.39333 8.93337L7.25333 11.8C7.31549 11.8618 7.3892 11.9106 7.47025 11.9439C7.55129 11.9771 7.63809 11.994 7.72569 11.9937C7.81329 11.9934 7.89996 11.9759 7.98077 11.9421C8.06159 11.9082 8.13495 11.8589 8.19667 11.7967C8.25839 11.7345 8.30726 11.6608 8.3405 11.5798C8.37373 11.4987 8.39068 11.4119 8.39037 11.3243C8.39006 11.2367 8.3725 11.1501 8.33869 11.0693C8.30489 10.9884 8.25549 10.9151 8.19333 10.8534L6 8.6667H12.6667C12.8435 8.6667 13.013 8.59646 13.1381 8.47144C13.2631 8.34642 13.3333 8.17685 13.3333 8.00004C13.3333 7.82322 13.2631 7.65366 13.1381 7.52863C13.013 7.40361 12.8435 7.33337 12.6667 7.33337Z"
        fill="#696969"
      />
    </svg>
  );
};
