import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { INotificationsApi } from "./types";

export const notifications: INotificationsApi = {
  markAsRead: (payload) =>
    http
      .post(API_ROUTES.notifications.markAsRead, {}, { params: payload })
      .then((response) => response.data),
  getGeneralConversation: () =>
    http
      .get(API_ROUTES.notifications.getGeneralConversation)
      .then((response) => response.data),
};
