import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

const getEmailsState = (state: RootState) => state.emails;

export const getNylasAuthUrl = createSelector(
  [getEmailsState],
  ({ nylasAuthUrl }) => nylasAuthUrl
);

export const selectIsNylasProcessing = createSelector(
  [getEmailsState],
  ({ isNylasProcessing }) => isNylasProcessing
);

export const selectNylasConnectionEstablished = createSelector(
  [getEmailsState],
  ({ nylasConnectionEstablished }) => nylasConnectionEstablished
);

export const selectCalendarAuthUrl = createSelector(
  [getEmailsState],
  ({ calendarAuthUrl }) => calendarAuthUrl
);

export const selectCalendarConnectionEstablished = createSelector(
  [getEmailsState],
  ({ calendarConnectionEstablished }) => calendarConnectionEstablished
);
