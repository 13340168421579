import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

const getChatState = (state: RootState) => state.chat;

export const selectIntercomThreadConversationPairs = createSelector(
  [getChatState],
  (chatState) => chatState.intercomThreadConversationPairs
);

export const selectIntercomChatState = createSelector(
  [getChatState],
  (chatState) => chatState.intercomChatState
);

export const selectIsGettingIntercomChatInfo = createSelector(
  [getChatState],
  (chatState) => chatState.isGettingIntercomChatInfo
);

export const selectIsUploadingSecurely = createSelector(
  [getChatState],
  (chatState) => chatState.isUploadingSecurely
);

export const selectUploadSecurelyFilesPayload = createSelector(
  [getChatState],
  (chatState) => chatState.uploadSecurelyFilesPayload
);

export const selectIsSecureUploadRequestProcessing = createSelector(
  [getChatState],
  (chatState) => chatState.isSecureUploadRequestProcessing
);

export const selectIsSecureUploadRequestSuccessful = createSelector(
  [getChatState],
  (chatState) => chatState.isSecureUploadRequestSuccessful
);
