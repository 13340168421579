import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { IMarkAsReadPayload } from "./types";
import { ACTION } from "const/actions";

export const markAsRead = createAsyncThunk(
  ACTION.NOTIFICATIONS.MARK_AS_READ,
  async (payload: IMarkAsReadPayload, { rejectWithValue }) => {
    try {
      const response = await api.notifications.markAsRead(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getGeneralConversation = createAsyncThunk(
  ACTION.NOTIFICATIONS.GET_GENERAL_CONVERSATION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.notifications.getGeneralConversation();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
