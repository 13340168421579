import classnames from 'classnames';
import styles from './LineMarker.module.scss';
import { ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  className? : string;
}

export const LineMarker = ({ children, className }: IProps) => {
  return (
    <div className={classnames(styles.LineMarker,className)}>
      <span className={classnames(styles.LineMarkerText)} >
        {children}
        </span>
    </div>
  );
};
