import { Helmet } from 'react-helmet';
import { Page, Panel, Panels } from 'components_old';
import { useTranslation, useWindowDimensions } from 'hooks';

export const PlanningSubmissionPage = () => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} | {t('routes.planningSubmissionForm')}
        </title>
      </Helmet>

      <Page>
        <Panels>
          <Panel
            header={<>{t('routes.planningSubmissionForm')}</>}
            body={
              <div>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSemfmtVQbHdtU1m2s5d_YFU-Y-VSjkPiiSJd7fJKl9zd68uZA/viewform?embedded=true"
                  height={height - 100}
                  frameBorder="0"
                  marginHeight={0}
                  marginWidth={0}
                  title="planningSubmissionForm"
                  style={{ width: '100%' }}
                >
                  Loading…
                </iframe>
              </div>
            }
          />
        </Panels >
      </Page >
    </>
  );
};

