import { PayloadAction, createSlice, current, isAnyOf } from "@reduxjs/toolkit";
import { IDataPoint, IDataPointsState } from "./types";
import {
  createDataPoint,
  deleteDataPoint,
  getDataPointsList,
  updateDataPoint,
} from "./actions";
import { v4 as uuidv4 } from "uuid";

const initialState: IDataPointsState = {
  isDataPointProcessing: false,
  dataPointsList: [],
  activeDataPoint: undefined,
  dataPointActionUUID: "",
};

const dataPointsSlice = createSlice({
  name: "dataPoints",
  initialState,
  reducers: {
    setActiveDataPoint: (
      state,
      action: PayloadAction<IDataPoint | undefined>
    ) => {
      state.activeDataPoint = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataPointsList.pending, (state, action) => {
        state.isDataPointProcessing = true;
      })
      .addCase(getDataPointsList.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isDataPointProcessing = false;
        state.dataPointsList = action.payload?.sort((a, b) =>
          a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
        );

        if (currentState?.activeDataPoint) {
          state.activeDataPoint = action.payload?.find(
            (item) => item?.id === currentState?.activeDataPoint?.id
          );
        }
      })
      .addCase(getDataPointsList.rejected, (state) => {
        state.isDataPointProcessing = false;
      })
      .addMatcher(
        isAnyOf(
          createDataPoint.pending,
          updateDataPoint.pending,
          deleteDataPoint.pending
        ),
        (state) => {
          state.isDataPointProcessing = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createDataPoint.fulfilled,
          updateDataPoint.fulfilled,
          deleteDataPoint.fulfilled
        ),
        (state, action) => {
          state.isDataPointProcessing = false;
          state.dataPointActionUUID = uuidv4();
          state.activeDataPoint = action.payload || undefined;
        }
      )
      .addMatcher(
        isAnyOf(
          createDataPoint.rejected,
          updateDataPoint.rejected,
          deleteDataPoint.rejected
        ),
        (state) => {
          state.isDataPointProcessing = false;
        }
      );
  },
});

export const { setActiveDataPoint } = dataPointsSlice.actions;

export default dataPointsSlice.reducer;
