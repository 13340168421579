import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";
import { IAdminMeetingTemplate, IMeetingTemplate, IMeetingTemplateFormData } from "./types";
import _ from "lodash";

export const getTemplates = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.GET_TEMPLATES,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.getTemplates();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getAllTemplates = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.GET_ALL_TEMPLATES,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.getAllTemplates();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createPersonalTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.CREATE_PERSONAL_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.createPersonalTemplate(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updatePersonalTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.UPDATE_PERSONAL_TEMPLATE,
  async (payload: IMeetingTemplateFormData, { rejectWithValue }) => {
    try {
      const callPayload = _.omit(payload, [
        "formSelectedTemplateId",
        "formSelectedDataPointOptions",
      ]);
      const response = await api.templatesManagement.updatePersonalTemplate(
        callPayload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deletePersonalTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.DELETE_PERSONAL_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.deletePersonalTemplate(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createOrgTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.CREATE_ORG_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.createOrgTemplate(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateOrgTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.UPDATE_ORG_TEMPLATE,
  async (payload: IMeetingTemplateFormData, { rejectWithValue }) => {
    try {
      const callPayload = _.omit(payload, [
        "formSelectedTemplateId",
        "formSelectedDataPointOptions",
      ]);
      const response = await api.templatesManagement.updateOrgTemplate(
        callPayload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteOrgTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.DELETE_ORG_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.deleteOrgTemplate(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateTemplatePrompt = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.UPDATE_TEMPLATE_PROMPT,
  async (payload: IAdminMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.updateTemplatePrompt(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
