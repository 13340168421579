import { useAppDispatch, useTranslation } from 'hooks';
import { FileUploader, StepperTitle } from 'components_old';
import { IStepMandatoryProps } from 'components_old/features/PortfolioAnalysis/types';
import { IFileWithMeta } from 'react-dropzone-uploader'
import { useSelector } from 'react-redux';
import { IUploadFilesResponse, selectUploadingFiles, uploadAnalysisFiles } from 'store/analysis';


export const FileUpload = ({ handleGoToNextStep, nextStep }: IStepMandatoryProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const uploadingFiles = useSelector(selectUploadingFiles)

  const doFileUpload = async (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const formData = new FormData();
    allFiles?.forEach(item => {
      formData.append('files', item.file);
    })

    try {
      const uploadResponse = await dispatch(uploadAnalysisFiles(formData));

      if (!uploadResponse.hasOwnProperty('error')) {
        const link = (uploadResponse?.payload as IUploadFilesResponse)?.link;
        handleGoToNextStep({ action: nextStep, payload: { google_drive_link: link } })
      }
    } catch (error) { }

  }

  return (
    <>
      <StepperTitle>
        <div>{t('analysis.fileUpload.titleA')}</div>
        <div>{t('analysis.fileUpload.titleB')}</div>
      </StepperTitle>
      <FileUploader onSubmit={doFileUpload} isLoading={uploadingFiles} />
    </>
  );
}
