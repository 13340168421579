import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ISaveCredentialsPayload } from "./types";
import { ACTION } from "const/actions";


export const saveCredentials = createAsyncThunk(
  ACTION.CREDENTIALS.SAVE_CREDENTIALS,
  async (payload: ISaveCredentialsPayload, { rejectWithValue }) => {
    try {
      const response = await api.credentials.saveCredentials(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
