import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IDataPointsApi } from "./types";

export const dataPoints: IDataPointsApi = {
  getDataPoints: (_) =>
    http
      .get(API_ROUTES.dataPoints.getDataPoints)
      .then((response) => response.data),
  createDataPoint: (payload) =>
    http
      .post(API_ROUTES.dataPoints.createDataPoint, payload)
      .then((response) => response.data),
  updateDataPoint: (payload) =>
    http
      .put(API_ROUTES.dataPoints.updateDataPoint(payload.id), payload)
      .then((response) => response.data),
  deleteDataPoint: (payload) =>
    http
      .delete(API_ROUTES.dataPoints.deleteDataPoint(payload.id))
      .then((response) => response.data),
};
