export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  APP_OLD: "/old",
  ADMIN_OLD: "/old/admin",
  ADMIN_USERS: "/old/admin/users",
  PARAPLANNER_OLD: "/old/paraplanner",
  PARAPLANNER_MAILBOX: "/old/paraplanner/mailbox",
  PARAPLANNER_MAILBOX_INBOX: "/old/paraplanner/mailbox/inbox",
  PARAPLANNER_MAILBOX_DRAFTS: "/old/paraplanner/mailbox/drafts",
  PARAPLANNER_MAILBOX_ARCHIVED: "/old/paraplanner/mailbox/archived",
  ADVISOR: "/old/advisor",
  ADVISOR_SETTINGS: "/old/advisor/settings",
  ADVISOR_SETTINGS_MODELS: "/old/advisor/settings/models",
  ADVISOR_SETTINGS_CONNECT: "/old/advisor/settings/connect",
  ADVISOR_PORTFOLIO_COMPARISON: "/old/advisor/portfolio-comparison",
  ADVISOR_PORTFOLIO_ANALYSIS: "/old/advisor/portfolio-analysis",
  ADVISOR_MAILBOX: "/old/advisor/mailbox",
  ADVISOR_MAILBOX_ALL: "/old/advisor/mailbox/all",
  ADVISOR_MAILBOX_WITH_CONVERSATION: "/old/advisor/mailbox/conversations",
  PLANNING_SUBMISSION_FORM: "/old/advisor/planning-submission-form",
  ADVISOR_DATA_EXTRACTOR: "/old/advisor/data-extractor",
  ADVISOR_PARAZOOM: "/old/advisor/parazoom",
  ADVISOR_AUTOMATIONS: "/old/advisor/automations",
  ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL:
    "/old/advisor/automations/investment-statement-extraction-into-excel",
  ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_EXTRACTION_INTO_EXCEL_HUMAN_CHECK:
    "/old/advisor/automations/investment-statement-extraction-into-excel-with-human-check",
  ADVISOR_AUTOMATIONS__INVESTMENT_STATEMENT_DATA_ENTRY:
    "/old/advisor/automations/investment-statement-data-entry",
  ADVISOR_AUTOMATIONS__PORTFOLIO_ANALYSIS:
    "/old/advisor/automations/portfolio-analysis",
  APP: {
    DEFAULT: "/app",
    DASHBOARD: "/app/dashboard",
    CALENDAR: "/app/calendar",
    TASKS: "/app/tasks",
    INBOX: {
      DEFAULT: "/app/inbox",
    },
    CLIENTS: {
      DEFAULT: "/app/clients",
      MEETING: "/app/clients/meeting/:id",
    },
    COPILOT: {
      DEFAULT: "/app/copilot",
      CONFIGURE: "/app/copilot/configure",
      MEETING: "/app/copilot/meeting",
      IN_PERSON: "/app/copilot/in-person",
      SUMMARY: "/app/copilot/summary",
    },
    PARAPLANNER: {
      DEFAULT: "/app/paraplanner",
      SHARE_LINK: "/app/paraplanner/share-link",
      UPLOAD_FILE: "/app/paraplanner/upload-file",
      STATEMENT_EXTRACTION: "/app/paraplanner/statement-extraction",
    },
    SETTINGS: {
      DEFAULT: "/app/settings",
      CREDENTIALS: "/app/settings/credentials",
      CONNECTIONS: "/app/settings/connections",
      MY_TEMPLATES: "/app/settings/my-templates",
      ORG_TEMPLATES: "/app/settings/organization-templates",
      DATA_POINTS: "/app/settings/data-points",
      CARDS: "/app/settings/cards",
      COMPLIANCE: "/app/settings/compliance",
      SECURITY: "/app/settings/security",
      INTEGRATIONS: "/app/settings/integrations",
      SUPPORT: "/app/settings/support",
      ADVANCED: "/app/settings/advanced",
    },
  },
  ADMIN: {
    DEFAULT: "/admin",
    SETTINGS: {
      DEFAULT: "/admin/settings",
      USER_MANAGEMENT: "/admin/settings/user-management",
      ORGANIZATION_MANAGEMENT: "/admin/settings/organization-management",
      TEMPLATES_MANAGEMENT: "/admin/settings/templates-management",
    },
  },
  PARAPLANNER: {
    DEFAULT: "/paraplanner",
    INBOXES: {
      DEFAULT: "/paraplanner/inboxes",
      INBOX: "/paraplanner/inboxes/inbox",
      DRAFTS: "/paraplanner/inboxes/drafts",
      ARCHIVED: "/paraplanner/inboxes/archived",
    },
  },
};

export const URL_PARAM = {
  REASON: "reason",
};
