import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IRedtailApi } from "./types";
import { IConnectRedtailPayload } from "store/redtail";

export const redtail: IRedtailApi = {
  connectRedtail: (payload: IConnectRedtailPayload) =>
    http
      .post(API_ROUTES.redtail.connectRedtail, payload)
      .then((response) => response.data),
  disconnectRedtail: (payload: void) =>
    http
      .delete(API_ROUTES.redtail.disconnectRedtail)
      .then((response) => response.data),
};
