import { Icon, IIconProps } from './Icon';

export const Close = (props: IIconProps) => {
  const { spin } = props;
  return (
    <Icon
      {...props}
      viewBox={!spin ? "0 0 14 14" : "0 0 24 24"}
    >
      <path d="M9.33334 4.66689C9.22395 4.55753 9.0756 4.49609 8.92092 4.49609C8.76624 4.49609 8.6179 4.55753 8.5085 4.66689L7.00001 6.17539L5.49151 4.66689C5.38149 4.56063 5.23414 4.50183 5.08119 4.50316C4.92825 4.50449 4.78194 4.56584 4.67378 4.67399C4.56563 4.78215 4.50428 4.92845 4.50295 5.0814C4.50162 5.23435 4.56042 5.3817 4.66668 5.49172L6.17518 7.00022L4.66668 8.50872C4.56042 8.61874 4.50162 8.76609 4.50295 8.91904C4.50428 9.07198 4.56563 9.21829 4.67378 9.32645C4.78194 9.4346 4.92825 9.49595 5.08119 9.49728C5.23414 9.49861 5.38149 9.43981 5.49151 9.33355L7.00001 7.82505L8.5085 9.33355C8.61852 9.43981 8.76587 9.49861 8.91882 9.49728C9.07177 9.49595 9.21808 9.4346 9.32623 9.32645C9.43439 9.21829 9.49573 9.07198 9.49706 8.91904C9.49839 8.76609 9.4396 8.61874 9.33334 8.50872L7.82484 7.00022L9.33334 5.49172C9.44269 5.38233 9.50413 5.23398 9.50413 5.0793C9.50413 4.92462 9.44269 4.77628 9.33334 4.66689Z" fill="#696969" />
      <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.003033 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.998 5.1441 13.2599 3.36479 11.9475 2.05247C10.6352 0.74015 8.8559 0.0020073 7 0V0ZM7 12.8333C5.84628 12.8333 4.71846 12.4912 3.75918 11.8502C2.79989 11.2093 2.05222 10.2982 1.61071 9.23232C1.16919 8.16642 1.05368 6.99353 1.27876 5.86197C1.50384 4.73042 2.05941 3.69102 2.87521 2.87521C3.69102 2.0594 4.73042 1.50383 5.86198 1.27875C6.99353 1.05367 8.16642 1.16919 9.23232 1.6107C10.2982 2.05221 11.2093 2.79989 11.8502 3.75917C12.4912 4.71846 12.8333 5.84628 12.8333 7C12.8316 8.54658 12.2165 10.0293 11.1229 11.1229C10.0293 12.2165 8.54658 12.8316 7 12.8333Z" fill="#696969" />
    </Icon>
  );
};