import { PayloadAction, createSlice, current, isAnyOf } from "@reduxjs/toolkit";
import {
  AiAnswerType,
  IClientData,
  IClientManagementState,
  IGetAiDataSearchAnswerResponse,
} from "./types";
import {
  getClientsList,
  createClient,
  deleteClient,
  getDataRecordsList,
  createDataRecord,
  deleteDataRecord,
  getAiDataSearchAnswer,
  linkGoogleDriveFolder,
  getAiDataSearchWhoIsClient,
  getAiDataSearchDoAgenda,
  syncSalesforce,
  importRedtail,
  syncRedtail,
} from "./actions";
import { i18n } from "i18n";

const initialState: IClientManagementState = {
  isGetClientsListProcessing: false,
  clientsList: [],
  isCreateClientProcessing: false,
  isGetClientProcessing: false,
  isDeleteClientProcessing: false,
  activeClient: null,
  isAskAiProcessing: false,
  isGetDataRecordsListProcessing: false,
  clientDataRecordsList: [],
  isCreateDataRecordProcessing: false,
  isDeleteDataRecordProcessing: false,
  isGetAiDataSearchAnswerProcessing: false,
  aiDataSearchAnswers: [],
  isSyncingSalesforce: false,
  isSyncingRedtail: false,
  isImportingFromToRedtail: false,
  importResult: null,
};

const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,
  reducers: {
    setActiveClient: (state, action: PayloadAction<IClientData>) => {
      state.activeClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsList.pending, (state) => {
        state.isGetClientsListProcessing = true;
      })
      .addCase(getClientsList.fulfilled, (state, action) => {
        state.isGetClientsListProcessing = false;
        state.clientsList = action.payload?.sort((a, b) =>
          a?.firstName?.toLowerCase().localeCompare(b?.firstName?.toLowerCase())
        );
      })
      .addCase(getClientsList.rejected, (state) => {
        state.isGetClientsListProcessing = false;
      })
      .addCase(createClient.pending, (state) => {
        state.isCreateClientProcessing = true;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isCreateClientProcessing = false;
        state.activeClient = action.payload;
        state.clientsList = [
          ...currentState?.clientsList,
          action.payload,
        ]?.sort((a, b) =>
          a?.firstName?.toLowerCase().localeCompare(b?.firstName?.toLowerCase())
        );
      })
      .addCase(createClient.rejected, (state) => {
        state.isCreateClientProcessing = false;
      })
      .addCase(deleteClient.pending, (state) => {
        state.isDeleteClientProcessing = true;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.isDeleteClientProcessing = false;
        const currentState = current(state);
        const deletedClientId = action.meta.arg.id;

        state.clientsList = currentState?.clientsList?.filter(
          (client) => client.id !== deletedClientId
        );
        if (currentState?.activeClient?.id === deletedClientId) {
          state.activeClient = null;
        }
      })
      .addCase(deleteClient.rejected, (state) => {
        state.isDeleteClientProcessing = false;
      })
      .addCase(getDataRecordsList.pending, (state) => {
        state.isGetDataRecordsListProcessing = true;
      })
      .addCase(getDataRecordsList.fulfilled, (state, action) => {
        state.isGetDataRecordsListProcessing = false;
        state.clientDataRecordsList = action.payload;
      })
      .addCase(getDataRecordsList.rejected, (state) => {
        state.isGetDataRecordsListProcessing = false;
      })
      .addCase(deleteDataRecord.pending, (state) => {
        state.isDeleteDataRecordProcessing = true;
      })
      .addCase(deleteDataRecord.fulfilled, (state, action) => {
        state.isDeleteDataRecordProcessing = false;
        const currentState = current(state);
        const deletedDataRecordId = action.meta.arg.recordId;

        state.clientDataRecordsList =
          currentState.clientDataRecordsList?.filter(
            (record) => record.id !== deletedDataRecordId
          );
      })
      .addCase(deleteDataRecord.rejected, (state) => {
        state.isDeleteDataRecordProcessing = false;
      })
      .addCase(getAiDataSearchAnswer.pending, (state) => {
        state.isGetAiDataSearchAnswerProcessing = true;
      })
      .addCase(getAiDataSearchAnswer.fulfilled, (state, action) => {
        state.isGetAiDataSearchAnswerProcessing = false;
        const currentState = current(state);

        const newRecord: IGetAiDataSearchAnswerResponse = {
          answer: action.payload || "N/A",
          question: action.meta.arg.question,
          clientId: action.meta.arg.clientId,
          botId: action.meta.arg.botId,
        };

        state.aiDataSearchAnswers = [
          ...(currentState.aiDataSearchAnswers || []),
          newRecord,
        ];
      })
      .addCase(getAiDataSearchAnswer.rejected, (state) => {
        state.isGetAiDataSearchAnswerProcessing = false;
      })
      .addCase(getAiDataSearchWhoIsClient.pending, (state) => {
        state.isGetAiDataSearchAnswerProcessing = true;
      })
      .addCase(getAiDataSearchWhoIsClient.fulfilled, (state, action) => {
        state.isGetAiDataSearchAnswerProcessing = false;
        const currentState = current(state);

        const newRecord: IGetAiDataSearchAnswerResponse = {
          answer: action.payload?.summary || "N/A",
          question: i18n.t("component.AskAIConversation.whoMyClientIs"),
          clientId: action.meta.arg.clientId,
          botId: "",
          aiAnswerType: AiAnswerType.MARKUP,
        };

        state.aiDataSearchAnswers = [
          ...(currentState.aiDataSearchAnswers || []),
          newRecord,
        ];
      })
      .addCase(getAiDataSearchWhoIsClient.rejected, (state) => {
        state.isGetAiDataSearchAnswerProcessing = false;
      })
      .addCase(getAiDataSearchDoAgenda.pending, (state) => {
        state.isGetAiDataSearchAnswerProcessing = true;
      })
      .addCase(getAiDataSearchDoAgenda.fulfilled, (state, action) => {
        state.isGetAiDataSearchAnswerProcessing = false;
        const currentState = current(state);

        const newRecord: IGetAiDataSearchAnswerResponse = {
          answer: action.payload?.agenda || "N/A",
          question: i18n.t("component.AskAIConversation.agendaHelp"),
          clientId: action.meta.arg.clientId,
          botId: "",
          aiAnswerType: AiAnswerType.MARKUP,
        };

        state.aiDataSearchAnswers = [
          ...(currentState.aiDataSearchAnswers || []),
          newRecord,
        ];
      })
      .addCase(getAiDataSearchDoAgenda.rejected, (state) => {
        state.isGetAiDataSearchAnswerProcessing = false;
      })
      .addCase(syncSalesforce.pending, (state) => {
        state.isSyncingSalesforce = true;
      })
      .addCase(syncSalesforce.fulfilled, (state, action) => {
        state.isSyncingSalesforce = false;
      })
      .addCase(syncSalesforce.rejected, (state) => {
        state.isSyncingSalesforce = false;
      })
      .addCase(syncRedtail.pending, (state) => {
        state.isSyncingRedtail = true;
      })
      .addCase(syncRedtail.fulfilled, (state, action) => {
        state.isSyncingRedtail = false;
      })
      .addCase(syncRedtail.rejected, (state) => {
        state.isSyncingRedtail = false;
      })
      .addCase(importRedtail.pending, (state) => {
        state.isImportingFromToRedtail = true;
      })
      .addCase(importRedtail.fulfilled, (state, action) => {
        state.isImportingFromToRedtail = false;
        state.importResult = action.payload;
      })
      .addCase(importRedtail.rejected, (state) => {
        state.isImportingFromToRedtail = false;
        state.importResult = null;
      })
      .addMatcher(
        isAnyOf(createDataRecord.pending, linkGoogleDriveFolder.pending),
        (state, action) => {
          state.isCreateDataRecordProcessing = true;
        }
      )
      .addMatcher(
        isAnyOf(createDataRecord.fulfilled, linkGoogleDriveFolder.fulfilled),
        (state, action) => {
          state.isCreateDataRecordProcessing = false;
          const currentState = current(state);
          state.clientDataRecordsList = [
            action.payload,
            ...(currentState.clientDataRecordsList || []),
          ];
        }
      )
      .addMatcher(
        isAnyOf(createDataRecord.rejected, linkGoogleDriveFolder.rejected),
        (state, action) => {
          state.isCreateDataRecordProcessing = false;
        }
      );
  },
});

export const { setActiveClient } = clientManagementSlice.actions;

export default clientManagementSlice.reducer;
