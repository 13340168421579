import { Fragment } from 'react';
import { useAppDispatch, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { createClient, IClientData, ICreateClientPayload, selectClientsList, selectIsCreateClientProcessing } from 'store/clientManagement';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { trim } from 'lodash';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  onCreateCompleted?: (client: IClientData) => void
}

export const AddClientModal = ({ isVisible, setIsVisible, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isCreateClientProcessing = useSelector(selectIsCreateClientProcessing);
  const clientsList = useSelector(selectClientsList);


  const formSchema = z.object({
    first_name: z.string().min(1, t('errors.required') || ''),
    last_name: z.string().min(1, t('errors.required') || ''),
    email: z.string().max(0, t('errors.wrongEmailFormat') || '').or(z.string().email())
  });

  const form = useForm<Required<ICreateClientPayload>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
    }
  });

  const handleCreateClient = async (values: z.infer<typeof formSchema>) => {
    const payload: ICreateClientPayload = { ...values };
    if (payload.email === '') {
      delete payload.email
    }

    const similarClient = clientsList?.find(client => {
      return makeItLowerCase(client?.firstName) === makeItLowerCase(values?.first_name)
        && makeItLowerCase(client?.lastName) === makeItLowerCase(values?.last_name)
    })

    if (similarClient) {
      toast(t('component.AddClientModal.similarClientExists', { style: 'warning' }))
    } else {
      const response = await dispatch(createClient(payload));

      // Call with success
      if ((response?.payload as IClientData)?.id) {
        form.reset();
        setIsVisible(false);
        toast(t('component.AddClientModal.saveSuccess'), { type: 'success' })
        onCreateCompleted && onCreateCompleted(response?.payload as IClientData)
      }
    }
  }

  const makeItLowerCase = (input: string) => {
    return trim(input?.toLowerCase())
  }

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t('component.AddClientModal.title')}</DialogTitle>
            <DialogDescription>
              {t('component.AddClientModal.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleCreateClient)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('common.firstName')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isCreateClientProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('common.lastName')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isCreateClientProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  loading={isCreateClientProcessing}
                >
                  {t('component.AddClientModal.saveBtn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
