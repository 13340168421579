import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { ICrmTask } from 'store/chat-assistant';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "components/ui/table"
import { SectionContainer } from 'components/common/_atoms/SectionContainer';
import { EditableField } from 'components/common/_atoms/EditableField';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { Calendar } from 'components/ui/calendar';
import { cn } from 'lib/utils';
import { format } from 'date-fns';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { InfoCard } from 'components/layout-atoms/InfoCard';

interface IProps {
  tasks: ICrmTask[]
  onTasksChange: (payload: ICrmTask[]) => void
}

interface IInputChangeFn {
  index: number
  field: string
  value: string | boolean | undefined
}

export const TasksTable = ({ tasks, onTasksChange }: IProps) => {
  const { t } = useTranslation();

  const [editedTasks, setEditedTasks] = useState(tasks);

  useEffect(() => {
    setEditedTasks(tasks)
  }, [tasks])

  useEffect(() => {
    onTasksChange(editedTasks)
  }, [editedTasks, onTasksChange])

  const handleInputChange = ({ index, field, value }: IInputChangeFn) => {
    const updatedTasks = [...editedTasks];
    updatedTasks[index] = { ...updatedTasks[index], [field]: value };
    setEditedTasks(updatedTasks);
  };

  return (
    <>
      <SectionContainer
        title={t('component.TasksTable.title') as string}
        description={t('component.TasksTable.description') as string}
      >
        {!tasks || tasks?.length === 0 ? (
          <InfoCard
            title={t('component.TasksTable.noData')}
          />
        ) : (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-36'>
                    {t('component.TasksTable.column.due')}
                  </TableHead>
                  <TableHead>
                    {t('component.TasksTable.column.title')}
                  </TableHead>
                  {/* <TableHead>
                  {t('component.TasksTable.column.description')}
                </TableHead> */}
                  <TableHead className='w-48'>
                    {t('component.TasksTable.column.assignee')}
                  </TableHead>
                  <TableHead className='w-20'>
                    {t('component.TasksTable.column.sync')}
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {editedTasks.map((task, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "min-h-9 text-left font-normal bg-transparent border-transparent shadow-none hover:border-input hover:shadow-sm hover:bg-transparent",
                              { "text-muted-foreground": !task.due_date }
                            )}
                            textContainerClassName="w-full justify-start"
                          >
                            {task.due_date ? (
                              format(task.due_date, "PP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={new Date(task.due_date)}
                            onSelect={(day) => {
                              if (day) {
                                handleInputChange({ index, field: 'due_date', value: format(day, 'yyyy-MM-dd') })
                              }
                            }}
                            disabled={(date) =>
                              date < new Date("1900-01-01")
                            }
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <EditableField
                        value={task.title}
                        onSave={({ newValue }) => {
                          handleInputChange({ index, field: 'title', value: newValue })
                        }}
                      />
                    </TableCell>
                    {/* <TableCell>
                    <EditableField
                      value={task.description}
                      onSave={({ newValue }) => {
                        handleInputChange({ index, field: 'description', value: newValue })
                      }}
                    />
                  </TableCell> */}
                    <TableCell>
                      <EditableField
                        value={task.assignee}
                        onSave={({ newValue }) => {
                          handleInputChange({ index, field: 'assignee', value: newValue })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Label className='px-3'>
                        <Switch
                          checked={task.sync}
                          onCheckedChange={(checked) => {
                            handleInputChange({ index, field: 'sync', value: checked })
                          }}
                        />
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </SectionContainer>
    </>
  );
};

