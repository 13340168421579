import { useAppSelector } from "hooks";
import { selectSelectedThread } from "store/mailbox";

export const ThreadDetailsHeader = () => {
  const activeThread = useAppSelector(selectSelectedThread);

  if(!activeThread) {
    return (<>&nbsp;</>)
  }

  return (
    <div>
      {activeThread && activeThread.subject ? activeThread.subject : '(no subject)'}
    </div>
  );
}

