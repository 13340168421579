import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ICreateRequestPayload } from "./types";
import { ACTION } from "const/actions";

export const createRequest = createAsyncThunk(
  ACTION.ANALYSIS.CREATE_REQUEST,
  async (payload: ICreateRequestPayload, { rejectWithValue }) => {
    try {
      const response = await api.analysis.createRequest(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const uploadAnalysisFiles = createAsyncThunk(
  ACTION.ANALYSIS.UPLOAD_ANALYSIS_FILES,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.analysis.uploadFiles(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const callOhNo = createAsyncThunk(
  ACTION.ANALYSIS.OH_NO,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.analysis.ohNo();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
