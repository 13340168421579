import { createSlice } from "@reduxjs/toolkit";
import { ICredentialsState } from "./types";
import { saveCredentials } from "./actions";

const initialState: ICredentialsState = {
  processing: false,
  saveCredentialsResponse: {},
};

const credentialsSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    resetCredentialsManagementResponse: (state) => {
      state.saveCredentialsResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCredentials.pending, (state, action) => {
        state.processing = true;
        state.saveCredentialsResponse = {};
      })
      .addCase(saveCredentials.fulfilled, (state, action) => {
        state.processing = false;
        state.saveCredentialsResponse = action.payload;
      })
      .addCase(saveCredentials.rejected, (state) => {
        state.processing = false;
        state.saveCredentialsResponse = {};
      });
  },
});

export const {
  resetCredentialsManagementResponse,
} = credentialsSlice.actions;

export default credentialsSlice.reducer;
