import { ReactNode, useEffect, useRef } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { BrainAvatar, SignOut } from 'icons';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { SignOutReason, getUser, logout } from 'store/authentication';
import { getProfileDetails, selectProfileDetails, updatePreferences } from 'store/profile';
import { GENERAL_CONVERSATION_ID, RoleEnum, INTERCOM_APP, INTRO_VIDEO_ID } from "const";

import styles from './Menu.module.scss';
import { MailboxSelector } from 'components_old/shared/MailboxSelector';
import { ROUTES } from 'routing/constants';
import classnames from 'classnames';
import { getAdvisorMailboxes, selectActiveMailbox, selectAdvisorMailboxes, updateActiveAdvisorMailbox } from 'store/users';
import { getMenuItems } from 'configurations';
import { getConversationId, setIntercomChatVisibility } from 'store/chat';
import { subscribeUser } from '../../../subscription';
import ModalVideo from 'react-modal-video';
import { getGeneralConversation } from 'store/notifications';
import NotificationsCenter from 'components_old/shared/NotificationsCenter/NotificationsCenter';


export interface IMenuLink {
  id: string;
  label: string;
  icon?: ReactNode;
  availableTo?: RoleEnum[];
  to?: string;
  onClick?: () => void;
  isActive?: boolean;
  target?: string;
}

export const Menu = () => {
  const hasSubscribed = useRef(false);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentUser = useAppSelector(getUser);
  const myProfileData = useAppSelector(selectProfileDetails)
  const isParaplanner = myProfileData.role === RoleEnum.Paraplanner;
  const isInboxesRoute = location.pathname.includes(ROUTES.PARAPLANNER_MAILBOX);
  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);
  const activeMailbox = useAppSelector(selectActiveMailbox);

  const links = getMenuItems(myProfileData.role, location.pathname);

  useEffect(() => {
    if (currentUser.id) {
      dispatch(getProfileDetails())
    }
  }, [currentUser?.id, dispatch]);

  /* Mailbox logic */
  // I have the profile, get the mailboxes list
  useEffect(() => {
    if (myProfileData.id && myProfileData.role !== RoleEnum.Admin) {
      dispatch(getAdvisorMailboxes(null));
    }
  }, [dispatch, myProfileData]);

  // After I get the data set the first mailbox as active
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authId = params.get('authId');

    if (advisorMailboxes?.length) {
      if (authId) {
        const mailboxFromParam = authId ? advisorMailboxes?.find(mailbox => mailbox?.connected_emails[0]?.authId === authId) : undefined

        if (authId) {
          params.delete('authId');
          setSearchParams(params);
        }

        dispatch(updateActiveAdvisorMailbox({ payload: mailboxFromParam || advisorMailboxes[0] }))
      } else if (!activeMailbox) {
        dispatch(updateActiveAdvisorMailbox({ payload: advisorMailboxes[0] }))
      }
    }
  }, [dispatch, advisorMailboxes, activeMailbox])

  useEffect(() => {
    if (hasSubscribed.current) {
      return
    }

    if (myProfileData.role === RoleEnum.Advisor) {
      // @ts-ignore
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: INTERCOM_APP,
        name: `${myProfileData?.firstName} ${myProfileData?.lastName}`,
        email: myProfileData?.email,
        user_id: myProfileData?.id,
        user_hash: myProfileData?.hashUserId
      });

      // @ts-ignore
      window.Intercom('onHide', () => {
        dispatch(setIntercomChatVisibility(false))
      });

      // @ts-ignore
      window.Intercom('onShow', () => {
        dispatch(setIntercomChatVisibility(true))
      });

      const { introVideoWatched: hasSeenIntroVideo } = myProfileData?.preferences || {};
      if (myProfileData?.role === RoleEnum.Advisor && hasSeenIntroVideo) {
        setTimeout(() => {
          dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, forceIsOpen: true }))
        }, 2000);
      }


      if (myProfileData.id) {
        subscribeUser(myProfileData.id)
        hasSubscribed.current = true;
      }
    }
  }, [myProfileData])

  useEffect(() => {
    if (!myProfileData.id) {
      return
    }

    if (myProfileData?.role === RoleEnum.Advisor) {
      dispatch(getGeneralConversation())
    }
  }, [myProfileData.id])

  const onCloseVideo = () => {
    dispatch(updatePreferences({ introVideoWatched: true }));
    dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, forceIsOpen: true }))
  }


  return (
    <div className={styles.Menu}>
      {myProfileData?.role === RoleEnum.Advisor && !myProfileData?.preferences?.introVideoWatched && (
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 1 }}
          isOpen={true}
          videoId={INTRO_VIDEO_ID}
          onClose={() => onCloseVideo()}
        />
      )}

      {myProfileData?.role === RoleEnum.Advisor && myProfileData?.id && (
        <NotificationsCenter
          id={myProfileData?.id}
        />
      )}

      <div className={styles.MenuHeader}>
        <div className={styles.Avatar}>
          <BrainAvatar size={26} hasHover={false} />
        </div>
        <div className={styles.UserName}>{myProfileData?.firstName} {myProfileData?.lastName}</div>
      </div>

      <div className={styles.MenuList}>
        {links?.length > 0 && (
          <ul>
            {links.map(({ icon, id, to, label, onClick, isActive, target }) => (
              <li key={id}>
                {to && (
                  <Link
                    className={classnames(styles.MenuListItem, { [styles['MenuListItem--isActive']]: isActive })}
                    to={to}
                    target={target}
                  >
                    {icon}
                    <span>{label}</span>
                  </Link>
                )}
                {onClick && (
                  <button
                    type="button"
                    onClick={onClick}
                    className={classnames(styles.MenuListItem, { [styles['MenuListItem--isActive']]: isActive })}
                  >
                    {icon}
                    <span>{label}</span>
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className={styles.MenuDivider} />

      {isParaplanner && isInboxesRoute && (<MailboxSelector />)}

      <div className={styles.MenuFooter}>
        <button
          type="button"
          onClick={() => dispatch(logout({ reason: SignOutReason.USER_ACTION }))}
          className={styles.MenuListItem}
        >
          <SignOut />
          <span>{t('common.signOut')}</span>
        </button>
      </div>
    </div>
  );
};
