import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'hooks';
import { CheckIcon, PencilLineIcon, UndoDotIcon } from 'lucide-react';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { Loading } from 'components/layout-atoms/Loading';
import { cn } from 'lib/utils';

interface IEditableFieldOnSave {
  newValue: string | undefined,
  oldValue?: string | undefined,
  id?: string | undefined
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  value: string;
  placeholder?: string;
  onSave?: (payload: IEditableFieldOnSave) => void;
  showActionButtons?: boolean;
  isProcessing?: boolean;
  containerClassName?: string;
}

export function EditableField({
  id,
  placeholder,
  value,
  onSave,
  showActionButtons,
  isProcessing,
  containerClassName,
  ...props
}: IProps) {
  const { t } = useTranslation();
  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isDirty, setIsDirty] = useState(false)
  const [initialValue, setInitialValue] = useState(value);
  const [updatedValue, setUpdatedValue] = useState(value);

  // useClickOutside(fieldRef, () => {
  //   if (revertValueOnClickOutside) {
  //     handleRevert();
  //   } else {
  //     onSave && onSave({ newValue: updatedValue, oldValue: initialValue, id })
  //   }
  // });

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  useEffect(() => {
    setUpdatedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setIsDirty(initialValue !== updatedValue);
  }, [initialValue, updatedValue]);

  const handleSave = () => {
    onSave && onSave({ newValue: updatedValue, oldValue: initialValue, id })
    setInitialValue(updatedValue)
    inputRef.current?.blur()
  }

  const handleRevert = () => {
    setUpdatedValue(initialValue)

    setTimeout(() => {
      inputRef.current?.blur()
    }, 10);
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation()
      handleSave()
    }

    if (e.key === 'Escape') {
      handleRevert()
    }
  }

  return (
    <div
      ref={fieldRef}
      className={cn('relative group', containerClassName)}
    >
      <Input
        ref={inputRef}
        placeholder={placeholder || ''}
        value={updatedValue}
        onChange={(e) => setUpdatedValue(e.target.value)}
        onBlur={() => handleSave()}
        onKeyDown={handleKeyDown}
        className='border-transparent shadow-none group-hover:border-input group-hover:shadow-sm pr-10 group-hover:pr-10 focus:pr-10 text-ellipsis'
        disabled={isProcessing}
        {...props}
      />

      {!isProcessing ? (
        <PencilLineIcon className='hidden absolute top-1/2 transform -translate-y-1/2 right-4 size-4 group-hover:block' />
      ) : (
        <Loading className='absolute top-1/2 transform -translate-y-1/2 right-4 size-4' />
      )}

      {showActionButtons && isDirty && (
        <div className='absolute flex gap-2'>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type='button'
                onClick={handleSave}
              >
                <CheckIcon className='size-4' />
              </Button>
            </TooltipTrigger>
            <TooltipContent side='left'>
              {t('general.save')}
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type='button'
                onClick={handleRevert}
              >
                <UndoDotIcon className='size-4' />
              </Button>
            </TooltipTrigger>
            <TooltipContent side='right'>
              {t('general.revert')}
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
