import { useTranslation } from 'hooks';
import { ROUTES } from 'routing/constants';
import { SideMenuSubLayout } from 'components/layout/SideMenuSubLayout';

export const InboxesPage = () => {
  const { t } = useTranslation();

  return (
    <SideMenuSubLayout
      menuItems={[
        {
          label: t("page.AdminSettingsPage.userManagement"),
          to: ROUTES.ADMIN.SETTINGS.USER_MANAGEMENT,
        },
      ]}
    />
  );
};

