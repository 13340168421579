import { processApiError } from "services";
import { api } from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION } from "const/actions";

export const getCalendarEvents = createAsyncThunk(
  ACTION.CALENDAR.GET_CALENDAR_EVENTS,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.calendar.getCalendarEvents();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
