import { useEffect } from "react";

const addBodyClass = (className: string) => {
  className && document?.body?.classList?.add(className);
};
const removeBodyClass = (className: string) => {
  className && document?.body?.classList?.remove(className);
};

export const useBodyClass = (className: string | string[]) => {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);

  return { addBodyClass, removeBodyClass };
};
