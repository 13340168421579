import styles from './UploadPortfolioModel.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, FileUploader, INPUT_TYPE, Input, StepperTitle } from 'components_old';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import { useSelector } from 'react-redux';
import { IPortfolioModel, IUploadPortfolioModelsResponse, createPortfolioModels, selectGettingModels, selectIsUploadingModels, uploadPortfolioModels } from 'store/portfolioModel';
import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { Add, Close } from 'icons';

interface IPortfolioName {
  id: string,
  name: string
}

interface IPortfolioUploadModelNames {
  names: IPortfolioName[];
}

interface IProps {
  handleExit: Function
}

export const UploadPortfolioModel = ({ handleExit }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isUploadingModels = useSelector(selectIsUploadingModels);
  const isGettingModels = useSelector(selectGettingModels);

  const [filesToUpload, setFilesToUpload] = useState<Array<IFileWithMeta>>([]);
  const [isLocalUploadIncomplete, setIsLocalUploadIncomplete] = useState(true);

  const { control, register, watch, trigger, handleSubmit, formState: { errors, isValid } } = useForm<IPortfolioUploadModelNames>({
    defaultValues: { names: [{ name: '', id: uuidv4() }] },
    mode: 'onBlur'
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "names",
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      trigger();
    });
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    const isIncomplete =
      filesToUpload.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
      !filesToUpload.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))
    setIsLocalUploadIncomplete(isIncomplete)
  }, [filesToUpload])

  const handleChangeStatus = (file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]) => {

    const isIncomplete =
      allFiles.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
      !allFiles.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))
    setIsLocalUploadIncomplete(isIncomplete)

    setFilesToUpload(allFiles);
  }

  const doFileUpload = async (values: IPortfolioUploadModelNames) => {
    const formData = new FormData();
    const { names } = values;
    
    filesToUpload?.forEach(item => {
      formData.append('files', item.file);
    })

    try {
      const uploadResponse = await dispatch(uploadPortfolioModels(formData));

      if (!uploadResponse.hasOwnProperty('error')) {
        const link = (uploadResponse?.payload as IUploadPortfolioModelsResponse)?.link;

        const createPayload: IPortfolioModel[] = names?.map(item => ({
          id: item.id,
          name: item.name,
          google_drive_link: link,
          tickers: []
        }))

        const response = await dispatch(createPortfolioModels(createPayload));
        if (!response.hasOwnProperty('error')) {
          handleExit();
          // mixpanel.track(EVENT.PORTFOLIO_MODELS.UPLOAD_MODELS);
        }
      }
    } catch (error) { }

  }

  return (
    <form onSubmit={handleSubmit(doFileUpload)}>
      <StepperTitle className={styles.Title}>
        <div>{t('portfolioModels.uploadPortfolioModel.titleA')}</div>
        <div>{t('portfolioModels.uploadPortfolioModel.titleB')}</div>
        <div>{t('portfolioModels.uploadPortfolioModel.titleC')}</div>
        <div>{t('portfolioModels.uploadPortfolioModel.titleD')}</div>
      </StepperTitle>

      <FileUploader
        onChangeStatus={handleChangeStatus}
        isLoading={isUploadingModels || isGettingModels}
        showSubmitButton={false}
      />

      <div className={styles.NamesWrapper}>
        {fields.map((field, index) => (
          <div className={styles.NameWrapper}>

            <Input
              placeholder={t("placeholder.portfolioName") as string}
              key={field.id}
              {...register(`names.${index}.name` as const, {
                required: true
              })}
              lookType={INPUT_TYPE.MINIMALIST}
              error={!!errors?.names?.[index]?.name}
              isProcessing={isUploadingModels || isGettingModels}
            />
            <Close
              size={16}
              tooltip={t('common.removeName')}
              wrapperStyle={{ marginLeft: 2 }}
              className={classnames(styles.CloseIcon)}
              onClick={() => remove(index)}
            />
          </div>
        ))}
      </div>

      <div className={styles.ButtonContainer}>
        <Add
          size={16}
          tooltip={t('common.addName')}
          className={styles.IconAdd}
          iconClassName={styles.Icon}
          onClick={() => append({ id: uuidv4(), name: '' })}
        />
      </div>

      <Button
        look={BUTTON_LOOK.SECONDARY}
        loading={isUploadingModels || isGettingModels}
        disabled={!isValid || isLocalUploadIncomplete}
        type='submit'
      >
        {t('portfolioModels.uploadPortfolioModel.uploadAndExit')}
      </Button>

    </form >
  );
}
