import { createSelector } from "@reduxjs/toolkit";
import { STEP_TYPE } from "components_old/features/PortfolioAnalysis/types";
import { RootState } from "store";

const getAnalysisState = (state: RootState) => state.analysis;

export const selectAnalysisProcessing = createSelector(
  [getAnalysisState],
  ({ processing }) => processing
);

export const selectAnalysisUploadingFiles = createSelector(
  [getAnalysisState],
  ({ uploadingFiles }) => uploadingFiles
);

export const selectUploadingFiles = createSelector(
  [getAnalysisState],
  ({ uploadingFiles }) => uploadingFiles
);

export const selectCurrentWizardRecord = createSelector(
  [getAnalysisState],
  ({ wizardHistory }) => wizardHistory[wizardHistory.length - 1]
);

export const selectWizardHistory = createSelector(
  [getAnalysisState],
  ({ wizardHistory }) => wizardHistory
);

export const selectTotalSteps = createSelector(
  [getAnalysisState],
  ({ wizardHistory }) => {
    const currentRecord = wizardHistory[wizardHistory.length - 1];
    if (!currentRecord) return 0;
    const { action } = currentRecord;
    if (!action) return 0;
    const frags = action.split("_");

    if ([STEP_TYPE.EXTRACTION].includes(frags[0])) return 4;
    if ([STEP_TYPE.ENTRY].includes(frags[0])) return 5;
    if ([STEP_TYPE.REPORT].includes(frags[0])) return 4;
    return 4;
  }
);

export const selectCurrentStep = createSelector(
  [getAnalysisState],
  ({ wizardHistory }) => {
    const currentRecord = wizardHistory[wizardHistory.length - 1];
    if (!currentRecord) return 0;
    const { action } = currentRecord;
    if (!action) return 0;
    const frags = action.split("_");

    return parseInt(frags[1]?.substring(4, 5));
  }
);


export const selectRequestPayload = createSelector(
  [getAnalysisState],
  ({ requestPayload }) => requestPayload
);

export const selectRequestSuccessful = createSelector(
  [getAnalysisState],
  ({ requestSuccessful }) => requestSuccessful
);
