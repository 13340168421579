import { Helmet } from 'react-helmet';
import { Page, Panel, Panels, PortfolioModelsManagement, } from 'components_old';
import { useTranslation } from 'hooks';
import { NylasConnect } from 'components_old/features/Mailbox/NylasConnect/NylasConnect';
import { useLocation } from 'react-router-dom';
import { MENU_ID, getSettingsMenu } from 'configurations';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  const menu = getSettingsMenu(pathname);
  const activeMenuItem = menu?.find(item => item?.isActive)


  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} | {activeMenuItem?.label}
        </title>
      </Helmet>

      <Page>
        <Panels>
          <Panel
            header={<>{t('routes.settings')}</>}
            submenu={menu}
            body={
              <>
                {activeMenuItem?.id === MENU_ID.SETTINGS.CONNECT && (
                  <NylasConnect />
                )}
                {activeMenuItem?.id === MENU_ID.SETTINGS.PORTFOLIO_MODELS_MANAGEMENT && (
                  <PortfolioModelsManagement />
                )}
              </>
            }
          />
        </Panels >
      </Page >
    </>
  );
};

