import classNames from "classnames";
import { Textarea } from "components/ui/textarea";
import React, { forwardRef, useCallback, useEffect } from "react";

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> { }

const AskAITextArea = forwardRef<HTMLTextAreaElement, IProps>(
  ({ className, placeholder, rows = 1, onChange, onKeyDown, ...props }, ref) => {
    const adjustHeight = useCallback(
      () => {
        const newRef = (ref as React.RefObject<HTMLTextAreaElement>);
        if (newRef && newRef.current) {
          newRef.current.style.height = "auto";
          newRef.current.style.height = `${newRef.current.scrollHeight + 2}px`;
        }
      },
      [ref],
    )

    useEffect(() => {
      setTimeout(() => {
        adjustHeight();
      }, 100);

    }, [adjustHeight, ref])

    return (
      <Textarea
        ref={ref as React.RefObject<HTMLTextAreaElement>}
        className={classNames("p-3 min-h-6 resize-none leading-normal rounded-lg border", className)}
        placeholder={placeholder}
        rows={rows}
        onChange={(e) => {
          adjustHeight();
          onChange && onChange(e);
        }}
        onKeyDown={(e) => {
          setTimeout(() => {
            adjustHeight();
          }, 0);
          onKeyDown && onKeyDown(e);
        }}
        {...props}
      />
    );
  }
);

export default AskAITextArea;
