import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import {
  ICreateUploadSecurelyFilesRequest,
  IGetConversationIdPayload,
} from "./types";
import { ACTION } from "const/actions";

export const getConversationId = createAsyncThunk(
  ACTION.CHAT.GET_CONVERSATION_ID,
  async (payload: IGetConversationIdPayload, { rejectWithValue }) => {
    const intercomConversationIds = payload?.intercomConversationIds;
    if (intercomConversationIds && intercomConversationIds[payload.threadId]) {
      return Promise.resolve({
        conversationId: intercomConversationIds[payload.threadId],
      });
    }

    try {
      const response = await api.chat.getConversationId(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const uploadSecurelyFiles = createAsyncThunk(
  ACTION.CHAT.UPLOAD_SECURELY_FILES,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.chat.uploadSecurelyFiles(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createUploadSecurelyFilesRequest = createAsyncThunk(
  ACTION.CHAT.CREATE_UPLOAD_SECURELY_FILES_REQUEST,
  async (payload: ICreateUploadSecurelyFilesRequest, { rejectWithValue }) => {
    try {
      const response = await api.chat.createUploadSecurelyFilesRequest({
        ...payload,
        note: payload.note || "-",
      });
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
