import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import axios, { AxiosHeaders, AxiosInstance } from "axios";

import { API_URL } from "const/env";
// import { store } from 'store';
// import { getAccessToken } from 'store/authentication';

export const http: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default http;

export * from "./processApiError";
