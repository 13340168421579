import { Icon, IIconProps } from './Icon';

export const Compare = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_403_3600)">
        <path d="M18.0002 5.086L12.9142 0H5.00015C4.2045 0 3.44144 0.31607 2.87883 0.87868C2.31622 1.44129 2.00015 2.20435 2.00015 3V20H18.0002V5.086ZM4.00015 18V3C4.00015 2.73478 4.10551 2.48043 4.29305 2.29289C4.48058 2.10536 4.73494 2 5.00015 2H12.0002V6H16.0002V18H4.00015ZM22.0002 9V24H7.00015V22H20.0002V7L22.0002 9Z" fill="#374957" />
      </g>
      <defs>
        <clipPath id="clip0_403_3600">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
