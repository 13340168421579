import classnames from 'classnames';
import styles from './Feedback.module.scss';
import { ReactNode } from 'react';

export enum FeedbackType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

interface IFeedbackProps {
  children?: ReactNode;
  type?: FeedbackType;
}

export const Feedback = ({ children, type = FeedbackType.INFO }: IFeedbackProps) => {
  return (
    <div className={classnames(styles.Feedback, styles['Feedback--' + type])} role="alert">
      {children}
    </div>
  );
};
