import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { ICredentialsApi } from "./types";

export const credentials: ICredentialsApi = {
  saveCredentials: (payload) =>
    http
      .post(API_ROUTES.credentials.saveCredentials, payload)
      .then((response) => response.data),
};
