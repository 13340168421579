import { Fragment, useEffect } from 'react';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog';
import { Form } from 'components/ui/form';
import { IMeetingTemplate, IMeetingTemplateFormData, selectIsCreatingTemplate, templateFormSchema } from 'store/templates-management';
import { MeetingTemplate } from '../settings/templates/MeetingTemplate';
import { v4 as uuidv4 } from 'uuid';
import { getDataPointsList } from 'store/data-points';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  onCreateCompleted?: (template: IMeetingTemplate) => void
}

export const AddTemplateModal = ({ isVisible, setIsVisible, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isCreatingTemplate = useSelector(selectIsCreatingTemplate);

  const defaultFormValues: IMeetingTemplateFormData = {
    name: 'New template',
    id: uuidv4(), sections: [], dataPoints: [], dataPointIds: [], formSelectedDataPointOptions: [],
    formSelectedTemplateId: ''
  }

  const form = useForm<IMeetingTemplateFormData>({
    resolver: zodResolver(templateFormSchema),
    mode: 'onSubmit',
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    dispatch(getDataPointsList())
  }, [dispatch])

  const handleOnCreateTemplate = async () => {
    const formValues = form.getValues();
    const payload = { ...formValues, dataPointIds: formValues?.formSelectedDataPointOptions?.map(option => option.value) };
  }

  return (
    <Fragment>
      <Dialog
        open={isVisible}
        onOpenChange={open => setIsVisible(open)}
      >
        <DialogContent className="sm:max-w-[625px] overflow-auto max-h-[90vh]">
          <MeetingTemplate
            form={form}
            template={defaultFormValues}
            inEditMode={true}
          />

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnCreateTemplate)} className="flex flex-col gap-4">
              <DialogFooter>
                <Button
                  type="submit"
                  loading={isCreatingTemplate}
                >
                  {t('component.AddTemplateModal.saveBtn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>

      </Dialog>
    </Fragment>
  );
};

