export const Logo = () => {
  return (
    <svg
      width="292"
      height="35"
      viewBox="0 0 292 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.430664 8.94687H5.63354V12.2514H5.87962C6.32492 11.1733 6.96942 10.2827 7.81313 9.57966C8.65684 8.87657 9.80522 8.52502 11.2583 8.52502C12.266 8.52502 13.1801 8.71251 14.0003 9.08749C14.844 9.46247 15.5589 10.0367 16.1448 10.8101C16.7541 11.5835 17.2228 12.5678 17.5509 13.7631C17.9025 14.9583 18.0783 16.3762 18.0783 18.0168C18.0783 19.6573 17.9025 21.0752 17.5509 22.2705C17.2228 23.4657 16.7541 24.45 16.1448 25.2234C15.5589 25.9968 14.844 26.571 14.0003 26.946C13.1801 27.321 12.266 27.5085 11.2583 27.5085C9.80522 27.5085 8.65684 27.1687 7.81313 26.489C6.96942 25.7859 6.32492 24.8836 5.87962 23.7821H5.63354V34.1176H0.430664V8.94687ZM9.00838 23.4657C10.1802 23.4657 11.0825 23.1142 11.7153 22.4111C12.3481 21.6846 12.6645 20.6885 12.6645 19.4229V16.6106C12.6645 15.345 12.3481 14.3607 11.7153 13.6576C11.0825 12.9311 10.1802 12.5678 9.00838 12.5678C8.09436 12.5678 7.29753 12.7904 6.61787 13.2357C5.96165 13.681 5.63354 14.3841 5.63354 15.345V20.6885C5.63354 21.6494 5.96165 22.3525 6.61787 22.7978C7.29753 23.2431 8.09436 23.4657 9.00838 23.4657Z"
        fill="black"
      />
      <path
        d="M36.5558 27.0866C35.4778 27.0866 34.6106 26.8171 33.9544 26.2781C33.2982 25.7156 32.9115 24.9188 32.7943 23.8876H32.6185C32.2904 25.0828 31.6342 25.9851 30.6499 26.5945C29.689 27.2038 28.5054 27.5085 27.0993 27.5085C25.3415 27.5085 23.9236 27.0398 22.8456 26.1023C21.7909 25.1414 21.2636 23.8055 21.2636 22.0947C21.2636 20.1963 21.955 18.7902 23.3377 17.8761C24.7205 16.9621 26.7477 16.5051 29.4195 16.5051H32.3373V15.5911C32.3373 14.513 32.0795 13.7045 31.5639 13.1654C31.0717 12.603 30.228 12.3217 29.0328 12.3217C27.9312 12.3217 27.0407 12.5326 26.361 12.9545C25.6814 13.3764 25.0954 13.9271 24.6033 14.6068L21.7558 12.0756C22.3651 11.0444 23.3143 10.2007 24.6033 9.5445C25.9157 8.86485 27.5914 8.52502 29.6304 8.52502C32.1146 8.52502 34.0481 9.09921 35.4309 10.2476C36.8371 11.3725 37.5402 13.0834 37.5402 15.3802V23.3251H39.544V27.0866H36.5558ZM29.0679 24.0985C29.9819 24.0985 30.7553 23.8759 31.3881 23.4306C32.0209 22.9853 32.3373 22.3408 32.3373 21.4971V19.3175H29.5601C27.4274 19.3175 26.361 20.0323 26.361 21.4619V22.165C26.361 22.7978 26.6071 23.2782 27.0993 23.6063C27.5914 23.9344 28.2476 24.0985 29.0679 24.0985Z"
        fill="black"
      />
      <path
        d="M43.116 23.0439H47.2994V12.9897H43.116V8.94687H52.5023V14.0794H52.7484C52.9124 13.4232 53.1351 12.7904 53.4163 12.1811C53.721 11.5483 54.096 10.9976 54.5413 10.5288C55.01 10.0367 55.5725 9.64997 56.2287 9.36873C56.8849 9.08749 57.6583 8.94687 58.5489 8.94687H60.2715V13.7279H56.4044C55.1154 13.7279 54.1428 14.1146 53.4866 14.888C52.8304 15.638 52.5023 16.5637 52.5023 17.6652V23.0439H58.4786V27.0866H43.116V23.0439Z"
        fill="black"
      />
      <path
        d="M78.7139 27.0866C77.6358 27.0866 76.7687 26.8171 76.1124 26.2781C75.4562 25.7156 75.0695 24.9188 74.9523 23.8876H74.7766C74.4484 25.0828 73.7922 25.9851 72.8079 26.5945C71.847 27.2038 70.6635 27.5085 69.2573 27.5085C67.4996 27.5085 66.0817 27.0398 65.0036 26.1023C63.949 25.1414 63.4216 23.8055 63.4216 22.0947C63.4216 20.1963 64.113 18.7902 65.4958 17.8761C66.8785 16.9621 68.9057 16.5051 71.5775 16.5051H74.4953V15.5911C74.4953 14.513 74.2375 13.7045 73.7219 13.1654C73.2298 12.603 72.386 12.3217 71.1908 12.3217C70.0893 12.3217 69.1987 12.5326 68.5191 12.9545C67.8394 13.3764 67.2535 13.9271 66.7613 14.6068L63.9138 12.0756C64.5231 11.0444 65.4723 10.2007 66.7613 9.5445C68.0738 8.86485 69.7495 8.52502 71.7884 8.52502C74.2727 8.52502 76.2062 9.09921 77.5889 10.2476C78.9951 11.3725 79.6982 13.0834 79.6982 15.3802V23.3251H81.702V27.0866H78.7139ZM71.2259 24.0985C72.14 24.0985 72.9134 23.8759 73.5462 23.4306C74.1789 22.9853 74.4953 22.3408 74.4953 21.4971V19.3175H71.7181C69.5854 19.3175 68.5191 20.0323 68.5191 21.4619V22.165C68.5191 22.7978 68.7651 23.2782 69.2573 23.6063C69.7495 23.9344 70.4057 24.0985 71.2259 24.0985Z"
        fill="black"
      />
      <path
        d="M84.7467 8.94687H89.9496V12.2514H90.1957C90.641 11.1733 91.2855 10.2827 92.1292 9.57966C92.9729 8.87657 94.1213 8.52502 95.5743 8.52502C96.5821 8.52502 97.4961 8.71251 98.3164 9.08749C99.1601 9.46247 99.8749 10.0367 100.461 10.8101C101.07 11.5835 101.539 12.5678 101.867 13.7631C102.219 14.9583 102.394 16.3762 102.394 18.0168C102.394 19.6573 102.219 21.0752 101.867 22.2705C101.539 23.4657 101.07 24.45 100.461 25.2234C99.8749 25.9968 99.1601 26.571 98.3164 26.946C97.4961 27.321 96.5821 27.5085 95.5743 27.5085C94.1213 27.5085 92.9729 27.1687 92.1292 26.489C91.2855 25.7859 90.641 24.8836 90.1957 23.7821H89.9496V34.1176H84.7467V8.94687ZM93.3244 23.4657C94.4963 23.4657 95.3986 23.1142 96.0313 22.4111C96.6641 21.6846 96.9805 20.6885 96.9805 19.4229V16.6106C96.9805 15.345 96.6641 14.3607 96.0313 13.6576C95.3986 12.9311 94.4963 12.5678 93.3244 12.5678C92.4104 12.5678 91.6136 12.7904 90.9339 13.2357C90.2777 13.681 89.9496 14.3841 89.9496 15.345V20.6885C89.9496 21.6494 90.2777 22.3525 90.9339 22.7978C91.6136 23.2431 92.4104 23.4657 93.3244 23.4657Z"
        fill="black"
      />
      <path
        d="M106.142 23.0439H111.697V5.11503H106.142V1.07225H116.899V23.0439H122.454V27.0866H106.142V23.0439Z"
        fill="black"
      />
      <path
        d="M141.951 27.0866C140.873 27.0866 140.006 26.8171 139.349 26.2781C138.693 25.7156 138.307 24.9188 138.189 23.8876H138.014C137.686 25.0828 137.029 25.9851 136.045 26.5945C135.084 27.2038 133.901 27.5085 132.494 27.5085C130.737 27.5085 129.319 27.0398 128.241 26.1023C127.186 25.1414 126.659 23.8055 126.659 22.0947C126.659 20.1963 127.35 18.7902 128.733 17.8761C130.116 16.9621 132.143 16.5051 134.815 16.5051H137.732V15.5911C137.732 14.513 137.475 13.7045 136.959 13.1654C136.467 12.603 135.623 12.3217 134.428 12.3217C133.326 12.3217 132.436 12.5326 131.756 12.9545C131.076 13.3764 130.491 13.9271 129.998 14.6068L127.151 12.0756C127.76 11.0444 128.709 10.2007 129.998 9.5445C131.311 8.86485 132.987 8.52502 135.025 8.52502C137.51 8.52502 139.443 9.09921 140.826 10.2476C142.232 11.3725 142.935 13.0834 142.935 15.3802V23.3251H144.939V27.0866H141.951ZM134.463 24.0985C135.377 24.0985 136.15 23.8759 136.783 23.4306C137.416 22.9853 137.732 22.3408 137.732 21.4971V19.3175H134.955C132.822 19.3175 131.756 20.0323 131.756 21.4619V22.165C131.756 22.7978 132.002 23.2782 132.494 23.6063C132.986 23.9344 133.643 24.0985 134.463 24.0985Z"
        fill="black"
      />
      <path
        d="M148.195 27.0866V8.94687H153.398V12.2514H153.609C153.819 11.7358 154.077 11.2554 154.382 10.8101C154.687 10.3648 155.05 9.97808 155.472 9.64997C155.917 9.29842 156.421 9.0289 156.983 8.84141C157.569 8.63048 158.225 8.52502 158.952 8.52502C159.819 8.52502 160.616 8.67736 161.343 8.98203C162.069 9.26327 162.69 9.69684 163.206 10.2827C163.721 10.8687 164.12 11.5835 164.401 12.4272C164.706 13.2709 164.858 14.2435 164.858 15.345V27.0866H159.655V16.0481C159.655 13.7045 158.636 12.5326 156.597 12.5326C156.198 12.5326 155.8 12.5912 155.401 12.7084C155.026 12.8022 154.687 12.9662 154.382 13.2006C154.077 13.4115 153.831 13.681 153.644 14.0091C153.48 14.3372 153.398 14.7239 153.398 15.1692V27.0866H148.195Z"
        fill="black"
      />
      <path
        d="M169.274 27.0866V8.94687H174.477V12.2514H174.688C174.898 11.7358 175.156 11.2554 175.461 10.8101C175.766 10.3648 176.129 9.97808 176.551 9.64997C176.996 9.29842 177.5 9.0289 178.062 8.84141C178.648 8.63048 179.304 8.52502 180.031 8.52502C180.898 8.52502 181.695 8.67736 182.422 8.98203C183.148 9.26327 183.769 9.69684 184.285 10.2827C184.8 10.8687 185.199 11.5835 185.48 12.4272C185.785 13.2709 185.937 14.2435 185.937 15.345V27.0866H180.734V16.0481C180.734 13.7045 179.715 12.5326 177.676 12.5326C177.277 12.5326 176.879 12.5912 176.48 12.7084C176.105 12.8022 175.766 12.9662 175.461 13.2006C175.156 13.4115 174.91 13.681 174.723 14.0091C174.559 14.3372 174.477 14.7239 174.477 15.1692V27.0866H169.274Z"
        fill="black"
      />
      <path
        d="M199.106 27.5085C196.059 27.5085 193.751 26.6648 192.181 24.9774C190.611 23.2899 189.825 20.9932 189.825 18.0871C189.825 16.6106 190.025 15.2864 190.423 14.1146C190.845 12.9193 191.431 11.9116 192.181 11.0913C192.954 10.2476 193.88 9.61481 194.958 9.19296C196.059 8.74767 197.29 8.52502 198.649 8.52502C200.009 8.52502 201.227 8.74767 202.305 9.19296C203.383 9.61481 204.297 10.2242 205.047 11.021C205.797 11.8178 206.372 12.7904 206.77 13.9388C207.192 15.0638 207.403 16.3293 207.403 17.7355V19.2823H194.993V19.5987C194.993 20.7705 195.356 21.7197 196.083 22.4462C196.809 23.1493 197.876 23.5009 199.282 23.5009C200.36 23.5009 201.286 23.3017 202.059 22.9032C202.833 22.4814 203.501 21.9306 204.063 21.251L206.875 24.3094C206.172 25.1766 205.188 25.9265 203.922 26.5593C202.657 27.1921 201.051 27.5085 199.106 27.5085ZM198.684 12.2514C197.559 12.2514 196.657 12.603 195.977 13.306C195.321 13.9857 194.993 14.9114 194.993 16.0833V16.3645H202.305V16.0833C202.305 14.888 201.977 13.9505 201.321 13.2709C200.688 12.5912 199.809 12.2514 198.684 12.2514Z"
        fill="black"
      />
      <path
        d="M211.748 23.0439H215.932V12.9897H211.748V8.94687H221.134V14.0794H221.38C221.545 13.4232 221.767 12.7904 222.048 12.1811C222.353 11.5483 222.728 10.9976 223.173 10.5288C223.642 10.0367 224.205 9.64997 224.861 9.36873C225.517 9.08749 226.29 8.94687 227.181 8.94687H228.904V13.7279H225.037C223.748 13.7279 222.775 14.1146 222.119 14.888C221.463 15.638 221.134 16.5637 221.134 17.6652V23.0439H227.111V27.0866H211.748V23.0439Z"
        fill="black"
      />
      <path
        d="M240.772 27.5085C239.46 27.5085 238.51 27.2273 237.925 26.6648C237.362 26.0789 237.081 25.3875 237.081 24.5907V23.3603C237.081 22.5634 237.362 21.8838 237.925 21.3213C238.51 20.7354 239.46 20.4424 240.772 20.4424C242.085 20.4424 243.022 20.7354 243.584 21.3213C244.17 21.8838 244.463 22.5634 244.463 23.3603V24.5907C244.463 25.3875 244.17 26.0789 243.584 26.6648C243.022 27.2273 242.085 27.5085 240.772 27.5085Z"
        fill="url(#paint0_linear_491_4015)"
      />
      <path
        d="M268.425 27.0866C267.347 27.0866 266.48 26.8171 265.824 26.2781C265.167 25.7156 264.781 24.9188 264.663 23.8876H264.488C264.16 25.0828 263.503 25.9851 262.519 26.5945C261.558 27.2038 260.375 27.5085 258.968 27.5085C257.211 27.5085 255.793 27.0398 254.715 26.1023C253.66 25.1414 253.133 23.8055 253.133 22.0947C253.133 20.1963 253.824 18.7902 255.207 17.8761C256.59 16.9621 258.617 16.5051 261.289 16.5051H264.206V15.5911C264.206 14.513 263.949 13.7045 263.433 13.1654C262.941 12.603 262.097 12.3217 260.902 12.3217C259.8 12.3217 258.91 12.5326 258.23 12.9545C257.551 13.3764 256.965 13.9271 256.472 14.6068L253.625 12.0756C254.234 11.0444 255.183 10.2007 256.472 9.5445C257.785 8.86485 259.461 8.52502 261.5 8.52502C263.984 8.52502 265.917 9.09921 267.3 10.2476C268.706 11.3725 269.409 13.0834 269.409 15.3802V23.3251H271.413V27.0866H268.425ZM260.937 24.0985C261.851 24.0985 262.625 23.8759 263.257 23.4306C263.89 22.9853 264.206 22.3408 264.206 21.4971V19.3175H261.429C259.297 19.3175 258.23 20.0323 258.23 21.4619V22.165C258.23 22.7978 258.476 23.2782 258.968 23.6063C259.461 23.9344 260.117 24.0985 260.937 24.0985Z"
        fill="black"
      />
      <path
        d="M284.055 6.62667C282.883 6.62667 282.063 6.39231 281.594 5.92358C281.149 5.43142 280.926 4.84551 280.926 4.16585V3.14637C280.926 2.44328 281.149 1.85737 281.594 1.38864C282.063 0.919911 282.883 0.685547 284.055 0.685547C285.227 0.685547 286.035 0.919911 286.481 1.38864C286.949 1.85737 287.184 2.44328 287.184 3.14637V4.16585C287.184 4.84551 286.949 5.43142 286.481 5.92358C286.035 6.39231 285.227 6.62667 284.055 6.62667ZM275.688 23.0439H281.454V12.9897H275.688V8.94687H286.657V23.0439H292V27.0866H275.688V23.0439Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_491_4015"
          x1="228.385"
          y1="17.4777"
          x2="292.015"
          y2="17.4777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC0AD6" />
          <stop offset="0.432292" stopColor="#660AD6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
