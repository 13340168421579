import { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './Panel.module.scss';
import { PanelHeader } from './PanelHeader';
import { PanelBody } from './PanelBody';
import { PanelMenu } from './PanelMenu';
import { IMenuLink } from '../Menu';


interface IPanelProps {
  header?: ReactNode;
  submenu?: IMenuLink[];
  body?: ReactNode;
  width?: number;
  className?: string;
  bodyClassName?: string;
}

export const Panel = ({ header, submenu, body, className, bodyClassName, width }: IPanelProps) => {
  return (
    <div
      className={classnames(styles.Panel, className)}
      style={{ width }}
    >
      {header && <PanelHeader>{header}</PanelHeader>}
      {submenu && <PanelMenu items={submenu} />}
      <PanelBody className={bodyClassName} hasHeader={!!header}>{body}</PanelBody>
    </div>
  );
};
