import styles from './PanelMenu.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { IMenuLink } from '../Menu';

interface IPanelHeaderProps {
  items?: IMenuLink[];
}

export const PanelMenu = ({ items }: IPanelHeaderProps) => {
  return (
    <div className={styles.Wrapper}>
      {
        items?.map(
          item =>
            <Link
              key={item.id}
              className={classnames(styles.Item, { [styles.ItemActive]: item.isActive })}
              to={item.to || ''}
            >
              {item?.icon}
              {item?.label}
            </Link>
        )
      }
    </div>
  );
};
