import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routing/constants';
import { useAppSelector } from 'hooks';
import { getUserRole } from 'store/authentication';
import { RoleEnum } from 'const/misc';

// INFO: for new role add default navigation route
export const AppGateway = () => {
  const role = useAppSelector(getUserRole);
  
  if (role === RoleEnum.Admin) {
    return <Navigate to={ROUTES.ADMIN.DEFAULT} />;
  }

  if (role === RoleEnum.Paraplanner) {
    return <Navigate to={ROUTES.PARAPLANNER_MAILBOX_INBOX} />;
  }

  if (role === RoleEnum.Advisor) {
    return <Navigate to={ROUTES.APP.DEFAULT} />;
  }

  return <Navigate to={ROUTES.LOGIN} />;
};
