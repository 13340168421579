import { useAppDispatch, useFetchDataAtInterval, useTranslation } from 'hooks';
import { getTranscriptVideoUrl, resetVideoUrl, selectIsGetTranscriptVideoUrlProcessing, selectOngoingCallBotId, selectVideoUrl } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player'
import { Card, CardHeader } from 'components/ui/card';
import { Info } from 'lucide-react';
import { CardDescription } from 'components/ui/card';
import { Loading } from 'components/layout-atoms/Loading';
import { IClientDataRecord } from 'store/clientManagement';

interface IProps {
  botId: string;
  dataRecord: IClientDataRecord;
}

export const MeetingVideo = ({ botId, dataRecord }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const videoUrl = useSelector(selectVideoUrl);
  const isGetTranscriptVideoUrlProcessing = useSelector(selectIsGetTranscriptVideoUrlProcessing);
  const ongoingCallBotId = useSelector(selectOngoingCallBotId);
  const maxNoTries = 4

  const [triesToGetVideo, setTriesToGetVideo] = useState(0);

  useFetchDataAtInterval({ botId, type: 'videoRecording', killRecurrence: !!videoUrl || triesToGetVideo >= maxNoTries });

  useEffect(() => {
    return () => {
      dispatch(resetVideoUrl())
    }
  }, [])


  useEffect(() => {
    if (botId) {
      dispatch(getTranscriptVideoUrl({ botId, dataRecord }))
    }
  }, [dispatch, botId])

  useEffect(() => {
    if (isGetTranscriptVideoUrlProcessing) {
      setTriesToGetVideo(triesToGetVideo => triesToGetVideo + 1);
    }
  }, [isGetTranscriptVideoUrlProcessing])


  if (!!ongoingCallBotId && ongoingCallBotId === botId) {
    return (
      <Card className='shadow-none border'>
        <CardHeader>
          <CardDescription className='flex items-center gap-3'>
            <Info className='min-w-4 size-4' />
            <span>
              {t('general.videoAvailableAfterMeeting')}
            </span>
          </CardDescription>
        </CardHeader>
      </Card>
    )
  }

  return (
    <Fragment>
      {!!videoUrl ? (
        <div className='h-full flex items-start'>
          <ReactPlayer
            url={videoUrl}
            controls={true}
            width="100%"
            height="auto"
          />
        </div>
      ) : (
        triesToGetVideo < maxNoTries ?
          <Loading variant="centered" className='aspect-video' /> :
          <Card className='shadow-none border'>
            <CardHeader>
              <CardDescription className='flex items-center gap-3'>
                <Info className='min-w-4 size-4' />
                <span>
                  {t('general.videoNotAvailable')}
                </span>
              </CardDescription>
            </CardHeader>
          </Card>
      )}
    </Fragment>
  );
};
