import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IMailboxApi } from "./types";

export const mailbox: IMailboxApi = {
  getMessage: (payload) =>
    http
      .get(API_ROUTES.mailbox.getMessage(payload.id))
      .then((response) => response.data),
  getDraft: (payload) =>
    http
      .get(API_ROUTES.mailbox.getDraft(payload.id))
      .then((response) => response.data),
  getThreadsList: (payload) =>
    http
      .get(API_ROUTES.mailbox.getThreadsList, { params: payload })
      .then((response) => response.data),
  searchThreadsList: (payload) =>
    http
      .get(API_ROUTES.mailbox.searchThreadsList, { params: payload })
      .then((response) => response.data),
  getDraftsList: (payload) =>
    http
      .get(API_ROUTES.mailbox.getDraftsList, { params: payload })
      .then((response) => response.data),
  getArchivedThreadsList: (payload) =>
    http
      .get(API_ROUTES.mailbox.getArchivedThreadsList, { params: payload })
      .then((response) => response.data),
  getThreadsWithConversationList: (payload) =>
    http
      .get(API_ROUTES.mailbox.getThreadsWithConversationList, {
        params: payload,
      })
      .then((response) => response.data),
  getEmailAttachment: (payload) =>
    http
      .get(API_ROUTES.mailbox.getEmailAttachment(payload.file.id), {
        responseType: "blob",
      })
      .then((response) => response.data),
  uploadFiles: (payload) =>
    http
      .post(API_ROUTES.mailbox.uploadFiles, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data),
  getThreadDetails: (payload) =>
    http
      .get(API_ROUTES.mailbox.getThreadDetails(payload.id))
      .then((response) => response.data),
  deleteFile: (payload) =>
    http
      .delete(API_ROUTES.mailbox.deleteFile(payload.fileId))
      .then((response) => response.data),
  createDraft: (payload) =>
    http
      .post(API_ROUTES.mailbox.createDraft, payload.draft)
      .then((response) => response.data),
  updateDraft: (payload) =>
    http
      .put(
        API_ROUTES.mailbox.updateDraft(payload.draft.id || ""),
        payload.draft
      )
      .then((response) => response.data),
  deleteDraft: (payload) =>
    http
      .delete(API_ROUTES.mailbox.deleteDraft(payload.id))
      .then((response) => response.data),
  updateThreadLabel: (payload) =>
    http
      .put(
        API_ROUTES.mailbox.updateThreadLabel(payload.threadId || ""),
        payload
      )
      .then((response) => response.data),
  deleteThreadLabel: (payload) =>
    http
      .delete(API_ROUTES.mailbox.deleteThreadLabel(payload.threadId || ""), {
        data: payload,
      })
      .then((response) => response.data),
};
