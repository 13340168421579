import { useEffect, useState } from 'react';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useForm } from 'react-hook-form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from 'react-toastify';
import { Form, FormControl, FormField, FormItem, FormLabel } from 'components/ui/form';
import { Button } from 'components/ui/button';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { Switch } from 'components/ui/switch';
import { MIXPANEL } from 'const';

interface IComplianceSettingsPayload {
  keepAll: boolean;
  keepAudio: boolean;
  keepTranscript: boolean;
  eraseAll: boolean;
}

// TODO: BE integration
export const ComplianceSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const [isProcessing, setIsProcessing] = useState(false);

  const formSchema = z.object({
    keepAll: z.boolean(),
    keepAudio: z.boolean(),
    keepTranscript: z.boolean(),
    eraseAll: z.boolean(),
  });

  const form = useForm<IComplianceSettingsPayload>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      keepAll: true, keepAudio: false, keepTranscript: false, eraseAll: false
    }
  });

  useEffect(() => {
    form.setFocus('keepAll');
  }, [form]);

  const onSubmit = (values: IComplianceSettingsPayload) => {
    trackEvent({ action: MIXPANEL.ACTION.SETTINGS.COMPLIANCE.SAVE })
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
      toast(t('component.ComplianceSettings.saveSuccess'))
      form.reset(values);
    }, 1000);

    // mixpanel.track(EVENT);
    // dispatch(saveCredentials(values))
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('component.ComplianceSettings.title')}</CardTitle>
        <CardDescription>
          {t('component.ComplianceSettings.description')}
        </CardDescription>
      </CardHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="flex flex-col gap-6">
            <FormField
              control={form.control}
              name="keepAll"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div className="space-y-1.5">
                    <FormLabel>
                      {t('component.ComplianceSettings.keepAll.title')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ComplianceSettings.keepAll.description')}
                    </FormLabel>
                  </div>

                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="keepAudio"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div className="space-y-1.5">
                    <FormLabel>
                      {t('component.ComplianceSettings.keepAudio.title')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ComplianceSettings.keepAudio.description')}
                    </FormLabel>
                  </div>

                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="keepTranscript"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div className="space-y-1.5">
                    <FormLabel>
                      {t('component.ComplianceSettings.keepTranscript.title')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ComplianceSettings.keepTranscript.description')}
                    </FormLabel>
                  </div>

                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="eraseAll"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div className="space-y-1.5">
                    <FormLabel>
                      {t('component.ComplianceSettings.eraseAll.title')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ComplianceSettings.eraseAll.description')}
                    </FormLabel>
                  </div>

                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

          </CardContent>

          <CardFooter>
            <Button
              loading={isProcessing}
              disabled={!form.formState.isDirty}
              type="submit"
            >
              {t('component.ComplianceSettings.saveBtn')}
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card >
  );
};
