import { useTranslation } from 'hooks';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from 'components/ui/dialog';

interface IProps {
  modalOpen: boolean;
  meetingBotId: string;
}

export const MeetingEndedDialog = ({ modalOpen, meetingBotId }: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={modalOpen}>
      <DialogContent className="sm:max-w-md" closeContainerClassName="hidden">
        <DialogHeader>
          <DialogTitle className='text-center'>
            {t('component.MeetingEndedDialog.title')}
          </DialogTitle>
        </DialogHeader>
        <DialogFooter className="sm:justify-start">
          <Button type="button" className='w-full'
            onClick={() => {
              router.navigate({
                pathname: ROUTES.APP.COPILOT.SUMMARY,
                search: `?meetingId=${meetingBotId}`
              })
            }}
          >
            {t('component.MeetingEndedDialog.btn')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )

}

