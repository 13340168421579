import { useAppDispatch, useTranslation } from 'hooks';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { useSelector } from 'react-redux';
import { ICalendarEvent, getCalendarEvents, selectActiveCalendarEvent, selectCalendarEvents, selectIsGettingCalendarEvents, setActiveCalendarEvent } from 'store/calendar';
import { useEffect, useState } from 'react';
import { CalendarEventItem } from './CalendarEventItem';
import { Loading } from 'components/layout-atoms/Loading';
import { ConfigureConnectCalendar } from './ConfigureConnectCalendar';
import { Alert, AlertDescription } from 'components/ui/alert';
import { Info, UnplugIcon } from 'lucide-react';
import { selectProfileDetails, selectProfileDetailsIsLoading } from 'store/profile';
import { Button } from 'components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';

export const CalendarEventsToday = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const adHocItem: ICalendarEvent = { id: 'ad-hoc', summary: 'Ad Hoc meeting', startTime: '', endTime: '' }

  const isGettingCalendarEvents = useSelector(selectIsGettingCalendarEvents);
  const activeCalendarEvent = useSelector(selectActiveCalendarEvent);
  const calendarEvents = useSelector(selectCalendarEvents);
  const profileDetails = useSelector(selectProfileDetails);
  const isLoadingProfileDetails = useSelector(selectProfileDetailsIsLoading);

  const [hasConnectedCalendar, setHasConnectedCalendar] = useState(false)

  useEffect(() => {
    const isCalendarConnected = !!profileDetails?.googleCalendar || !!profileDetails?.microsoftCalendar
    setHasConnectedCalendar(isCalendarConnected)
  }, [profileDetails])

  useEffect(() => {
    if (hasConnectedCalendar) {
      dispatch(getCalendarEvents())
    }
  }, [dispatch, hasConnectedCalendar])


  return (
    <>
      {(isGettingCalendarEvents || isLoadingProfileDetails) ? (
        <Card className='flex flex-col h-full overflow-auto'>
          <Loading variant="centered" iconClassName="size-6" />
        </Card>
      ) : (
        <>
          {
            !hasConnectedCalendar ? (
              <ConfigureConnectCalendar />
            ) : (
              <Card className='flex flex-col h-full overflow-auto'>
                <CardHeader>
                  <CardTitle className='flex justify-between items-center'>
                    {t('component.CalendarEventsToday.title')}
                    <Button
                      type='button'
                      variant="link"
                      className='p-0'
                      onClick={() => router.navigate(ROUTES.APP.SETTINGS.CONNECTIONS)}
                    >
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <UnplugIcon className='size-4 text-muted-foreground' />
                        </TooltipTrigger>
                        <TooltipContent>
                          {t('component.CalendarEventsToday.disconnectCalendar')}
                        </TooltipContent>
                      </Tooltip>
                    </Button>
                  </CardTitle>

                  {calendarEvents && calendarEvents?.length > 0 && (
                    <CardDescription>
                      {t('component.CalendarEventsToday.description')}
                    </CardDescription>
                  )}
                </CardHeader>

                <CardContent className='flex flex-col gap-3'>
                  <>
                    {
                      calendarEvents && calendarEvents?.length > 0 ? (
                        <>
                          {calendarEvents?.map(item => {
                            return (
                              <CalendarEventItem
                                key={item.meetingUrl}
                                item={item}
                                selectedItem={activeCalendarEvent}
                                handleClick={item => dispatch(setActiveCalendarEvent(item))}
                              />
                            )
                          })}

                          <div className='text-center font-semibold'>
                            OR
                          </div>

                          <CalendarEventItem
                            item={adHocItem}
                            selectedItem={activeCalendarEvent}
                            handleClick={item => dispatch(setActiveCalendarEvent(item))}
                          />
                        </>
                      ) : (
                        <Alert>
                          <Info className="h-4 w-4" />
                          <AlertDescription>
                            {t('component.CalendarEventsToday.noMeetings')}
                          </AlertDescription>
                        </Alert>
                      )}
                  </>
                </CardContent>
              </Card>
            )
          }
        </>
      )}
    </>
  );
};
