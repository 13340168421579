import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IDataExtractorApi } from "./types";

export const dataExtractor: IDataExtractorApi = {
  createDataExtractorRequest: (payload) =>
    http
      .post(API_ROUTES.dataExtractor.createDataExtractorRequest, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      })
      .then((response) => response.data),
};
