import styles from './Tile.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { BUTTON_LOOK, Button } from 'components_old';
import { useTranslation } from 'react-i18next';
import { router } from "routing/router";

interface IProps {
  title: ReactNode;
  subtitle?: ReactNode;
  priceA?: ReactNode;
  priceB?: ReactNode;
  className?: string;
  redirectUrl?: string
}
export const Tile = ({
  title,
  subtitle,
  priceA,
  priceB,
  className,
  redirectUrl
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.Tile, className)}>
      <div className={styles.Upper}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Subtitle}>{subtitle}</div>
      </div>

      <div className={styles.Lower}>
        <div className={styles.Price}>
          <div className={styles.Price}>{priceA}</div>
          {priceB && <div className={styles.Price}>{priceB}</div>}
        </div>

        {redirectUrl && (
          <Button
            look={BUTTON_LOOK.OUTLINE}
            className={styles.Button}
            onClick={() => {
              router.navigate(redirectUrl)
            }}
          >
            {t('common.tryNow')}
          </Button>
        )}
      </div>
    </div>
  );
};
