import { AxiosProgressEvent } from "axios";

export enum UploadStatusEnum {
  IDLE = "idle",
  UPLOADING = "uploading",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface IRecordingManagementState {
  uploadStatus: UploadStatusEnum;
  uploadProgressEvent: AxiosProgressEvent | null;
}
