export interface IAnalysisState {
  processing: boolean;
  uploadingFiles: boolean;
  wizardHistory: WizardRecord[];
  totalSteps: number;
  requestPayload: ICreateRequestPayload;
  requestSuccessful: boolean;
}

export enum WIZARD_OPERATION {
  EXTRACTION = "extraction",
  ENTRY = "entry",
  REPORT = "report",
}

export enum WIZARD_TOOL {
  MORNING_STAR = "morningstar",
  KWANTI = "kwanti",
  NITROGEN = "nitrogen",
  OTHER = "other",
}

export enum WIZARD_HOW_TO_SHARE_FILE {
  upload = "upload",
  link = "link",
}

export interface WizardRecord {
  action: string;
  payload?: Partial<ICreateRequestPayload>;
}

export interface IUploadFilesResponse {
  link: string;
}

export interface ICreateRequestPayload {
  type?: WIZARD_OPERATION;
  tool?: WIZARD_TOOL | string;
  google_drive_link?: string;
  access_type?: WIZARD_HOW_TO_SHARE_FILE;
  username?: string;
  password?: string;
}
