import { GeneralLayout } from 'components/layout/GeneralLayout';
import { Outlet } from 'react-router-dom';
import { SideMenu } from 'components/layout-atoms/SideMenu';
import { IMenuItem } from 'components/layout-atoms/MenuItem';

interface IProps {
  menuItems: IMenuItem[],
  sideMenuClassName?: string;
}

export const SideMenuSubLayout = ({ menuItems, sideMenuClassName }: IProps) => {
  return (
    <GeneralLayout isOneScreenOnly={true}>
      <div className="mx-auto grid w-full h-full max-w-6xl items-start gap-6 grid-cols-[150px_1fr] lg:grid-cols-[200px_1fr]">

        <nav className="flex flex-col h-full overflow-auto text-sm text-muted-foreground gap-4 pr-2">
          <SideMenu
            menuItems={menuItems}
            className={sideMenuClassName}
          />
        </nav>

        <div className='flex flex-col h-full overflow-auto gap-6 pr-2'>
          <Outlet />
        </div>
      </div>
    </GeneralLayout>
  );
};

