import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
const getOrganizationsState = (state: RootState) => state.organizations;


export const selectOrganizationsList = createSelector(
  [getOrganizationsState],
  ({ organizationsList }) => organizationsList
);

export const selectIsGettingOrganizationsList = createSelector(
  [getOrganizationsState],
  ({ isGettingOrganizationsList }) => isGettingOrganizationsList
);

export const selectIsOrganizationActionProcessing = createSelector(
  [getOrganizationsState],
  ({ isOrganizationActionProcessing }) => isOrganizationActionProcessing
);

export const selectActiveOrganization = createSelector(
  [getOrganizationsState],
  ({activeOrganization }) => activeOrganization
);
