import { Fragment } from 'react';
import { useAppDispatch, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { getAllTemplates, IAdminMeetingTemplate, selectIsCreatingTemplate, selectIsUpdatingTemplate, setActiveAdminTemplate, templateFormSchema, updateTemplatePrompt } from 'store/templates-management';
import { Textarea } from 'components/ui/textarea';
import { toast } from 'react-toastify';

interface IProps {
  data: IAdminMeetingTemplate;
  onCreateCompleted?: (_: void) => void
}

export const EditTemplatePrompts = ({ data, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isUpdatingTemplate = useSelector(selectIsUpdatingTemplate);

  const defaultFormValues: IAdminMeetingTemplate = {
    ...data,
    sections: data.sections.map((section) => ({
      id: section.id,
      name: section.name,
      questions: section.questions.map((question) => ({
        ...question,
        prompt: question.prompt || ''
      })),
    })),
  }

  const form = useForm<IAdminMeetingTemplate>({
    resolver: zodResolver(templateFormSchema),
    mode: 'onSubmit',
    defaultValues: defaultFormValues,
  });

  const handleOnEditTemplatePrompt = async () => {
    const formValues = form.getValues();
    const response = await dispatch(updateTemplatePrompt(formValues))

    if (updateTemplatePrompt.fulfilled.match(response)) {
      toast(t('component.EditTemplatePrompts.updateSuccess'));
      onCreateCompleted && onCreateCompleted();
      dispatch(getAllTemplates());
      dispatch(setActiveAdminTemplate())
    }
  }

  return (
    <Fragment>
      <Dialog
        open={!!data}
        onOpenChange={open => {
          if (!open) { dispatch(setActiveAdminTemplate()) }
        }}
      >
        <DialogContent className="sm:max-w-[625px] overflow-auto max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>
              {t('component.EditTemplatePrompts.title')}: {data?.name}
            </DialogTitle>

            <DialogDescription>
              {t('component.EditTemplatePrompts.description')}
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnEditTemplatePrompt)} className="flex flex-col gap-4">
              {data?.sections?.map((section, sectionIndex) => (
                <div key={section.id}>
                  <h3 className='font-semibold'>{section.name}</h3>
                  <div className="flex flex-col gap-4">
                    {section.questions.map((question, questionIndex) => (
                      <FormField
                        key={question.id}
                        control={form.control}
                        name={`sections.${sectionIndex}.questions.${questionIndex}.prompt`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel variant="description">
                              {question.text}
                            </FormLabel>

                            <FormControl>
                              <Textarea rows={2} {...field} data-1p-ignore disabled={isUpdatingTemplate} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                </div>
              ))}

              <DialogFooter>
                <Button
                  type="submit"
                  loading={isUpdatingTemplate}
                >
                  {t('component.EditTemplatePrompts.btn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>

      </Dialog>
    </Fragment>
  );
};
