import ReactQuill, { Quill } from 'react-quill';

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "32px",
];
Quill.register(Size, true);


export const Parchment = Quill.import("parchment")

const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const TAB_MULTIPLIER = 40

export class IndentAttributor extends (Parchment.Attributor.Style as {
  new(formatName: any, styleProperty: any, attributorOptions: any): any;
}) {
  constructor(formatName: any, styleProperty: any, attributorOptions: any) {
    super(formatName, styleProperty, attributorOptions)
  }

  public add(node: HTMLElement, value: string): boolean {
    return super.add(node, `${+value * TAB_MULTIPLIER}px`)
  }

  public value(node: HTMLElement): number | undefined {
    return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined // Don't return NaN
  }
}

export const IndentStyle = new IndentAttributor("indent", "margin-left", {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map(value => `${value * TAB_MULTIPLIER}px`),
})

Quill.register({ "formats/indent": IndentStyle }, true)

var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
// var SizeClass = Quill.import('attributors/class/size');
// Quill.register(SizeClass, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);


interface IProps {
  value: string;
  onChange(value: string): void
}

export const ComposeEditor = ({ value, onChange }: IProps) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
          [{ 'font': [] }],
          [{
            size: [
              "13px",
              "10px",
              "18px",
              "32px",
            ],
          }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, 'blockquote', { 'indent': '-1' }, { 'indent': '+1' }],
          ['link'],
          ['clean'],
        ]
      }}
    />);

}

