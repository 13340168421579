import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './langs/en.json';
import { LangEnum, LangResources } from './types';

export const resources: LangResources = {
  [LangEnum.EN]: {
    translation: enTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: LangEnum.EN,
});

export { i18n };
