import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useMixpanel, useTranslation } from 'hooks';
import { Button } from 'components/ui/button';
import { MIXPANEL } from 'const';
import { Label } from 'components/ui/label';
import { getProfileDetails, selectProfileHasConnectedRedtail } from 'store/profile';
import { toast } from 'react-toastify';
import { ConnectRedtailModal } from 'components/common/app/_general/ConnectRedtailModal';
import { disconnectRedtail, selectIsConnectingToRedtail } from 'store/redtail';
import { getClientsList } from 'store/clientManagement';

interface IProps {
  title?: string;
  descriptionConnect?: string;
  descriptionDisconnect?: string;
}

export const ConnectRedtail = ({ title, descriptionConnect, descriptionDisconnect }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isProcessing = useAppSelector(selectIsConnectingToRedtail);
  const hasConnectedRedtail = useAppSelector(selectProfileHasConnectedRedtail);

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      setIsModalVisible(false)
    }
  }, [])

  const handleConnectRedtail = () => {
    setIsModalVisible(true)
  }

  const onConnectCompleted = () => {
    trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.CONNECT_REDTAIL });
    toast(t('component.ConnectRedtailModal.connectSuccess'), { type: 'success' })
    dispatch(getProfileDetails())
    dispatch(getClientsList())
  }

  const handleDisconnectRedtail = async () => {
    const response = await dispatch(disconnectRedtail())

    if (!response?.hasOwnProperty('error')) {
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.DISCONNECT_REDTAIL });
      toast(t('component.ConnectRedtailModal.disconnectSuccess'), { type: 'success' })
      dispatch(getProfileDetails())
    }
  }

  return (
    <div className="flex flex-row items-center justify-between gap-6">
      <div className="space-y-1.5"> 
        <Label>
          {title || t('component.ConnectRedtail.title')}
        </Label>
        <Label variant="description">
          {!hasConnectedRedtail ? (
            descriptionConnect || t('component.ConnectRedtail.descriptionConnect')
          ) : (
            descriptionDisconnect || t('component.ConnectRedtail.descriptionDisconnect')
          )}
        </Label>
      </div>

      {!hasConnectedRedtail ? (
        <Button
          type='button'
          onClick={handleConnectRedtail}
          loading={isProcessing}
        >
          {t('general.connect')}
        </Button>
      ) : (
        <Button
          type='button'
          variant="destructive"
          onClick={handleDisconnectRedtail}
          loading={isProcessing}
        >
          {t('general.disconnect')}
        </Button>
      )}

      <ConnectRedtailModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        onCreateCompleted={onConnectCompleted}
      />
    </div>
  );
};
