import { formatPreviewDate } from 'utils/date.js';
import styles from './ConversationInfo.module.scss';
import classnames from 'classnames';
import { Clip, Pencil } from 'icons';
import { messageHasAttachments } from 'utils/mailbox';
import { useAppSelector } from 'hooks';
import { IEmail, IEmailThread, selectSelectedThread } from 'store/mailbox';

interface IProps {
  thread?: IEmailThread;
  message?: IEmail;
  isMessageExpanded?: boolean;
  className?: string;
};

export const ConversationInfo = ({ thread, message, isMessageExpanded, className }: IProps) => {
  const activeThread = useAppSelector(selectSelectedThread);
  const messageHasDraft = !!(message && activeThread?.drafts.find(draft => draft.reply_to_message_id === message.id));
  const threadHasDraft = !!(thread && thread?.drafts?.length);

  const getName = () => {
    if (thread) {
      if (thread?.messages?.length > 0) {
        const fromContact = thread?.messages?.[0]?.from?.[0] || thread?.drafts?.[0]?.from?.[0];
        return fromContact?.name || fromContact?.email || '-';
      }

      if (thread?.drafts?.length > 0) {
        return '[Draft]';
      }

      return '-'
    } else if (message) {
      const fromContact = message?.from?.[0];
      return fromContact?.name || fromContact?.email || 'Unknown';
    }
  }

  const getEmail = () => {
    if (message && isMessageExpanded) {
      const fromContact = message?.from?.[0];
      return fromContact?.email;
    }
    return '';
  }

  const getDateAndTime = () => {
    if (thread) {
      const lastMessage = thread?.messages[thread?.messages?.length - 1];
      const lastDraft = thread?.drafts[thread?.drafts?.length - 1];

      if (lastMessage) {
        return formatPreviewDate(
          new Date(Math.floor(lastMessage?.date * 1000))
        )
      }

      if (lastDraft) {
        return formatPreviewDate(
          new Date(Math.floor(lastDraft?.date * 1000))
        )
      }

      return '-'
    } else if (message) {
      return formatPreviewDate(
        new Date(Math.floor(message?.date * 1000))
      )
    }
  }

  return (
    <div className={classnames(styles.Wrapper, className)}>
      <div className={styles.TopLine}>
        <div className={classnames(styles.FromAndCount, styles.Ellipsis)}>
          <div className={classnames(styles.From, styles.Ellipsis)}>
            {getName()}
            <span className={styles.Email}>{getEmail()}</span>
          </div>

          {!!thread?.messages?.length && thread?.messages?.length > 1 && (
            <span className={styles.Count}>
              ({thread.messages?.length})
            </span>
          )}
        </div>

        <div className={styles.Time}>
          {(messageHasDraft || threadHasDraft) && <Pencil hasHover={false} />}
          {( messageHasAttachments(message)) && <Clip hasHover={false} />}
          {getDateAndTime()}
        </div>
      </div>

      {thread && (
        <div className={classnames(styles.Subject)}>
          <div className={classnames(styles.Ellipsis)}>
            {thread?.subject || '(no subject)'}
          </div>
        </div>
      )}

      {(thread || !isMessageExpanded) && (
        <div className={classnames(styles.Snippet)}>
          <div className={classnames(styles.Ellipsis)}>
            {thread?.snippet || message?.snippet || thread?.drafts?.[0]?.snippet || '(no body)'}
          </div>
        </div>
      )}
    </div>
  );
}

