import styles from './AutomationCountDown.module.scss';
import { useTranslation } from 'hooks';
import { LoadingRow, StepperTitle } from 'components_old';
import { selectDataExtractorIsProcessing } from 'store/dataExtractor';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TAutomationStep, IAutomationWizardRecord } from 'components_old/features/Automations/types';

const COUNTER_START = 60;

export interface IProps {
  handleGoToNextStep(record?: IAutomationWizardRecord): void;
  nextStep: TAutomationStep,
}

export const AutomationCountDown = ({ handleGoToNextStep, nextStep }: IProps) => {
  const { t } = useTranslation();
  const isProcessing = useSelector(selectDataExtractorIsProcessing)
  const [counter, setCounter] = useState(COUNTER_START);

  useEffect(() => {
    const intervalLogic = setInterval(() => {
      if (counter >= 1) {
        setCounter(Math.max(counter - 1))
      }
    }, 1000);

    return () => {
      clearInterval(intervalLogic);
    };
  });

  useEffect(() => {
    if (!isProcessing) {
      handleGoToNextStep({ action: nextStep })
    }
  }, [isProcessing, handleGoToNextStep, nextStep])


  return (
    <>
      {!!counter && (
        <>
          <StepperTitle>
            <div>{t('dataExtractor.progressIndicator.countdown.titleA')}</div>
            <div>{t('dataExtractor.progressIndicator.countdown.titleB')}</div>
          </StepperTitle>

          <StepperTitle>
            <div className={styles.Counter}>{counter}</div>
          </StepperTitle>
        </>
      )}

      {!counter && (
        <>
          <StepperTitle>
            <div>{t('dataExtractor.progressIndicator.extraTime.titleA')}</div>
            <div>{t('dataExtractor.progressIndicator.extraTime.titleB')}</div>
          </StepperTitle>

          <StepperTitle>
            <LoadingRow />
          </StepperTitle>
        </>
      )}
    </>
  );
}

