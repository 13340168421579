import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useForm } from 'react-hook-form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { ICreateUploadSecurelyFilesRequest, createUploadSecurelyFilesRequest, resetIsSecureUploadSuccessful, selectIsSecureUploadRequestProcessing, selectIsSecureUploadRequestSuccessful } from 'store/chat';
import { WIZARD_HOW_TO_SHARE_FILE } from 'store/analysis';
import { Textarea } from 'components/ui/textarea';
import { MIXPANEL } from 'const';

export const ShareFilesViaLink = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const isSecureUploadRequestSuccessful = useSelector(selectIsSecureUploadRequestSuccessful);
  const isSecureUploadRequestProcessing = useSelector(selectIsSecureUploadRequestProcessing);


  const formSchema = z.object({
    google_drive_link: z.string().min(1, t('errors.required') || ''),
    access_type: z.string(),
    note: z.string(),
  });

  const form = useForm<ICreateUploadSecurelyFilesRequest>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      access_type: WIZARD_HOW_TO_SHARE_FILE.link, google_drive_link: '', note: ''
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetIsSecureUploadSuccessful())
    }
  }, []);

  useEffect(() => {
    form.setFocus('google_drive_link');
  }, [form]);

  useEffect(() => {
    if (isSecureUploadRequestSuccessful) {
      form.reset();
    }
  }, [isSecureUploadRequestSuccessful, form]);

  const onSubmit = (values: ICreateUploadSecurelyFilesRequest) => {
    dispatch(createUploadSecurelyFilesRequest(values));
    trackEvent({ action: MIXPANEL.ACTION.PARAPLANNER_AI.SHARE_LINK })
  };

  return (
    <Card>
      {!isSecureUploadRequestSuccessful ? (
        <>
          <CardHeader>
            <CardTitle>{t('component.ShareFilesViaLink.title')}</CardTitle>
            <CardDescription>{t('component.ShareFilesViaLink.description')}
            </CardDescription>
          </CardHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <CardContent className="flex flex-col gap-6">
                <FormField
                  control={form.control}
                  name="google_drive_link"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareFilesViaLink.link')}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t('component.ShareFilesViaLink.linkDescription')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareFilesViaLink.note')}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t('component.ShareFilesViaLink.noteDescription')}
                      </FormLabel>

                      <FormControl>
                        <Textarea rows={5} {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareFilesViaLink.password')}
                      </FormLabel>
                      <FormControl>
                        <Input type="password" {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                /> */}
              </CardContent>

              <CardFooter>
                <Button
                  loading={isSecureUploadRequestProcessing}
                  type="submit"
                >
                  {t('component.ShareFilesViaLink.btn')}
                </Button>
              </CardFooter>
            </form>
          </Form>
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>{t('component.ShareFilesViaLink.successState.title')}</CardTitle>
            <CardDescription>{t('component.ShareFilesViaLink.successState.description')}
            </CardDescription>
          </CardHeader>

          <CardFooter>
            <Button
              onClick={() => {
                dispatch(resetIsSecureUploadSuccessful())
              }}
            >
              {t('component.ShareFilesViaLink.successState.newRequest')}
            </Button>
          </CardFooter>
        </>
      )}
    </Card>

  );
};
