import { useAppDispatch, useTranslation } from 'hooks';
import { FileUploader, StepperTitle } from 'components_old';
import { IFileWithMeta } from 'react-dropzone-uploader'
import { useSelector } from 'react-redux';
import { IStepperComponentProps } from '../types';
import { IUploadComparisonFilesResponse, selectIsUploadingComparisonFiles, uploadComparisonFiles } from 'store/portfolioModel';


export const FileUpload = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const uploadingFiles = useSelector(selectIsUploadingComparisonFiles)

  const doFileUpload = async (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const formData = new FormData();
    allFiles?.forEach(item => {
      formData.append('files', item.file);
    })

    try {
      const uploadResponse = await dispatch(uploadComparisonFiles(formData));

      if (!uploadResponse.hasOwnProperty('error')) {
        const link = (uploadResponse?.payload as IUploadComparisonFilesResponse)?.link;
        handleGoToNextStep(nextStep, { google_drive_link: link })
      }
    } catch (error) { }

  }

  return (
    <>
      <StepperTitle>
        <div>{t('portfolioComparison.fileUpload.titleA')}</div>
        <div>{t('portfolioComparison.fileUpload.titleB')}</div>
      </StepperTitle>
      <FileUploader onSubmit={doFileUpload} isLoading={uploadingFiles} />
    </>
  );
}

