import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  IAuthenticationState,
  ILoginSuccessPayload,
  IRefreshSuccessPayload,
  ISignUpPayload,
} from "./types";
import { api } from "services/api";
import { processApiError } from "services";
import { ACTION } from "const";
import { IProfileDetails } from "store/profile";

export const signUp = createAsyncThunk(
  ACTION.AUTH.SIGN_UP,
  async (payload: ISignUpPayload, { rejectWithValue }) => {
    try {
      const response = await api.authentication.signUp(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

const initialState: IAuthenticationState = {
  user: {
    email: "",
    role: null,
    id: "",
  },
  accessToken: "",
  refreshToken: "",
  loading: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<ILoginSuccessPayload>) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.loading = false;
    },
    refreshSuccess: (state, action: PayloadAction<IRefreshSuccessPayload>) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    loginFailure: (state) => {
      state.loading = false;
    },
    resetAuthenticationSlice: () => {
      return { ...initialState };
    },
    updateLoggedInUser: (state, action: PayloadAction<IProfileDetails>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        const { access_token, refresh_token } = action.payload;
        state.accessToken = access_token;
        state.refreshToken = refresh_token;
        state.loading = false;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
      })
   
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  resetAuthenticationSlice,
  refreshSuccess,
  updateLoggedInUser,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
