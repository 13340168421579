import styles from './StepperBox.module.scss';
import { ReactNode } from 'react';

interface IPageProps {
  title?: ReactNode | undefined;
  content?: ReactNode | undefined;
  onClick?: Function;
}

export const StepperBox = ({ title, content, onClick }: IPageProps) => {
  return (
    <div className={styles.Box} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick && onClick(e)}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Content}>{content}</div>
    </div>
  )
};
