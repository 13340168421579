import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICreateComparisonRequest, IPortfolioModelState } from "./types";
import {
  createComparisonRequest,
  createPortfolioModels,
  listPortfolioModels,
  updatePortfolioModels,
  uploadComparisonFiles,
  uploadPortfolioModels,
} from "./actions";
import { toast } from "react-toastify";
import { i18n } from "i18n";
import { ACTION } from "const";

const initialState: IPortfolioModelState = {
  isGettingModels: false,
  isCreatingModels: false,
  isUpdatingModels: false,
  isUploadingModels: false,
  isUploadingComparisonFiles: false,
  isCreatingRequest: false,
  creatingRequestSuccess: false,
  portfolioModels: [],
  comparisonRequestPayload: {},
};

const portfolioModelSlice = createSlice({
  name: "portfolioModel",
  initialState,
  reducers: {
    updateComparisonRequestPayload: (state, action: PayloadAction<ICreateComparisonRequest>) => {
      state.comparisonRequestPayload = {
        ...state.comparisonRequestPayload,
        ...action.payload,
      };
    },
    resetComparisonRequestPayload: (state) => {
      state.comparisonRequestPayload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePortfolioModels.pending, (state, action) => {
        state.isUpdatingModels = true;
      })
      .addCase(updatePortfolioModels.fulfilled, (state, action) => {
        state.isUpdatingModels = false;
        state.portfolioModels = action.payload;
        toast(i18n.t(`success.${ACTION.PORTFOLIO_MODEL.UPDATE_MODELS}`));
      })
      .addCase(updatePortfolioModels.rejected, (state) => {
        state.isUpdatingModels = false;
      })
      .addCase(createPortfolioModels.pending, (state, action) => {
        state.isCreatingModels = true;
      })
      .addCase(createPortfolioModels.fulfilled, (state, action) => {
        state.isCreatingModels = false;
        state.portfolioModels = action.payload;
        toast(i18n.t(`success.${ACTION.PORTFOLIO_MODEL.CREATE_MODELS}`));
      })
      .addCase(createPortfolioModels.rejected, (state) => {
        state.isCreatingModels = false;
      })
      .addCase(listPortfolioModels.pending, (state, action) => {
        state.isGettingModels = true;
      })
      .addCase(listPortfolioModels.fulfilled, (state, action) => {
        state.isGettingModels = false;
        state.portfolioModels = action.payload;
      })
      .addCase(listPortfolioModels.rejected, (state) => {
        state.isGettingModels = false;
      })
      .addCase(uploadPortfolioModels.pending, (state, action) => {
        state.isUploadingModels = true;
      })
      .addCase(uploadPortfolioModels.fulfilled, (state, action) => {
        state.isUploadingModels = false;
      })
      .addCase(uploadPortfolioModels.rejected, (state) => {
        state.isUploadingModels = false;
      })
      .addCase(uploadComparisonFiles.pending, (state, action) => {
        state.isUploadingComparisonFiles = true;
      })
      .addCase(uploadComparisonFiles.fulfilled, (state, action) => {
        state.isUploadingComparisonFiles = false;
      })
      .addCase(uploadComparisonFiles.rejected, (state) => {
        state.isUploadingComparisonFiles = false;
      })
      .addCase(createComparisonRequest.pending, (state, action) => {
        state.isCreatingRequest = true;
      })
      .addCase(createComparisonRequest.fulfilled, (state, action) => {
        state.isCreatingRequest=false
        state.creatingRequestSuccess = true;
        portfolioModelSlice.caseReducers.resetComparisonRequestPayload(state);
      })
      .addCase(createComparisonRequest.rejected, (state) => {
        state.isCreatingRequest = false;
        state.creatingRequestSuccess = false;
      })
  },
});

export const { updateComparisonRequestPayload } =
  portfolioModelSlice.actions;

export default portfolioModelSlice.reducer;
