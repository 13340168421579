import styles from './MatchAccountsWithPortfolios.module.scss'
import { useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, INPUT_TYPE, Input, StepperTitle } from 'components_old';
import { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { Add, Close } from 'icons';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { IComparisonItem, selectPortfolioModels } from 'store/portfolioModel';
import { IStepperComponentProps } from '../types';


interface IComparisonEntryFormData {
  id: string,
  accounts: string
  models: string[]
}

interface IComparisonFormData {
  entries: IComparisonEntryFormData[];
  extra_note: string;
}

interface IOption {
  value: string;
  label: string;
}

export const MatchAccountsWithPortfolios = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();

  const portfolioModels = useSelector(selectPortfolioModels);
  const [modelOptions, setModelOptions] = useState<IOption[]>([])

  const { control, register, watch, trigger, handleSubmit, setValue, formState: { errors, isValid } } = useForm<IComparisonFormData>({
    defaultValues: { entries: [{ id: uuidv4(), accounts: 'All accounts', models: [] }] },
    mode: 'onBlur'
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "entries",
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      trigger();
    });
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    const options: IOption[] = portfolioModels?.map(model => ({
      label: model?.name,
      value: model?.name
    }))
    setModelOptions(options)
  }, [portfolioModels]);

  const onSubmit = async (values: IComparisonFormData) => {
    const { entries } = values;
    const comparisonPayload: IComparisonItem[] = []
    entries?.forEach(entry => {
      entry?.models?.forEach(model => comparisonPayload.push({ account: entry.accounts, model }))
    })
    handleGoToNextStep(nextStep, { extra_note: values?.extra_note, comparison: comparisonPayload });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
      <StepperTitle className={styles.Title}>
        <div>{t('portfolioComparison.matchAccountsWithPortfolios.titleA')}</div>
        <div>{t('portfolioComparison.matchAccountsWithPortfolios.titleB')}</div>
        <div>{t('portfolioComparison.matchAccountsWithPortfolios.titleC')}</div>
      </StepperTitle>


      <div className={styles.LinesWrapper}>
        {fields.map((field, index) => (
          <div className={styles.FormLine}>
            <Input
              placeholder={t("placeholder.accounts") as string}
              key={field.id}
              {...register(`entries.${index}.accounts` as const, {
                required: true
              })}
              lookType={INPUT_TYPE.MINIMALIST}
              className={styles.Input}
              error={!!errors?.entries?.[index]?.accounts}
            />

            <Controller
              control={control}
              name={`entries.${index}.models`}
              rules={{
                required: true
              }}
              render={({ field: { value } }) => (
                <Select
                  className={classnames(styles.Select, {
                    [styles.SelectError]: !!errors?.entries?.[index]?.models
                  })}
                  options={modelOptions}
                  value={modelOptions.filter(option => value?.includes(option.value))}
                  isMulti
                  placeholder={t("portfolioComparison.matchAccountsWithPortfolios.selectPlaceholder")}
                  onChange={selectedOptions => {
                    setValue(`entries.${index}.models`, selectedOptions?.map(option => option.value))
                  }}
                />
              )}
            />

            <Close
              size={16}
              tooltip={t('common.removeName')}
              wrapperStyle={{ marginLeft: 2 }}
              className={classnames(styles.CloseIcon)}
              onClick={() => remove(index)}
            />
          </div>
        ))}
      </div>

      <div className={styles.ButtonContainer}>
        <Add
          size={16}
          tooltip={t('common.addName')}
          className={styles.IconAdd}
          iconClassName={styles.Icon}
          onClick={() => append({ id: uuidv4(), accounts: '', models: [] })}
        />
      </div>

      <textarea
        placeholder={t("portfolioComparison.matchAccountsWithPortfolios.notesPlaceholder") as string}
        {...register(`extra_note` as const)}
        className={classnames(styles.Textarea, {
          [styles.TextareaError]: !!errors?.extra_note
        })}
        rows={5}
      />

      <Button
        look={BUTTON_LOOK.SECONDARY}
        disabled={!isValid}
        type='submit'
      >
        {t('common.submit')}
      </Button>

    </form >
  );
}
