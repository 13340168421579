import jwtDecode from "jwt-decode";

import { api } from "services/api";
import { ILoginPayload } from "services/api/authentication/types";
import { AppDispatch, RootState, persistor } from "store";

import { router } from "routing/router";
import { ROUTES, URL_PARAM } from "routing/constants";
import { processApiError } from "services/http";
import mixpanel from "mixpanel-browser";
import {
  resetAuthenticationSlice,
  loginFailure,
  loginRequest,
  loginSuccess,
  refreshSuccess,
} from "./authenticationSlice";
import { IAccessTokenPayload, ICurrentUser, ISignOutPayload } from "./types";
import { resetChatSlice } from "store/chat";
import * as Sentry from "@sentry/react";
import { resetChatAssistant, resetRedux } from "store/chat-assistant";

export const login =
  (payload: ILoginPayload) => async (dispatch: AppDispatch) => {
    dispatch(loginRequest());
    try {
      const response = await api.authentication.login(payload);

      const jwtPayload = jwtDecode<IAccessTokenPayload>(response.access_token);
      const user: ICurrentUser = {
        email: jwtPayload.email,
        role: jwtPayload.role,
        id: jwtPayload.sub,
        muninai: payload.muninai,
      };

      dispatch(
        loginSuccess({
          user,
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        })
      );

      mixpanel.identify(user.email);
      Sentry.setUser({ email: user.email });

      const sessionSavedRedirect = sessionStorage.getItem("redirectPath");

      let redirectRoute: string;
      redirectRoute =
        sessionSavedRedirect && ![ROUTES.LOGIN].includes(sessionSavedRedirect)
          ? sessionSavedRedirect
          : ROUTES.APP.DEFAULT;

      const redirectRouteFrags = redirectRoute.split("?");
      sessionStorage.removeItem("redirectPath");

      router.navigate({
        pathname: redirectRouteFrags[0],
        search:
          redirectRouteFrags.length > 1 ? `?${redirectRouteFrags[1]}` : "",
      });
    } catch (error) {
      processApiError(error);
      dispatch(loginFailure());
    }
  };

export const refresh =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await api.authentication.refresh({
        refresh_token: getState().authentication.refreshToken,
      });
      dispatch(
        refreshSuccess({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        })
      );
    } catch (error) {
      processApiError(error);
      dispatch(loginFailure());
    }
  };

export const logout =
  ({ reason }: ISignOutPayload) =>
  async (dispatch: AppDispatch) => {
    // remove persist storage
    persistor.pause();
    await persistor.flush();
    await persistor.purge();

    dispatch(resetAuthenticationSlice());
    dispatch(resetChatSlice());
    dispatch(resetChatAssistant());
    dispatch(resetRedux());
    mixpanel.reset();

    window.location.href = `${ROUTES.LOGIN}?${URL_PARAM.REASON}=${reason}`;
  };
