import { useAppDispatch, useFetchDataAtInterval, useMixpanel, useTranslation } from 'hooks';
import { downloadAudio, getTranscript, resetProcessingIndicators, selectAudioUrl, selectClientId, selectIsDownloadingAudio, selectIsDownloadingVideo, selectIsGetTranscriptError, selectOngoingCallBotId, selectVideoUrl } from 'store/chat-assistant';
import { downloadTranscript, downloadVideo, resetChatAssistant, selectIsDownloadingTranscript } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { Button } from 'components/ui/button';
import { Fieldset } from 'components/ui/fieldset';
import { ClientDataRecordType, IClientDataRecord, deleteDataRecord, selectActiveClient, selectIsDeleteDataRecordProcessing } from 'store/clientManagement';
import { toast } from 'react-toastify';
import { MeetingTabInfo } from 'components/common/app/copilot/summary/MeetingTabInfo';
import { MeetingVideo } from 'components/common/app/copilot/summary/MeetingVideo';
import { ChevronLeft, Download, Plus } from 'lucide-react';
import { DisplayMeetingTranscript } from 'components/common/app/copilot/summary/DisplayMeetingTranscript';
import { AiConversationType, AskAIConversation } from 'components/common/app/_general/AskAIConversation';
import { Loading } from '../../../layout-atoms/Loading';
import { InfoCard } from '../../../layout-atoms/InfoCard';
import { MIXPANEL } from 'const';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { MeetingAudio } from '../copilot/summary/MeetingAudio';

export enum MeetingBreakDownContext {
  SUMMARY = 'summary',
  DATA_RECORD = "dataRecord"
}

interface IProps {
  dataRecord: IClientDataRecord | undefined,
  context: MeetingBreakDownContext
}

export const MeetingBreakDown = ({ context, dataRecord }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isGetTranscriptError = useSelector(selectIsGetTranscriptError);
  const isDownloadingTranscript = useSelector(selectIsDownloadingTranscript);
  const isDownloadingVideo = useSelector(selectIsDownloadingVideo);
  const isDownloadingAudio = useSelector(selectIsDownloadingAudio);
  const videoUrl = useSelector(selectVideoUrl);
  const audioUrl = useSelector(selectAudioUrl);

  const ongoingCallBotId = useSelector(selectOngoingCallBotId);

  const isDeleteDataRecordProcessing = useSelector(selectIsDeleteDataRecordProcessing);
  const activeClient = useSelector(selectActiveClient);

  const [isDeleteRecordVisible, setIsDeleteRecordVisible] = useState(false)

  useFetchDataAtInterval({ botId: dataRecord?.recallBotId || '', type: 'transcript' });

  // Call get transcript for the case when call completed and useFetch does not execute
  useEffect(() => {
    if (!dataRecord?.recallBotId || ongoingCallBotId) return;

    dispatch(getTranscript({ botId: dataRecord?.recallBotId, dataRecord }));
  }, [dispatch, ongoingCallBotId, dataRecord])

  useEffect(() => {
    if (!ongoingCallBotId && context === MeetingBreakDownContext.SUMMARY) {
      toast(t('component.MeetingBreakDown.theCallHasEnded'), {
        type: "warning",
      });
    }
  }, [ongoingCallBotId, t, context]);

  const handleDeleteDataRecord = async () => {
    if (!dataRecord || !activeClient) return;

    const response = await dispatch(deleteDataRecord({
      clientId: activeClient?.id,
      recordId: dataRecord?.id
    }));

    // Call with success
    if (!response.hasOwnProperty('error')) {
      toast(t('component.MeetingBreakDown.confirm.deleteRecord.success'), { type: 'success' })
      setIsDeleteRecordVisible(false);
      router.navigate(-1);
    }
  }


  return (
    dataRecord?.recallBotId && !isGetTranscriptError ? (
      <Fragment>
        <div className="flex flex-1 overflow-auto gap-6">
          <Fieldset legend={t('component.MeetingBreakDown.meetingSummary')} className='basis-2/3'>
            <MeetingTabInfo botId={dataRecord?.recallBotId} dataRecord={dataRecord} />
          </Fieldset>

          <div className='flex flex-1 overflow-auto gap-6 flex-col basis-1/3'>
            {dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING && (
              <Fieldset
                className='grow-0 overflow-visible'
                legend={t('component.MeetingBreakDown.meetingVideo')}
                fieldsetActions={videoUrl ? [
                  {
                    onClick: () => {
                      if (!isDownloadingVideo) {
                        trackEvent({ action: MIXPANEL.ACTION.COPILOT.SUMMARY.DOWNLOAD_VIDEO })
                        dispatch(downloadVideo({ botId: dataRecord?.recallBotId, dataRecord }))
                      }
                    },
                    tooltip: t('component.MeetingBreakDown.downloadVideo'),
                    content: !isDownloadingVideo ? (
                      <Download className='size-4' />
                    ) : (
                      <Loading />
                    )
                  }
                ] : []}
              >
                <MeetingVideo botId={dataRecord?.recallBotId} dataRecord={dataRecord} />
              </Fieldset>
            )}
            
            {dataRecord?.type === ClientDataRecordType.IN_PERSON_MEETING && (
              <Fieldset
                className='grow-0 overflow-visible'
                legend={t('component.MeetingBreakDown.meetingAudio')}
                fieldsetActions={audioUrl ? [
                  {
                    onClick: () => {
                      if (!isDownloadingAudio) {
                        trackEvent({ action: MIXPANEL.ACTION.COPILOT.SUMMARY.DOWNLOAD_AUDIO })
                        dispatch(downloadAudio({ botId: dataRecord?.recallBotId, dataRecord }))
                      }
                    },
                    tooltip: t('component.MeetingBreakDown.downloadAudio'),
                    content: !isDownloadingAudio ? (
                      <Download className='size-4' />
                    ) : (
                      <Loading />
                    )
                  }
                ] : []}
              >
                <MeetingAudio botId={dataRecord?.recallBotId} dataRecord={dataRecord} />
              </Fieldset>
            )}

            <Fieldset
              legend={t('component.MeetingBreakDown.meetingTranscript')}
              fieldsetActions={[
                {
                  onClick: () => {
                    if (!isDownloadingTranscript) {
                      trackEvent({ action: MIXPANEL.ACTION.COPILOT.SUMMARY.DOWNLOAD_TRANSCRIPT })
                      dispatch(downloadTranscript({ botId: dataRecord?.recallBotId, dataRecord }))
                    }
                  },
                  tooltip: t('component.MeetingBreakDown.downloadTranscript'),
                  content: !isDownloadingVideo ? (
                    <Download className='size-4' />
                  ) : (
                    <Loading />
                  )
                }
              ]}
            >
              <DisplayMeetingTranscript />

              <AskAIConversation
                botId={dataRecord?.recallBotId}
                clientId={dataRecord?.clientId}
                type={AiConversationType.TRANSCRIPT}
                placeholder={t('component.MeetingBreakDown.askQuestionAboutCall')}
                conversationHiddenUntilContent={true}
                dataRecord={dataRecord}
              />
            </Fieldset>
          </div>
        </div>

        <div className="h-24 w-full self-center">
          <Fieldset
            className='justify-center items-center'
          >
            {context === MeetingBreakDownContext.SUMMARY && (
              <div className='w-full flex gap-2'>
                <Button
                  onClick={() => {
                    dispatch(resetProcessingIndicators())
                    trackEvent({ action: MIXPANEL.ACTION.COPILOT.SUMMARY.RETURN_TO_MEETING })
                    router.navigate(ROUTES.APP.COPILOT.MEETING);
                  }}
                  className='flex flex-1 gap-2 items-center justify-center'
                  variant='secondary'
                  disabled={!ongoingCallBotId}
                >
                  <ChevronLeft className='size-4' />
                  <span>{t('component.MeetingBreakDown.returnToMeeting')}</span>
                </Button>

                <Button
                  onClick={() => {
                    dispatch(resetChatAssistant());
                    trackEvent({ action: MIXPANEL.ACTION.COPILOT.SUMMARY.NEW_CALL })
                    router.navigate(ROUTES.APP.COPILOT.CONFIGURE);
                  }}
                  className='flex flex-1 gap-2 items-center justify-center'
                >
                  <Plus className='size-4' />
                  <span>{t('component.MeetingBreakDown.joinNewCall')}</span>
                </Button>
              </div>
            )}

            {context === MeetingBreakDownContext.DATA_RECORD && (
              <div className='w-full flex justify-between'>
                <Button
                  onClick={() => {
                    router.navigate(-1);
                  }}
                  variant='secondary'
                >
                  <ChevronLeft className='size-4' />
                  <span>{t('general.goBack')}</span>
                </Button>

                <Button
                  onClick={() => { setIsDeleteRecordVisible(true) }}
                  variant='destructive'
                >
                  <span>{t('component.MeetingBreakDown.deleteDataRecord')}</span>
                </Button>
              </div>
            )}
          </Fieldset>
        </div>

        <ConfirmActionDialog
          onOk={handleDeleteDataRecord}
          isVisible={isDeleteRecordVisible}
          setIsVisible={setIsDeleteRecordVisible}
          title={t('component.MeetingBreakDown.confirm.deleteRecord.title')}
          description={t('component.MeetingBreakDown.confirm.deleteRecord.description')}
          isProcessing={isDeleteDataRecordProcessing}
        />
      </Fragment>
    ) : (
      <InfoCard title={t('component.MeetingBreakDown.noMeetingInfo')} className='max-w-3xl self-center' variant="default" />
    )
  );
};

