import { ReactNode } from 'react';

import styles from './Stepper.module.scss';
import classnames from 'classnames';
import { Back } from 'icons';
import { TOOLTIP_ID } from 'const';

export enum STEPPER_LOOK {
  PRIMARY = "primary",
  AUTOMATIONS = "automations",
};


interface IProps {
  activeStep: number;
  totalSteps: number;
  children?: ReactNode;
  showBackBtn?: boolean;
  showSteps?: boolean;
  handleBackAction?: Function;
  wrapperClassName?: string;
  stepperClassName?: string;
  look?: STEPPER_LOOK
}

export const Stepper = ({
  activeStep, totalSteps, children, showBackBtn, handleBackAction, showSteps = true, wrapperClassName = '', stepperClassName = '', look = STEPPER_LOOK.PRIMARY
}: IProps) => {
  return (
    <div className={classnames(styles.Wrapper, styles[`Wrapper--${look}`], wrapperClassName)}>
      {showBackBtn && (
        <div
          className={styles.BackBtnContainer}
          data-tooltip-id={TOOLTIP_ID}
          data-tooltip-content='Go back'
          onClick={() => { handleBackAction && handleBackAction() }}
        >
          <Back className={styles.BackBtn} />
        </div>
      )}
      <div className={classnames(styles.Stepper, stepperClassName)}>
        <div className={styles.Content}>
          {children}
        </div>
        {showSteps && (
          <div className={styles.StepsContainer}>
            {
              Array(totalSteps).fill(0)?.map((item, index) => {
                return (<div key={index} className={classnames(styles.Step, { [styles.StepActive]: index === activeStep - 1 })}></div>)
              })
            }
          </div>
        )}
      </div>
    </div>
  )
};
