import { Icon, IIconProps } from './Icon';

export const Pause = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox={"0 0 24 24"}
    >
      <g clipPath="url(#clip0_406_2125)">
        <path d="M6.5 0C5.57174 0 4.6815 0.368749 4.02513 1.02513C3.36875 1.6815 3 2.57174 3 3.5V20.5C3 21.4283 3.36875 22.3185 4.02513 22.9749C4.6815 23.6313 5.57174 24 6.5 24C7.42826 24 8.3185 23.6313 8.97487 22.9749C9.63125 22.3185 10 21.4283 10 20.5V3.5C10 2.57174 9.63125 1.6815 8.97487 1.02513C8.3185 0.368749 7.42826 0 6.5 0Z" fill="#374957" />
        <path d="M17.5002 0C16.5719 0 15.6817 0.368749 15.0253 1.02513C14.3689 1.6815 14.0002 2.57174 14.0002 3.5V20.5C14.0002 21.4283 14.3689 22.3185 15.0253 22.9749C15.6817 23.6313 16.5719 24 17.5002 24C18.4284 24 19.3186 23.6313 19.975 22.9749C20.6314 22.3185 21.0002 21.4283 21.0002 20.5V3.5C21.0002 2.57174 20.6314 1.6815 19.975 1.02513C19.3186 0.368749 18.4284 0 17.5002 0Z" fill="#374957" />
      </g>
      <defs>
        <clipPath id="clip0_406_2125">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

