import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataPointValue,
  getQuestionsStatus,
  getTranscript,
  getTranscriptVideoUrl,
  selectIsGetDataPointValueProcessing,
  selectIsGetQuestionValueProcessing,
  selectIsGetTranscriptProcessing,
  selectIsGetTranscriptVideoUrlProcessing,
  selectIsTranscriptStale,
  selectOngoingCallBotId,
} from "store/chat-assistant";

const REFETCH_INTERVAL_MS = 5000;

type TypeOptions = "dataPoints" | "questions" | "transcript" | "videoRecording";

interface IProps {
  botId: string;
  type: TypeOptions;
  refetchInterval?: number;
  killRecurrence?: boolean;
}

const isProcessingSelector: Record<TypeOptions, any> = {
  dataPoints: selectIsGetDataPointValueProcessing,
  questions: selectIsGetQuestionValueProcessing,
  transcript: selectIsGetTranscriptProcessing,
  videoRecording: selectIsGetTranscriptVideoUrlProcessing,
};

const fetchAction: Record<TypeOptions, any> = {
  dataPoints: getDataPointValue,
  questions: getQuestionsStatus,
  transcript: getTranscript,
  videoRecording: getTranscriptVideoUrl,
};

export const useFetchDataAtInterval = ({
  botId,
  type,
  refetchInterval = REFETCH_INTERVAL_MS,
  killRecurrence,
}: IProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const fetchTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  const isProcessing = useSelector(isProcessingSelector[type]);

  const isTranscriptStale = useSelector(selectIsTranscriptStale);
  const ongoingCallBotId = useSelector(selectOngoingCallBotId);

  const [startCallTs, setStartCallTs] = useState<number>(0);

  useEffect(() => {
    return () => {
      fetchTimeout.current && clearTimeout(fetchTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (killRecurrence) {
      fetchTimeout.current && clearTimeout(fetchTimeout.current);
    }
  }, [killRecurrence]);

  useEffect(() => {
    if (killRecurrence) {
      return;
    }

    if (
      ["dataPoints", "questions", "transcript"].includes(type) &&
      (!botId || isProcessing || !ongoingCallBotId)
    ) {
      return;
    }

    if (
      ["videoRecording"].includes(type) &&
      (!botId || isProcessing || ongoingCallBotId)
    ) {
      return;
    }

    if (["dataPoints", "questions"].includes(type) && isTranscriptStale) {
      return;
    }

    const now = Date.now();
    const diff = Math.max(now - startCallTs, 0);

    fetchTimeout.current = setTimeout(() => {
      setStartCallTs(Date.now());
      dispatch(fetchAction[type]({ botId }));
    }, Math.max(refetchInterval - diff, 100));

    return () => fetchTimeout.current && clearTimeout(fetchTimeout.current);
  }, [
    botId,
    isProcessing,
    type,
    refetchInterval,
    startCallTs,
    ongoingCallBotId,
    isTranscriptStale,
    dispatch,
    killRecurrence,
  ]);

  return { refetchInterval };
};
