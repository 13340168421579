import { createSlice } from "@reduxjs/toolkit";
import { IDataExtractorState } from "./types";
import { createDataExtractorRequest } from "./actions";

const initialState: IDataExtractorState = {
  isProcessing: false,
  isProcessingError: false,
  dataExtractorBlobObjectUrl: "",
};

const dataExtractorSlice = createSlice({
  name: "dataExtractor",
  initialState,
  reducers: {
    resetDataExtractorState: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDataExtractorRequest.pending, (state, action) => {
        return { ...initialState, isProcessing: true };
      })
      .addCase(createDataExtractorRequest.fulfilled, (state, action) => {
        state.dataExtractorBlobObjectUrl = action.payload;
        state.isProcessing = false;
      })
      .addCase(createDataExtractorRequest.rejected, (state) => {
        state.isProcessing = false;
        state.isProcessingError = true;
      });
  },
});

export const { resetDataExtractorState } = dataExtractorSlice.actions;

export default dataExtractorSlice.reducer;
