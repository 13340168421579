import { useAppDispatch, useMixpanel } from 'hooks';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { MuninRecorder } from 'components/common/_atoms/MuninRecorder';
import { useEffect } from 'react';
import { updateSavedCopilotConfigurationFormValues } from 'store/chat-assistant';

export const InPersonMeetingPage = () => {
  const { trackEvent } = useMixpanel();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSavedCopilotConfigurationFormValues(null))
  }, [dispatch])

  return (
    <GeneralLayout hideMenu={true} contentClassName="items-center">
      <MuninRecorder />
    </GeneralLayout>
  );
};

