import styles from './ParaplannerAI.module.scss';
import { Brain, Logo } from 'icons';


export const ParaplannerAI = () => {
  return (
    <div className={styles.ParaplannerAI}>
      <Brain />
      <Logo />
    </div>
  );
};
