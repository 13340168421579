import { Icon, IIconProps } from './Icon';

export const Delete = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 14 14"
    >
      <g clipPath="url(#clip0_1570_915)">
        <path d="M12.2498 2.33333H10.4415C10.3061 1.67499 9.9479 1.08345 9.42723 0.658419C8.90657 0.233386 8.25529 0.000848473 7.58317 0L6.4165 0C5.74438 0.000848473 5.09311 0.233386 4.57244 0.658419C4.05178 1.08345 3.69356 1.67499 3.55817 2.33333H1.74984C1.59513 2.33333 1.44675 2.39479 1.33736 2.50419C1.22796 2.61358 1.1665 2.76196 1.1665 2.91667C1.1665 3.07138 1.22796 3.21975 1.33736 3.32915C1.44675 3.43854 1.59513 3.5 1.74984 3.5H2.33317V11.0833C2.3341 11.8566 2.64169 12.5979 3.18847 13.1447C3.73525 13.6915 4.47657 13.9991 5.24984 14H8.74984C9.5231 13.9991 10.2644 13.6915 10.8112 13.1447C11.358 12.5979 11.6656 11.8566 11.6665 11.0833V3.5H12.2498C12.4045 3.5 12.5529 3.43854 12.6623 3.32915C12.7717 3.21975 12.8332 3.07138 12.8332 2.91667C12.8332 2.76196 12.7717 2.61358 12.6623 2.50419C12.5529 2.39479 12.4045 2.33333 12.2498 2.33333ZM6.4165 1.16667H7.58317C7.945 1.16711 8.29783 1.27947 8.59328 1.48834C8.88873 1.69721 9.11233 1.99237 9.23342 2.33333H4.76625C4.88734 1.99237 5.11095 1.69721 5.4064 1.48834C5.70185 1.27947 6.05468 1.16711 6.4165 1.16667ZM10.4998 11.0833C10.4998 11.5475 10.3155 11.9926 9.98728 12.3208C9.65909 12.649 9.21397 12.8333 8.74984 12.8333H5.24984C4.78571 12.8333 4.34059 12.649 4.0124 12.3208C3.68421 11.9926 3.49984 11.5475 3.49984 11.0833V3.5H10.4998V11.0833Z" fill="#727272" />
        <path d="M5.83333 10.4997C5.98804 10.4997 6.13641 10.4382 6.24581 10.3288C6.35521 10.2194 6.41666 10.071 6.41666 9.91633V6.41634C6.41666 6.26163 6.35521 6.11326 6.24581 6.00386C6.13641 5.89447 5.98804 5.83301 5.83333 5.83301C5.67862 5.83301 5.53025 5.89447 5.42085 6.00386C5.31146 6.11326 5.25 6.26163 5.25 6.41634V9.91633C5.25 10.071 5.31146 10.2194 5.42085 10.3288C5.53025 10.4382 5.67862 10.4997 5.83333 10.4997Z" fill="#727272" />
        <path d="M8.16634 10.4997C8.32105 10.4997 8.46943 10.4382 8.57883 10.3288C8.68822 10.2194 8.74968 10.071 8.74968 9.91633V6.41634C8.74968 6.26163 8.68822 6.11326 8.57883 6.00386C8.46943 5.89447 8.32105 5.83301 8.16634 5.83301C8.01163 5.83301 7.86326 5.89447 7.75386 6.00386C7.64447 6.11326 7.58301 6.26163 7.58301 6.41634V9.91633C7.58301 10.071 7.64447 10.2194 7.75386 10.3288C7.86326 10.4382 8.01163 10.4997 8.16634 10.4997Z" fill="#727272" />
      </g>
      <defs>
        <clipPath id="clip0_1570_915">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
