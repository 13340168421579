import { Helmet } from 'react-helmet';
import { Page, Panel, Panels } from 'components_old';
import { useTranslation } from 'hooks';
import { ReactNode } from 'react';

interface IProps {
  title: ReactNode;
  children: ReactNode | undefined;
}


export const GenericPage = ({ title, children }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('common.appName')} | {title}
        </title>
      </Helmet>

      <Page>
        <Panels>
          <Panel
            header={<>{title}</>}
            body={
              <>
                {children}
              </>
            }
          />
        </Panels >
      </Page >
    </>
  );
};

