import { useDevFlag, useTranslation } from 'hooks';
import { ROUTES } from 'routing/constants';
import { SideMenuSubLayout } from 'components/layout/SideMenuSubLayout';
import { selectProfileIsOrgAdmin } from 'store/profile';
import { useSelector } from 'react-redux';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { isDevEnv } = useDevFlag()
  const isOrgAdmin = useSelector(selectProfileIsOrgAdmin);

  return (
    <SideMenuSubLayout
      menuItems={[
        {
          label: t("page.SettingsPage.myTemplates"),
          to: ROUTES.APP.SETTINGS.MY_TEMPLATES,
        },
        ...(isOrgAdmin
          ? [
            {
              label: t("page.SettingsPage.organizationTemplates"),
              to: ROUTES.APP.SETTINGS.ORG_TEMPLATES,
            },
          ]
          : []),
          {
            label: t("page.SettingsPage.dataPoints"),
            to: ROUTES.APP.SETTINGS.DATA_POINTS,
          },
        {
          label: t("page.SettingsPage.credentials"),
          to: ROUTES.APP.SETTINGS.CREDENTIALS,
        },
        {
          label: t("page.SettingsPage.connections"),
          to: ROUTES.APP.SETTINGS.CONNECTIONS,
          // isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.headsUpDisplay"),
          to: ROUTES.APP.SETTINGS.CARDS,
          isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.compliance"),
          to: ROUTES.APP.SETTINGS.COMPLIANCE,
          isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.integrations"),
          to: ROUTES.APP.SETTINGS.INTEGRATIONS,
          isComingSoon: !isDevEnv
        },
      ]}
    />
  );
};

