import { useState } from 'react';
import { Stepper, GenericPage, ISDEIEHCDataExtractorUploader, ISDEIEHCDataExtractorCongrats, AutomationStartScreen, STEPPER_LOOK, LinkText } from 'components_old';
import { IAutomationWizardRecord, ISDEIEHC_STEP, TAutomationStep } from '../../types';
import { INTRO_VIDEO_ID } from 'const';
import { useTranslation } from 'react-i18next';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import ISDEIE_Sample from 'documents/Side-by-Side Comparison.pdf'


export const ISDEIEHCWizard = () => {
  const { t } = useTranslation();
  const [stepsArray, setStepsArray] = useState<Array<TAutomationStep>>([ISDEIEHC_STEP.S1]);

  const handleGoToNextStep = ({ action, payload }: IAutomationWizardRecord) => {
    const isAnotherRequest = action?.includes('step1');

    setStepsArray(isAnotherRequest ? [action] : [...stepsArray, action]);

    // mixpanel.track(action, payload);
  }

  const handleBackAction = () => {
    const currentStep = stepsArray[stepsArray.length - 1];

    if (currentStep === ISDEIEHC_STEP.S1) {
      router.navigate(ROUTES.ADVISOR_AUTOMATIONS)
    } else {
      setStepsArray(stepsArray.slice(0, -1));
    }

    // mixpanel.track(EVENT.BACK);
  }

  return (
    <GenericPage title={t('automations.tiles.investmentStatementExtractionIntoExcelHumanCheck.title')}>
      <Stepper
        activeStep={0}
        totalSteps={0}
        showSteps={false}
        showBackBtn={true}
        handleBackAction={handleBackAction}
        look={STEPPER_LOOK.AUTOMATIONS}
      >
        {stepsArray[stepsArray.length - 1] === ISDEIEHC_STEP.S1 && (
          <AutomationStartScreen
            description={
              <>
                <div>{t('automations.tiles.investmentStatementExtractionIntoExcelHumanCheck.descriptionA')}</div>
                <div>{t('automations.tiles.investmentStatementExtractionIntoExcelHumanCheck.descriptionB')}</div>

                <div>
                  <LinkText title={t('common.viewSample')} href={ISDEIE_Sample} target='_blank'>
                    {t('common.viewSample')}
                  </LinkText>
                </div>
              </>
            }
            videoId={INTRO_VIDEO_ID}
          >
            <ISDEIEHCDataExtractorUploader handleGoToNextStep={handleGoToNextStep} nextStep={ISDEIEHC_STEP.S2} />
          </AutomationStartScreen>
        )}

        {stepsArray[stepsArray.length - 1] === ISDEIEHC_STEP.S2 && (
          <ISDEIEHCDataExtractorCongrats handleGoToNextStep={handleGoToNextStep} nextStep={ISDEIEHC_STEP.S1} />
        )}
      </Stepper>
    </GenericPage>
  );
}
