import { Button } from 'components/ui/button';
import { useRef, useState, useEffect } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { useAppDispatch, useMixpanel, usePreventRefresh, useTranslation } from 'hooks';
import { MicIcon, PauseIcon, PlayIcon, RotateCcwIcon, UploadCloudIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { toast } from 'react-toastify';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { RecorderTimer } from 'components/common/_atoms/RecorderTimer';
import { ROUTES } from 'routing/constants';
import { router } from 'routing/router';
import { useSelector } from 'react-redux';
import { resetChatAssistant, selectInPersonRecordingData, uploadInPersonRecording } from 'store/chat-assistant';
import { UploadStatusEnum, resetUploadManagementSlice, selectUploadEvent, selectUploadStatus } from 'store/upload-management';
import { niceBytes } from 'utils/misc';
import { ConfirmActionDialog } from './ConfirmActionDialog';
import { Progress } from 'components/ui/progress';
import { MIXPANEL } from 'const';

enum RecordingState {
  INITIAL = "initial",
  ONGOING = "recordingOngoing",
  PAUSED = "recordingPaused",
  FINISHED = "recordingFinished",
}

export const MuninRecorder = () => {
  const { t } = useTranslation();
  const recorderControls = useAudioRecorder();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const inPersonRecordingData = useSelector(selectInPersonRecordingData)

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const animationIdRef = useRef<number | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { isPaused, isRecording, recordingBlob, startRecording, stopRecording, togglePauseResume } = recorderControls;

  const [isDeleteRecordingVisible, setIsDeleteRecordingVisible] = useState(false);
  const [isConsentVisible, setIsConsentVisible] = useState(true);
  const [recordingState, setRecordingState] = useState(RecordingState.INITIAL);

  const uploadStatus = useSelector(selectUploadStatus);
  const uploadProgressEvent = useSelector(selectUploadEvent);

  const [uploadProgressValue, setUploadProgressValue] = useState(0)
  const [uploadDoneSize, setUploadDoneSize] = useState('')
  const [uploadTotalSize, setUploadTotalSize] = useState("")

  const [audioSrc, setAudioSrc] = useState<string>("")


  usePreventRefresh(![RecordingState.INITIAL].includes(recordingState));

  useEffect(() => {
    dispatch(resetUploadManagementSlice())
  }, [dispatch])

  useEffect(() => {
    if (!isRecording && animationIdRef.current) {
      cancelAnimationFrame(animationIdRef.current);
    }

    if (isRecording && !isPaused) {
      setupVisualizer();
    } else {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
    }
  }, [isRecording, isPaused]);

  useEffect(() => {
    if (recordingBlob) {
      setAudioSrc(URL.createObjectURL(recordingBlob))
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (audioSrc && audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", () => {
        if (audioRef.current && audioRef.current?.duration === Infinity) {
          audioRef.current.currentTime = 1e101;
          audioRef.current.addEventListener("timeupdate", () => {
            if (audioRef.current) {
              audioRef.current.currentTime = 0;
            }
          }, { once: true });
        }
      })
    }
  }, [audioRef, audioSrc]);

  useEffect(() => {
    if ([UploadStatusEnum.IDLE, UploadStatusEnum.FAILED].includes(uploadStatus)) {
      setUploadProgressValue(0);
      setUploadDoneSize('');
      setUploadTotalSize('')
    }
    if ([UploadStatusEnum.COMPLETED].includes(uploadStatus)) {
      setUploadProgressValue(100);
      setUploadDoneSize('');
      setUploadTotalSize('Upload completed')
    }
    if ([UploadStatusEnum.UPLOADING].includes(uploadStatus)) {
      if (!uploadProgressEvent || !uploadProgressEvent.progress) {
        setUploadProgressValue(0);
        setUploadDoneSize('');
        setUploadTotalSize('');
      } else {
        setUploadProgressValue(uploadProgressEvent.progress * 100);
        setUploadDoneSize(niceBytes(uploadProgressEvent.loaded));
        setUploadTotalSize(niceBytes(uploadProgressEvent.total));
      }
    }
  }, [uploadStatus, uploadProgressEvent])

  const onStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      startRecording();
      setupVisualizer();
      setRecordingState(RecordingState.ONGOING)
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.START_RECORDING, type: MIXPANEL.TYPE.CLICKED })
    } catch (error) {
      toast(t('error.microphoneAccess'), { type: 'error' })
    }
  };

  const onStopRecording = () => {
    trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.STOP_RECORDING, type: MIXPANEL.TYPE.CLICKED })
    setRecordingState(RecordingState.FINISHED)
    stopRecording();
    // Remove tab red dot
    mediaStreamRef?.current?.getTracks()?.[0]?.stop();
  };

  const onTogglePauseResume = () => {
    if ([RecordingState.ONGOING].includes(recordingState)) {
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.PAUSE_RECORDING, type: MIXPANEL.TYPE.CLICKED })
      setRecordingState(RecordingState.PAUSED)
    } else {
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.PLAY_RECORDING, type: MIXPANEL.TYPE.CLICKED })
      setRecordingState(RecordingState.ONGOING)
    }

    togglePauseResume();
  };

  const setupVisualizer = () => {
    if (!canvasRef.current || !mediaStreamRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    analyserRef.current = analyser;

    const source = audioContext.createMediaStreamSource(mediaStreamRef.current);
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    dataArrayRef.current = dataArray;

    const draw = () => {
      if (!recorderControls.isRecording || isPaused) return;

      analyser.getByteFrequencyData(dataArray);
      if (canvasCtx) {
        canvasCtx.fillStyle = 'rgb(255, 255, 255)'; // White background
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

        const barWidth = (canvas.width / bufferLength) * 2.5;
        let barHeight;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          barHeight = dataArray[i];

          // Reduce sensitivity for the first 10 bars
          if (i < 10) {
            barHeight = Math.max(barHeight * 0.5 * 0.2, 2); // Adjust this multiplier to fine-tune sensitivity
          } else {
            barHeight = Math.max(barHeight * 0.2, 2);
          }

          canvasCtx.fillStyle = '#71717a'; // Bar color
          canvasCtx.fillRect(x, canvas.height - barHeight / 2 - canvas.height / 2, barWidth, barHeight);

          x += barWidth + 1;
        }
      }
      animationIdRef.current = requestAnimationFrame(draw);
    };

    draw();
  };

  const leaveScreen = () => {
    dispatch(resetChatAssistant());
    router.navigate(ROUTES.APP.COPILOT.CONFIGURE)
  }

  const handleUpload = async () => {
    if (!recordingBlob) {
      return;
    }

    try {
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.UPLOAD, type: MIXPANEL.TYPE.CLICKED })

      const formData = new FormData();
      formData.append('file', recordingBlob, 'recording.wav');
      formData.append('data', JSON.stringify(inPersonRecordingData) as any);

      const uploadResponse = await dispatch(uploadInPersonRecording(formData));
    } catch (err) {
      console.error('Failed to upload file', err);
    }
  }

  return (
    <>
      <Card className='max-w-3xl w-full'>
        <CardHeader>
          <CardTitle>{t('component.MuninRecorder.title')}</CardTitle>
          <CardDescription>{t('component.MuninRecorder.description')}
          </CardDescription>
        </CardHeader>

        <CardContent className="flex flex-col gap-6">
          {![RecordingState.FINISHED].includes(recordingState) && (
            <div className='flex gap-6 items-center'>
              {[RecordingState.INITIAL].includes(recordingState) && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button type='button' variant='outline' className="size-12 rounded-full" onClick={onStartRecording}>
                      <MicIcon className='size-5' />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {t('component.MuninRecorder.startRecording')}
                  </TooltipContent>
                </Tooltip>
              )}

              {[RecordingState.ONGOING].includes(recordingState) && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button type='button' variant='outline' className="size-12 rounded-full" onClick={onTogglePauseResume}>
                      <PauseIcon className='size-5' />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {t('component.MuninRecorder.pauseRecording')}
                  </TooltipContent>
                </Tooltip>
              )}

              {[RecordingState.PAUSED].includes(recordingState) && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button type='button' variant='outline' className="size-12 rounded-full" onClick={onTogglePauseResume}>
                      <PlayIcon className='size-5' />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {t('component.MuninRecorder.resumeRecording')}
                  </TooltipContent>
                </Tooltip>
              )}

              {[RecordingState.ONGOING, RecordingState.PAUSED].includes(recordingState) && (
                <>
                  <RecorderTimer isCounting={[RecordingState.ONGOING].includes(recordingState)} />
                  <div className='flex justify-center'>
                    <canvas ref={canvasRef} width="140" height="48"></canvas>
                  </div>
                </>
              )}
            </div>
          )}

          {[RecordingState.FINISHED].includes(recordingState) && (
            <>
              {[UploadStatusEnum.IDLE].includes(uploadStatus) && (
                <>
                  <InfoCard
                    variant="item"
                    title={t('component.MuninRecorder.infoCard.title') as string}
                    description={t('component.MuninRecorder.infoCard.description') as string}
                  />
                  {recordingBlob && audioSrc && (
                    <audio ref={audioRef} src={audioSrc} controls controlsList="nodownload" />
                  )}
                </>
              )}

              {
                ![UploadStatusEnum.IDLE].includes(uploadStatus) && (
                  <div className="flex flex-col w-full items-center">
                    <div className='w-full'>
                      <div className="flex flex-row justify-between w-full min-h-5">
                        <div className="text-sm font-normal">{uploadDoneSize}</div>
                        <div className="text-sm font-normal">{uploadTotalSize}</div>
                      </div>

                      <Progress value={uploadProgressValue} className='w-full' />

                      <div className='w-full min-h-7 mt-5'>
                        <CardDescription className='text-center'>
                          {[UploadStatusEnum.UPLOADING].includes(uploadStatus) && (
                            <span>
                              {t('component.MuninRecorder.statusDescription.uploading')}
                            </span>
                          )}
                          {[UploadStatusEnum.COMPLETED].includes(uploadStatus) && (
                            <span className='text-primary'>
                              {t('component.MuninRecorder.statusDescription.completed')}
                            </span>
                          )}
                          {[UploadStatusEnum.FAILED].includes(uploadStatus) && (
                            <span className='text-destructive'>
                              {t('component.MuninRecorder.statusDescription.failed')}
                            </span>
                          )}
                        </CardDescription>
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          )}
        </CardContent >

        <CardFooter className="flex justify-between">
          {[RecordingState.INITIAL].includes(recordingState) && (
            <>
              <Button
                type="button"
                onClick={onStartRecording}
              >
                <MicIcon className='size-4' />
                {t('component.MuninRecorder.btn.startRecording')}
              </Button>

              <Button
                type="button"
                variant="outline"
                onClick={() => leaveScreen()}
              >
                {t('component.MuninRecorder.btn.leaveMeetingScreen')}
              </Button>
            </>
          )}

          {[RecordingState.ONGOING, RecordingState.PAUSED].includes(recordingState) && (
            <>
              <span />
              <Button
                type="button"
                variant="outline"
                className='self-end'
                onClick={onStopRecording}
              >
                {t('component.MuninRecorder.btn.endMeeting')}
              </Button>
            </>
          )}

          {[RecordingState.FINISHED].includes(recordingState) && (
            <>
              {[UploadStatusEnum.IDLE, UploadStatusEnum.FAILED].includes(uploadStatus) && (
                <>
                  <Button
                    type="button"
                    onClick={handleUpload}
                  >
                    {[UploadStatusEnum.FAILED].includes(uploadStatus) ? (
                      <>
                        <RotateCcwIcon className='size-4' />
                        {t('component.MuninRecorder.btn.retry')}
                      </>
                    ) : (
                      <>
                        <UploadCloudIcon className='size-4' />
                        {t('component.MuninRecorder.btn.upload')}
                      </>
                    )}
                  </Button>

                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setIsDeleteRecordingVisible(true)}
                  >
                    {t('component.MuninRecorder.btn.discard')}
                  </Button>
                </>
              )}

              {[UploadStatusEnum.UPLOADING].includes(uploadStatus) && (
                <>
                  <Button
                    type="button"
                    loading={true}
                  >
                    <UploadCloudIcon className='size-4' />
                    {t('component.MuninRecorder.btn.uploading')}
                  </Button>
                </>
              )}


              {[UploadStatusEnum.COMPLETED].includes(uploadStatus) && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.COMPLETED_LEAVE_SCREEN, type: MIXPANEL.TYPE.CLICKED })
                    leaveScreen()
                  }}
                >
                  {t('general.ok')}
                </Button>
              )}
            </>
          )}
        </CardFooter>

        <ConfirmActionDialog
          onOk={() => {
            trackEvent({ action: MIXPANEL.ACTION.COPILOT.IN_PERSON.DELETE_RECORDING, type: MIXPANEL.TYPE.CLICKED })
            leaveScreen()
          }}
          isVisible={isDeleteRecordingVisible}
          setIsVisible={setIsDeleteRecordingVisible}
          title={t('component.MuninRecorder.confirm.deleteRecording.title')}
          description={t('component.MuninRecorder.confirm.deleteRecording.description')}
        />

        <ConfirmActionDialog
          onOk={() => setIsConsentVisible(false)}
          onCancel={() => {
            toast("Without the client's consent the meeting cannot be recorded", { type: 'warning' })
            router.navigate(ROUTES.APP.COPILOT.CONFIGURE)
          }}
          isVisible={isConsentVisible}
          setIsVisible={setIsConsentVisible}
          title={t('component.MuninRecorder.confirm.consent.title')}
          description={t('component.MuninRecorder.confirm.consent.description')}
          okText={t('component.MuninRecorder.confirm.consent.okText')}
          cancelText={t('general.no')}
        />
      </Card>
    </>
  );
};

