import { LOCAL_STORAGE, MIXPANEL } from 'const';
import mixpanel from 'mixpanel-browser';

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY || '')

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function sendSubscription(userId: string, subscription: any) {
  return fetch(`${process.env.REACT_APP_API_URL}/notifications/subscribe`, {
    method: 'POST',
    body: JSON.stringify({user_id: userId, subscription}),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export async function subscribeUser(userId: string) {
  if ('serviceWorker' in navigator) {
    try {
      const userConsent = await showCustomConsentUI();
      if (!userConsent) {
        console.log('User did not consent to notifications.');
        return;
      }

      const registration = await navigator.serviceWorker.ready;
      if (!registration.pushManager) {
        console.log('Push manager unavailable.');
        return;
      }

      try {
        const existedSubscription = await registration.pushManager.getSubscription();
        if (existedSubscription === null) {
          console.log('No subscription detected, requesting native permission.');

          const permissionResult = await Notification.requestPermission();
          if (permissionResult !== 'granted') {
            console.log('Permission was not granted.');
            return;
          }

          try {
            const newSubscription = await registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            });
            console.log('New subscription added.');
            sendSubscription(userId, newSubscription);
          } catch (e) {
            console.error('An error occurred during the subscription process.', e);
          }
        } else {
          console.log('Existing subscription detected.');
          sendSubscription(userId, existedSubscription);
        }
      } catch (e) {
        console.error('An error occurred while getting the subscription.', e);
      }
    } catch (e) {
      console.error('An error occurred during Service Worker registration.', e);
    }
  }
}


function showCustomConsentUI(): Promise<boolean> {
  if (localStorage.getItem(LOCAL_STORAGE.CONSENT_GIVEN) === 'true') {
    return Promise.resolve(false);
  }

  return new Promise<boolean>((resolve) => {
    const modalHTML = `
      <div id="consentModal" style="position: fixed; left: 0; top: 0; width: 100%; height: 100vh; background-color: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: start; z-index: 1000; padding-top: 0;">
        <div style="background-color: white; padding: 20px; border-radius: 10px; text-align: center; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); width: 700px; margin-top: 20px;">
          <div style="margin-bottom: 20px;">
            <p>We'd like to show you notifications for new conversation messages from your administrative assistant and paraplanner</p>
          </div>
          <button id="noButton" style="border: none; background-color: #ddd; color: black; padding: 10px 20px; margin-right: 8px; border-radius: 5px; cursor: pointer;">NO THANKS</button>
          <button id="yesButton" style="border: none; background-color: rgb(102, 10, 214); color: white; padding: 10px 20px; border-radius: 5px; cursor: pointer;">ALLOW</button>
        </div>
      </div>
    `;
    const modalContainer = document.getElementById('consentModalContainer');
    if (!modalContainer) throw new Error("Modal container not found");
    modalContainer.innerHTML = modalHTML;

    const yesButton = document.getElementById('yesButton');
    const noButton = document.getElementById('noButton');
    const consentModal = document.getElementById('consentModal');

    if (!yesButton || !noButton || !consentModal) throw new Error("Modal elements not found");

    yesButton.addEventListener('click', () => {
      consentModal.remove();
      localStorage.setItem(LOCAL_STORAGE.CONSENT_GIVEN, 'true')
      resolve(true);
      mixpanel.track(`${MIXPANEL.TYPE.CLICKED}_${MIXPANEL.ACTION.CONSENT.YES}`);
    });
    
    noButton.addEventListener('click', () => {
      consentModal.remove();
      localStorage.setItem(LOCAL_STORAGE.CONSENT_GIVEN, 'true')
      resolve(false);
      mixpanel.track(`${MIXPANEL.TYPE.CLICKED}_${MIXPANEL.ACTION.CONSENT.NO}`);
    });
  });
}