import { api } from "services/api";
import { processApiError } from "services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION } from "const";
import { IProfilePreferences } from "./types";

export const getProfileDetails = createAsyncThunk(
  ACTION.PROFILE.GET_PROFILE_DETAILS,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.myProfile.getProfileDetails();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updatePreferences = createAsyncThunk(
  ACTION.PROFILE.UPDATE_PREFERENCES,
  async (payload: IProfilePreferences, { rejectWithValue }) => {
    try {
      const response = await api.myProfile.updatePreferences(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
