export const isProd = () => {
  const url = window.location.href;

  return !(url.includes("localhost") || url.includes("app.dev.paraplanner.ai"));
};


export const niceBytes = (x: number | undefined) => {
  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let l = 0, n = x || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const convertUtcToLocalTime = (utcTimeString: string): string => {
  // Split the time string into hours and minutes
  const [hours, minutes] = utcTimeString.split(':').map(Number);

  // Create a new Date object with the current date and the provided time in UTC
  const utcDate = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), hours, minutes));

  // Convert the UTC date to the local time zone
  const localTimeString = utcDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return localTimeString;
};