import classnames from "classnames";
import { CardDescription } from "components/ui/card";
import { ReactNode } from "react";

interface IProps {
  children?: ReactNode | undefined;
  hidden?: boolean,
  blinker?: boolean,
}

export const Phrase = ({ children, hidden = false, blinker = false }: IProps) => {
  return (
    <CardDescription
      className={
        classnames(
          'break-words after:content-[" "] after:w-[10px] after:h-[10px] after:inline-flex after:bg-foreground after:rounded-full  after:ml-1 after:animate-blinking',
          {
            'after:hidden': !blinker,
            hidden: hidden
          }
        )
      }
    >
      {children}
    </CardDescription>
  )
}
