import { combineReducers, configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authenticationReducer from "./authentication/authenticationSlice";
import usersReducer from "./users/users.slice";
import emailsReducer from "./nylas/nylas.slice";
import mailboxReducer from "./mailbox/mailboxSlice";
import profileReducer from "./profile/profile.slice";
import analysisReducer from "./analysis/analysisSlice";
import credentialsReducer from "./credentials/credentialsSlice";
import portfolioModelReducer from "./portfolioModel/portfolioModelSlice";
import chatReducer from "./chat/chatSlice";
import notificationsReducer from "./notifications/notificationsSlice";
import dataExtractorReducer from "./dataExtractor/dataExtractorSlice";
import automationsReducer from "./automations/automationsSlice";
import chatAssistantReducer from "./chat-assistant/chat-assistant.slice";
import clientManagementReducer from "./clientManagement/clientManagementSlice";
import templatesManagementReducer from "./templates-management/templates-management.slice";
import organizationsReducer from "./organizations/organizations.slice";
import headsUpCardsReducer from "./heads-up-cards/headsUpCardsSlice";
import uploadManagementReducer from "./upload-management/upload-management.slice";
import calendarReducer from "./calendar/calendar.slice";
import redtailReducer from "./redtail/redtail.slice";
import dataPointsReducer from "./data-points/data-points.slice";

import mailboxMiddleware from "./mailboxMiddleware";

// Define the initial state of the reducers
const rootReducer = combineReducers({
  authentication: authenticationReducer,
  users: usersReducer,
  emails: emailsReducer,
  mailbox: mailboxReducer,
  myProfile: profileReducer,
  analysis: analysisReducer,
  credentials: credentialsReducer,
  portfolioModel: portfolioModelReducer,
  chat: chatReducer,
  notifications: notificationsReducer,
  dataExtractor: dataExtractorReducer,
  automations: automationsReducer,
  chatAssistant: chatAssistantReducer,
  clientManagement: clientManagementReducer,
  templatesManagement: templatesManagementReducer,
  organizations: organizationsReducer,
  headsUpCards: headsUpCardsReducer,
  uploadManagement: uploadManagementReducer,
  calendar: calendarReducer,
  redtail: redtailReducer,
  dataPoints: dataPointsReducer,
});

// Enhance rootReducer to handle logout action
const appReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: Action
) => {
  if (action.type === "chatAssistant/resetRedux") {
    state = undefined;
  }
  return rootReducer(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(mailboxMiddleware), // Add your sequence middleware here
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
