import { http } from "services/http";

import { API_ROUTES } from "../constants";
import { IEmailsApi } from "./types";
import {
  IExchangeCalendarTokenPayload,
  IExchangeMailboxTokenPayload,
  IGenerateAuthUrlPayload,
} from "store/nylas/types";

export const emails: IEmailsApi = {
  generateAuthUrl: (payload: IGenerateAuthUrlPayload) =>
    http
      .post(API_ROUTES.emails.generateAuthUrl, {
        email_address: payload.emailAddress,
        success_url: payload.successUrl,
      })
      .then((response) => response.data),
  exchangeMailboxToken: (payload: IExchangeMailboxTokenPayload) =>
    http
      .post(API_ROUTES.emails.exchangeMailboxToken, { token: payload.token })
      .then((response) => response.data),
  generateCalendarAuthUrl: (payload: IGenerateAuthUrlPayload) =>
    http
      .post(API_ROUTES.emails.generateCalendarAuthUrl, {
        email_address: payload.emailAddress,
        success_url: payload.successUrl,
      })
      .then((response) => response.data),
  exchangeCalendarToken: (payload: IExchangeCalendarTokenPayload) =>
    http
      .post(API_ROUTES.emails.exchangeCalendarToken, {
        code: payload.code,
        success_url: payload.successUrl,
      })
      .then((response) => response.data),
  deleteCalendarAccount: (payload: void) =>
    http
      .delete(API_ROUTES.emails.deleteCalendarAccount)
      .then((response) => response.data),
};
