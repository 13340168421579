import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { trim } from "lodash";
import { ICalendarEvent, ICalendarState } from "./types";
import { getCalendarEvents } from "./actions";

const initialState: ICalendarState = {
  isGettingCalendarEvents: false,
  calendarEvents: [],
  activeCalendarEvent: null,
  isGettingCalendarAuthLink: false,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setActiveCalendarEvent: (
      state,
      action: PayloadAction<ICalendarEvent | null>
    ) => {
      state.activeCalendarEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCalendarEvents.pending, (state) => {
        state.isGettingCalendarEvents = true;
      })
      .addCase(getCalendarEvents.fulfilled, (state, action) => {
        state.isGettingCalendarEvents = false;

        state.calendarEvents = action.payload?.map((event) => {
          const participants = event.attendees
            ?.split(",")
            ?.map((entry) => trim(entry))
            ?.filter((entry) => !!entry);
          return { ...event, participants };
        });
      })
      .addCase(getCalendarEvents.rejected, (state) => {
        state.isGettingCalendarEvents = false;
      });
  },
});

export const { setActiveCalendarEvent } = calendarSlice.actions;

export default calendarSlice.reducer;
