import styles from './LoginLayout.module.scss';
import { ReactNode } from 'react';
import { Brain, Logo } from 'icons';
import classnames from 'classnames';

interface IProps {
  header?: ReactNode | string;
  info?: ReactNode | string;
  content?: ReactNode | string;
  containerClassName?: string;
}

export const LoginLayout = ({
  header = (
    <>
      <Brain />
      <Logo />
    </>
  ),
  info = <></>,
  content = <></>,
  containerClassName
}: IProps) => {
  return (
    <div className={classnames(styles.Container, containerClassName)}>
      <div className={styles.Header}>
        {header}
      </div>
      <div className={styles.Info}>
        {info}
      </div>
      <div className={styles.Content}>
        {content}
      </div>
    </div>
  );
}
