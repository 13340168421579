import { useTranslation } from 'hooks'
import { cn } from 'lib/utils'
import Dropzone, {
  IDropzoneProps, IPreviewProps, ILayoutProps, ISubmitButtonProps, formatBytes, formatDuration, IFileWithMeta, StatusValue, IInputProps
} from 'react-dropzone-uploader'
import { Loading } from 'components/layout-atoms/Loading'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { LabelDiv } from 'components/ui/label'
import { Fragment } from 'react'
import { Card, CardHeader } from 'components/ui/card'
import { CardDescription } from 'components/ui/card'
import { FileImageIcon, FileTextIcon, X } from 'lucide-react'
import { Badge } from 'components/ui/badge'

const LayoutComponent = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
  return (
    <div className="flex flex-col gap-4">
      {files.length < maxFiles && (
        <div {...dropzoneProps}>
          {input}
        </div>
      )}

      {previews && previews.length > 0 && (
        <div className="flex flex-col gap-3">
          {previews}
        </div>
      )}

      {submitButton}
    </div>
  )
}

interface IInputContent {
  isLoading?: boolean
  hasFiles?: boolean
  titleNoDocs?: string;
  titleWithDocs?: string;
  descriptionNoDocs?: string;
  descriptionWithDocs?: string;
}

const InputContent = ({ isLoading, hasFiles, titleNoDocs, titleWithDocs, descriptionNoDocs, descriptionWithDocs
}: IInputContent) => {
  const { t } = useTranslation();

  return (
    <div className='relative w-full text-center'>
      <div className={cn("space-y-1.5", { "invisible": isLoading })}>
        <LabelDiv>
          {!hasFiles ? titleNoDocs || t('component.FileUploader.InputContent.titleNoDocs') : titleWithDocs || t('component.FileUploader.InputContent.titleWithDocs')}
        </LabelDiv>
        <LabelDiv variant="description">
          {!hasFiles ? descriptionNoDocs || t('component.FileUploader.InputContent.descriptionNoDocs') : descriptionWithDocs || t('component.FileUploader.InputContent.descriptionWithDocs')}
        </LabelDiv>
      </div>
      {isLoading && <Loading className="absolute inset-0 flex items-center justify-center z-10" iconClassName="text-inherit" />}
    </div>
  )
}

const Preview = (props: IPreviewProps) => {
  const { t } = useTranslation();

  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: { name = '', percent = 0, size = 0, previewUrl, status, duration, validationError },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    extra: { minSizeBytes },
  } = props

  const fileName = name || '?';
  const fileSize = formatBytes(size);
  // const fileDuration = duration && isFinite(duration) ? formatDuration(duration) : '';

  let displayTitle = fileName;
  let errorMessage = '';

  if (status === 'error_file_size') {
    errorMessage = t(size < minSizeBytes ? 'component.FileUploader.Preview.fileTooSmall' : 'component.FileUploader.Preview.fileTooBig');
  }
  if (status === 'error_validation') {
    errorMessage = String(validationError);
  }

  if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
    errorMessage = t('component.FileUploader.Preview.uploadFailed');
  }

  if (status === 'aborted') {
    errorMessage = t('component.FileUploader.Preview.uploadCancelled');
  }

  return (
    <div className={className} style={style}>
      <Card variant="item">
        <CardHeader className="p-0">
          <CardDescription className="text-sm flex gap-3 justify-between group">
            <div className='flex gap-3 items-center p-3'>
              {previewUrl ? <FileImageIcon className="size-4" /> : <FileTextIcon className="size-4" />}
              <span>{displayTitle}</span>
              {/* <span className='opacity-60'>({fileSize})</span> */}
              {
                errorMessage && <span>
                  <Badge variant="destructive">{errorMessage}</Badge>
                </span>
              }
            </div>

            <Button
              variant="destructive"
              className="rounded-l-none px-2 hidden group-hover:flex"
              onClick={remove}
            >
              <X className='size-6' />
            </Button>
          </CardDescription>
        </CardHeader>
      </Card>

      {/* <div className="dzu-previewStatusContainer">
        {isUpload && (
          <progress max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent} />
        )}

        {status === 'uploading' && canCancel && (
          <span className="dzu-previewButton" onClick={cancel}><Pause /></span>
        )}
        {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
          <span className="dzu-previewButton" onClick={remove}>
            <Close />
          </span>
        )}
        {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
          canRestart && <span className="dzu-previewButton" onClick={restart}><Loader /></span>}
      </div> */}
    </div>
  )
}

const SubmitButton = (props: ISubmitButtonProps) => {
  const { disabled, content, onSubmit, files } = props

  const _disabled =
    files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
    !files.some(f => ['headers_received', 'done'].includes(f.meta.status))
  const isDisable =
    files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
    !files.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))

  const handleSubmit = () => {
    onSubmit(files.filter(f => ['headers_received', 'done'].includes(f.meta.status)))
  }

  return (
    !!onSubmit ? (
      <Button onClick={handleSubmit} disabled={disabled || isDisable} >
        {content}
      </Button>
    ) : (
      <Fragment></Fragment>
    )
  )
}

const NoSubmitButton = (props: ISubmitButtonProps) => {
  return (
    <Fragment></Fragment>
  )
}

const InputComponent = (props: IInputProps) => {
  const {
    className,
    labelClassName,
    labelWithFilesClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    content,
    withFilesContent,
    onFiles,
    files,
  } = props

  return (
    <label
      className={
        cn(
          "relative flex flex-col items-center justify-center w-full p-6 border-2 border-input border-dashed rounded-lg transition-colors cursor-pointer bg-gray-50 hover:bg-gray-100 ",
          {
            [labelWithFilesClassName || '']: files?.length > 0,
            [labelClassName || '']: files?.length === 0,
          }
        )
      }
      style={files.length > 0 ? labelWithFilesStyle : labelStyle}
    >
      {files.length > 0 ? withFilesContent : content}
      <Input
        className={cn('hidden', className)}
        style={style}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async e => {
          const target = e.target
          const chosenFiles = await getFilesFromEvent(e)
          onFiles(chosenFiles)
          //@ts-ignore
          target.value = null
        }}
      />
    </label>
  )
}


interface IProps extends Partial<IDropzoneProps> {
  onSubmit?(successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]): void,
  isLoading?: boolean;
  showSubmitButton?: boolean;
  onChangeStatus?(file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]): void,
  titleNoDocs?: string;
  titleWithDocs?: string;
  descriptionNoDocs?: string;
  descriptionWithDocs?: string;
}

export const FileUploader = ({
  onSubmit, onChangeStatus, isLoading = false, showSubmitButton = true, ...rest
}: IProps) => {
  const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    if (onSubmit) {
      onSubmit(files, allFiles)
    }
  }

  return (
    <Dropzone
      classNames={{ dropzone: "", inputLabelWithFiles: "", inputLabel: "", preview: "" }}
      LayoutComponent={LayoutComponent}
      inputContent={<InputContent isLoading={isLoading} hasFiles={false} {...rest} />}
      inputWithFilesContent={<InputContent isLoading={isLoading} hasFiles={true} {...rest} />}
      onChangeStatus={onChangeStatus}
      onSubmit={handleSubmit}
      InputComponent={InputComponent}
      PreviewComponent={Preview}
      SubmitButtonComponent={showSubmitButton ? SubmitButton : NoSubmitButton}
      autoUpload={false}
      disabled={isLoading}
      {...rest}
    />
  )
}

