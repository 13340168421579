import { useTranslation } from 'hooks';
import { ROUTES } from 'routing/constants';
import { SideMenuSubLayout } from 'components/layout/SideMenuSubLayout';

export const AdminSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <SideMenuSubLayout
      menuItems={[
        {
          label: t("page.AdminSettingsPage.userManagement"),
          to: ROUTES.ADMIN.SETTINGS.USER_MANAGEMENT,
        },
        {
          label: t("page.AdminSettingsPage.organizationManagement"),
          to: ROUTES.ADMIN.SETTINGS.ORGANIZATION_MANAGEMENT,
        },
        {
          label: t("page.AdminSettingsPage.templatesManagement"),
          to: ROUTES.ADMIN.SETTINGS.TEMPLATES_MANAGEMENT,
        },
      ]}
    />
  );
};

