import {
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ForwardedRef,
} from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';

export const INPUT_TYPE = {
  STANDARD: 'standard',
  MINIMALIST: 'minimalist'
} as const;
export type TInputType = typeof INPUT_TYPE[keyof typeof INPUT_TYPE];

export interface IInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | boolean;
  formed?: boolean;
  wrapperClassName?: string;
  placeholder?: string;
  lookType?: TInputType;
  isProcessing?: boolean;
}

export const Input = forwardRef(
  (
    { error, formed, className, wrapperClassName = '', placeholder, isProcessing, lookType = INPUT_TYPE.STANDARD, ...rest }: IInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (lookType === INPUT_TYPE.MINIMALIST) {
      return (
        <input
          placeholder={placeholder}
          ref={ref}
          disabled={isProcessing || rest.disabled}
          className={classnames(styles.InputMinimalist, {
            [styles.InputMinimalistError]: error,
            [styles.InputMinimalistProcessing]: isProcessing,
            className
          },
            className)}
          {...rest}
        />
      )
    }


    return (
      <div
        className={classnames(styles.InputWrapper, {
          [styles['InputWrapper--formed']]: formed,
          [wrapperClassName]: !!wrapperClassName,
        })}
      >
        <input
          ref={ref}
          placeholder={placeholder}
          className={classnames(styles.Input, className, {
            [styles['Input--error']]: error,
          })}
          {...rest}
        />
        {error && <div className={styles.Error}>{error}</div>}
      </div>
    );
  },
);
