import { ReactNode } from 'react';

import styles from './Page.module.scss';

interface IPageProps {
  children?: ReactNode | undefined;
}

export const Page = ({ children }: IPageProps) => {
  return <div className={styles.Page}>{children}</div>;
};
