import { useTranslation } from 'hooks';
import { cn } from 'lib/utils';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

interface IProps extends DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> {
  text?: string | null;
  email: string,
  subject: string;
  body?: string | null;
}

export const ContactUs = ({
  email, subject, body = "", text, className, ...rest
}: IProps) => {
  const { t } = useTranslation();

  return (
    <a
      className={cn("underline", className)}
      href={`mailto:${email}?subject=${subject}&body=${body}`}
      title={text || t('general.contactUs') as string}
      rel="noreferrer"
      id="link"
      {...rest}
    >
      {text || t('common.contactUs')}
    </a>
  );
};
