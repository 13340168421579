import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';

import { LoadingRow } from '../LoadingRow';

import styles from './Button.module.scss';

export enum BUTTON_LOOK {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERNARY = "ternary",
  OUTLINE = "outline",
};


interface IButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  look?: BUTTON_LOOK
}

export const Button = ({ children, loading, look, className, disabled, ...restProps }: IButtonProps) => {
  return (
    <button
      {...restProps}
      className={classnames(styles.Button, styles[`Button--${look}`], {
        [styles['Button--loading']]: loading,
      }, className)}
      disabled={loading || disabled}
    >
      <span>{loading ? <LoadingRow /> : children}</span>
    </button>
  );
};
