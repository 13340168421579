import { useTranslation } from 'hooks';
import { StepperBox, StepperTitle } from 'components_old';
import { WIZARD_HOW_TO_SHARE_FILE } from 'store/analysis';
import { IStepperComponentProps } from '../types';

export const HowToShareFile = ({ handleGoToNextStep, nextSteps }: IStepperComponentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepperTitle>
        <div>{t('analysis.howToShareFile.titleA')}</div>
        <div>{t('analysis.howToShareFile.titleB')}</div>
      </StepperTitle>
      <StepperBox
        title={t('analysis.howToShareFile.upload.title')}
        content={t('analysis.howToShareFile.upload.content')}
        onClick={() => handleGoToNextStep(
          nextSteps?.[0] , { access_type: WIZARD_HOW_TO_SHARE_FILE.upload }
        )}
      />
      <StepperBox
        title={t('analysis.howToShareFile.link.title')}
        content={t('analysis.howToShareFile.link.content')}
        onClick={() => handleGoToNextStep(
          nextSteps?.[1], { access_type: WIZARD_HOW_TO_SHARE_FILE.link }
        )}
      />
    </>
  );
}
