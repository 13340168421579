import { i18n } from "i18n";

export enum CopilotTemplateType {
  DISCOVERY_MEETING = "discoveryMeeting",
  CLIENT_MEETING = "clientMeeting",
  FACT_FINDER = "factFinder",
  DEMO_CALL = "demoCall",
  DEFAULT = "default",
}

export interface ICopilotTemplate {
  label: string;
  dataPoints: string[];
  questions: string[];
}

export const copilotStaticTemplates: Record<
  CopilotTemplateType,
  ICopilotTemplate
> = {
  [CopilotTemplateType.DISCOVERY_MEETING]: {
    label: i18n.t("page.ConfigureCopilotPage.templates.discoveryMeeting"),
    dataPoints: [
      "Legal name",
      "Date of birth",
      "Legal address",
      "Email address",
      "Phone numbers",
      "Preferred method of contact",
      "Marital status",
      "Number of children and grandchildren",
      "Dependents' names and ages",
      "Current annual income",
      "Current annual savings",
      "Current annual expenses",
      "Planned one-time future expenses",
      "Desired retirement age and living expenses",
      "Other specific financial objectives",
      "Investment experience and knowledge level",
      "Current investment portfolio overview",
      "Risk tolerance levels from the risk tolerance questionnaire",
      "Client's availability for a follow-up meeting",
    ],
    questions: [
      "Could you please provide your full legal name, date of birth, and contact details, including your preferred method of communication?",
      "Can you tell us a bit about your family structure, such as marital status and any dependents?",
      "What are your short-term and long-term financial goals?",
      "Are there any significant future expenses, like education for your children or buying a home, that we should be aware of?",
      "How would you describe your risk tolerance and investment experience?",
      "Are you currently working towards any specific savings or investment goals?",
      "Would you like me to explain the specific services we offer that might help you achieve your financial goals?",
      "If you feel comfortable with the initial information and services discussed, can we schedule a follow-up proposal meeting to delve deeper into a customized financial/investment plan?",
      "Any specific concerns or areas you would like to focus on in the next meeting?",
    ],
  },
  [CopilotTemplateType.CLIENT_MEETING]: {
    label: i18n.t("page.ConfigureCopilotPage.templates.clientMeeting"),
    dataPoints: ["Referrals"],
    questions: [
      "Let’s review your current investments and financial plan. Are there any areas where you’ve seen challenges or wish to adjust our strategy?",
      "How do you feel about the progress towards your financial goals based on the latest reports and analyses?",
      "How has your onboarding experience been so far? Have you encountered any difficulties with logging in, accessing information, or understanding your first statements?",
      "Do you need further clarification on how to use our client support resources or where to find specific information?",
      "In our conversations, you’ve mentioned your family and friends. Do you think there are people in your circle who might benefit from discussing their financial plans with us?",
      "Considering your positive experiences and the goals we are achieving, would you feel comfortable introducing us to someone who could also benefit from our services?",
      "Is there anything specific you would like to discuss or add to our agenda for the next meeting?",
      "How frequently would you like to have these reviews, and are there any particular times that work best for you?",
    ],
  },
  [CopilotTemplateType.FACT_FINDER]: {
    label: i18n.t("page.ConfigureCopilotPage.templates.factFinder"),
    dataPoints: [
      "Current annual income",
      "Current annual savings",
      "Current annual expenses",
      "Planned one-time future expenses",
      "Desired retirement age and living expenses",
      "Other specific financial objectives",
      "Marital Status",
      "Spouse Name",
      "Number of children and grandchildren",
      "Dependents' names and ages",
      "Legal name",
      "Date of birth",
      "Legal address",
      "Email address",
      "Phone numbers",
      "Preferred method of contact",
    ],
    questions: [
      "So what’s on your mind? And what else?",
      "You mentioned your spouse…Tell me about your family.",
      "Retirement? That’s exciting! What do you think you’ll be able to do in retirement that you can’t do now?",
      "Can you think of any other tangible goals that will require money and planning to achieve?",
      "What would a really bad retirement look like to you?",
      "Have you tried anything so far to accomplish these things? [goals from previous answers]",
      "Curious Tone: Can you maybe go over with me what you’re looking for when you’re choosing a financial advisor to work with? ",
      "Are there any worries or concerns that come to mind that you’d like to make sure you avoid?",
      "Identify their problem, repeat back to them, find their pain. What’s stopping them from their ideal future?",
      "Can you walk me through your decision making process when it comes the financial part of life?",
      "I think I’ll be able to help with [goals and values]....Do you mind if I ask some questions about your finances?",
      "Tell me about what you have saved between your different taxable buckets",
      "When it comes to investments, do you know how you’re invested now?",
      "When it comes to your income plan, have you mapped out a strategy for how much to take from each account and when?",
      "Other Planning Topics",
      "Can I ask you a non-financial question before we get off the phone?",
      "Family, Faith, Fitness, Finances, Fun",
    ],
  },
  [CopilotTemplateType.DEMO_CALL]: {
    label: i18n.t("page.ConfigureCopilotPage.templates.demoCall"),
    dataPoints: [
      "Employer",
      "Job title",
      "Marital status",
      "Spouse name",
      "Children",
      "Children’s names",
      "Monthly salary / Gross income",
      "Emergency fund",
    ],
    questions: [
      "What is X’s financial goal for 2024?",
      "What’s a purchase that X has made that he’s still happy with?",
    ],
  },
  [CopilotTemplateType.DEFAULT]: {
    label: i18n.t("page.ConfigureCopilotPage.templates.default"),
    dataPoints: [
      "Employer",
      "Job Title",
      "Marital Status",
      "Spouse Name",
      "Spouse Birth Date",
      "Birth Date",
      "Monthly Salary / Gross Income",
      "Housing",
      "Emergency fund",
      "Next meeting date",
    ],
    questions: [
      // "What is X’s financial goal for 2024?",
      // "What’s a purchase that X has made that he’s still happy with?",
    ],
  },
};
