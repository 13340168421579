import { createSlice } from "@reduxjs/toolkit";
import { INylasState } from "./types";
import { deleteCalendarAccount, exchangeCalendarCodeFromUrlForToken, exchangeCodeFromUrlForToken, generateAuthUrl, generateCalendarAuthUrl } from "./actions";

const initialState: INylasState = {
  isNylasProcessing: false,
  nylasAuthUrl: "",
  calendarAuthUrl: "",
  nylasConnectionEstablished: false,
  calendarConnectionEstablished: false,
};

const nylasSlice = createSlice({
  name: "nylas",
  initialState,
  reducers: {
    clearNylasAuthUrlValue: (state) => {
      state.nylasAuthUrl = "";
      state.calendarAuthUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateAuthUrl.pending, (state) => {
        state.isNylasProcessing = true;
      })
      .addCase(generateAuthUrl.fulfilled, (state, action) => {
        state.isNylasProcessing = false;
        state.nylasAuthUrl = action.payload;
      })
      .addCase(generateAuthUrl.rejected, (state) => {
        state.isNylasProcessing = false;
      })
      .addCase(generateCalendarAuthUrl.pending, (state) => {
        state.isNylasProcessing = true;
      })
      .addCase(generateCalendarAuthUrl.fulfilled, (state, action) => {
        state.isNylasProcessing = false;
        state.calendarAuthUrl = action.payload;
      })
      .addCase(generateCalendarAuthUrl.rejected, (state) => {
        state.isNylasProcessing = false;
      })
      .addCase(exchangeCodeFromUrlForToken.pending, (state) => {
        state.isNylasProcessing = true;
      })
      .addCase(exchangeCodeFromUrlForToken.fulfilled, (state, action) => {
        state.isNylasProcessing = false;
        state.nylasConnectionEstablished = true;
      })
      .addCase(exchangeCodeFromUrlForToken.rejected, (state) => {
        state.isNylasProcessing = false;
        state.nylasConnectionEstablished = false;
      })
      .addCase(exchangeCalendarCodeFromUrlForToken.pending, (state) => {
        state.isNylasProcessing = true;
      })
      .addCase(exchangeCalendarCodeFromUrlForToken.fulfilled, (state, action) => {
        state.isNylasProcessing = false;
        state.calendarConnectionEstablished = true;
      })
      .addCase(exchangeCalendarCodeFromUrlForToken.rejected, (state) => {
        state.isNylasProcessing = false;
        state.calendarConnectionEstablished = false;
      })
      .addCase(deleteCalendarAccount.pending, (state) => {
        state.isNylasProcessing = true;
      })
      .addCase(deleteCalendarAccount.fulfilled, (state, action) => {
        state.isNylasProcessing = false;
        state.calendarConnectionEstablished = false;
      })
      .addCase(deleteCalendarAccount.rejected, (state) => {
        state.isNylasProcessing = false;
      });
  },
});

export const { clearNylasAuthUrlValue } = nylasSlice.actions;

export default nylasSlice.reducer;
