import { useAppDispatch, useTranslation } from 'hooks';
import { FileUploader, StepperTitle } from 'components_old';
import { IFileWithMeta } from 'react-dropzone-uploader'
import { IStepperComponentProps } from '../../types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { IUploadFilesResponse, selectAnalysisUploadingFiles, uploadAnalysisFiles } from 'store/analysis';


export const ISDataEntryUploader = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isProcessing = useSelector(selectAnalysisUploadingFiles);
  const [apiCalled, setApiCalled] = useState(false)
  const [link, setLink] = useState('')

  useEffect(() => {
    if (apiCalled && nextStep && link) {
      handleGoToNextStep({ action: nextStep, payload: { google_drive_link: link } })
    }
  }, [apiCalled, handleGoToNextStep, nextStep, link])

  const doISDataExtractionUpload = async (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    const formData = new FormData();
    allFiles?.forEach(item => {
      formData.append('files', item.file);
    })

    try {
      setApiCalled(true);
      // const uploadResponse = await dispatch(uploadAnalysisFiles(formData));
      const uploadResponse = { payload: { link: 'the- link' } };

      if (!uploadResponse.hasOwnProperty('error')) {
        setLink((uploadResponse?.payload as IUploadFilesResponse)?.link);
      }
    } catch (error) {
      setApiCalled(false);
    }
  }

  return (
    <>
      <StepperTitle>
        <div>{t('dataExtractor.uploader.titleA')}</div>
        <div>{t('dataExtractor.uploader.titleB')}</div>
      </StepperTitle>

      <FileUploader
        submitButtonContent={t('dataExtractor.uploader.extractData')}
        onSubmit={doISDataExtractionUpload}
        maxFiles={1}
        multiple={false}
        isLoading={isProcessing}
      />
    </>
  );
}

