export const ENV = {
  DEV: "dev",
  PROD: "prod",
};

export const REPLY_TYPE = {
  REPLY: "REPLY",
  REPLY_TO_ALL: "REPLY_TO_ALL",
} as const;

export enum RoleEnum {
  None = "none",
  Admin = "admin",
  Advisor = "advisor",
  Paraplanner = "paraplanner",
}

export const FIXED_PANEL_WIDTH = 376;
export const THREAD_LIST_LIMIT = 50;
export const PARAPLANNER_LINK = "https://www.paraplanner.ai/";

export enum EmailType {
  gmail = "gmail",
  outlook = "ews",
}

export enum THREAD_LABEL {
  PARAPLANNER = "Paraplanner",
  SKIPPED = "Skipped",
  ENHANCED_BY_AI = "EnhancedByAI",
  DO_NOT_PROCESS = "DoNotProcess",
}

export const TOOLTIP_ID = "paraplanner_tip";

export enum MAILBOX_TYPE {
  INBOX = "inbox",
  DRAFTS = "drafts",
  ARCHIVED = "archived",
  CONVERSATIONS = "conversations",
  SEARCH = "search",
}

export const GENERAL_CONVERSATION_ID = "general_conversation_thread";

export enum LINKS {
  TERMS_AND_CONDITIONS = "https://docs.google.com/document/d/1ux_Fa1C688s_Q8knfiDp9lQuTS0-tQyP",
  PRIVACY_POLICY = "https://docs.google.com/document/d/1XzDV4J_Vl85oh5fMuuhMPARfnGgEV4u8",
  BETA_AGREEMENT = "https://docs.google.com/document/d/1SP1DgkrkImJ5VWE_phvfG8Q4aLk8BdBK0TLz8noOtXE/edit#heading=h.i6bwkl19pau",
  BOOK_A_CALL = "https://calendly.com/deniskonoplev/muninai",
}

export const INTRO_VIDEO_ID = "a5XFzg-pzOM";

export const LOCAL_STORAGE = {
  CONSENT_GIVEN: "consentGiven",
  BETA_TESTING: "betaTestingConsentGiven",
  NOTETAKER_NAME: "notetakerName"
};
