import { ReactNode, useRef } from 'react';
import classnames from 'classnames';

import { useClickOutside } from 'hooks';

import styles from './Modal.module.scss';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal = ({ children, open, onClose }: IModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useClickOutside(modalRef, () => {
    onClose();
  });

  return (
    <>
      <div
        className={classnames(styles.Background, {
          [styles['Background--open']]: open,
        })}
      >
        &nbsp;
      </div>
      <section
        className={classnames(styles.Modal, {
          [styles['Modal--open']]: open,
        })}
        ref={modalRef}
      >
        {children}
      </section>
    </>
  );
};
