import { Option } from "components/ui/multi-select";
import { z } from "zod";
import { t } from "i18next";
import { IDataPoint } from "store/data-points";

export interface ITemplatesState {
  activeTemplate: IMeetingTemplate | undefined;
  activeAdminTemplate: IAdminMeetingTemplate | undefined;
  meetingTemplates: ITemplatesList;
  allAdminTemplates: IAdminMeetingTemplate[];
  isGettingTemplates: boolean;
  isCreatingTemplate: boolean;
  isUpdatingTemplate: boolean;
  isDeletingTemplate: boolean;
}

export interface ITemplatesList {
  myTemplates: IMeetingTemplate[];
  orgTemplates: IMeetingTemplate[];
}

export interface IMeetingTemplate {
  id: string;
  name: string;
  sections: IMeetingQuestionsSection[];
  dataPoints: IDataPoint[];
  dataPointIds: string[];
  organizationId?: string;
  organizationName?: string;
  userEmail?: string;
  templateType?: string;
}
export interface IAdminMeetingTemplate extends IMeetingTemplate {
  userEmail: string;
  organizationName: string;
  templateType: TemplateTypeEnum;
  totalNoQuestions?: number;
  totalNoPrompts?: number;
  promptsLeftToFill?: number;
}

export enum TemplateTypeEnum {
  ORG = "organization",
  PERSONAL = "personal",
}

export interface ITemplateManagementViewPayload {
  orgView?: boolean;
}

export interface IMeetingTemplateFormData extends IMeetingTemplate {
  formSelectedTemplateId: string;
  formSelectedDataPointOptions: Option[];
}

export interface IMeetingQuestionsSection {
  id?: string;
  name: string;
  questions: IMeetingQuestionItem[];
}

export interface IMeetingQuestionItem {
  id?: string;
  text: string;
  prompt?: string;
}


const questionItemSchema = z.object({
  id: z.string().optional(),
  text: z.string().min(1, "Question text is required"),
  prompt: z.string().nullish().optional(),
});

const questionsSectionSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, t("errors.required") as string),
  questions: z.array(questionItemSchema).optional(),
});

export const templateFormSchema = z.object({
  name: z.string().min(1, t("errors.required") as string),
  sections: z
    .array(questionsSectionSchema)
    .optional()
    .refine(
      (sections) => {
        if (!sections) return true;
        return sections.every((section) => {
          if (!section.questions || section.questions.length === 0) return true;
          return section.name.length > 0;
        });
      },
      {
        message: t("errors.required") as string,
        path: ["sections"],
      }
    ),
});

export enum TemplateManagementTypeEnum {
  PERSONAL = "personal",
  ORGANIZATION = "organization",
}
