import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IAutomationsApi } from "./types";

export const automations: IAutomationsApi = {
  createInvestmentStatementExtractionWithHumanCheckRequest: (payload) =>
    http
      .post(API_ROUTES.automations.createInvestmentStatementExtractionWithHumanCheckRequest, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      })
      .then((response) => response.data),
};
