import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import GoogleCalendarIcon from 'images/google_calendar.png';
import OutlookIcon from 'images/outlook_logo.png';
import { Loading } from 'components/layout-atoms/Loading';
import { clearNylasAuthUrlValue, exchangeCalendarCodeFromUrlForToken, generateCalendarAuthUrl, selectCalendarAuthUrl, selectCalendarConnectionEstablished, selectIsNylasProcessing } from 'store/nylas';
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import { CALENDAR_CONNECT_SUCCESS_URL } from 'const';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ConfigureConnectCalendar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isRedirecting, setIsRedirecting] = useState(false)

  const isProcessing = useAppSelector(selectIsNylasProcessing);
  const profileDetails = useAppSelector(selectProfileDetails)
  const calendarAuthUrl = useAppSelector(selectCalendarAuthUrl)
  const calendarConnectionEstablished = useAppSelector(selectCalendarConnectionEstablished)

  // Redirect to Nylas to connect calendar on their side
  useEffect(() => {
    if (calendarAuthUrl) {
      const redirectUrl = calendarAuthUrl;
      setIsRedirecting(true)
      dispatch(clearNylasAuthUrlValue());
      window.location.href = redirectUrl;
    }
  }, [calendarAuthUrl, dispatch])

  // Handle the code that is passed in the query params from Nylas after a successful login
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      params.delete('code');
      setSearchParams(params);

      dispatch(exchangeCalendarCodeFromUrlForToken({ code, successUrl: CALENDAR_CONNECT_SUCCESS_URL }));
    }

    return () => {
      setIsRedirecting(false)
    }
  }, []);

  useEffect(() => {
    if (calendarConnectionEstablished) {
      dispatch(getProfileDetails())
    }
  }, [calendarConnectionEstablished]);

  const generateAuthUrl = () => {
    dispatch(generateCalendarAuthUrl({
      successUrl: CALENDAR_CONNECT_SUCCESS_URL
    }));
  };

  return (
    <Card>
      <CardHeader className="">
        <CardTitle className="">
          {t('component.ConfigureConnectCalendar.title')}
        </CardTitle>
        <CardDescription>
          {t('component.ConfigureConnectCalendar.description')}
        </CardDescription>
      </CardHeader>

      <CardContent className='flex gap-2 justify-between items-center min-h-28'>
        {(isProcessing || isRedirecting) ? (
          <Loading variant="centered" className='min-h-20zz' />
        ) : (
          <>
            <Button
              type='button'
              variant="outline"
              className='w-full px-1'
              onClick={generateAuthUrl}
            >
              <div className='flex flex-col items-center'>
                <img alt="avatar" src={OutlookIcon} className='size-8 m-1' />
                <span className='text-sm'>Outlook</span>
              </div>
            </Button>

            <Button
              type='button'
              variant="outline"
              className='w-full px-1'
              onClick={generateAuthUrl}
            >
              <div className='flex flex-col items-center'>
                <img alt="avatar" src={GoogleCalendarIcon} className='size-10' />
                <span className='text-sm'>Google Calendar</span>
              </div>
            </Button></>
        )}

      </CardContent>
    </Card>
  )
}
