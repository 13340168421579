import { Fragment, useEffect } from 'react';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { MIXPANEL } from 'const';
import { v4 as uuidv4 } from 'uuid';
import { createDataPoint, IDataPoint, selectIsDataPointProcessing, updateDataPoint } from 'store/data-points';

interface IProps {
  dataPoint?: IDataPoint;
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
}

export const CreateEditDataPointModal = ({ dataPoint, isVisible, setIsVisible }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isProcessing = useSelector(selectIsDataPointProcessing);

  const formSchema = z.object({
    id: z.string().optional(),
    name: z.string().min(1, t('errors.required') || ''),
  });

  const form = useForm<Required<IDataPoint>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: dataPoint || {
      id: uuidv4(),
      name: '',
    }
  });

  useEffect(() => {
    form.reset(
      dataPoint || {
        id: uuidv4(),
        name: '',
      }
    )
  }, [dataPoint, form])

  const handleOnSubmit = async (values: IDataPoint) => {
    const response = dataPoint ? await dispatch(updateDataPoint(values)) : await dispatch(createDataPoint({
      ...values,
      id: uuidv4(),
    }));

    if (createDataPoint.fulfilled.match(response) || updateDataPoint.fulfilled.match(response)) {
      form.reset();
      setIsVisible(false);
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CARDS.CREATE_CARD })
      toast(t(dataPoint ? 'component.CreateEditDataPointModal.update.success' : 'component.CreateEditDataPointModal.create.success'))
    }
  }

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="max-w-lg overflow-auto max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>
              {t(dataPoint ? 'component.CreateEditDataPointModal.update.title' : 'component.CreateEditDataPointModal.create.title')}
            </DialogTitle>
            <DialogDescription>
              {t(dataPoint ? 'component.CreateEditDataPointModal.update.description' : 'component.CreateEditDataPointModal.create.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="id"
                render={({ field }) => (
                  <FormItem className='hidden'>
                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} className='hidden' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.CreateEditDataPointModal.name')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.CreateEditDataPointModal.nameDesc')}
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button type="submit" loading={isProcessing}>
                  {t(dataPoint ? 'component.CreateEditDataPointModal.update.actionBtn' : 'component.CreateEditDataPointModal.create.actionBtn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
