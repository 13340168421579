import { cn } from 'lib/utils';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

interface IProps {
  markdown?: string,
  className?: string,
}

export const MarkdownRenderer = ({ markdown, className }: IProps) => {
  const [cleanedMarkdown, setCleanedMarkdown] = useState('');

  useEffect(() => {
    setCleanedMarkdown((markdown || '')?.replace(/\n\s+/g, '\n'))
  }, [markdown])


  return (
    <ReactMarkdown
      children={cleanedMarkdown}
      remarkPlugins={[remarkBreaks]}
      className={cn('', className)}

      components={{
        p: ({ node, ...props }) => {
          const defaultClassName = node?.position?.start?.line === 1 && node?.position?.start?.column === 1 ? "" : "mt-4";
          return <p className={defaultClassName} {...props} />;
        },
        ul: ({ node, ...props }) => <ul className="list-disc list-inside my-0" {...props} />,
      }}
    />
  );
};
