import { ChatType, MessageType } from "components_old/shared";

import { IMessage } from "./types";

import brainFileSrc from "images/brain.png";
const denisAvatar =
  "https://media.licdn.com/dms/image/D5603AQEGAeMBMboZQQ/profile-displayphoto-shrink_200_200/0/1678221108743?e=1688601600&v=beta&t=vfv97yRfj4YQjmmyT04d0dJhJnxIJRb7csTyoX3q2SU";

export const messages: IMessage[] = [
  {
    id: "1",
    avatar: denisAvatar,
    text: "Thanks for sending me the information! For the next step, it’s important to understand and agree some priorities. If you had to only pick 3 things to work on this year what would they be?",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.SENT,
    who: "paraplanner.ai",
  },
  {
    id: "2",
    who: "Advisor 2",
    text: "I’d like to retire at age 71, I want to make sure that my kids are looked after, and I’ve recently had a difficult time financially and used up my emergency funds so I need to make sure I can have money in case something unexpected comes up.",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.RECEIVED,
  },
  {
    id: "3",
    when: "Mar 3, 9:45 pm",
    avatar: denisAvatar,
    who: "paraplanner.ai",
    text: "Hi John, thank you! For the next step, I need to understand your current income and your assets so I can help you with your goals. Do you have a recent pay stub?",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.SENT,
  },
  {
    id: "4",
    who: "Advisor 2",
    text: "Sure, let me have a look if I can find it",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.RECEIVED,
  },
  {
    id: "5",
    avatar: brainFileSrc,
    text: "Thanks for sending me the information! For the next step, it’s important to understand and agree some priorities. If you had to only pick 3 things to work on this year what would they be?",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.SENT,
    who: "paraplanner.ai",
  },
  {
    id: "6",
    whoLetter: "W",
    text: "Sure, let me have a look if I can find it",
    chatType: ChatType.PARAPLANNER,
    messageType: MessageType.RECEIVED,
  },
];
