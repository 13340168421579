import { useState } from 'react';
import { Input } from 'components_old/shared';
import { ConversationInfoLayout } from 'components_old';
import { toast } from 'react-toastify';
import { Tag } from 'components_old/shared/Tag';

import styles from './MessageAddressList.module.scss';
import { IEmailRecipient } from 'store/mailbox';

interface IProps {
  label: string;
  recipients: IEmailRecipient[],
  updateRecipients?: (recipients: IEmailRecipient[]) => void,
}

export const MessageAddressList = ({
  label,
  recipients,
  updateRecipients,
}: IProps) => {
  const [emailInput, setEmailInput] = useState<string>('');
  const modeEdit = !!updateRecipients;

  const handleDeleteRecipient = (contact: IEmailRecipient) => {
    if (modeEdit) {
      updateRecipients(
        recipients?.filter(recipient => contact.email !== recipient.email)
      )
    }
  }

  const handleAddingRecipient = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && emailInput) {
      const re = /\S+@\S+\.\S+/;
      const isValid = re.test(emailInput);

      if (!isValid) {
        toast("The email address you entered is invalid!");
      } else {
        setEmailInput('')
        if (modeEdit) {
          updateRecipients([
            ...recipients,
            { email: emailInput, name: '' }
          ])
        }
      }
    }
  }

  return (
    <ConversationInfoLayout
      label={label}
      content={
        <div className={styles.Tags}>
          {recipients?.map(contact => (
            <Tag
              key={contact.email}
              text={contact.email}
              onClose={modeEdit ? () => { handleDeleteRecipient(contact) } : undefined}
            />
          ))}

          {modeEdit && (
            <Input
              formed
              wrapperClassName={styles.InputWrapper}
              className={styles.Input}
              placeholder='enter email and hit enter'
              value={emailInput}
              onChange={(e) => { setEmailInput(e.target.value) }}
              onKeyDown={handleAddingRecipient}
            />
          )}</div>
      }
    />
  );
}
