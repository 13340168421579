import classnames from 'classnames';

import styles from './Avatar.module.scss';

interface IAvatarProps {
  imgSrc?: string;
  letter?: string;
  size?: number;
  wrapperClassName?: string;
}

export const Avatar = ({ imgSrc, letter, size = 36, wrapperClassName }: IAvatarProps) => {
  return (
    <div
      className={classnames(styles.AvatarContainer, styles[`size-${size}`], { [styles.NoImage]: !imgSrc })}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <div
        className={classnames(styles.AvatarWrapper, wrapperClassName)}
        style={{ height: `${size}px`, width: `${size}px` }}
      >
        {imgSrc && <img alt="avatar" src={imgSrc} style={{ maxHeight: `${size}px` }} />}

        {!imgSrc && (
          <span className={classnames(styles.AvatarLetter)} style={{ fontSize: `${0.6 * size}px` }}>
            {letter?.substring(0, 1)?.toUpperCase()}
          </span>
        )}
      </div>
    </div>
  );
};
