import { useTranslation } from 'hooks';
import { Card, CardHeader, CardTitle, CardContent } from 'components/ui/card';
import { IMeetingTemplate } from 'store/templates-management';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { cn } from 'lib/utils';

interface IProps {
  template: IMeetingTemplate,
  className?: string,
  cardClassName?: string,
}

export const TemplateReadOnlyDisplay = ({ template, className, cardClassName }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('', className)}>
      <Card className={cn(cardClassName)}>
        <CardHeader>
          <CardTitle>
            {t('component.TemplateReadOnlyDisplay.agenda')}
          </CardTitle>
        </CardHeader>

        <CardContent className='flex flex-col gap-6'>
          {
            (!template?.sections || template?.sections?.length === 0) && (
              <InfoCard title={t('component.TemplateReadOnlyDisplay.agendaEmpty')} />
            )
          }

          {
            template?.sections?.map(section => {
              return (
                <div className='space-y-0' key={section.id}>
                  <div className="text-sm font-semibold">
                    {section.name}
                  </div>
                  <ul className="space-y-0.5 text-muted-foreground list-disc list-inside text-sm">
                    {section.questions?.map(question => (
                      <li key={question.id}>
                        {question.text}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            })
          }
        </CardContent>
      </Card>


      <Card className={cn(cardClassName)}>
        <CardHeader>
          <CardTitle>
            {t('component.TemplateReadOnlyDisplay.dataPoints')}
          </CardTitle>
        </CardHeader>

        <CardContent className='flex flex-col gap-3'>
          <ul className="space-y-0.5 text-muted-foreground list-disc list-inside text-sm">
            {
              template?.dataPoints?.map(dataPoint => {
                return (
                  <li key={dataPoint?.id}>
                    {dataPoint?.name}
                  </li>
                )
              })
            }

            {
              (!template?.dataPoints || template?.dataPoints?.length === 0) && (
                <InfoCard title={t('component.TemplateReadOnlyDisplay.dataPointsEmpty')} />
              )
            }
          </ul>
        </CardContent>
      </Card>
    </div>
  );
}
