import classnames from "classnames";
import { ReactNode } from "react";

interface IProps {
  speaker: ReactNode,
  children?: ReactNode | undefined;
  hidden?: boolean,
}

export const SpeakerBlock = ({ speaker, children, hidden = false }: IProps) => {

  return (
    <div className={
      classnames('w-full', { hidden })
    }>
      <div className='font-semibold text-sm'>{speaker}</div>
      <div className="space-y-1 font-normal text-sm">
        {children}
      </div>
    </div>
  )
}
