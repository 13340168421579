import { LoadingRow } from 'components_old/shared/LoadingRow';
import { ThreadPreview } from '../ThreadPreview/ThreadPreview';
// import { IEmailThread } from '../types';
import { useAppDispatch, useAppSelector, useIsInViewport, useTranslation } from 'hooks';
import { selectSelectedThread, selectThreadsListHasMoreData, selectThreadsListOffset, selectThreadListLoading, getThreadsList, selectThreadsListData, getArchivedThreadsList, getDraftsList, mailboxActions, getThreadsWithConversationList, searchThreadsList, ISearchThreadsListPayload, IGetThreadsListPayload, selectIsRefreshingMailbox, IEmailThread } from 'store/mailbox';
import { useEffect, useRef, useState } from 'react';
import { Feedback, Input } from 'components_old/shared';
import { ACTION } from 'const/actions';
import { MAILBOX_TYPE, THREAD_LIST_LIMIT } from 'const';
import { LineMarker } from 'components_old/shared/LineMarker';
import styles from './ThreadsList.module.scss';
import { selectConnectedEmailAuthId } from 'store/users';
import { selectProfileRole } from 'store/profile';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Close, Cross } from 'icons';
import classnames from 'classnames';

export interface IThreadsListProps {
  type?: MAILBOX_TYPE
}

export const ThreadsList = ({ type = MAILBOX_TYPE.INBOX }: IThreadsListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const threadsList = useAppSelector(selectThreadsListData) || [];

  const activeThread = useAppSelector(selectSelectedThread);
  const threadsListOffset = useAppSelector(selectThreadsListOffset);
  const threadsListHasMoreData = useAppSelector(selectThreadsListHasMoreData);
  const threadsListIsLoading = useAppSelector(selectThreadListLoading);
  const connectedEmailAuthId = useAppSelector(selectConnectedEmailAuthId);
  const profileRole = useAppSelector(selectProfileRole);
  const isRefreshingMailbox = useAppSelector(selectIsRefreshingMailbox);

  const [searchValue, setSearchValue] = useState("");
  const [threadCallType, setThreadCallType] = useState("");

  const loaderRef = useRef(null)
  const isInViewport = useIsInViewport(loaderRef);

  useEffect(() => {
    setThreadCallType(type);
  }, [type])

  useEffect(() => {
    if (isRefreshingMailbox || !([MAILBOX_TYPE.INBOX, MAILBOX_TYPE.SEARCH] as string[]).includes(threadCallType)) {
      setSearchValue('')
    }
  }, [threadCallType, isRefreshingMailbox])


  useEffect(() => {
    const initNewTab = async () => {
      if (!connectedEmailAuthId) return;
      if (threadCallType === MAILBOX_TYPE.SEARCH) return;

      const payload = { limit: THREAD_LIST_LIMIT, offset: 0 };

      await dispatch(mailboxActions.resetThreadsAndSelectedThread());

      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.CONVERSATIONS) {
        await dispatch(getThreadsWithConversationList(payload));
      }
    }

    initNewTab();
  }, [threadCallType, connectedEmailAuthId, dispatch])


  const debouncedGetData = debounce(async () => {
    if (searchValue) return;

    if (isInViewport && !threadsListIsLoading && threadsListOffset && threadsListHasMoreData) {
      const payload = { limit: THREAD_LIST_LIMIT, offset: threadsListOffset };


      if (threadCallType === MAILBOX_TYPE.INBOX) {
        await dispatch(getThreadsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.DRAFTS) {
        await dispatch(getDraftsList(payload));
      }
      if (threadCallType === MAILBOX_TYPE.ARCHIVED) {
        await dispatch(getArchivedThreadsList(payload));
      }

    }
  }, 1000);

  useEffect(() => {
    if (!connectedEmailAuthId) return;

    const getData = async () => {
      debouncedGetData();
    };

    getData();
  }, [isInViewport, threadsListOffset, threadsListHasMoreData, threadsListIsLoading, connectedEmailAuthId, dispatch, type, searchValue])

  useEffect(() => {
    return () => {
      debouncedGetData.cancel();
    };
  }, []);


  useEffect(() => {
    if (threadsList?.length && !activeThread) {

      const params = new URLSearchParams(window.location.search);
      const threadId = params.get('threadId');
      const threadFromParam = threadId ? threadsList?.find(thread => thread.thread_id === threadId) : undefined

      if (threadId) {
        params.delete('threadId');
        setSearchParams(params);
      }

      dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: threadFromParam })
    }
  }, [threadsList, activeThread, dispatch])

  const handleThreadSelect = (thread: IEmailThread) => {
    dispatch({ type: ACTION.MAILBOX_MIDDLEWARE.TRIGGER_SET_SELECTED_THREAD, payload: thread })
  };

  const handleSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && searchValue) {
      setThreadCallType(MAILBOX_TYPE.SEARCH)

      const payload = { limit: THREAD_LIST_LIMIT, q: searchValue };
      dispatch(searchThreadsList(payload));
    }
    if (e.key === 'Enter' && !searchValue) {
      setThreadCallType(MAILBOX_TYPE.INBOX)
    }
  }

  const handleClearSearch = async () => {
    setSearchValue('');
    setThreadCallType(MAILBOX_TYPE.INBOX)
  }

  return (
    <div className={styles.Wrapper}>
      {type === MAILBOX_TYPE.INBOX && (
        <div
          className={styles.SearchWrapper}>
          <Input
            placeholder={t('common.searchInEmails') as string}
            className={styles.Search}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleSearch}
          />
          {searchValue && (
            <Cross
              size={14}
              className={styles.Close}
              tooltip={t('common.clearSearch')}
              tooltipPlace='right'
              onClick={handleClearSearch}
            />
          )}

        </div>
      )}

      <div className={classnames({ [styles.ListContent]: type === MAILBOX_TYPE.INBOX })}>
        {!threadsListIsLoading && threadsList.length === 0 && (
          <Feedback>
            There are no email messages
          </Feedback>
        )}


        {threadsList?.filter(thread => {
          if (!thread.messages?.length && !thread.drafts?.length) return false;

          if (type === MAILBOX_TYPE.INBOX && thread?.labels?.length > 0) return false;
          if (type === MAILBOX_TYPE.ARCHIVED && thread?.labels?.length > 0) return false;

          return true;
        })?.map((thread) => (
          <div key={thread.thread_id} onClick={() => handleThreadSelect(thread)}>
            <ThreadPreview
              thread={thread}
              isActive={activeThread?.thread_id === thread.thread_id}
              type={type}
              profileRole={profileRole}
            />
          </div>
        ))}

        {threadsListIsLoading && <LoadingRow />}
        {!threadsListHasMoreData && !threadsListIsLoading && <LineMarker className={styles.LineMarker}>There are no more emails</LineMarker>}

        <div ref={loaderRef} className={styles.LoaderRef}>&nbsp;</div>
      </div>
    </div>
  );
};

