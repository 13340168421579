import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IDraftComposer, IEmail, IEmailRecipient, IUploadedFile, mailboxActions, selectDeletingDraft, selectDeletingFiles, selectDownloadingFile, selectUpdatingDraft, selectUploadingFiles } from 'store/mailbox';
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import { REPLY_TYPE } from 'const';
import { BUTTON_LOOK, Button, Input } from 'components_old/shared';
import classnames from 'classnames';
import { Delete, Clip, ThreeDots, Close } from 'icons';
import { convertFileSize, getMessageMeta } from 'utils/mailbox';

import styles from './EmailCompose.module.scss';
import { MessageAddressList, ConversationInfoLayout, ComposeEditor } from 'components_old';

interface IEmailComposeProps {
  targetEmail: IEmail | null,
  draftEmail: IEmail,
  type?: keyof typeof REPLY_TYPE
}

export const EmailCompose = ({
  draftEmail,
}: IEmailComposeProps) => {
  const dispatch = useAppDispatch();
  const options = [
    { value: REPLY_TYPE.REPLY, label: 'Reply' },
    { value: REPLY_TYPE.REPLY_TO_ALL, label: 'Reply to All' },
  ];

  const [localDraft, setLocalDraft] = useState<IDraftComposer>({ ...draftEmail, replyToMessageId: draftEmail.reply_to_message_id })
  const [files, setFiles] = useState<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isPreviousConversationVisible, setIsPreviousConversationVisible] = useState(false);
  const [clickedFileId, setClickedFileId] = useState<string>('')

  const updatingDraft = useAppSelector(selectUpdatingDraft);
  const deletingDraft = useAppSelector(selectDeletingDraft);
  const uploadingFiles = useAppSelector(selectUploadingFiles);
  const deletingFiles = useAppSelector(selectDeletingFiles);
  const downloadingFile = useAppSelector(selectDownloadingFile);

  const isLoading = updatingDraft || deletingDraft || uploadingFiles || deletingFiles;

  const { isGmailWithHistory, isOutlookWithHistory } = getMessageMeta(draftEmail);

  const updateLocalDraft = (updateObject: Partial<IDraftComposer>) => {
    if (updateObject.hasOwnProperty('files')) {
      updateObject.fileIdsToAttach = updateObject?.files?.map(file => file.id)
    }
    if (updateObject.hasOwnProperty('cc')) {
      updateObject.carbonCopy = updateObject?.cc
    }
    setLocalDraft({ ...localDraft, ...updateObject })
  }


  const handleUpdateDraft = async () => {
    if (localDraft) {
      const updateDraftResponse = await dispatch(
        mailboxActions.updateDraft({ draft: localDraft })
      );
      const updatedDraft = updateDraftResponse.payload as IEmail;
      updateLocalDraft({ ...updatedDraft })
    }
  };

  const handleDeleteDraft = () => {
    dispatch(mailboxActions.deleteDraft({ id: draftEmail?.id || '' }));
  };

  useEffect(() => {
    const handleUpload = async () => {
      if (!files) return;

      const formData = new FormData();
      const filesArray = Array.from(files);
      for (const file of filesArray) {
        formData.append('files', file);
      }

      try {
        const uploadResponse = await dispatch(
          mailboxActions.uploadFiles(formData)
        );

        updateLocalDraft({ files: [...localDraft.files, ...(uploadResponse.payload as IUploadedFile[])] })

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) { }
    };

    handleUpload();
  }, [files]);

  const handleDeleteFile = async (file: IUploadedFile) => {
    try {
      const deleteDraftResponse = await dispatch(mailboxActions.deleteFile({ fileId: file.id, messageId: localDraft?.id }));
      if (!deleteDraftResponse.hasOwnProperty('error')) {
        updateLocalDraft({ files: [...localDraft.files?.filter(item => item.id !== file.id)] })

        const updatedDraft = deleteDraftResponse.payload as IEmail;
        updateLocalDraft({ ...updatedDraft })
      }
    } catch (error) { }
  }


  const downloadAttachment = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: IUploadedFile) => {
    event.stopPropagation();
    setClickedFileId(file.id);
    dispatch(
      mailboxActions.getEmailAttachment({ file })
    )
  };

  // const handleDraftTypeChange = (value: keyof typeof REPLY_TYPE) => {
  //   setDraftType(value);
  //   if (targetEmail) {
  //     const { to, cc } = buildRecipientsList({ email: targetEmail, type: value, connectedEmail })
  //     updateLocalDraft({ to, cc })
  //   }
  // }

  return (
    <div className={styles.ComposeContainer}>
      <div className={styles.InfoContainer}>
        {/* {targetEmail && (targetEmail?.to?.length > 1 || targetEmail?.cc?.length > 0) && (
          <Select
            options={options}
            value={options.find(option => option.value === draftType)}
            isSearchable={false}
            onChange={option => handleDraftTypeChange(option?.value || REPLY_TYPE.REPLY)}
          />
        )} */}

        <MessageAddressList
          label='To'
          recipients={localDraft.to || []}
          updateRecipients={(recipients: IEmailRecipient[]) => {
            updateLocalDraft({ to: recipients })
          }}
        />
        {localDraft?.cc?.length > -1 && <MessageAddressList
          label='Cc'
          recipients={localDraft.cc || []}
          updateRecipients={(recipients: IEmailRecipient[]) => updateLocalDraft({ cc: recipients })}
        />}

        <ConversationInfoLayout label='Subj' content={
          <Input
            wrapperClassName={classnames(styles.SubjectWrapper)}
            className={styles.Subject}
            aria-label="Subject"
            value={localDraft.subject}
            onChange={
              (e) => updateLocalDraft({ subject: e.target.value })
            }
          />
        } />
      </div>

      <ComposeEditor
        value={localDraft.body || ''}
        onChange={value => updateLocalDraft({ body: value })}
      />

      {/* <div className={styles.MessageCompose} >
        <ContentEditable
          className={classnames(styles.EditableMessage,
            {
              'msg__gmail--hide-history': !isPreviousConversationVisible && isGmailWithHistory,
              'msg__outlook--hide-history': !isPreviousConversationVisible && isOutlookWithHistory,
            })}
          html={localDraft.body || ''}
          onChange={
            (e: ContentEditableEvent) => updateLocalDraft({ body: e.target.value })
          }
        />
        {!isPreviousConversationVisible && (
          <ThreeDots hasHover={false} className={styles.IconThreeDots} onClick={() => setIsPreviousConversationVisible(true)} />
        )}
      </div> */}

      {localDraft?.files?.length > 0 && (
        <div className={styles.Attachments}>
          <div className={styles.Title}>Attachments</div>
          <div className={styles.Container}>
            {localDraft?.files?.map((file, index) => (
              <div
                key={index}
                className={styles.AttachmentContainer}
              >
                <div
                  className={styles.AttachmentText}
                  onClick={(event) => downloadAttachment(event, file)}
                >
                  {file?.filename} ({convertFileSize(file?.size)})
                </div>
                <Close
                  hasHover={false}
                  onClick={() => handleDeleteFile(file)}
                  isLoading={deletingFiles || (downloadingFile && file.id === clickedFileId)}
                  spin={deletingFiles || (downloadingFile && file.id === clickedFileId)}
                />
              </div>
            ))}
          </div>
        </div>
      )}




      <div className={styles.Actions}>
        <Button
          loading={isLoading}
          className={styles.SaveBtn}
          disabled={(!localDraft?.to?.length && !localDraft?.body)}
          onClick={() => handleUpdateDraft()}
          look={BUTTON_LOOK.OUTLINE}
        >
          Save draft
        </Button>
        <label>
          <input
            ref={fileInputRef}
            type="file"
            disabled={isLoading}
            className={styles.UploadInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFiles(e.target.files);
            }}
            multiple
          />
          <Clip className={styles.ActionIcon} />
        </label>
        <Delete onClick={() => handleDeleteDraft()} className={styles.ActionIcon} />
      </div>
    </div >
  );
}
