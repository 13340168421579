import { useAppDispatch, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { ClientDataRecordType, IClientData, IClientDataRecord, createDataRecord, deleteDataRecord, linkGoogleDriveFolder, selectIsCreateDataRecordProcessing, selectIsDeleteDataRecordProcessing } from 'store/clientManagement';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Textarea } from 'components/ui/textarea';
import { toast } from 'react-toastify';
import { GDRIVE_PERMISSION_EMAIL } from 'const';
import { CopyValue } from 'components/common/_atoms/CopyValue';

export enum RESOURCE_MODAL_MODE {
  ADD,
  VIEW
}

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  client: IClientData | null;
  mode: RESOURCE_MODAL_MODE;
  type: ClientDataRecordType;
  data?: IClientDataRecord;
}

interface FormData {
  title: string;
  value: string;
}

export const ClientDataRecordModal = ({ isVisible, setIsVisible, client, mode, data, type }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isCreateDataRecordProcessing = useSelector(selectIsCreateDataRecordProcessing);
  const isDeleteDataRecordProcessing = useSelector(selectIsDeleteDataRecordProcessing);

  const [translationPrefix, setTranslationPrefix] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [formSchema, setFormSchema] = useState(z.object({
    title: z.string().min(1, t('errors.required') || ''),
    value: z.string().min(1, t('errors.required') || ''),
  }));

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      value: '',
    }
  });

  useEffect(() => {
    form.reset({
      title: mode === RESOURCE_MODAL_MODE.VIEW ? data?.title : '',
      value: mode === RESOURCE_MODAL_MODE.VIEW ? data?.value : '',
    })
  }, [data, form, mode])

  useEffect(() => {
    setIsReadOnly(mode === RESOURCE_MODAL_MODE.VIEW)
  }, [mode])

  useEffect(() => {
    if (type === ClientDataRecordType.TEXT) {
      setTranslationPrefix('typeText');
      setFormSchema(z.object({
        title: z.string().min(1, t('errors.required') || ''),
        value: z.string().min(1, t('errors.required') || ''),
      }));
    }

    if (type === ClientDataRecordType.G_DRIVE) {
      setTranslationPrefix('typeGoogleDrive')
      setFormSchema(z.object({
        title: z.string().min(0, t('errors.required') || ''),
        value: z.string().url(),
      }));
    }
  }, [type, t])


  const handleCreateResource = async (values: z.infer<typeof formSchema>) => {
    if (!client) return;

    let response;
    if (type === ClientDataRecordType.TEXT) {
      response = await dispatch(createDataRecord({ ...values, client_id: client?.id }));
    }
    if (type === ClientDataRecordType.G_DRIVE) {
      response = await dispatch(linkGoogleDriveFolder({ link: values.value, client_id: client?.id }));
    }

    // Call with success
    if (!response?.hasOwnProperty('error')) {
      toast(t(`component.ClientDataRecordModal.${translationPrefix}.saveSuccess`), { type: 'success' })
      form.reset();
      setIsVisible(false);
    }
  }

  const handleDeleteResource = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!data || !client) return;

    const response = await dispatch(deleteDataRecord({
      clientId: client?.id,
      recordId: data?.id
    }));

    // Call with success
    if (!response.hasOwnProperty('error')) {
      toast(t(`component.ClientDataRecordModal.${translationPrefix}.deleteSuccess`), { type: 'success' })
      form.reset();
      setIsVisible(false);
    }
  }

  return (
    <Fragment>
      {isVisible && (
        <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
          <DialogContent className="sm:max-w-[425px]">
            {!isReadOnly && (
              <DialogHeader>
                <DialogTitle>
                  {t(`component.ClientDataRecordModal.${translationPrefix}.title`)}
                </DialogTitle>
                <DialogDescription>
                  <p>
                    {t(`component.ClientDataRecordModal.${translationPrefix}.description`)}
                  </p>
                  {
                    type === ClientDataRecordType.G_DRIVE &&
                    <ul className='list-disc pl-4'>
                      <li>{t(`component.ClientDataRecordModal.${translationPrefix}.descriptionA`)}</li>
                      <li>
                        {t(`component.ClientDataRecordModal.${translationPrefix}.descriptionB`)?.replace('{{email}}', GDRIVE_PERMISSION_EMAIL)}
                        <CopyValue value={GDRIVE_PERMISSION_EMAIL} className='inline-block ml-2' />
                      </li>
                      <li>{t(`component.ClientDataRecordModal.${translationPrefix}.descriptionC`)}</li>
                      <li>{t(`component.ClientDataRecordModal.${translationPrefix}.descriptionD`)}</li>
                    </ul>
                  }
                </DialogDescription>
              </DialogHeader>
            )}

            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleCreateResource)} className="flex flex-col gap-6">

                {type !== ClientDataRecordType.G_DRIVE && (
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t(`component.ClientDataRecordModal.${translationPrefix}.titleLabel`)}
                        </FormLabel>
                        <FormLabel variant="description">
                          {t(`component.ClientDataRecordModal.${translationPrefix}.titleLabelDesc`)}
                        </FormLabel>

                        <FormControl>
                          <Input {...field} autoComplete="off" data-1p-ignore disabled={isCreateDataRecordProcessing || isReadOnly} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name="value"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.ClientDataRecordModal.${translationPrefix}.valueLabel`)}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t(`component.ClientDataRecordModal.${translationPrefix}.valueLabelDesc`)}
                      </FormLabel>

                      <FormControl>
                        <Textarea {...field}
                          autoComplete="off" data-1p-ignore
                          rows={type !== ClientDataRecordType.G_DRIVE ? 15 : 3}
                          disabled={isCreateDataRecordProcessing || isReadOnly}
                          className='disabled:opacity-1 disabled:text-muted-foreground'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />


                <DialogFooter>
                  {!isReadOnly ? (
                    <Button
                      type="submit"
                      loading={isCreateDataRecordProcessing}
                    >
                      {t(`component.ClientDataRecordModal.${translationPrefix}.saveBtn`)}
                    </Button>
                  ) : (
                    <Button
                      loading={isDeleteDataRecordProcessing}
                      variant="destructive"
                      onClick={handleDeleteResource}
                    >
                      {t(`component.ClientDataRecordModal.${translationPrefix}.deleteBtn`)}
                    </Button>
                  )}
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

