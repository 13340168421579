import { useEffect } from "react";
import mixpanel, { Dict } from "mixpanel-browser";
import { MIXPANEL } from "const";

interface ITrackEvent {
  action: string;
  type?: string;
  payload?: Dict;
}

export interface IUseMixpanel {
  trackEvent(params: ITrackEvent): void;
}

interface IProps {
  trackPageVisit?: boolean;
}

export const useMixpanel = ({
  trackPageVisit = true,
}: IProps = {}): IUseMixpanel => {
  useEffect(() => {
    if (trackPageVisit) {
      mixpanel.track(`${MIXPANEL.TYPE.VISITED}_${window.location.pathname}`);
    }
  }, []);

  const trackEvent = (params: ITrackEvent) => {
    const { type = MIXPANEL.TYPE.CLICKED, action, payload } = params;
    mixpanel.track(`${type}_${window.location.pathname}_${action}`, payload);
  };

  return {
    trackEvent,
  };
};
