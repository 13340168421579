import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getClientManagementState = (state: RootState) => state.clientManagement;

export const selectClientsList = createSelector(
  [getClientManagementState],
  ({ clientsList }) => clientsList
);

export const selectIsGetClientsListProcessing = createSelector(
  [getClientManagementState],
  ({ isGetClientsListProcessing }) => isGetClientsListProcessing
);

export const selectIsCreateClientProcessing = createSelector(
  [getClientManagementState],
  ({ isCreateClientProcessing }) => isCreateClientProcessing
);

export const selectActiveClient = createSelector(
  [getClientManagementState],
  ({ activeClient }) => activeClient
);

export const selectIsDeleteClientProcessing = createSelector(
  [getClientManagementState],
  ({ isDeleteClientProcessing }) => isDeleteClientProcessing
);

export const selectIsGetDataRecordsListProcessing = createSelector(
  [getClientManagementState],
  ({ isGetDataRecordsListProcessing }) => isGetDataRecordsListProcessing
);

export const selectClientDataRecordsList = createSelector(
  [getClientManagementState],
  ({ clientDataRecordsList }) => clientDataRecordsList
);

export const selectIsCreateDataRecordProcessing = createSelector(
  [getClientManagementState],
  ({ isCreateDataRecordProcessing }) => isCreateDataRecordProcessing
);

export const selectIsDeleteDataRecordProcessing = createSelector(
  [getClientManagementState],
  ({ isDeleteDataRecordProcessing }) => isDeleteDataRecordProcessing
);

export const selectIsGetAiDataSearchAnswerProcessing = createSelector(
  [getClientManagementState],
  ({ isGetAiDataSearchAnswerProcessing }) => isGetAiDataSearchAnswerProcessing
);

export const selectAiDataSearchAnswers = createSelector(
  [getClientManagementState],
  ({ aiDataSearchAnswers }) => aiDataSearchAnswers
);

export const selectIsSyncingSalesforce = createSelector(
  [getClientManagementState],
  ({ isSyncingSalesforce }) => isSyncingSalesforce
);

export const selectIsSyncingRedtail = createSelector(
  [getClientManagementState],
  ({ isSyncingRedtail }) => isSyncingRedtail
);

export const selectIsImportingFromToRedtail = createSelector(
  [getClientManagementState],
  ({ isImportingFromToRedtail }) =>isImportingFromToRedtail
);