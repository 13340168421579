export const Brain = () => {
  return (
    <svg
      width="41"
      height="34"
      viewBox="0 0 41 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.69287" y="4.99414" width="1.231" height="1.231" fill="black" />
      <rect y="11.1475" width="1.231" height="1.231" fill="black" />
      <rect x="1.23145" y="12.3799" width="1.231" height="1.231" fill="black" />
      <rect x="6.15527" y="22.2285" width="1.231" height="1.231" fill="black" />
      <rect x="7.38623" y="23.4561" width="1.231" height="1.231" fill="black" />
      <rect x="9.84814" y="25.9219" width="1.231" height="1.231" fill="black" />
      <rect x="30.7759" y="29.6143" width="1.231" height="1.231" fill="black" />
      <rect x="38.1611" y="19.7646" width="1.231" height="1.231" fill="black" />
      <rect x="35.6992" y="6.22559" width="1.231" height="1.231" fill="black" />
      <rect x="34.4683" y="4.99414" width="1.231" height="1.231" fill="black" />
      <rect x="33.2373" y="3.76367" width="1.231" height="1.231" fill="black" />
      <rect x="30.7759" y="2.53125" width="2.462" height="1.231" fill="black" />
      <rect x="28.313" y="1.30176" width="2.462" height="1.231" fill="black" />
      <rect
        x="11.0791"
        y="27.1523"
        width="4.92401"
        height="1.231"
        fill="black"
      />
      <rect
        x="16.0029"
        y="28.3828"
        width="3.69301"
        height="1.231"
        fill="black"
      />
      <rect
        x="24.6201"
        y="32.0762"
        width="3.69301"
        height="1.231"
        fill="black"
      />
      <rect x="19.6958" y="29.6143" width="2.462" height="1.231" fill="black" />
      <rect x="33.2373" y="25.9219" width="2.462" height="1.231" fill="black" />
      <rect x="22.1582" y="30.8467" width="2.462" height="1.231" fill="black" />
      <rect x="28.313" y="30.8467" width="2.462" height="1.231" fill="black" />
      <rect x="8.61719" y="24.6904" width="1.231" height="2.462" fill="black" />
      <rect x="32.0059" y="27.1523" width="1.231" height="2.462" fill="black" />
      <rect x="35.6992" y="23.4561" width="1.231" height="2.462" fill="black" />
      <rect x="36.9307" y="20.9961" width="1.231" height="2.462" fill="black" />
      <rect x="38.1611" y="9.91797" width="1.231" height="2.462" fill="black" />
      <rect x="36.9307" y="7.45605" width="1.231" height="2.462" fill="black" />
      <rect
        x="39.3921"
        y="12.3799"
        width="1.231"
        height="7.38601"
        fill="black"
      />
      <rect x="2.46191" y="6.22559" width="1.231" height="2.462" fill="black" />
      <rect x="1.23145" y="8.68555" width="1.231" height="2.462" fill="black" />
      <rect x="1.23145" y="18.5361" width="1.231" height="2.462" fill="black" />
      <rect y="13.6123" width="1.231" height="4.92401" fill="black" />
      <rect
        x="9.84814"
        y="1.30176"
        width="3.69301"
        height="1.231"
        fill="black"
      />
      <rect x="7.38623" y="2.53125" width="2.462" height="1.231" fill="black" />
      <rect x="4.92383" y="3.76367" width="2.462" height="1.231" fill="black" />
      <rect
        x="2.46191"
        y="20.9961"
        width="3.69301"
        height="1.231"
        fill="black"
      />
      <rect
        x="13.541"
        y="0.0693359"
        width="14.772"
        height="1.231"
        fill="black"
      />
      <rect
        x="19.6948"
        y="27.1533"
        width="2.462"
        height="2.462"
        fill="#3A1C5E"
      />
      <rect
        x="16.0024"
        y="27.1533"
        width="3.69301"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="20.9268"
        y="25.9219"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="22.1582"
        y="27.1533"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="22.1582"
        y="25.9219"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="23.3887"
        y="24.6875"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="24.6196"
        y="24.6875"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="30.7749"
        y="27.1533"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="29.5435"
        y="27.1533"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="6.1543"
        y="20.9971"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="4.92334"
        y="19.7676"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="3.69238"
        y="19.7676"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="6.1543"
        y="19.7676"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="6.1543"
        y="18.5352"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="7.38574"
        y="19.7676"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="7.38574"
        y="18.5352"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="8.6167"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="9.84766"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="14.7715"
        y="16.0732"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="11.0786"
        y="25.9219"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="20.9268"
        y="13.6113"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="1.23047"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="23.3887"
        y="25.9219"
        width="9.84802"
        height="1.231"
        fill="#3A1C5E"
      />
      <rect
        x="1.23047"
        y="14.8438"
        width="1.231"
        height="2.462"
        fill="#6E3FA6"
      />
      <rect
        x="3.69189"
        y="16.0752"
        width="1.231"
        height="3.69301"
        fill="#6E3FA6"
      />
      <rect
        x="4.92285"
        y="17.3037"
        width="1.231"
        height="2.462"
        fill="#6E3FA6"
      />
      <rect
        x="6.1543"
        y="17.3037"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="8.6167"
        y="16.0752"
        width="6.15501"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="14.7715"
        y="14.8438"
        width="2.462"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="17.2329"
        y="14.8438"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="16.0024"
        y="16.0752"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="23.3887"
        y="17.3037"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="8.6167"
        y="18.5381"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="27.0811"
        y="19.7676"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="19.6948"
        y="22.2275"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="25.8501"
        y="24.6914"
        width="9.84802"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="34.4673"
        y="18.5381"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="29.5435"
        y="29.6143"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="30.7744"
        y="28.3857"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="24.6191"
        y="30.8457"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="22.1582"
        y="29.6143"
        width="2.462"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="22.1582"
        y="28.3857"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="23.3887"
        y="27.1543"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="16.0024"
        y="24.6914"
        width="7.38601"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="16.0024"
        y="25.9199"
        width="4.92401"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect x="13.5405" y="23.46" width="1.231" height="2.462" fill="#6E3FA6" />
      <rect
        x="9.84717"
        y="22.2275"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="12.3091"
        y="25.9199"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="14.7715"
        y="23.46"
        width="4.92401"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect x="8.6167" y="23.46" width="1.231" height="1.231" fill="#6E3FA6" />
      <rect
        x="9.84717"
        y="24.6914"
        width="2.462"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="25.8501"
        y="18.5381"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="22.1582"
        y="13.6113"
        width="6.15501"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="32.0059"
        y="16.0752"
        width="1.231"
        height="2.462"
        fill="#6E3FA6"
      />
      <rect
        x="30.7744"
        y="17.3037"
        width="3.69301"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="7.38574"
        y="13.6113"
        width="1.231"
        height="4.92401"
        fill="#6E3FA6"
      />
      <rect
        x="13.5405"
        y="12.3818"
        width="1.231"
        height="4.92401"
        fill="#6E3FA6"
      />
      <rect x="7.38574" y="21" width="1.231" height="2.462" fill="#6E3FA6" />
      <rect
        x="2.46143"
        y="16.0752"
        width="1.231"
        height="4.92401"
        fill="#6E3FA6"
      />
      <rect x="16.0024" y="21" width="1.231" height="1.231" fill="#6E3FA6" />
      <rect
        x="24.6191"
        y="22.2275"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="19.6948"
        y="2.5332"
        width="1.231"
        height="1.231"
        fill="#6E3FA6"
      />
      <rect
        x="4.92383"
        y="4.99609"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="6.1543"
        y="4.99609"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="3.69238"
        y="7.45801"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="2.46143"
        y="8.6875"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="1.23047"
        y="13.6123"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="4.92383"
        y="16.0742"
        width="2.462"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="9.84766"
        y="14.8438"
        width="3.69301"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="8.6167"
        y="19.7686"
        width="18.465"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="14.7715"
        y="13.6123"
        width="6.15501"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="17.2334"
        y="16.0742"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="11.0786"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="9.84766"
        y="18.5361"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="8.6167"
        y="22.2285"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="9.84766"
        y="23.46"
        width="3.69301"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="11.0786"
        y="22.2285"
        width="8.61701"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="14.7715"
        y="17.3057"
        width="2.462"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="28.313"
        y="13.6123"
        width="4.92401"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="30.7749"
        y="14.8438"
        width="4.92401"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="33.2363"
        y="16.0742"
        width="2.462"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="34.4678"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="30.7749"
        y="16.0742"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="3.69238"
        y="9.91797"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="1.23047"
        y="11.1494"
        width="6.15501"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="2.46143"
        y="12.3799"
        width="6.15501"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="8.6167"
        y="6.22559"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="7.38574"
        y="8.6875"
        width="1.231"
        height="4.92401"
        fill="#CF6ED4"
      />
      <rect
        x="13.5405"
        y="9.91797"
        width="1.231"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="12.3096"
        y="12.3799"
        width="1.231"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="8.6167"
        y="13.6123"
        width="1.231"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="2.46143"
        y="13.6123"
        width="4.92401"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="17.2334"
        y="9.91797"
        width="1.231"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="20.9268"
        y="14.8438"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="23.3887"
        y="14.8438"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="24.6196"
        y="16.0742"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="22.1582"
        y="17.3057"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="14.7715"
        y="24.6885"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="12.3096"
        y="24.6885"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="8.6167"
        y="20.9961"
        width="7.38601"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="17.2334"
        y="20.9961"
        width="19.696"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect x="19.6953" y="23.46" width="1.231" height="1.231" fill="#CF6ED4" />
      <rect
        x="29.5435"
        y="28.3848"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="23.3887"
        y="28.3848"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="20.9268"
        y="23.46"
        width="14.772"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="25.8501"
        y="22.2285"
        width="11.079"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="24.6196"
        y="27.1543"
        width="4.92401"
        height="3.69301"
        fill="#CF6ED4"
      />
      <rect
        x="24.6196"
        y="17.3057"
        width="6.15501"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="28.313"
        y="19.7686"
        width="9.84802"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="29.5435"
        y="18.5361"
        width="2.462"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="33.2363"
        y="18.5361"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="18.4644"
        y="18.5361"
        width="7.38601"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="18.4644"
        y="11.1494"
        width="3.69301"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="20.9268"
        y="12.3799"
        width="9.84802"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="28.313"
        y="11.1494"
        width="9.84802"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="7.38574"
        y="3.76367"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="9.84766"
        y="2.5332"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="11.0786"
        y="3.76367"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="12.3096"
        y="3.76367"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="12.3096"
        y="4.99609"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="20.9268"
        y="1.30176"
        width="2.462"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="18.4644"
        y="2.5332"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="27.0815"
        y="1.30176"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="25.8501"
        y="2.5332"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="28.313"
        y="6.22559"
        width="3.69301"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="35.6987"
        y="9.91797"
        width="2.462"
        height="9.84802"
        fill="#CF6ED4"
      />
      <rect
        x="38.1611"
        y="12.3799"
        width="1.231"
        height="7.38601"
        fill="#CF6ED4"
      />
      <rect
        x="13.5405"
        y="1.30176"
        width="2.462"
        height="2.462"
        fill="#CF6ED4"
      />
      <rect
        x="23.3887"
        y="22.2285"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="27.0815"
        y="2.5332"
        width="1.231"
        height="1.231"
        fill="#CF6ED4"
      />
      <rect
        x="16.0029"
        y="1.30176"
        width="2.462"
        height="2.462"
        fill="#E6B4E9"
      />
      <rect
        x="18.4648"
        y="1.30176"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect x="28.313" y="2.5332" width="2.462" height="1.231" fill="#E6B4E9" />
      <rect
        x="8.61719"
        y="3.76367"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="7.38574"
        y="4.99609"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="3.69287"
        y="8.6875"
        width="3.69301"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="4.92432"
        y="9.91797"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="8.61719"
        y="9.91797"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="8.61719"
        y="11.1494"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="8.61719"
        y="12.3799"
        width="3.69301"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="9.84814"
        y="13.6123"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="30.7749"
        y="12.3799"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="33.2378"
        y="13.6123"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="22.1582"
        y="14.8438"
        width="1.231"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="24.6201"
        y="14.8438"
        width="6.15501"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="25.8511"
        y="16.0742"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="32.0059"
        y="18.5361"
        width="1.231"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="18.4648"
        y="16.0742"
        width="6.15501"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="12.3105"
        y="17.3057"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="17.2339"
        y="17.3057"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="11.0791"
        y="18.5361"
        width="7.38601"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="22.1582"
        y="11.1494"
        width="6.15501"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="18.4648"
        y="9.91797"
        width="17.234"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="8.61719"
        y="8.6875"
        width="28.313"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="2.46191"
        y="9.91797"
        width="1.231"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="4.92432"
        y="7.45801"
        width="32.0061"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="32.0059"
        y="6.22559"
        width="3.69301"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="13.541"
        y="4.99609"
        width="20.927"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="13.541"
        y="3.76367"
        width="19.696"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="20.9272"
        y="2.5332"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="11.0791"
        y="2.5332"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="23.3896"
        y="1.30176"
        width="3.69301"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="14.772"
        y="9.91797"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="14.772"
        y="11.1494"
        width="2.462"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="14.772"
        y="12.3799"
        width="6.15501"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="3.69287"
        y="6.22559"
        width="4.92401"
        height="1.231"
        fill="#E6B4E9"
      />
      <rect
        x="9.84814"
        y="6.22559"
        width="18.465"
        height="1.231"
        fill="#E6B4E9"
      />
    </svg>
  );
};
