import { AxiosHeaders, AxiosRequestConfig } from "axios";

import instance from "./index";
import { IAdvisorsMailbox } from "store/users";
import { API_ROUTES } from "../api/constants";
import { SignOutReason, logout, refresh } from "../../store/authentication";
import { IRefreshResponse } from "../api/authentication/types";

const setUpInterceptor = (store: any) => {
  instance.interceptors.request.use(
    async (config: any | AxiosRequestConfig) => {
      const state = store.getState();
      const accessToken = state.authentication.accessToken;
      const activeAdvisorMailbox: IAdvisorsMailbox =
        state.users.activeAdvisorMailbox;
      const connectedEmail = activeAdvisorMailbox?.connected_emails[0];
      const authId = connectedEmail?.authId;

      if (accessToken && !config.url.includes("/refresh")) {
        (config.headers as AxiosHeaders).set(
          "Authorization",
          `Bearer ${accessToken}`
        );
      }

      if (config.url && config.url.includes("/mailbox/")) {
        config.params = {
          ...config.params,
          auth_id: authId,
        };
      }

      return config;
    }
  );

  let refreshTokenPromise: Promise<IRefreshResponse> | null = null;

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (
        originalConfig.url !== API_ROUTES.authentication.refresh &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          if (!refreshTokenPromise) {
            refreshTokenPromise = store.dispatch(refresh());
          }

          try {
            await refreshTokenPromise;
            refreshTokenPromise = null;
            return instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      if (
        originalConfig.url === API_ROUTES.authentication.refresh &&
        err.response.status !== 200
      ) {
        return store.dispatch(
          logout({ reason: SignOutReason.SESSION_EXPIRED })
        );
      }

      return Promise.reject(err);
    }
  );
};

export default setUpInterceptor;

export * from "./processApiError";
