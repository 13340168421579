import { useState } from 'react';
import { Stepper, GenericPage, ISDataEntryUploader, AutomationStartScreen, STEPPER_LOOK, ISDataEntryCongrats, ISDataEntryShareCredentials } from 'components_old';
import { IAutomationWizardRecord, ISDataEntry_STEP, TAutomationStep } from '../../types';
import { INTRO_VIDEO_ID } from 'const';
import { useTranslation } from 'react-i18next';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { useAppDispatch } from 'hooks';


export const ISDataEntryWizard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [stepsArray, setStepsArray] = useState<Array<TAutomationStep>>([ISDataEntry_STEP.S1]);

  const handleGoToNextStep = ({ action, payload }: IAutomationWizardRecord) => {
    const isAnotherRequest = stepsArray[stepsArray.length - 1] === ISDataEntry_STEP.S3 && action === ISDataEntry_STEP.S1;

    setStepsArray(isAnotherRequest ? [action] : [...stepsArray, action]);

    // mixpanel.track(action, omit(payload, "password"));

    if (payload) {
      // dispatch(updateComparisonRequestPayload(payload))
    }
  }

  const handleBackAction = () => {
    const currentStep = stepsArray[stepsArray.length - 1];

    if (currentStep === ISDataEntry_STEP.S1) {
      router.navigate(ROUTES.ADVISOR_AUTOMATIONS)
    } else {
      setStepsArray(stepsArray.slice(0, -1));
    }

    // mixpanel.track(EVENT.BACK);
  }

  return (
    <GenericPage title={t('automations.tiles.investmentStatementDataEntry.title')}>
      <Stepper
        activeStep={0}
        totalSteps={0}
        showSteps={false}
        showBackBtn={true}
        handleBackAction={handleBackAction}
        look={STEPPER_LOOK.AUTOMATIONS}
      >
        {stepsArray[stepsArray.length - 1] === ISDataEntry_STEP.S1 && (
          <AutomationStartScreen
            description={
              <>
                <div>{t('automations.tiles.investmentStatementDataEntry.descriptionA')}</div>
                <div>{t('automations.tiles.investmentStatementDataEntry.descriptionB')}</div>
              </>
            }
            videoId={INTRO_VIDEO_ID}
          >
            <ISDataEntryUploader handleGoToNextStep={handleGoToNextStep} nextStep={ISDataEntry_STEP.S2} />
          </AutomationStartScreen>
        )}

        {stepsArray[stepsArray.length - 1] === ISDataEntry_STEP.S2 && (
          <ISDataEntryShareCredentials handleGoToNextStep={handleGoToNextStep} nextStep={ISDataEntry_STEP.S3} />
        )}

        {stepsArray[stepsArray.length - 1] === ISDataEntry_STEP.S3 && (
          <ISDataEntryCongrats handleGoToNextStep={handleGoToNextStep} nextStep={ISDataEntry_STEP.S1} />
        )}
      </Stepper>
    </GenericPage>
  );
}
