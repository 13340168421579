import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { IHeadsUpCard, IHeadsUpCardsState } from "./types";
import { createCard, deleteCard, getCards, updateCard } from "./actions";

const initialState: IHeadsUpCardsState = {
  headsUpCards: [],
  activeCard: undefined,
  isGettingCards: false,
  isCreatingCard: false,
  isUpdatingCard: false,
  isDeletingCard: false,
};

const headsUpCardsSlice = createSlice({
  name: "headsUpCards",
  initialState,
  reducers: {
    setActiveCard: (state, action: PayloadAction<IHeadsUpCard | undefined>) => {
      state.activeCard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCards.pending, (state, action) => {
        state.isGettingCards = true;
      })
      .addCase(getCards.fulfilled, (state, action) => {
        state.isGettingCards = false;
        state.headsUpCards = action.payload?.sort((a, b) =>
          a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
        );
      })
      .addCase(getCards.rejected, (state) => {
        state.isGettingCards = false;
      })
      .addCase(createCard.pending, (state, action) => {
        state.isCreatingCard = true;
      })
      .addCase(createCard.fulfilled, (state, action) => {
        return {
          ...state,
          isCreatingCard: false,
          headsUpCards: [...state.headsUpCards, action.payload]?.sort((a, b) =>
            a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
          ),
          activeCard: action.payload,
        };
      })
      .addCase(createCard.rejected, (state) => {
        state.isCreatingCard = false;
      })
      .addCase(updateCard.pending, (state, action) => {
        state.isUpdatingCard = true;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        return {
          ...state,
          isUpdatingCard: false,
          headsUpCards: state.headsUpCards
            .map((card) =>
              card.id === action.payload.id ? action.payload : card
            )
            ?.sort((a, b) =>
              a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
            ),
          activeCard: action.payload,
        };
      })
      .addCase(updateCard.rejected, (state) => {
        state.isUpdatingCard = false;
      })
      .addCase(deleteCard.pending, (state, action) => {
        state.isDeletingCard = true;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const currentState = current(state);

        const deletedCard = action?.meta.arg;
        return {
          ...state,
          isDeletingCard: false,
          headsUpCards: state.headsUpCards.filter(
            (card) => card.id !== deletedCard?.id
          ),
          activeCard:
            currentState?.activeCard?.id === deletedCard?.id
              ? undefined
              : currentState?.activeCard,
        };
      })
      .addCase(deleteCard.rejected, (state) => {
        state.isDeletingCard = false;
      });
  },
});

export const { setActiveCard } = headsUpCardsSlice.actions;

export default headsUpCardsSlice.reducer;
