import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

import styles from './LinkText.module.scss';

interface ILinkTextProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  title: string;
}

export const LinkText = ({
  children,
  title,
  href = '#',
  ...rest
}: ILinkTextProps) => {
  return (
    <label htmlFor="link" className={styles.Wrapper}>
      <a
        className={styles.LinkText}
        href={href}
        title={title}
        rel="noreferrer"
        id="link"
        {...rest}
      >
        {children}
      </a>
      <div className={styles.Underline} />
    </label>
  );
};
