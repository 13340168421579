import { Helmet } from 'react-helmet';
import { useTranslation } from 'hooks';

export const MetaContent = () => {
  const { t } = useTranslation();
  
  return (
    <Helmet>
      <title>
        {t('general.appName')} - {t('general.appCredo')}
      </title>
      {/* <link rel="icon" type="image/png" href="/munin.ico" sizes="16x16" /> */}
    </Helmet>
  )
}


