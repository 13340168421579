import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export const enhanceWithLineNumbers = (input: string) =>
  (input || '')
    .split("\n")
    .map((line, i) => `<span class='absolute left-[4px]'>&#8226;</span>${line}`)
    .join("\n");