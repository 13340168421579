import { RoleEnum } from "const";
import { IOrganization } from "store/organizations";
import { IProfilePreferences } from "store/profile";

export interface IUsersState {
  advisorsMailboxLoading: boolean;
  advisorMailboxes: IAdvisorsMailbox[];
  activeAdvisorMailbox: IAdvisorsMailbox | null;

  isGetUsersListProcessing: boolean;
  usersList: IUser[];
  isGetUserDetailsProcessing: boolean;
  activeUser: IUser | null;
  isUserOperationProcessing: boolean;
}

export enum UserRoleInOrgEnum {
  Admin = "admin",
  User = "user",
}

export interface IOrganizationUserData {
  organisationId: string;
  organisationName: string;
  industryType: string;
  role: UserRoleInOrgEnum;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  preferences: IProfilePreferences;
  organisations: IOrganizationUserData[];
}

export type ICreateUserPayload = Pick<
  IUser,
  "firstName" | "lastName" | "email"
> & {
  orgId: string;
  userRole: UserRoleInOrgEnum;
};

export interface IUpdateUserPayload extends ICreateUserPayload {
  id: string;
}

export interface IAddUserRequestPayload {
  values: ICreateUserPayload;
  cb: () => void;
}

export interface INewAddedUser extends IUser {
  password: string;
  role: RoleEnum;
}

export interface IConnectedEmail {
  email: string;
  authId: string;
  provider: string;
}

export interface IAdvisorsMailbox {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  connected_emails: IConnectedEmail[];
}

export interface ISignUpPayload {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  linkedin?: string;
}

export interface ISignUpResponse {
  access_token: string;
  refresh_token: string;
}

export interface IGetUserDetailsPayload {
  id: string;
}
