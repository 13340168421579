import styles from './ThreadWithMessagesDetails.module.scss';
import classnames from 'classnames';
import React, { useState } from 'react';
import { selectActiveMailboxConnectedEmail } from 'store/users/selectors';
import { BUTTON_LOOK, Button, Feedback } from 'components_old/shared';
import { buildInitialDraft, convertFileSize, getMessageMeta } from 'utils/mailbox';
import { REPLY_TYPE, RoleEnum } from 'const';
import { Clip, ThreeDots } from 'icons';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { selectSelectedThread, selectLoadingThreadMessageId, selectCreatingDraft, mailboxActions, selectThreadsListData, selectExpandedMessageIds, selectLoadingGetThreadDetails, selectDownloadingFile, IUploadedFile, IEmail } from 'store/mailbox';
import { MessageAddressList } from '../MessageAddressList';
// import { IEmail, IUploadedFile } from '../types';
import { cleanEmailBody, filterRelevantMessageAttachments } from 'utils/mailbox';
import { EmailCompose } from '../EmailCompose';
import { LoadingRow } from 'components_old/shared/LoadingRow';
import { ConversationInfo } from '../ConversationInfo';
import { selectProfileRole } from 'store/profile';

export const ThreadWithMessagesDetails = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const threadsList = useAppSelector(selectThreadsListData);
  const activeThread = useAppSelector(selectSelectedThread);
  const messages = activeThread?.messages || [];

  const loadingGetThreadDetails = useAppSelector(selectLoadingGetThreadDetails);
  const loadingMessageId = useAppSelector(selectLoadingThreadMessageId);
  const creatingEmailDraft = useAppSelector(selectCreatingDraft);
  const downloadingFile = useAppSelector(selectDownloadingFile);
  const connectedEmail = useAppSelector(selectActiveMailboxConnectedEmail);
  const expandedMessageIds = useAppSelector(selectExpandedMessageIds);
  const profileRole = useAppSelector(selectProfileRole);

  const [draftTypes, setDraftTypes] = useState<{ [key: string]: keyof typeof REPLY_TYPE }>({});
  const [showCollapsedContentForMsgs, setShowCollapsedContentForMsgs] = useState<string[]>([])
  const [clickedFileId, setClickedFileId] = useState<string>('')

  const handleToggleMessage = async (message: IEmail) => {
    if (messages && messages?.length <= 1) return;

    if (!message.body) {
      dispatch(
        mailboxActions.getMessage({ id: message.id })
      );
    } else {
      if (expandedMessageIds.includes(message.id)) {
        dispatch(mailboxActions.removeIdFromExpandedMessages(message.id));
      } else {
        dispatch(mailboxActions.addIdToExpandedMessages(message.id));
      }
    }
  };

  const handleReplyToAll = (message: IEmail) => {
    const draftEmail = buildInitialDraft({ email: message, type: REPLY_TYPE.REPLY_TO_ALL, connectedEmail });
    setDraftTypes({ ...draftTypes, [message.id]: REPLY_TYPE.REPLY_TO_ALL })
    dispatch(mailboxActions.createDraft({ draft: draftEmail }))
  }

  const downloadAttachment = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: IUploadedFile) => {
    event.stopPropagation();
    setClickedFileId(file.id);
    dispatch(
      mailboxActions.getEmailAttachment({ file })
    )
  };

  const toggleCollapsedContent = (messageId: string) => {
    if (showCollapsedContentForMsgs.includes(messageId)) {
      setShowCollapsedContentForMsgs(showCollapsedContentForMsgs?.filter(id => id !== messageId))
    } else {
      setShowCollapsedContentForMsgs([...showCollapsedContentForMsgs, messageId])
    }
  }

  if (loadingGetThreadDetails) {
    return <LoadingRow className={styles.LoadingThreadDetails} />
  }

  return (
    <div className={styles.Wrapper}>
      {
        threadsList && threadsList?.length > 0 && !activeThread && (
          <Feedback>Select a thread to view the messages</Feedback>
        )
      }

      {messages?.map((message, index) => {
        const isMessageLoading = message?.id === loadingMessageId;
        const isExpanded = expandedMessageIds && expandedMessageIds?.includes(message.id);

        const draftMessages = activeThread?.drafts?.filter(draft => draft.reply_to_message_id === message.id) || [];
        const draftMessage = draftMessages?.length > 0 ? draftMessages[draftMessages?.length - 1] : null;

        const { hasAttachments, isGmailWithHistory, isOutlookWithHistory } = getMessageMeta(message);
        const hasHistory = isGmailWithHistory || isOutlookWithHistory;

        return (
          <React.Fragment key={message.id}>
            <div className={styles.MessageContainer}>
              <div className={styles.MessageInfo} onClick={() => handleToggleMessage(message)}>
                <ConversationInfo message={message} isMessageExpanded={isExpanded} />

                {isExpanded && (
                  <>
                    <MessageAddressList
                      label='To'
                      recipients={message.to || []}
                    />
                    {message?.cc?.length > 0 && <MessageAddressList
                      label='Cc'
                      recipients={message.cc || []}
                    />}
                  </>
                )}
              </div>


              {isExpanded && (
                <div className={styles.MessageBodyWrapper}>
                  <div
                    className={classnames(styles.MessageBody,
                      {
                        'msg__gmail--hide-history': isGmailWithHistory,
                        'msg__outlook--hide-history': isOutlookWithHistory,
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: cleanEmailBody(message.body || ''),
                    }}
                  />

                  {hasHistory && <ThreeDots hasHover={false}
                    className={styles.IconThreeDots}
                    onClick={() => toggleCollapsedContent(message.id)} />
                  }

                  {hasHistory && showCollapsedContentForMsgs.includes(message.id) && (
                    <div
                      className={classnames(styles.MessageBody,
                        {
                          'msg__gmail--hide-current': isGmailWithHistory,
                          'msg__outlook--hide-current': isOutlookWithHistory,
                        }
                      )}
                      dangerouslySetInnerHTML={{
                        __html: cleanEmailBody(message.body || ''),
                      }}
                    />
                  )}
                </div>
              )}



              {isExpanded && hasAttachments && (
                <div className={styles.Attachments}>
                  <div className={styles.Title}>Attachments</div>

                  {
                    filterRelevantMessageAttachments(message).map((f: IUploadedFile) => {
                      return (
                        <div
                          className={styles.AttachedFile}
                          key={f.id}
                          onClick={(event) => downloadAttachment(event, f)}
                        >
                          <Clip hasHover={false} isLoading={downloadingFile && f.id === clickedFileId} spin={downloadingFile && f.id === clickedFileId} />
                          <span>{f.filename} ({convertFileSize(f.size)})</span>
                        </div>
                      )
                    })
                  }
                </div>
              )}

              {isExpanded && draftMessage && (
                <div className={styles.ComposeWrapper}>
                  <div className={styles.ComposeContainer}>
                    <EmailCompose targetEmail={message} draftEmail={draftMessage} type={draftTypes[message.id]} />
                  </div>
                </div>
              )}

              {profileRole !== RoleEnum.Advisor && isExpanded && !draftMessage && (
                <div className={styles.Actions}>
                  <Button look={BUTTON_LOOK.OUTLINE} loading={creatingEmailDraft} onClick={() => handleReplyToAll(message)}                    >
                    {(message.to?.length > 1 || message.cc?.length > 0) ? t('common.replyToAll') : t('common.reply')}
                  </Button>
                </div>
              )}

              {isMessageLoading && (
                <LoadingRow />
              )}
            </div>
            {index !== messages?.length - 1 && <hr className={styles.MessageDivider} />}
          </React.Fragment>
        );
      })}
    </div >
  );
}
