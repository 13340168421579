import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { useTranslation } from 'hooks';
import { cn } from 'lib/utils';
import { CheckIcon, CopyIcon } from 'lucide-react';
import { useState } from 'react';

interface IProps {
  value?: string,
  className?: string
}

export const CopyValue = ({ value, className }: IProps) => {
  const { t } = useTranslation();

  const [copyIconClicked, setCopyIconClicked] = useState(false);

  const copyValue = (value: string | undefined) => {
    navigator.clipboard.writeText(value || '')
    setCopyIconClicked(true)
    setTimeout(() => { setCopyIconClicked(false) }, 2000);
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild className={cn('', className)}>
        {
          !copyIconClicked ? (
            <CopyIcon
              className='cursor-pointer size-4 text-muted-foreground hover:text-primary'
              onClick={() => copyValue(value)}
            />
          ) : (
            <CheckIcon className='cursor-pointer size-4 text-primary' />
          )
        }
      </TooltipTrigger>
      <TooltipContent>{t('general.copyToClipboard') || ''}</TooltipContent>
    </Tooltip>
  );
};

