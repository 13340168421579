import classnames from 'classnames';
import styles from './ChatBubble.module.scss';
import { Avatar } from 'components_old/shared/Avatar';

export enum ChatType {
  CLIENT = 'client',
  PARAPLANNER = 'paraplanner',
}

export enum MessageType {
  SENT = 'sent',
  RECEIVED = 'received',
}


interface IChatBubbleProps {
  when?: string;
  who?: string;
  whoLetter?: string;
  avatar?: string;
  text?: string;
  chatType?: ChatType;
  messageType?: MessageType;
}

export const ChatBubble = ({ when, who, avatar, text, chatType, messageType, whoLetter }: IChatBubbleProps) => {

  return (
    <div className={classnames(styles.ChatBubbleContainer, styles[`ChatType-${chatType}`], styles[`MessageType-${messageType}`])}>
      {when && <div className={classnames(styles.When)}>{when}</div>}

      <div className={classnames(styles.ChatBubble)}>
        <div className={classnames(styles.AvatarPlaceholder)}>
          {messageType === MessageType.RECEIVED && (
            <Avatar imgSrc={avatar} letter={whoLetter} />
          )}
        </div>

        <div className={classnames(styles.MessageContainer)}>
          <div className={classnames(styles.Who)}>{who}</div>
          <div className={classnames(styles.Message)}>
            {text}
          </div>
        </div>

        <div className={classnames(styles.AvatarPlaceholder)}>
          {messageType === MessageType.SENT && (
            <Avatar imgSrc={avatar} letter={whoLetter} />
          )}
        </div>
      </div>
    </div>
  );
};
