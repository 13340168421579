import { cn } from 'lib/utils';
import { formatPreviewDate } from 'utils/date';
import { PencilIcon } from 'lucide-react';
import { IEmailThread } from 'store/mailbox';

export interface IThreadPreviewProps {
  thread: IEmailThread;
  isActive: boolean;
};

export const MailThreadPreview = ({ thread, isActive }: IThreadPreviewProps) => {

  const getName = () => {
    if (thread?.messages?.length > 0) {
      const fromContact = thread?.messages?.[0]?.from?.[0] || thread?.drafts?.[0]?.from?.[0];
      return fromContact?.name || fromContact?.email || '-';
    }

    if (thread?.drafts?.length > 0) {
      return '[Draft]';
    }

    return '-'

  }

  const getDateAndTime = () => {
    const lastMessage = thread?.messages[thread?.messages?.length - 1];
    const lastDraft = thread?.drafts[thread?.drafts?.length - 1];

    if (lastMessage) {
      return formatPreviewDate(
        new Date(Math.floor(lastMessage?.date * 1000))
      )
    }

    if (lastDraft) {
      return formatPreviewDate(
        new Date(Math.floor(lastDraft?.date * 1000))
      )
    }

    return '-'
  }


  return (
    <div className='xxx'>
      <button
        className={cn(
          "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
          isActive && "bg-muted"
        )}
      >
        <div className="flex w-full flex-col gap-1">
          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <div className="font-semibold line-clamp-1">
                {getName()}
              </div>
              {!!thread?.messages?.length && thread?.messages?.length > 1 && (
                <span className='font-normal'>
                  ({thread.messages?.length})
                </span>
              )}
              {thread?.unread && (
                <span className="flex h-2 w-2 rounded-full bg-blue-600" />
              )}
            </div>

            <div
              className={cn(
                "ml-auto text-xs flex shrink-0 items-center",
                isActive ? "text-foreground" : "text-muted-foreground"
              )}
            >
              {!!(thread && thread?.drafts?.length) && <PencilIcon className='size-4 mr-1' />}
              {getDateAndTime()}
            </div>
          </div>
          <div className="text-xs font-medium">
            {thread?.subject || '(no subject)'}</div>
        </div>

        <div className="line-clamp-2 text-xs text-muted-foreground">
          {thread?.snippet || thread?.drafts?.[0]?.snippet || '(no body)'}
        </div>
      </button>
    </div>
  );
}



