import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
const getUsersState = (state: RootState) => state.users;

export const advisorsMailboxLoading = createSelector(
  [getUsersState],
  ({ advisorsMailboxLoading }) => advisorsMailboxLoading
);

export const selectAdvisorMailboxes = createSelector(
  [getUsersState],
  ({ advisorMailboxes }) => advisorMailboxes
);

export const selectActiveMailbox = createSelector(
  [getUsersState],
  ({ activeAdvisorMailbox }) => activeAdvisorMailbox
);

export const selectActiveMailboxConnectedEmail = createSelector(
  [getUsersState],
  ({ activeAdvisorMailbox }) => activeAdvisorMailbox?.connected_emails?.[0]
);

export const selectConnectedEmailAuthId = createSelector(
  [getUsersState],
  ({ activeAdvisorMailbox }) =>
    activeAdvisorMailbox?.connected_emails?.[0]?.authId
);

// ===================================

export const selectIsGetUsersListProcessing = createSelector(
  [getUsersState],
  ({ isGetUsersListProcessing }) => isGetUsersListProcessing
);

export const selectUsersList = createSelector(
  [getUsersState],
  ({ usersList }) => usersList
);

export const selectActiveUser = createSelector(
  [getUsersState],
  ({ activeUser }) => activeUser
);

export const selectIsUserOperationProcessing = createSelector(
  [getUsersState],
  ({ isUserOperationProcessing }) => isUserOperationProcessing
);
