import { Close, Pause, UploadCloud } from 'icons'
import { Button, LoadingRow } from 'components_old'
import 'react-dropzone-uploader/dist/styles.css'
import styles from './FileUploader.module.scss'
import Dropzone, {
  IDropzoneProps, IPreviewProps, ILayoutProps, ISubmitButtonProps, formatBytes, formatDuration, IFileWithMeta, StatusValue
} from 'react-dropzone-uploader'
import classnames from 'classnames'

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
  const className = classnames(dropzoneProps.className, { [styles.dropzoneNoMoreDrop]: files.length >= maxFiles })

  return (
    <div className={styles.Layout}>
      {previews && previews.length > 0 && (
        <div className={styles.PreviewContainer}>
          {previews}
        </div>
      )}

      <div {...{ ...dropzoneProps, className }}>
        {files.length < maxFiles && input}
      </div>

      {submitButton}
    </div>
  )
}

const Preview = (props: IPreviewProps) => {
  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: { name = '', percent = 0, size = 0, previewUrl, status, duration, validationError },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    extra: { minSizeBytes },
  } = props

  let title = `${name || '?'}, ${formatBytes(size)}`
  if (duration) title = `${title}, ${formatDuration(duration)}`

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
        {status === 'error_validation' && <span>{String(validationError)}</span>}
        {canRemove && <span className="dzu-previewButton" onClick={remove}><Close /></span>}
      </div>
    )
  }

  if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
    title = `${title} (upload failed)`
  }
  if (status === 'aborted') title = `${title} (cancelled)`

  return (
    <div className={className} style={style}>
      {previewUrl && <img className={imageClassName} style={imageStyle} src={previewUrl} alt={title} title={title} />}
      {!previewUrl && <span className="dzu-previewFileName">{title}</span>}

      <div className="dzu-previewStatusContainer">
        {isUpload && (
          <progress max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent} />
        )}

        {status === 'uploading' && canCancel && (
          <span className="dzu-previewButton" onClick={cancel}><Pause /></span>
        )}
        {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
          <span className="dzu-previewButton" onClick={remove}>
            <Close />
          </span>
        )}
        {/* {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
          canRestart && <span className="dzu-previewButton" onClick={restart}><Refresh /></span>} */}
      </div>
    </div>
  )
}

const SubmitButton = (props: ISubmitButtonProps) => {
  const { disabled, content, onSubmit, files } = props

  const _disabled =
    files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
    !files.some(f => ['headers_received', 'done'].includes(f.meta.status))
  const isDisable =
    files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
    !files.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))

  const handleSubmit = () => {
    onSubmit(files.filter(f => ['headers_received', 'done'].includes(f.meta.status)))
  }

  return (
    !!onSubmit ?
      <Button onClick={handleSubmit} disabled={disabled || isDisable} >
        {content}
      </Button> : <div></div>
  )
}

const NoSubmitButton = (props: ISubmitButtonProps) => {
  return (
    <div></div>
  )
}

interface IProps extends Partial<IDropzoneProps> {
  // multiple?: boolean
  onSubmit?(successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]): void,
  isLoading?: boolean;
  showSubmitButton?: boolean;
  onChangeStatus?(file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]): void,
}

export const FileUploader = ({
  onSubmit, onChangeStatus, isLoading = false, showSubmitButton = true, ...rest
}: IProps) => {
  const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    if (onSubmit) {
      onSubmit(files, allFiles)
    }
  }

  return (
    <Dropzone
      classNames={{ dropzone: styles.dropzone, inputLabelWithFiles: styles.inputLabelWithFiles, inputLabel: styles.inputLabel, preview: styles.preview }}
      inputContent={!isLoading ? <UploadCloud size={24} /> : <LoadingRow />}
      inputWithFilesContent={!isLoading ? <UploadCloud size={24} /> : <LoadingRow />}
      onChangeStatus={onChangeStatus}
      onSubmit={handleSubmit}
      LayoutComponent={Layout}
      PreviewComponent={Preview}
      SubmitButtonComponent={showSubmitButton ? SubmitButton : NoSubmitButton}
      autoUpload={false}
      disabled={isLoading}
      {...rest}
    />
  )
}