import { useTranslation } from 'hooks';
import { ROUTES } from 'routing/constants';
import { SideMenuSubLayout } from 'components/layout/SideMenuSubLayout';

export const ParaplannerAIPage = () => {
  const { t } = useTranslation();

  return (
    <SideMenuSubLayout
      menuItems={[
        {
          label: t("page.ParaplannerAIPage.uploadFile"),
          to: ROUTES.APP.PARAPLANNER.UPLOAD_FILE,
        },
        {
          label: t("page.ParaplannerAIPage.shareLink"),
          to: ROUTES.APP.PARAPLANNER.SHARE_LINK,
        },
        {
          label: t("page.ParaplannerAIPage.statementExtraction"),
          to: ROUTES.APP.PARAPLANNER.STATEMENT_EXTRACTION,
        },
      ]}
    />
  );
};

