
import styles from './Congrats.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { Button, ContactUs, LoadingRow, ParaplannerAI, StepperTitle } from 'components_old';
import { useSelector } from 'react-redux';
import { createRequest, selectRequestPayload, selectAnalysisProcessing, resetRequestPayload, selectRequestSuccessful, resetWizardHistory } from 'store/analysis';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { IStepMandatoryProps } from '../../../types';

export const Congrats = ({ handleGoToNextStep, nextStep }: IStepMandatoryProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestPayload = useSelector(selectRequestPayload);
  const processing = useSelector(selectAnalysisProcessing);
  const requestSuccessful = useSelector(selectRequestSuccessful);


  useEffect(() => {
    if (!isEmpty(requestPayload)) {
      dispatch(createRequest(requestPayload));
      dispatch(resetRequestPayload());
    }
  }, [requestPayload, dispatch])


  return (
    processing ? (
      <div className={styles.Wrapper}>
        <LoadingRow />
      </div>
    ) : (
      <div className={styles.Wrapper}>
        {requestSuccessful ? (
          <>
            <StepperTitle>
              <ParaplannerAI />
              <div>{t('analysis.congrats.titleA')}</div>
              <div>{t('analysis.congrats.titleB')}</div>
            </StepperTitle>
            <StepperTitle>
              <div>{t('analysis.congrats.aiBusy')}</div>
            </StepperTitle>
          </>
        ) : (
          <StepperTitle>
            <ParaplannerAI />
            <div>{t('analysis.congrats.errorOccurred')}</div>
          </StepperTitle>
        )}

        <StepperTitle>
          <div>{t('common.somethingUrgent')}</div>
          <ContactUs
            email={t('analysis.congrats.contact.email')}
            subject={t('analysis.congrats.contact.subject')}
            body={t('analysis.congrats.contact.body')}
          /> {t('common.forSupport')}
        </StepperTitle>
        <Button onClick={async () => {
          await dispatch(resetWizardHistory());
          // mixpanel.track(EVENT.ANALYTICS.ANOTHER_REQUEST);
          handleGoToNextStep({ action: nextStep });
        }}>
          {t('analysis.congrats.newRequest')}
        </Button>
      </div >
    )
  );
}
