import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import {
  IGetEmailAttachmentPayload,
  IGetMessagePayload,
  IGetThreadsListPayload,
  IUpdateDraftPayload,
  IGetThreadPayload,
  ICreateDraftPayload,
  IDeleteDraftPayload,
  IGetDraftPayload,
  IDeleteFilePayload,
  IUpdateThreadLabelPayload,
  IDeleteThreadLabelPayload,
  ISearchThreadsListPayload,
} from "./types";
import { ACTION } from "const/actions";

export const getThreadsList = createAsyncThunk(
  ACTION.MAILBOX.GET_THREADS_LIST,
  async (payload: IGetThreadsListPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getThreadsList(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const searchThreadsList = createAsyncThunk(
  ACTION.MAILBOX.SEARCH_THREADS_LIST,
  async (payload: ISearchThreadsListPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.searchThreadsList(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getDraftsList = createAsyncThunk(
  ACTION.MAILBOX.GET_DRAFTS_LIST,
  async (payload: IGetThreadsListPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getDraftsList(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getArchivedThreadsList = createAsyncThunk(
  ACTION.MAILBOX.GET_ARCHIVED_THREADS_LIST,
  async (payload: IGetThreadsListPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getArchivedThreadsList(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getThreadsWithConversationList = createAsyncThunk(
  ACTION.MAILBOX.GET_ARCHIVED_THREADS_LIST,
  async (payload: IGetThreadsListPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getThreadsWithConversationList(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getMessage = createAsyncThunk(
  ACTION.MAILBOX.GET_MESSAGE,
  async (payload: IGetMessagePayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getMessage(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getDraft = createAsyncThunk(
  ACTION.MAILBOX.GET_DRAFT,
  async (payload: IGetDraftPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getDraft(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getEmailAttachment = createAsyncThunk(
  "getEmailAttachment",
  async (payload: IGetEmailAttachmentPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getEmailAttachment(payload);
      const blobFile = window.URL.createObjectURL(response);

      const a = document.createElement("a");
      a.href = blobFile;
      a.download = payload.file.filename ?? payload.file.id;
      a.target = "_blank";
      a.click();
      a.remove();
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const uploadFiles = createAsyncThunk(
  "uploadFiles",
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.uploadFiles(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getThreadDetails = createAsyncThunk(
  "getThreadDetails",
  async (payload: IGetThreadPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.getThreadDetails(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (payload: IDeleteFilePayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.deleteFile(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createDraft = createAsyncThunk(
  "createDraft",
  async (payload: ICreateDraftPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.createDraft(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateDraft = createAsyncThunk(
  "updateDraft",
  async (payload: IUpdateDraftPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.updateDraft(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteDraft = createAsyncThunk(
  "deleteDraft",
  async (payload: IDeleteDraftPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.deleteDraft(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateThreadLabel = createAsyncThunk(
  ACTION.MAILBOX.UPDATE_THREAD_LABEL,
  async (payload: IUpdateThreadLabelPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.updateThreadLabel(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteThreadLabel = createAsyncThunk(
  ACTION.MAILBOX.DELETE_THREAD_LABEL,
  async (payload: IDeleteThreadLabelPayload, { rejectWithValue }) => {
    try {
      const response = await api.mailbox.deleteThreadLabel(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
