import styles from './ComparisonWizard.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ICreateComparisonRequest, listPortfolioModels, selectGettingModels, selectPortfolioModels, updateComparisonRequestPayload } from 'store/portfolioModel';
import {
  AddPortfolioModel, ComparisonGettingStarted, HowToAddModel, LoadingRow, Stepper, UploadPortfolioModel, FileSecureLink, FileUpload, HowToShareFile, CongratsComparison, MatchAccountsWithPortfolios
} from 'components_old';
import { MODELS_COMPARISON_STEP, TModelsComparisonStep } from '../types';
import { useSelector } from 'react-redux';


export const ComparisonWizard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isGettingModels = useSelector(selectGettingModels);
  const portfolioModels = useSelector(selectPortfolioModels);

  const [stepsArray, setStepsArray] = useState<Array<TModelsComparisonStep>>([MODELS_COMPARISON_STEP.S1]);

  useEffect(() => {
    if (stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S1) {
      dispatch(listPortfolioModels())
    }
  }, [stepsArray, dispatch])


  if (isGettingModels && !portfolioModels?.length) {
    return <LoadingRow />
  }

  const handleGoToNextStep = (whereToStep: TModelsComparisonStep, payload?: ICreateComparisonRequest) => {
    const isAnotherRequest = stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S7 && whereToStep === MODELS_COMPARISON_STEP.S1;

    setStepsArray(isAnotherRequest ? [whereToStep] : [...stepsArray, whereToStep]);

    // mixpanel.track(whereToStep, payload);

    if (payload) {
      dispatch(updateComparisonRequestPayload(payload))
    }
  }

  const handleBackAction = () => {
    setStepsArray(stepsArray.slice(0, -1));
    // mixpanel.track(EVENT.PORTFOLIO_MODELS.BACK);
  }

  return (
    <Stepper
      activeStep={0}
      totalSteps={0}
      showSteps={false}
      showBackBtn={stepsArray?.length > 1}
      handleBackAction={handleBackAction}
      stepperClassName={
        classnames(
          { [styles.StepperNarrow]: MODELS_COMPARISON_STEP.S1 === stepsArray[stepsArray.length - 1] || true }
        )
      }
    >
      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S1 && (
        <ComparisonGettingStarted handleGoToNextStep={handleGoToNextStep} nextStep={portfolioModels?.length ? MODELS_COMPARISON_STEP.S4 : MODELS_COMPARISON_STEP.S2} />
      )}
      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S2 && (
        <HowToAddModel<TModelsComparisonStep> handleGoToNextStep={handleGoToNextStep} nextSteps={[MODELS_COMPARISON_STEP.S3A, MODELS_COMPARISON_STEP.S3B]} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S3A && (
        <AddPortfolioModel handleExit={() => handleGoToNextStep(MODELS_COMPARISON_STEP.S4)} saveAndExitText={t('common.continue')} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S3B && (
        <UploadPortfolioModel handleExit={() => handleGoToNextStep(MODELS_COMPARISON_STEP.S4)} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S4 && (
        <HowToShareFile handleGoToNextStep={handleGoToNextStep} nextSteps={[MODELS_COMPARISON_STEP.S5A, MODELS_COMPARISON_STEP.S5B]} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S5A && (
        <FileUpload handleGoToNextStep={handleGoToNextStep} nextStep={MODELS_COMPARISON_STEP.S6} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S5B && (
        <FileSecureLink handleGoToNextStep={handleGoToNextStep} nextStep={MODELS_COMPARISON_STEP.S6} />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S6 && (
        <MatchAccountsWithPortfolios handleGoToNextStep={handleGoToNextStep} nextStep={MODELS_COMPARISON_STEP.S7}  />
      )}

      {stepsArray[stepsArray.length - 1] === MODELS_COMPARISON_STEP.S7 && (
        <CongratsComparison handleGoToNextStep={handleGoToNextStep} nextStep={MODELS_COMPARISON_STEP.S1} />
      )}
    </Stepper>
  );
}
