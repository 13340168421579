import { ReactNode } from 'react';

import styles from './Panels.module.scss';

interface IPanelsProps {
  children?: ReactNode | undefined;
}

export const Panels = ({ children }: IPanelsProps) => {
  return <div className={styles.Panels}>{children}</div>;
};
