import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";
import { IDataPoint } from "./types";

export const getDataPointsList = createAsyncThunk(
  ACTION.DATA_POINTS.GET_DATA_POINTS,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.dataPoints.getDataPoints();
      return response;
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const createDataPoint = createAsyncThunk(
  ACTION.DATA_POINTS.CREATE_DATA_POINT,
  async (payload: IDataPoint, { rejectWithValue }) => {
    try {
      const response = await api.dataPoints.createDataPoint(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateDataPoint = createAsyncThunk(
  ACTION.DATA_POINTS.UPDATE_DATA_POINT,
  async (payload: IDataPoint, { rejectWithValue }) => {
    try {
      const response = await api.dataPoints.updateDataPoint(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteDataPoint = createAsyncThunk(
  ACTION.DATA_POINTS.DELETE_DATA_POINT,
  async (payload: IDataPoint, { rejectWithValue }) => {
    try {
      const response = await api.dataPoints.deleteDataPoint(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
