import { processApiError } from "services";
import { api } from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION } from "const/actions";
import { IConnectRedtailPayload } from "./types";

export const connectRedtail = createAsyncThunk(
  ACTION.REDTAIL.CONNECT,
  async (payload: IConnectRedtailPayload, { rejectWithValue }) => {
    try {
      const response = await api.redtail.connectRedtail(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);


export const disconnectRedtail = createAsyncThunk(
  ACTION.REDTAIL.DISCONNECT,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.redtail.disconnectRedtail();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
