import { ReactNode } from 'react';
import styles from './PanelHeader.module.scss';

interface IPanelHeaderProps {
  children?: ReactNode | undefined;
}

export const PanelHeader = ({ children }: IPanelHeaderProps) => {
  return (
    <div className={styles.PanelHeader}>
      {children}
    </div>
  );
};
