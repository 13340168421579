import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IOrganizationsApi } from "./types";

export const organizations: IOrganizationsApi = {
  createOrganization: (payload) =>
    http
      .post(API_ROUTES.organizations.createOrganization, payload)
      .then((response) => response.data),
  getOrganizations: (_) =>
    http
      .get(API_ROUTES.organizations.getOrganizations)
      .then((response) => response.data),
  getOrganization: (payload) =>
    http
      .get(API_ROUTES.organizations.getOrganization(payload.id))
      .then((response) => response.data),
  updateOrganization: (payload) =>
    http
      .put(API_ROUTES.organizations.updateOrganization(payload.id), payload)
      .then((response) => response.data),
  deleteOrganization: (payload) =>
    http
      .delete(API_ROUTES.organizations.deleteOrganization(payload.id))
      .then((response) => response.data),
};
