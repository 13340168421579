import { Fragment, useEffect } from 'react';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useSelector } from 'react-redux';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IHeadsUpCard, createCard, selectIsCreatingCard, selectIsUpdatingCard, updateCard } from 'store/heads-up-cards';
import { Textarea } from 'components/ui/textarea';
import { MIXPANEL } from 'const';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  card?: IHeadsUpCard;
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
}

export const CreateEditHeadsUpCardModal = ({ card, isVisible, setIsVisible }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isCreatingCard = useSelector(selectIsCreatingCard);
  const isUpdatingCard = useSelector(selectIsUpdatingCard);

  const formSchema = z.object({
    id: z.string().optional(),
    title: z.string().min(1, t('errors.required') || ''),
    content: z.string().min(1, t('errors.required') || ''),
    longContent: z.string().min(1, t('errors.required') || ''),
    context: z.string().min(1, t('errors.required') || ''),
  });

  const form = useForm<Required<IHeadsUpCard>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: card || {
      id: uuidv4(),
      title: '',
      content: '',
      longContent: '',
      context: ''
    }
  });

  useEffect(() => {
    if (card) {
      form.reset(card)
    }
  }, [card, form])


  const handleOnSubmit = (values: IHeadsUpCard) => {
    if (card) {
      onUpdateCard(values)
    } else {
      onCreateCard(values)
    }
  }

  const onCreateCard = async (values: IHeadsUpCard) => {
    const response = await dispatch(createCard(values));

    if (!response?.hasOwnProperty('error')) {
      form.reset();
      setIsVisible(false);
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CARDS.CREATE_CARD })
      toast(t('component.CreateEditHeadsUpCardModal.createSuccess'))
    }
  }

  const onUpdateCard = async (values: IHeadsUpCard) => {
    const response = await dispatch(updateCard(values));

    if (!response?.hasOwnProperty('error')) {
      form.reset();
      setIsVisible(false);
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CARDS.UPDATE_CARD })
      toast(t('component.CreateEditHeadsUpCardModal.updateSuccess'))
    }
  }

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="max-w-lg overflow-auto max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>{t('component.CreateEditHeadsUpCardModal.title')}</DialogTitle>
            <DialogDescription>
              {t('component.CreateEditHeadsUpCardModal.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="id"
                render={({ field }) => (
                  <FormItem className='hidden'>
                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isCreatingCard || isUpdatingCard} className='hidden' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.CreateEditHeadsUpCardModal.cardTitle')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.CreateEditHeadsUpCardModal.cardTitleDesc')}
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isCreatingCard || isUpdatingCard} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.CreateEditHeadsUpCardModal.content')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.CreateEditHeadsUpCardModal.contentDesc')}
                    </FormLabel>

                    <FormControl>
                      <Textarea rows={2} {...field} data-1p-ignore disabled={isCreatingCard || isUpdatingCard} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="longContent"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.CreateEditHeadsUpCardModal.longContent')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.CreateEditHeadsUpCardModal.longContentDesc')}
                    </FormLabel>

                    <FormControl>
                      <Textarea rows={3} {...field} data-1p-ignore disabled={isCreatingCard || isUpdatingCard} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="context"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.CreateEditHeadsUpCardModal.context')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.CreateEditHeadsUpCardModal.contextDesc')}
                    </FormLabel>

                    <FormControl>
                      <Textarea rows={3} {...field} data-1p-ignore disabled={isCreatingCard || isUpdatingCard} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />


              <DialogFooter>
                <Button type="submit" loading={isCreatingCard || isUpdatingCard}>
                  {!card ? t('component.CreateEditHeadsUpCardModal.createBtn') : t('component.CreateEditHeadsUpCardModal.updateBtn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
