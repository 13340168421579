import { Close } from 'icons';
import styles from './Tag.module.scss';
import { ReactNode } from 'react';
import classnames from 'classnames';

interface ITag {
  text: ReactNode;
  onClose?: Function;
  className?: string;
}
export const Tag = ({
  text,
  onClose,
  className
}: ITag) => {
  return (
    <div className={classnames(styles.Tag, className)}>
      {text}
      {onClose && <Close iconClassName={styles.Icon} onClick={onClose} hasBorder={false} hasHover={false} />}
    </div>
  );
};
