import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

const getAuthenticationState = (state: RootState) => state.authentication;

export const getAuthenticationLoading = createSelector(
  [getAuthenticationState],
  (authentication) => authentication.loading
);

export const getUser = createSelector(
  [getAuthenticationState],
  (authentication) => authentication.user
);

export const getUserRole = createSelector([getUser], (user) => user.role);
export const getUserEmail = createSelector([getUser], (user) => user.email);

export const getAccessToken = createSelector(
  [getAuthenticationState],
  (authentication) => authentication.accessToken
);

export const getRefreshToken = createSelector(
  [getAuthenticationState],
  (authentication) => authentication.refreshToken
);
