import { Card, CardHeader, CardTitle } from 'components/ui/card';
import { cn } from 'lib/utils';
import { isEqual } from 'lodash';
import { ICalendarEvent } from 'store/calendar';
import { convertUtcToLocalTime } from 'utils/misc';

interface ICalendarEventItemProps {
  item: ICalendarEvent
  selectedItem: ICalendarEvent | null
  handleClick: (item: ICalendarEvent) => void
  isAdHoc?: boolean
}

export const CalendarEventItem = ({ item, selectedItem, handleClick, isAdHoc }: ICalendarEventItemProps) => {
  const maxNoParticipants = 2;

  return (
    <Card
      variant="item"
      className={cn("cursor-pointer", { "border-primary": isEqual(item, selectedItem) })}
      onClick={() => { handleClick && handleClick(item) }}
    >
      <CardHeader className="p-3">
        <CardTitle className="flex flex-row items-center gap-3">
          {!isAdHoc && item?.startTime && (
            <div className='text-sm font-normal text-muted-foreground min-w-20 flex justify-between'>
              <div className=''>{convertUtcToLocalTime(item?.startTime)}</div>
              <div>-</div>
              <div className=''>{convertUtcToLocalTime(item?.endTime)}</div>
            </div>
          )}

          <div className='flex flex-col gap-1 w-full'>
            <div className={cn('text-sm', { 'text-center': isAdHoc })}>{item.summary}</div>
            {item?.participants && item?.participants?.length > 0 && (
              <div>
                {item?.participants?.slice(0, maxNoParticipants)?.map(participant => {
                  return (
                    <div key={`${item?.meetingUrl}-${participant}`} className='text-xs font-normal'>
                      {participant}
                    </div>
                  )
                })}

                {item?.participants && item?.participants?.length > maxNoParticipants && (
                  <div className='text-xs font-normal'>
                    {`+ ${item?.participants?.length - maxNoParticipants} more`}
                  </div>
                )}
              </div>
            )}
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  )
}
