import { ReactNode } from 'react';
import { cn } from 'lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';
import { Button } from './button';

interface IProps {
  children: ReactNode,
  legend?: ReactNode,
  fieldsetActions?: FieldsetAction[],
  className?: string,
  legendClassName?: string,
  wrapperClassName?: string,
  divVersion?: boolean,
}

export interface FieldsetAction {
  tooltip: ReactNode;
  onClick: Function;
  content: ReactNode
}

export const Fieldset = ({ children, legend, fieldsetActions, className, legendClassName, wrapperClassName, divVersion }: IProps) => {
  const fieldsetDefaultClasses = "relative flex flex-col flex-1 overflow-auto h-full gap-3 rounded-lg border p-4 text-card-foreground bg-card";
  const legendDefaultClasses = cn("-ml-1 px-1 text-sm font-medium break-all", { "px-0": !legend });

  return (
    !divVersion ? (
      <fieldset className={cn(fieldsetDefaultClasses, className)}>
        <legend className={cn(legendDefaultClasses, legendClassName)}>
          {legend}
          {
            fieldsetActions && fieldsetActions?.length > 0 && (
              <div className='absolute right-4 -top-1 flex gap-2'>
                {fieldsetActions?.map((action, index) => {
                  return (
                    <Tooltip key={index}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="secondary"
                          className="bg-neutral-50 rounded-full focus-visible:ring-0" size="icon"
                          onClick={() => action.onClick && action.onClick()}
                        >
                          {action.content}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {action.tooltip}
                      </TooltipContent>
                    </Tooltip>
                  )
                })}
              </div>
            )
          }
        </legend>
        {children}
      </fieldset>
    ) : (
      <div className={cn("relative pt-2", wrapperClassName)}>
          <legend className={cn(legendDefaultClasses, "absolute -top-0.5 left-3 z-10", legendClassName)}>
            {legend}
          </legend>
        <div className={cn(fieldsetDefaultClasses, className)}>
          {children}
        </div>
      </div>
    )
  );
};

