import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector, useBodyClass } from 'hooks';
import { getUser } from 'store/authentication';
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import { GENERAL_CONVERSATION_ID, RoleEnum, INTERCOM_APP } from "const";
import { getConversationId, setIntercomChatVisibility } from 'store/chat';
import { subscribeUser } from '../../subscription';
import { getGeneralConversation } from 'store/notifications';
import NotificationsCenter from './NotificationsCenter';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routing/constants';

export const Intercom = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isIntercomVisible = pathname.includes(ROUTES.APP.PARAPLANNER.DEFAULT);
  const { addBodyClass, removeBodyClass } = useBodyClass("");
  if (!isIntercomVisible) {
    addBodyClass('intercom-hidden');
  } else {
    removeBodyClass('intercom-hidden');
  }

  const hasSubscribed = useRef(false);

  const currentUser = useAppSelector(getUser);
  const myProfileData = useAppSelector(selectProfileDetails)

  useEffect(() => {
    return () => {
      addBodyClass('intercom-hidden');
    }
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(getProfileDetails())
    }
  }, [currentUser, dispatch]);


  useEffect(() => {
    if (hasSubscribed.current) {
      return
    }

    if (myProfileData.role === RoleEnum.Advisor) {
      // @ts-ignore
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: INTERCOM_APP,
        name: `${myProfileData?.firstName} ${myProfileData?.lastName}`,
        email: myProfileData?.email,
        user_id: myProfileData?.id,
        user_hash: myProfileData?.hashUserId
      });

      // @ts-ignore
      window.Intercom('onHide', () => {
        dispatch(setIntercomChatVisibility(false))
      });

      // @ts-ignore
      window.Intercom('onShow', () => {
        dispatch(setIntercomChatVisibility(true))
      });

      if (myProfileData?.role === RoleEnum.Advisor) {
        setTimeout(() => {
          dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, forceIsOpen: true }))
        }, 2000);
      }


      if (myProfileData.id) {
        subscribeUser(myProfileData.id)
        hasSubscribed.current = true;
      }
    }
  }, [myProfileData, dispatch])

  useEffect(() => {
    if (!myProfileData.id) {
      return
    }

    if (myProfileData?.role === RoleEnum.Advisor) {
      dispatch(getGeneralConversation())
    }
  }, [myProfileData, dispatch])

  return (
    <div className='absolute size-4 -top-5 -left-5'>
      {myProfileData?.role === RoleEnum.Advisor && myProfileData?.id && (
        <NotificationsCenter id={myProfileData?.id} />
      )}
    </div>
  );
};
