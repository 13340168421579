export interface INotificationsState {
  markingAsRead: boolean;
  gettingGeneralConversation: boolean;
  generalConversationRecord: IConversationRecord | null;
}

export interface IMarkAsReadPayload {
  conversation_id: string;
}

export interface IConversationRecord {
  emailThreadId: string;
  id: string;
  intercomConversationId: string;
  isActive: boolean;
  isUnread: boolean;
  nylasAuthId: string;
  type: string;
  userId: string;
}

export const CONVERSATION_RECORD_TYPE = {
  GENERAL: "general",
  THREAD: "thread",
};
