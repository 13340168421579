import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getMailboxState = (state: RootState) => state.mailbox;

export const selectThreadsListData = createSelector(
  [getMailboxState],
  ({ threadsList }) => threadsList?.data
);

export const selectThreadsListOffset = createSelector(
  [getMailboxState],
  ({ threadsList }) => threadsList?.offset
);

export const selectThreadsListHasMoreData = createSelector(
  [getMailboxState],
  ({ threadsList }) => threadsList?.hasMoreData
);

export const selectThreadsListIsLoading = createSelector(
  [getMailboxState],
  ({ threadsList }) => threadsList?.isLoading
);

export const selectThreadListLoading = createSelector(
  [getMailboxState],
  ({ threadsList }) => threadsList?.isLoading
);

export const selectLoadingGetThreadDetails = createSelector(
  [getMailboxState],
  ({ loadingGetThreadDetails }) => loadingGetThreadDetails
);


export const selectSelectedThread = createSelector(
  [getMailboxState],
  ({ selectedThread }) => selectedThread
);

export const selectLoadingThreadMessageId = createSelector(
  [getMailboxState],
  ({ loadingThreadMessageId }) => loadingThreadMessageId
);

export const selectCreatingDraft = createSelector(
  [getMailboxState],
  ({ creatingDraft }) => creatingDraft
);

export const selectGettingDraft = createSelector(
  [getMailboxState],
  ({ gettingDraft }) => gettingDraft
);

export const selectUpdatingDraft = createSelector(
  [getMailboxState],
  ({ updatingDraft }) => updatingDraft
);

export const selectDeletingDraft = createSelector(
  [getMailboxState],
  ({ deletingDraft }) => deletingDraft
);

export const selectUploadingFiles = createSelector(
  [getMailboxState],
  ({ uploadingFiles }) => uploadingFiles
);

export const selectDeletingFiles = createSelector(
  [getMailboxState],
  ({ deletingFiles }) => deletingFiles
);

export const selectExpandedMessageIds = createSelector(
  [getMailboxState],
  ({ expandedMessageIds }) => expandedMessageIds
);

export const selectDownloadingFile = createSelector(
  [getMailboxState],
  ({ downloadingFile }) => downloadingFile
);

export const selectExecutingActionOnThreadId = createSelector(
  [getMailboxState],
  ({ executingActionOnThreadId }) => executingActionOnThreadId
);

export const selectIsRefreshingMailbox = createSelector(
  [getMailboxState],
  ({ isRefreshingMailbox }) => isRefreshingMailbox
);

