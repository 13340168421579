import styles from './PortfolioModelForm.module.scss'
import { useTranslation } from 'hooks';
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Add, Close } from 'icons';
import { v4 as uuidv4 } from 'uuid';
import { IPortfolioModel, IPortfolioModelFormValues } from 'store/portfolioModel';
import { INPUT_TYPE, Input } from 'components_old';

interface IPortfolioModelForm {
  register: UseFormRegister<IPortfolioModelFormValues>;
  control: Control<IPortfolioModelFormValues, any>;
  getValues: UseFormGetValues<IPortfolioModelFormValues>;
  setValue: UseFormSetValue<IPortfolioModelFormValues>;
  watch: UseFormWatch<IPortfolioModelFormValues>;
  trigger: UseFormTrigger<IPortfolioModelFormValues>;
  errors: FieldErrors<IPortfolioModelFormValues>,
  isValid: boolean;
  model: IPortfolioModel;
  modelIndex: number;
  isEditing?: boolean;
  isProcessing?: boolean;
  onDelete?: Function;
  onDuplicate?: Function;
}

export const PortfolioModelForm = ({
  register,
  control,
  getValues,
  setValue,
  watch,
  trigger,
  errors,
  isValid,
  model,
  modelIndex,
  isEditing,
  isProcessing,
  onDelete,
  onDuplicate
}: IPortfolioModelForm) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    name: `models.${modelIndex}.tickers`,
    control
  });

  const [sum, setSum] = useState<number | undefined>(undefined)

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      trigger();

      const allocations = value?.models?.[modelIndex]?.tickers?.map(item => item?.allocation)

      if (allocations?.some(value => !value || !isFinite(+value))) {
        setSum(undefined)
      } else {
        const total = allocations?.reduce((acc, curr) => acc + parseFloat(curr || '0'), 0);
        setSum(total)
      };
    });

    return () => {
      subscription.unsubscribe()
    }
  }, [watch, trigger, modelIndex]);

  useEffect(() => {
    setValue(`models.${modelIndex}.allocationsTotal`, (sum || '').toString())
  }, [sum, setValue, modelIndex])

  const validateValueRange = (value: any) => {
    if (!isFinite(+value)) return false;

    const numericValue = parseFloat(value.toString());
    return numericValue >= 0 && numericValue <= 100;
  };

  return (
    <div
      key={model.id}
      className={styles.ModelWrapper}
    >
      <div
        className={styles.TitleWrapper}
      >
        <Input
          placeholder={t("placeholder.portfolioName") as string}
          {...register(`models.${modelIndex}.name` as const, {
            required: true
          })}
          className={classnames(styles.Title, {
            [styles.Bold]: !isEditing
          })}
          disabled={!isEditing || isProcessing}
          lookType={INPUT_TYPE.MINIMALIST}
          error={!!errors?.models?.[modelIndex]?.name}
          isProcessing={isProcessing}
        />


        <Close
          size={16}
          tooltip={t('common.deleteModel')}
          wrapperStyle={{ marginLeft: 2 }}
          iconClassName={classnames(styles.Icon, { [styles.Hidden]: !isEditing || !onDelete })}
          onClick={onDelete}
        />

        {/* <Copy
          size={16}
          tooltip={t('common.duplicateModel')}
          wrapperStyle={{ marginLeft: 2 }}
          iconClassName={classnames(styles.Icon, { [styles.Hidden]: !isEditing || !onDuplicate })}
          onClick={onDuplicate}
        /> */}
      </div>

      {fields.map((field, tickerIndex) => {
        return (
          <div
            key={field.id}
            className={classnames(styles.ItemWrapper, styles.Uppercase)}
          >
            <Input
              placeholder={t("common.ticker") as string}
              {...register(`models.${modelIndex}.tickers.${tickerIndex}.ticker` as const, {
                required: true
              })}
              className={classnames(styles.Input, styles.Uppercase)}
              disabled={!isEditing || isProcessing}
              lookType={INPUT_TYPE.MINIMALIST}
              error={!!errors?.models?.[modelIndex]?.tickers?.[tickerIndex]?.ticker}
              isProcessing={isProcessing}
            />

            {(!isEditing) ? (
              <div className={styles.DisplayValue}>
                {getValues(`models.${modelIndex}.tickers.${tickerIndex}.allocation`)}%
              </div>
            ) : (
              <Input
                placeholder={t("common.allocation") as string}
                {...register(`models.${modelIndex}.tickers.${tickerIndex}.allocation` as const, {
                  validate: {
                    numericValue: validateValueRange,
                  }
                })}
                className={classnames(styles.Input)}
                disabled={!isEditing || isProcessing}
                lookType={INPUT_TYPE.MINIMALIST}
                error={!!errors?.models?.[modelIndex]?.tickers?.[tickerIndex]?.allocation}
                isProcessing={isProcessing}
              />
            )}

            <Close
              size={16}
              tooltip={t('common.deleteTicker')}
              wrapperStyle={{ marginLeft: 2 }}
              iconClassName={classnames(styles.Icon, { [styles.Hidden]: !isEditing })}
              onClick={() => {
                // mixpanel.track(EVENT.PORTFOLIO_MODELS.DELETE_TICKER);
                remove(tickerIndex)
              }}
            />
          </div>
        );
      })}

      {isEditing && errors?.models?.[modelIndex]?.allocationsTotal && (
        <div className={styles.Error}>
          {t('errors.allocationSum', { sum: sum })}
          {errors?.models?.[modelIndex]?.allocationsTotal?.message}
        </div>
      )}

      <div className={classnames(styles.AddWrapper, { [styles.Hidden]: !isEditing })} >
        <Add
          size={16}
          tooltip={t('common.addTicker')}
          className={styles.IconAdd}
          iconClassName={styles.Icon}
          onClick={() => {
            // mixpanel.track(EVENT.PORTFOLIO_MODELS.ADD_TICKER);
            append({ id: uuidv4(), ticker: '', allocation: '' })
          }}
        />
        <input
          placeholder={t("common.ticker") as string}
          {...register(`models.${modelIndex}.allocationsTotal` as const, {
            min: 100,
            max: 100
          })}
          disabled={true}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
}
