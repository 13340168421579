import { ReactNode } from 'react';
import styles from './PanelBody.module.scss';
import classnames from 'classnames';

interface IPanelBodyProps {
  children?: ReactNode | undefined;
  hasHeader?: boolean;
  className?: string;
}

export const PanelBody = ({ children, hasHeader, className }: IPanelBodyProps) => {
  return (
    <div className={classnames(
      styles.PanelBody,
      { [styles['PanelBody--hasHeader']]: hasHeader },
      className
    )}>
      {children}
    </div>
  );
};
