import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
const getCalendarState = (state: RootState) => state.calendar;

export const selectIsGettingCalendarEvents = createSelector(
  [getCalendarState],
  ({ isGettingCalendarEvents }) => isGettingCalendarEvents
);

export const selectCalendarEvents = createSelector(
  [getCalendarState],
  ({ calendarEvents }) => calendarEvents
);

export const selectActiveCalendarEvent = createSelector(
  [getCalendarState],
  ({ activeCalendarEvent }) => activeCalendarEvent
);

export const selectIsGettingCalendarAuthLink = createSelector(
  [getCalendarState],
  ({ isGettingCalendarAuthLink: isGettingGoogleAuthLink }) => isGettingGoogleAuthLink
);
