import styles from './ThreadWithDraftCompose.module.scss';
import { useAppSelector } from 'hooks';
import { selectSelectedThread, selectLoadingGetThreadDetails, selectGettingDraft } from 'store/mailbox';
import { LoadingRow } from 'components_old/shared/LoadingRow';
import { EmailCompose } from '../EmailCompose';

export const ThreadWithDraftCompose = () => {
  const activeThread = useAppSelector(selectSelectedThread);

  const loadingGetThreadDetails = useAppSelector(selectLoadingGetThreadDetails);
  const gettingDraft = useAppSelector(selectGettingDraft);

  const loneDraft = activeThread?.drafts[activeThread?.drafts?.length - 1]

  if (!loneDraft) return (<></>)

  if (loadingGetThreadDetails || gettingDraft) {
    return <LoadingRow className={styles.LoadingThreadDetails} />
  }

  return (
    <div className={styles.Wrapper}>
      <EmailCompose targetEmail={null} draftEmail={loneDraft} />
    </div >
  );
}
