import { Icon, IIconProps } from './Icon';


export const ArrowGrow = (props: IIconProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_1974_1190)">
        <path d="M14.9997 2.65039H12.9423C12.8105 2.65042 12.6816 2.68953 12.572 2.76279C12.4624 2.83605 12.377 2.94015 12.3265 3.06195C12.2761 3.18375 12.2629 3.31778 12.2886 3.44708C12.3143 3.57638 12.3778 3.69516 12.471 3.78839L13.195 4.51239L10.8043 6.90239C10.6773 7.02379 10.5084 7.09154 10.3327 7.09154C10.1569 7.09154 9.98801 7.02379 9.86099 6.90239L9.74765 6.78839C9.36673 6.42444 8.86016 6.22134 8.33332 6.22134C7.80648 6.22134 7.29991 6.42444 6.91899 6.78839L3.51899 10.1884C3.39372 10.314 3.32348 10.4843 3.32373 10.6617C3.32398 10.8391 3.3947 11.0091 3.52032 11.1344C3.64595 11.2597 3.81619 11.3299 3.9936 11.3296C4.17101 11.3294 4.34105 11.2587 4.46632 11.1331L7.86632 7.73306C7.99327 7.61151 8.16223 7.54366 8.33799 7.54366C8.51374 7.54366 8.68271 7.61151 8.80965 7.73306L8.92299 7.84706C9.30407 8.21071 9.81057 8.4136 10.3373 8.4136C10.8641 8.4136 11.3706 8.21071 11.7517 7.84706L14.1423 5.45639L14.8663 6.18039C14.9599 6.27209 15.0784 6.3342 15.207 6.35896C15.3356 6.38371 15.4687 6.37003 15.5896 6.3196C15.7105 6.26918 15.8139 6.18425 15.8868 6.07543C15.9597 5.96661 15.999 5.83872 15.9997 5.70772V3.65039C15.9997 3.38517 15.8943 3.13082 15.7068 2.94328C15.5192 2.75575 15.2649 2.65039 14.9997 2.65039Z" fill="#696969" />
        <path d="M15.3333 14.6507H3.33333C2.8029 14.6507 2.29419 14.44 1.91912 14.0649C1.54405 13.6898 1.33333 13.1811 1.33333 12.6507V0.666667C1.33333 0.489856 1.2631 0.320286 1.13807 0.195262C1.01305 0.0702379 0.843478 0 0.666667 0C0.489856 0 0.320286 0.0702379 0.195262 0.195262C0.0702379 0.320286 0 0.489856 0 0.666667L0 12.6507C0.00105857 13.5344 0.352588 14.3816 0.97748 15.0065C1.60237 15.6314 2.4496 15.9829 3.33333 15.984H15.3333C15.5101 15.984 15.6797 15.9138 15.8047 15.7887C15.9298 15.6637 16 15.4941 16 15.3173C16 15.1405 15.9298 14.971 15.8047 14.8459C15.6797 14.7209 15.5101 14.6507 15.3333 14.6507Z" fill="#696969" />
      </g>
      <defs>
        <clipPath id="clip0_1974_1190">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

