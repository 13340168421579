import * as React from "react"
import {
  Inbox,
} from "lucide-react"

import { type Mail } from "../data"
import { useMail } from "../use-mail"
import { TooltipProvider } from "components/ui/tooltip"
import { Separator } from "components/ui/separator"
import { Nav } from "./nav"
import { AccountSwitcher } from "./account-switcher"
import { cn } from "lib/utils"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "components/ui/resizable"
import { MailList } from "./mail-list"
import { MailDisplay } from "./mail-display"
import { useAppSelector } from "hooks"
import { selectAdvisorMailboxes } from "store/users"

interface MailProps {
  accounts: {
    label: string
    email: string
    icon: React.ReactNode
  }[]
  mails: Mail[]
  defaultLayout: number[] | undefined
  defaultCollapsed?: boolean
  navCollapsedSize: number
}

export function Mail({
  accounts,
  mails,
  defaultLayout = [265, 440, 655],
  defaultCollapsed = false,
  navCollapsedSize,
}: MailProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)
  const [mail] = useMail()

  const advisorMailboxes = useAppSelector(selectAdvisorMailboxes);

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
          )}`
        }}
        className="h-full items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          minSize={15}
          maxSize={20}
          onCollapse={() => {
            setIsCollapsed(true)
            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
              true
            )}`
          }}
          onExpand={() => {
            setIsCollapsed(false)
            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
              false
            )}`
          }}
          className={cn(
            isCollapsed &&
            "min-w-[50px] transition-all duration-300 ease-in-out"
          )}
        >
          <div
            className={cn(
              "flex h-[52px] items-center justify-center",
              isCollapsed ? "h-[52px]" : "px-2"
            )}
          >
            <AccountSwitcher isCollapsed={isCollapsed} />
          </div>
          <Separator />
          <Nav
            isCollapsed={isCollapsed}
            links={[
              {
                title: "Inbox",
                label: "",
                icon: Inbox,
                variant: "default",
              },
              // {
              //   title: "Drafts",
              //   label: "9",
              //   icon: File,
              //   variant: "ghost",
              // },
              // {
              //   title: "Sent",
              //   label: "",
              //   icon: Send,
              //   variant: "ghost",
              // },
              // {
              //   title: "Junk",
              //   label: "23",
              //   icon: ArchiveX,
              //   variant: "ghost",
              // },
              // {
              //   title: "Trash",
              //   label: "",
              //   icon: Trash2,
              //   variant: "ghost",
              // },
              // {
              //   title: "Archive",
              //   label: "",
              //   icon: Archive,
              //   variant: "ghost",
              // },
            ]}
          />
          {/* <Separator />
          <Nav
            isCollapsed={isCollapsed}
            links={[
              {
                title: "Social",
                label: "972",
                icon: Users2,
                variant: "ghost",
              },
              {
                title: "Updates",
                label: "342",
                icon: AlertCircle,
                variant: "ghost",
              },
              {
                title: "Forums",
                label: "128",
                icon: MessagesSquare,
                variant: "ghost",
              },
              {
                title: "Shopping",
                label: "8",
                icon: ShoppingCart,
                variant: "ghost",
              },
              {
                title: "Promotions",
                label: "21",
                icon: Archive,
                variant: "ghost",
              },
            ]}
          /> */}
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          <Tabs defaultValue="all" className="flex flex-col h-full">
            <div className="flex items-center px-4 py-2">
              <h1 className="text-xl font-bold">Inbox</h1>
              <TabsList className="ml-auto">
                <TabsTrigger
                  value="all"
                  className="text-zinc-600 dark:text-zinc-200"
                >
                  All mail
                </TabsTrigger>
                <TabsTrigger
                  value="unread"
                  className="text-zinc-600 dark:text-zinc-200"
                >
                  Unread
                </TabsTrigger>
              </TabsList>
            </div>

            <Separator />

            <TabsContent value="all" className="m-0 grow overflow-auto">
              <MailList />
            </TabsContent>

            <TabsContent value="unread" className="m-0 grow overflow-auto">
              <MailList />
            </TabsContent>
          </Tabs>
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel defaultSize={defaultLayout[2]}>
          <MailDisplay
            mail={mails.find((item) => item.id === mail.selected) || null}
          />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  )
}
