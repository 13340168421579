
import styles from './OhNo.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { ContactUs, ParaplannerAI, StepperBox, StepperTitle } from 'components_old';
import { IStepProps, STEP } from 'components_old/features/PortfolioAnalysis/types';
import { WIZARD_OPERATION, WIZARD_TOOL, callOhNo, selectRequestPayload } from 'store/analysis';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const OhNo = ({ handleGoToNextStep }: IStepProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestPayload = useSelector(selectRequestPayload);

  useEffect(() => {
    if (!isEmpty(requestPayload)) {
      dispatch(callOhNo());
    }
  }, [requestPayload, dispatch])

  return (
    <div className={styles.Wrapper}>
      <StepperTitle>
        <ParaplannerAI />
        <div>{t('analysis.ohNo.titleA')}</div>
        <div>{t('analysis.ohNo.titleB')}</div>
        <div>{t('analysis.ohNo.titleC')}</div>
        
        <div className={styles.Alternative}>
          <div>{t('analysis.ohNo.alternativeA')}</div>
          <div>{t('analysis.ohNo.alternativeB')}</div>
          <div>{t('analysis.ohNo.alternativeC')}</div>
        </div>
      </StepperTitle>

      <StepperBox
        title={t('analysis.selectTool.morningstar')}
        content={t('analysis.ohNo.morningstarAlternativeTitle')}
        onClick={async () => {
          // mixpanel.track(EVENT.ANALYTICS.ALT_REPORT_MORNINGSTAR);
          handleGoToNextStep(
            {
              action: STEP.S3_REPORT,
              payload: { type: WIZARD_OPERATION.REPORT, tool: WIZARD_TOOL.MORNING_STAR }
            }
          )
        }}
      />
      <StepperBox
        title={t('analysis.selectTool.kwanti')}
        content={t('analysis.ohNo.kwantiAlternativeTitle')}
        onClick={async () => {
          // mixpanel.track(EVENT.ANALYTICS.ALT_REPORT_KWANTI);
          handleGoToNextStep(
            {
              action: STEP.S3_REPORT,
              payload: { type: WIZARD_OPERATION.REPORT, tool: WIZARD_TOOL.KWANTI }
            }
          )
        }}
      />

      <StepperBox
        title={t('analysis.gettingStarted.extraction.title')}
        content={t('analysis.gettingStarted.extraction.content')}
        onClick={async () => {
          // mixpanel.track(EVENT.ANALYTICS.ALT_DATA_EXTRACTION);
          handleGoToNextStep(
            {
              action: STEP.S2_EXTRACTION,
              payload: { type: WIZARD_OPERATION.EXTRACTION }
            }
          )
        }}
      />
      <StepperBox
        title={t('analysis.gettingStarted.entry.title')}
        content={t('analysis.gettingStarted.entry.content')}
        onClick={async () => {
          // mixpanel.track(EVENT.ANALYTICS.ALT_DATA_ENTRY);
          handleGoToNextStep(
            {
              action: STEP.S2_ENTRY, payload: { type: WIZARD_OPERATION.ENTRY }
            }
          )
        }}
      />

      <StepperTitle className={styles.ContactUs}>
        <div>{t('common.somethingUrgent')}</div>
        <ContactUs
          email={t('analysis.ohNo.contact.email')}
          subject={t('analysis.ohNo.contact.subject')}
          body={t('analysis.ohNo.contact.body')}
        /> {t('common.forSupport')}
      </StepperTitle>
    </div>
  );
}
