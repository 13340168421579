import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from 'components/ui/form';
import { Card, CardContent } from 'components/ui/card';
import { QuestionActions } from './QuestionActions';
import { PlusIcon } from 'lucide-react';
import { IMeetingTemplateFormData } from 'store/templates-management';
import { useTranslation } from 'hooks';

interface IProps {
  form: UseFormReturn<IMeetingTemplateFormData>;
  sectionIndex: number
}

export const AgendaQuestionsSet = ({ form, sectionIndex }: IProps) => {
  const { t } = useTranslation();

  const { fields, append, remove, insert } = useFieldArray({
    control: form.control,
    name: `sections.${sectionIndex}.questions`
  });

  return (
    <Card className='shadow-none border-none p-0'>
      <CardContent className="p-0">
        <div className='flex flex-col gap-1.5'>
          <div className='space-y-1.5'>
            <FormLabel>
              {t('component.AgendaQuestionsSet.title')}
            </FormLabel>
            <FormLabel variant="description">
              {t('component.AgendaQuestionsSet.description')}
            </FormLabel>
          </div>

          {fields.map((item, index) => {
            return (
              <div key={item.id} className='w-full flex group'>
                {/* <FormField
                  control={form.control}
                  name={`sections.${sectionIndex}.questions.${index}.prompt`}
                  render={({ field }) => (
                    <FormItem className='hidden'>
                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore className='hidden' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                /> */}

                <FormField
                  control={form.control}
                  name={`sections.${sectionIndex}.questions.${index}.text`}
                  render={({ field }) => (
                    <FormItem className='w-full'>
                      <FormControl>
                        <Input
                          className='group-hover:border-r-0 group-hover:rounded-r-none'
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              insert(index + 1, { text: '' });
                            }
                          }}
                          autoComplete="off" data-1p-ignore
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <QuestionActions
                  remove={remove}
                  insert={insert}
                  index={index}
                />
              </div>
            );
          })}

          <Button
            variant="ghost"
            className='w-fit'
            onClick={(e) => {
              e.preventDefault()
              append({ text: '' });
            }}
          >
            <PlusIcon className='size-4' />
            {t('component.AgendaQuestionsSet.button.add')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}