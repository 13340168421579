import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "services/api";
import { ACTION } from "const";
import { processApiError } from "services";
import { IExchangeCalendarTokenPayload, IExchangeMailboxTokenPayload, IGenerateAuthUrlPayload } from "./types";

export const generateAuthUrl = createAsyncThunk(
  ACTION.NYLAS.GENERATE_AUTH_URL,
  async (payload: IGenerateAuthUrlPayload, { rejectWithValue }) => {
    try {
      const response = await api.emails.generateAuthUrl(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const exchangeCodeFromUrlForToken = createAsyncThunk(
  ACTION.NYLAS.EXCHANGE_CODE_FROM_URL_FOR_TOKEN,
  async (payload: IExchangeMailboxTokenPayload, { rejectWithValue }) => {
    try {
      const response = await api.emails.exchangeMailboxToken(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const generateCalendarAuthUrl = createAsyncThunk(
  ACTION.NYLAS.GENERATE_CALENDAR_AUTH_URL,
  async (payload: IGenerateAuthUrlPayload, { rejectWithValue }) => {
    try {
      const response = await api.emails.generateCalendarAuthUrl(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const exchangeCalendarCodeFromUrlForToken = createAsyncThunk(
  ACTION.NYLAS.EXCHANGE_CALENDAR_CODE_FROM_URL_FOR_TOKEN,
  async (payload: IExchangeCalendarTokenPayload, { rejectWithValue }) => {
    try {
      const response = await api.emails.exchangeCalendarToken(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteCalendarAccount = createAsyncThunk(
  ACTION.NYLAS.DELETE_CALENDAR_ACCOUNT,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.emails.deleteCalendarAccount();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
