import { cn } from 'lib/utils';
import { useRef, useState, useEffect } from 'react';

interface IProps {
  isCounting: boolean,
  className?: string
}

export const RecorderTimer = ({ isCounting, className }: IProps) => {
  const counter = useRef(1);
  const counterInterval = useRef<ReturnType<typeof setInterval> | undefined>();

  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [hours, setHours] = useState("");
  const [displayString, setDisplayString] = useState("");



  useEffect(() => {
    return () => {
      counterInterval.current && clearInterval(counterInterval.current);
    }
  }, []);

  useEffect(() => {
    if (isCounting) {
      counterInterval.current = setInterval(() => {
        const secondsValue = counter.current % 60;
        const minutesValue = Math.floor(counter.current / 60) % 60;
        const hoursValue = Math.floor(counter.current / 3600);

        const padWithZero = (number: number) => {
          const string = number.toString();
          if (number < 10) {
            return "0" + string;
          }
          return string;
        };

        setSeconds(padWithZero(secondsValue));
        setMinutes(padWithZero(minutesValue));
        setHours(padWithZero(hoursValue));

        setDisplayString((hours !== '' && hours !== '00' ? `${hours}:` : '') + minutes + ":" + seconds)

        counter.current = counter.current + 1
      }, 1000);
    }

    return () => {
      counterInterval.current && clearInterval(counterInterval.current);
    }
  }, [isCounting, counter]);


  return (
    <div className={cn('font-segoe', className)}>
      {(hours !== '' && hours !== '00' ? `${hours}:` : '') + minutes + ":" + seconds}
    </div>
  )
}