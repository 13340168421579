import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useForm } from 'react-hook-form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { ISaveCredentialsPayload, resetCredentialsManagementResponse, saveCredentials, selectCredentialsProcessing, selectSaveCredentialsResponse } from 'store/credentials';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { ContactUs } from 'components/layout-atoms/ContactUs';
import { MIXPANEL } from 'const';

export const ShareCredentials = () => {
  const { t } = useTranslation();
  const { trackEvent } = useMixpanel();
  const dispatch = useAppDispatch();

  const saveCredentialsResponse = useSelector(selectSaveCredentialsResponse);
  const credentialsProcessing = useSelector(selectCredentialsProcessing);

  const requestWasSuccessful = !!saveCredentialsResponse?.id;

  const formSchema = z.object({
    password: z.string().min(1, t('errors.required') || ''),
    tool: z.string().min(1, t('errors.required') || ''),
    username: z.string().min(1, t('errors.required') || ''),
  });

  const form = useForm<ISaveCredentialsPayload>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      password: '', tool: '', username: ''
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetCredentialsManagementResponse())
    }
  }, []);

  useEffect(() => {
    form.setFocus('tool');
  }, [form]);

  useEffect(() => {
    if (requestWasSuccessful) {
      form.reset();
    }
  }, [requestWasSuccessful, form]);

  const onSubmit = (values: ISaveCredentialsPayload) => {
    dispatch(saveCredentials(values))
    trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CREDENTIALS.SHARE })
  };

  return (
    <Card>
      {!requestWasSuccessful ? (
        <>
          <CardHeader>
            <CardTitle>{t('component.ShareCredentials.title')}</CardTitle>
            <CardDescription>{t('component.ShareCredentials.description')}
            </CardDescription>
          </CardHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <CardContent className="flex flex-col gap-6">
                <FormField
                  control={form.control}
                  name="tool"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareCredentials.tool')}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t('component.ShareCredentials.toolDescription')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore  />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareCredentials.username')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} autoComplete="off" data-1p-ignore  />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ShareCredentials.password')}
                      </FormLabel>
                      <FormControl>
                        <Input type="password" {...field} autoComplete="off" data-1p-ignore  />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>

              <CardFooter>
                <Button
                  loading={credentialsProcessing}
                  type="submit"
                >
                  {t('component.ShareCredentials.share')}
                </Button>
              </CardFooter>
            </form>
          </Form>
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>{t('component.ShareCredentials.successState.title')}</CardTitle>
            <CardDescription>{t('component.ShareCredentials.successState.description')}
            </CardDescription>
          </CardHeader>

          <CardFooter className="flex-col gap-4">
            <Button
              onClick={() => {
                dispatch(resetCredentialsManagementResponse())
              }}
            >
              {t('component.ShareCredentials.successState.newRequest')}
            </Button>

            <div className="text-center text-sm">
              {t('component.ShareCredentials.successState.revokeAccess')}
              &nbsp;
              <ContactUs
                text={t('component.ShareCredentials.successState.contact.text')}
                email={t('component.ShareCredentials.successState.contact.email')}
                subject={t('component.ShareCredentials.successState.contact.subject')}
                body={t('component.ShareCredentials.successState.contact.body')}
              />
            </div>
          </CardFooter>
        </>
      )}
    </Card>

  );
};
