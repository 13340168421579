import { useTranslation } from 'hooks';
import { Button, Input, StepperBox, StepperTitle } from 'components_old';
import { IShareAccessPayload, IStepMandatoryProps, STEP } from 'components_old/features/PortfolioAnalysis/types';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { WIZARD_OPERATION } from 'store/analysis';

export const ShareAccess = ({ handleGoToNextStep, nextStep }: IStepMandatoryProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IShareAccessPayload>({ mode: 'onBlur' });

  const onSubmit = (values: IShareAccessPayload) => {
    handleGoToNextStep(
      {
        action: nextStep,
        payload: values
      }
    )
  };

  useEffect(() => {
    setFocus('username');
  }, [setFocus]);

  return (
    <>
      <StepperTitle>
        {t('analysis.shareAccess.title')}
      </StepperTitle>
      <form
        style={{ marginBottom: 10 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <Input
          formed
          placeholder={t('placeholder.tool') as string}
          error={errors?.tool?.message}
          {...register('tool', {
            required: { value: true, message: t('errors.required') },
          })}
        /> */}
        <Input
          formed
          placeholder={t('placeholder.username') as string}
          error={errors?.username?.message}
          {...register('username', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Input
          formed
          type="password"
          placeholder={t('placeholder.password') as string}
          error={errors?.password?.message}
          {...register('password', {
            required: { value: true, message: t('errors.required') },
          })}
        />
        <Button disabled={!isValid} type="submit">
          {t('common.continue')}
        </Button>
      </form>
      <StepperTitle>
        <div>{t('analysis.shareAccess.subtitleA')}</div>
        <div>{t('analysis.shareAccess.subtitleB')}</div>
      </StepperTitle>
      <StepperBox
        title={t('analysis.gettingStarted.extraction.title')}
        content={t('analysis.gettingStarted.extraction.content')}
        onClick={() => {
          // mixpanel.track(EVENT.ANALYTICS.REFUSED_TO_SHARE_CREDENTIALS);
          handleGoToNextStep(
            {
              action: STEP.S2_EXTRACTION,
              payload: { type: WIZARD_OPERATION.EXTRACTION }
            }
          )
        }
        }
      />
      <StepperBox
        title={t('analysis.gettingStarted.report.title')}
        content={t('analysis.gettingStarted.report.content')}
        onClick={() => {
          // mixpanel.track(EVENT.ANALYTICS.REFUSED_TO_SHARE_CREDENTIALS);
          handleGoToNextStep(
            {
              action: STEP.S2_REPORT,
              payload: { type: WIZARD_OPERATION.REPORT }
            }
          )
        }
        }
      />
    </>
  );
}
