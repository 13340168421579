
import styles from './ISDEIEDataExtractorCongrats.module.scss'
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { BUTTON_LOOK, Button, ParaplannerAI, StepperTitle } from 'components_old';
import { useSelector } from 'react-redux';
import { IStepperComponentProps } from '../../types';
import { selectDataExtractorBlobObjectUrl, selectDataExtractorIsProcessingError } from 'store/dataExtractor';
import { useEffect } from 'react';
import { selectIntercomChatState, setIntercomChatVisibility } from 'store/chat';


export const ISDEIEDataExtractorCongrats = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const blobObjectUrl = useSelector(selectDataExtractorBlobObjectUrl);
  const IsProcessingError = useSelector(selectDataExtractorIsProcessingError);
  const intercomChatState = useAppSelector(selectIntercomChatState);

  useEffect(() => {
    setTimeout(() => {
      if (!intercomChatState?.isOpen) {
        dispatch(setIntercomChatVisibility(true))
      }
    }, 2000);
  }, [])



  return (
    <div className={styles.Wrapper}>
      {!IsProcessingError ? (
        <>
          <StepperTitle>
            <ParaplannerAI />
          </StepperTitle>

          <StepperTitle>
            <div>{t('dataExtractor.congrats.titleA')}</div>
            <div>{t('dataExtractor.congrats.titleB')}</div>
          </StepperTitle>

          <StepperTitle>
            <div>{t('dataExtractor.congrats.titleC')}</div>
          </StepperTitle>
        </>
      ) : (
        <>
          <StepperTitle>
            <ParaplannerAI />
          </StepperTitle>
          <StepperTitle>
            <div>{t('dataExtractor.congrats.errorOccurredA')}</div>
            <div>{t('dataExtractor.congrats.errorOccurredB')}</div>
          </StepperTitle>
        </>
      )}

      <div className={styles.Buttons}>
        {!IsProcessingError && blobObjectUrl && (
          <Button
            onClick={async () => {
              // mixpanel.track(EVENT.DATA_EXTRACTOR.DOWNLOAD);

              const a = document.createElement("a");
              a.href = blobObjectUrl;
              a.download = "result.csv";
              a.target = "_blank";

              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }}
          >
            {t('dataExtractor.congrats.downloadResult')}
          </Button>
        )}

        <Button
          look={BUTTON_LOOK.TERNARY}
          onClick={async () => {
            // mixpanel.track(EVENT.ANOTHER_REQUEST);
            handleGoToNextStep({action: nextStep});
          }}
        >
          {t('dataExtractor.congrats.newRequest')}
        </Button>
      </div>
    </div >
  );
}
