import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getUploadManagementState = (state: RootState) => state.uploadManagement;

export const selectUploadStatus = createSelector(
  [getUploadManagementState],
  ({ uploadStatus }) => uploadStatus
);

export const selectUploadEvent = createSelector(
  [getUploadManagementState],
  ({ uploadProgressEvent }) => uploadProgressEvent
);
