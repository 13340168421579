import { Icon, IIconProps } from './Icon';

export const BrainAvatar = (props: IIconProps) => {
  return (
    <>
      <Icon
        {...props}
        hasNoSvgFormatting
        viewBox="0 0 128 128"
      >
        <rect width="128" height="128" fill="transparent" />
        <rect x="11.6367" y="27.5156" width="3.87879" height="3.87879" fill="black" />
        <rect y="46.9082" width="3.87879" height="3.87879" fill="black" />
        <rect x="3.87891" y="50.7871" width="3.87879" height="3.87879" fill="black" />
        <rect x="19.3945" y="81.8184" width="3.87879" height="3.87879" fill="black" />
        <rect x="23.2734" y="85.6973" width="3.87879" height="3.87879" fill="black" />
        <rect x="31.0312" y="93.4551" width="3.87879" height="3.87879" fill="black" />
        <rect x="96.9688" y="105.092" width="3.87879" height="3.87879" fill="black" />
        <rect x="120.242" y="74.0605" width="3.87879" height="3.87879" fill="black" />
        <rect x="112.484" y="31.3945" width="3.87879" height="3.87879" fill="black" />
        <rect x="108.605" y="27.5156" width="3.87879" height="3.87879" fill="black" />
        <rect x="104.727" y="23.6367" width="3.87879" height="3.87879" fill="black" />
        <rect x="96.9688" y="19.7578" width="7.75758" height="3.87879" fill="black" />
        <rect x="89.2109" y="15.8789" width="7.75758" height="3.87879" fill="black" />
        <rect x="34.9102" y="97.334" width="15.5152" height="3.87879" fill="black" />
        <rect x="50.4258" y="101.213" width="11.6364" height="3.87879" fill="black" />
        <rect x="77.5742" y="112.848" width="11.6364" height="3.87879" fill="black" />
        <rect x="62.0625" y="105.092" width="7.75758" height="3.87879" fill="black" />
        <rect x="104.727" y="93.4551" width="7.75758" height="3.87879" fill="black" />
        <rect x="69.8164" y="108.969" width="7.75758" height="3.87879" fill="black" />
        <rect x="89.2109" y="108.969" width="7.75758" height="3.87879" fill="black" />
        <rect x="27.1523" y="89.5762" width="3.87879" height="7.75758" fill="black" />
        <rect x="100.848" y="97.334" width="3.87879" height="7.75758" fill="black" />
        <rect x="112.484" y="85.6973" width="3.87879" height="7.75758" fill="black" />
        <rect x="116.363" y="77.9395" width="3.87879" height="7.75758" fill="black" />
        <rect x="120.242" y="43.0312" width="3.87879" height="7.75758" fill="black" />
        <rect x="116.363" y="35.2734" width="3.87879" height="7.75758" fill="black" />
        <rect x="124.121" y="50.7871" width="3.87879" height="23.2727" fill="black" />
        <rect x="7.75781" y="31.3945" width="3.87879" height="7.75758" fill="black" />
        <rect x="3.87891" y="39.1523" width="3.87879" height="7.75758" fill="black" />
        <rect x="3.87891" y="70.1816" width="3.87879" height="7.75758" fill="black" />
        <rect y="54.666" width="3.87879" height="15.5152" fill="black" />
        <rect x="31.0312" y="15.8789" width="11.6364" height="3.87879" fill="black" />
        <rect x="23.2734" y="19.7578" width="7.75758" height="3.87879" fill="black" />
        <rect x="15.5156" y="23.6367" width="7.75758" height="3.87879" fill="black" />
        <rect x="7.75781" y="77.9395" width="11.6364" height="3.87879" fill="black" />
        <rect x="42.668" y="12" width="46.5455" height="3.87879" fill="black" />
        <rect x="62.0625" y="97.334" width="7.75758" height="7.75758" fill="#3A1C5E" />
        <rect x="50.4258" y="97.334" width="11.6364" height="3.87879" fill="#3A1C5E" />
        <rect x="65.9375" y="93.4551" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="69.8164" y="97.334" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="69.8164" y="93.4551" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="73.6953" y="89.5762" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="77.5742" y="89.5762" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="96.9688" y="97.334" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="93.0898" y="97.334" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="19.3945" y="77.9395" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="15.5156" y="74.0605" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="11.6367" y="74.0605" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="19.3945" y="74.0605" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="19.3945" y="70.1816" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="23.2734" y="74.0605" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="23.2734" y="70.1816" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="27.1523" y="66.3027" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="31.0312" y="66.3027" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="46.5469" y="62.4238" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="34.9102" y="93.4551" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="65.9375" y="54.666" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="3.87891" y="66.3027" width="3.87879" height="3.87879" fill="#3A1C5E" />
        <rect x="73.6953" y="93.4551" width="31.0303" height="3.87879" fill="#3A1C5E" />
        <rect x="3.87891" y="58.5449" width="3.87879" height="7.75758" fill="#6E3FA6" />
        <rect x="11.6367" y="62.4238" width="3.87879" height="11.6364" fill="#6E3FA6" />
        <rect x="15.5156" y="66.3027" width="3.87879" height="7.75758" fill="#6E3FA6" />
        <rect x="19.3945" y="66.3027" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="27.1523" y="62.4238" width="19.3939" height="3.87879" fill="#6E3FA6" />
        <rect x="46.5469" y="58.5449" width="7.75758" height="3.87879" fill="#6E3FA6" />
        <rect x="54.3047" y="58.5449" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="50.4258" y="62.4238" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="73.6953" y="66.3027" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="27.1523" y="70.1816" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="85.332" y="74.0605" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="62.0625" y="81.8184" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="81.4531" y="89.5762" width="31.0303" height="3.87879" fill="#6E3FA6" />
        <rect x="108.605" y="70.1816" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="93.0898" y="105.092" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="96.9688" y="101.213" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="77.5742" y="108.971" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="69.8164" y="105.092" width="7.75758" height="3.87879" fill="#6E3FA6" />
        <rect x="69.8164" y="101.213" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="73.6953" y="97.334" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="50.4258" y="89.5762" width="23.2727" height="3.87879" fill="#6E3FA6" />
        <rect x="50.4258" y="93.4551" width="15.5152" height="3.87879" fill="#6E3FA6" />
        <rect x="42.668" y="85.6973" width="3.87879" height="7.75758" fill="#6E3FA6" />
        <rect x="31.0312" y="81.8184" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="38.7891" y="93.4551" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="46.5469" y="85.6973" width="15.5152" height="3.87879" fill="#6E3FA6" />
        <rect x="27.1523" y="85.6973" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="31.0312" y="89.5762" width="7.75758" height="3.87879" fill="#6E3FA6" />
        <rect x="81.4531" y="70.1816" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="69.8164" y="54.666" width="19.3939" height="3.87879" fill="#6E3FA6" />
        <rect x="100.848" y="62.4238" width="3.87879" height="7.75758" fill="#6E3FA6" />
        <rect x="96.9688" y="66.3027" width="11.6364" height="3.87879" fill="#6E3FA6" />
        <rect x="23.2734" y="54.666" width="3.87879" height="15.5152" fill="#6E3FA6" />
        <rect x="42.668" y="50.7871" width="3.87879" height="15.5152" fill="#6E3FA6" />
        <rect x="23.2734" y="77.9395" width="3.87879" height="7.75758" fill="#6E3FA6" />
        <rect x="7.75781" y="62.4238" width="3.87879" height="15.5152" fill="#6E3FA6" />
        <rect x="50.4258" y="77.9395" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="77.5742" y="81.8184" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="62.0625" y="19.7578" width="3.87879" height="3.87879" fill="#6E3FA6" />
        <rect x="15.5156" y="27.5156" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="19.3945" y="27.5156" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="11.6367" y="35.2734" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="7.75781" y="39.1504" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="3.87891" y="54.666" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="15.5156" y="62.4238" width="7.75758" height="3.87879" fill="#CF6ED4" />
        <rect x="31.0312" y="58.5449" width="11.6364" height="3.87879" fill="#CF6ED4" />
        <rect x="27.1523" y="74.0605" width="58.1818" height="3.87879" fill="#CF6ED4" />
        <rect x="46.5469" y="54.666" width="19.3939" height="3.87879" fill="#CF6ED4" />
        <rect x="54.3047" y="62.4238" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="34.9102" y="66.3027" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="31.0312" y="70.1816" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="27.1523" y="81.8184" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="31.0312" y="85.6973" width="11.6364" height="3.87879" fill="#CF6ED4" />
        <rect x="34.9102" y="81.8184" width="27.1515" height="3.87879" fill="#CF6ED4" />
        <rect x="46.5469" y="66.3027" width="7.75758" height="3.87879" fill="#CF6ED4" />
        <rect x="89.2109" y="54.666" width="15.5152" height="3.87879" fill="#CF6ED4" />
        <rect x="96.9688" y="58.5449" width="15.5152" height="3.87879" fill="#CF6ED4" />
        <rect x="104.727" y="62.4238" width="7.75758" height="3.87879" fill="#CF6ED4" />
        <rect x="108.605" y="66.3027" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="96.9688" y="62.4238" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="11.6367" y="43.0293" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="3.87891" y="46.9082" width="19.3939" height="3.87879" fill="#CF6ED4" />
        <rect x="7.75781" y="50.7871" width="19.3939" height="3.87879" fill="#CF6ED4" />
        <rect x="27.1523" y="31.3945" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="23.2734" y="39.1504" width="3.87879" height="15.5152" fill="#CF6ED4" />
        <rect x="42.668" y="43.0293" width="3.87879" height="7.75758" fill="#CF6ED4" />
        <rect x="38.7891" y="50.7871" width="3.87879" height="7.75758" fill="#CF6ED4" />
        <rect x="27.1523" y="54.666" width="3.87879" height="7.75758" fill="#CF6ED4" />
        <rect x="7.75781" y="54.666" width="15.5152" height="7.75758" fill="#CF6ED4" />
        <rect x="54.3047" y="43.0293" width="3.87879" height="7.75758" fill="#CF6ED4" />
        <rect x="65.9375" y="58.5449" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="73.6953" y="58.5449" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="77.5742" y="62.4238" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="69.8164" y="66.3027" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="46.5469" y="89.5762" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="38.7891" y="89.5762" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="27.1523" y="77.9395" width="23.2727" height="3.87879" fill="#CF6ED4" />
        <rect x="54.3047" y="77.9395" width="62.0606" height="3.87879" fill="#CF6ED4" />
        <rect x="62.0625" y="85.6973" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="93.0898" y="101.213" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="73.6953" y="101.213" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="65.9375" y="85.6973" width="46.5455" height="3.87879" fill="#CF6ED4" />
        <rect x="81.4531" y="81.8184" width="34.9091" height="3.87879" fill="#CF6ED4" />
        <rect x="77.5742" y="97.334" width="15.5152" height="11.6364" fill="#CF6ED4" />
        <rect x="77.5742" y="66.3027" width="19.3939" height="3.87879" fill="#CF6ED4" />
        <rect x="89.2109" y="74.0605" width="31.0303" height="3.87879" fill="#CF6ED4" />
        <rect x="93.0898" y="70.1816" width="7.75758" height="3.87879" fill="#CF6ED4" />
        <rect x="104.727" y="70.1816" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="58.1836" y="70.1816" width="23.2727" height="3.87879" fill="#CF6ED4" />
        <rect x="58.1836" y="46.9082" width="11.6364" height="3.87879" fill="#CF6ED4" />
        <rect x="65.9375" y="50.7871" width="31.0303" height="3.87879" fill="#CF6ED4" />
        <rect x="89.2109" y="46.9082" width="31.0303" height="3.87879" fill="#CF6ED4" />
        <rect x="23.2734" y="23.6367" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="31.0312" y="19.7578" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="34.9102" y="23.6367" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="38.7891" y="23.6367" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="38.7891" y="27.5156" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="65.9375" y="15.8789" width="7.75758" height="3.87879" fill="#CF6ED4" />
        <rect x="58.1836" y="19.7578" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="85.332" y="15.8789" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="81.4531" y="19.7578" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="89.2109" y="31.3945" width="11.6364" height="3.87879" fill="#CF6ED4" />
        <rect x="112.484" y="43.0293" width="7.75758" height="31.0303" fill="#CF6ED4" />
        <rect x="120.242" y="50.7871" width="3.87879" height="23.2727" fill="#CF6ED4" />
        <rect x="42.668" y="15.8789" width="7.75758" height="7.75758" fill="#CF6ED4" />
        <rect x="73.6953" y="81.8184" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="85.332" y="19.7578" width="3.87879" height="3.87879" fill="#CF6ED4" />
        <rect x="50.4258" y="15.8789" width="7.75758" height="7.75758" fill="#E6B4E9" />
        <rect x="58.1836" y="15.8789" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="89.2109" y="19.7578" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="27.1523" y="23.6367" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="23.2734" y="27.5156" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="11.6367" y="39.1504" width="11.6364" height="3.87879" fill="#E6B4E9" />
        <rect x="15.5156" y="43.0293" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="27.1523" y="43.0293" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="27.1523" y="46.9082" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="27.1523" y="50.7871" width="11.6364" height="3.87879" fill="#E6B4E9" />
        <rect x="31.0312" y="54.666" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="96.9688" y="50.7871" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="104.727" y="54.666" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="69.8164" y="58.5449" width="3.87879" height="3.87879" fill="#E6B4E9" />
        <rect x="77.5742" y="58.5449" width="19.3939" height="3.87879" fill="#E6B4E9" />
        <rect x="81.4531" y="62.4238" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="100.848" y="70.1816" width="3.87879" height="3.87879" fill="#E6B4E9" />
        <rect x="58.1836" y="62.4238" width="19.3939" height="3.87879" fill="#E6B4E9" />
        <rect x="38.7891" y="66.3027" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="54.3047" y="66.3027" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="34.9102" y="70.1816" width="23.2727" height="3.87879" fill="#E6B4E9" />
        <rect x="69.8164" y="46.9082" width="19.3939" height="3.87879" fill="#E6B4E9" />
        <rect x="58.1836" y="43.0293" width="54.303" height="3.87879" fill="#E6B4E9" />
        <rect x="27.1523" y="39.1504" width="89.2121" height="3.87879" fill="#E6B4E9" />
        <rect x="7.75781" y="43.0293" width="3.87879" height="3.87879" fill="#E6B4E9" />
        <rect x="15.5156" y="35.2734" width="100.848" height="3.87879" fill="#E6B4E9" />
        <rect x="100.848" y="31.3945" width="11.6364" height="3.87879" fill="#E6B4E9" />
        <rect x="42.668" y="27.5156" width="65.9394" height="3.87879" fill="#E6B4E9" />
        <rect x="42.668" y="23.6367" width="62.0606" height="3.87879" fill="#E6B4E9" />
        <rect x="65.9375" y="19.7578" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="34.9102" y="19.7578" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="73.6953" y="15.8789" width="11.6364" height="3.87879" fill="#E6B4E9" />
        <rect x="46.5469" y="43.0293" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="46.5469" y="46.9082" width="7.75758" height="3.87879" fill="#E6B4E9" />
        <rect x="46.5469" y="50.7871" width="19.3939" height="3.87879" fill="#E6B4E9" />
        <rect x="11.6367" y="31.3945" width="15.5152" height="3.87879" fill="#E6B4E9" />
        <rect x="31.0312" y="31.3945" width="58.1818" height="3.87879" fill="#E6B4E9" />
      </Icon>
    </>
  );
};
