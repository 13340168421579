import styles from './ChatList.module.scss';
import { useEffect } from 'react';
import { ChatPreview } from '../ChatPreview';

const testArr = new Array(3).fill(1);

export const ChatList = () => {
  useEffect(() => {
    async function getChats() {
      // const response = await api.chat.getConversations(null);
    }

    getChats();
  }, []);

  return (
    <ul className={styles.Wrapper}>
      {testArr.map((_, idx) => {
        return (
          <li key={idx} className={styles.ListItem}>
            <ChatPreview />
          </li>
        );
      })}
    </ul>
  );
};
