export interface IOrganizationsState {
  isGettingOrganizationsList: boolean;
  isOrganizationActionProcessing: boolean;
  organizationsList: IOrganization[];
  activeOrganization: IOrganization | undefined;
}

// export enum OrgTypeEnum {
//   Single = "single",
//   Team = "team",
//   Enterprise = "enterprise",
// }

export enum OrgIndustryTypeEnum {
  RIA = "ria",
  Insurance = "insurance",
  Startup = "startup",
}

export interface IOrganization {
  id: string;
  name: string;
  domain: string;
  // type: OrgTypeEnum;
  industryType: OrgIndustryTypeEnum;
}

export interface IOrganizationsPayload extends Pick<IOrganization, "id"> {}
