
import styles from './CongratsComparison.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { Button, ContactUs, LoadingRow, ParaplannerAI, StepperTitle } from 'components_old';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { IStepperComponentProps } from '../types';
import { createComparisonRequest, selectComparisonRequestPayload, selectCreatingRequestSuccess, selectIsCreatingRequest } from 'store/portfolioModel';



export const CongratsComparison = ({ handleGoToNextStep, nextStep }: IStepperComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const comparisonRequestPayload = useSelector(selectComparisonRequestPayload);
  const isCreatingRequest = useSelector(selectIsCreatingRequest);
  const requestSuccessful = useSelector(selectCreatingRequestSuccess);


  useEffect(() => {
    if (!isEmpty(comparisonRequestPayload)) {
      dispatch(createComparisonRequest(comparisonRequestPayload));
    }
  }, [comparisonRequestPayload, dispatch])


  return (
    isCreatingRequest ? (
      <div className={styles.Wrapper}>
        <LoadingRow />
      </div>
    ) : ( 
      <div className={styles.Wrapper}>
        {requestSuccessful ? (
          <>
            <StepperTitle>
              <ParaplannerAI />
              <div>{t('analysis.congrats.titleA')}</div>
              <div>{t('analysis.congrats.titleB')}</div>
            </StepperTitle>
            <StepperTitle>
              <div>{t('analysis.congrats.aiBusy')}</div>
            </StepperTitle>
          </>
        ) : (
          <StepperTitle>
            <ParaplannerAI />
            <div>{t('analysis.congrats.errorOccurred')}</div>
          </StepperTitle>
        )}

        <StepperTitle>
          <div>{t('common.somethingUrgent')}</div>
          <ContactUs
            email={t('analysis.congrats.contact.email')}
            subject={t('analysis.congrats.contact.subject')}
            body={t('analysis.congrats.contact.body')}
          /> {t('common.forSupport')}
        </StepperTitle>
        <Button onClick={async () => {
          // mixpanel.track(EVENT.PORTFOLIO_COMPARISON.ANOTHER_REQUEST);
          handleGoToNextStep(nextStep);
        }}>
          {t('analysis.congrats.newRequest')}
        </Button>
      </div >
    )
  );
}
