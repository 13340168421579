import styles from './AutomationStartScreen.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { AutomationDescription } from 'components_old';
import YouTube, { YouTubeEvent } from 'react-youtube';

interface IProps {
  description?: ReactNode;
  videoId?: string;
  children?: ReactNode;
}
export const AutomationStartScreen = ({
  description,
  videoId,
  children
}: IProps) => {
  const opts = {
    height: '195',
    width: '320',
  };

  const onReady = (event: YouTubeEvent<any>) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }


  return (
    <div className={classnames(styles.Wrapper)}>
      <div className={classnames(styles.Column, styles.Left)}>
        <div className={styles.Video}>
          <YouTube
            videoId={videoId}
            opts={opts}
            className={styles.VideoClassName}
            iframeClassName={styles.VideoIframeClassName}
          />
        </div>

        <AutomationDescription>
          {description}
        </AutomationDescription>
      </div>

      <div className={classnames(styles.Column, styles.Right)}>
        {children}
      </div>
    </div>
  );
};
