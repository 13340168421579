import { PayloadAction, createSlice, current, isAnyOf } from "@reduxjs/toolkit";
import {
  IAdminMeetingTemplate,
  IMeetingTemplate,
  ITemplatesState,
} from "./types";
import {
  createOrgTemplate,
  createPersonalTemplate,
  deleteOrgTemplate,
  deletePersonalTemplate,
  getAllTemplates,
  getTemplates,
  updateOrgTemplate,
  updatePersonalTemplate,
  updateTemplatePrompt,
} from "./actions";

const initialState: ITemplatesState = {
  meetingTemplates: {
    myTemplates: [],
    orgTemplates: [],
  },
  allAdminTemplates: [],
  activeTemplate: undefined,
  activeAdminTemplate: undefined,
  isGettingTemplates: false,
  isCreatingTemplate: false,
  isUpdatingTemplate: false,
  isDeletingTemplate: false,
};

const templatesManagementSlice = createSlice({
  name: "templatesManagement",
  initialState,
  reducers: {
    setActiveTemplate: (
      state,
      action: PayloadAction<IMeetingTemplate | undefined>
    ) => {
      state.activeTemplate = action.payload;
    },
    setActiveAdminTemplate: (
      state,
      action: PayloadAction<IAdminMeetingTemplate | undefined>
    ) => {
      state.activeAdminTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state, action) => {
        state.isGettingTemplates = true;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.isGettingTemplates = false;
        state.meetingTemplates = {
          myTemplates: action.payload?.myTemplates?.sort((a, b) =>
            a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
          ),
          orgTemplates: action.payload?.orgTemplates?.sort((a, b) =>
            a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
          ),
        };
      })
      .addCase(getTemplates.rejected, (state) => {
        state.isGettingTemplates = false;
      })
      .addCase(getAllTemplates.pending, (state, action) => {
        state.isGettingTemplates = true;
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.isGettingTemplates = false;
        state.allAdminTemplates = action.payload
          ?.map((template) => {
            let totalNoQuestions = 0;
            let totalNoPrompts = 0;

            template?.sections?.forEach((section) => {
              section?.questions?.forEach((item) => {
                totalNoQuestions += 1;
                if (item?.prompt) {
                  totalNoPrompts += 1;
                }
              });
            });

            return {
              ...template,
              totalNoQuestions,
              totalNoPrompts,
              promptsLeftToFill: totalNoQuestions - totalNoPrompts,
            };
          })
          ?.sort((a, b) => b?.promptsLeftToFill - a?.promptsLeftToFill);
      })
      .addCase(getAllTemplates.rejected, (state) => {
        state.isGettingTemplates = false;
      })
      .addCase(updateTemplatePrompt.pending, (state, action) => {
        state.isUpdatingTemplate = true;
      })
      .addCase(updateTemplatePrompt.fulfilled, (state, action) => {
        state.isUpdatingTemplate = false;
      })
      .addCase(updateTemplatePrompt.rejected, (state) => {
        state.isUpdatingTemplate = false;
      })
      .addMatcher(
        isAnyOf(createPersonalTemplate.pending, createOrgTemplate.pending),
        (state) => {
          state.isCreatingTemplate = true;
        }
      )
      .addMatcher(
        isAnyOf(createPersonalTemplate.fulfilled, createOrgTemplate.fulfilled),
        (state, action) => {
          state.isCreatingTemplate = false;
          state.activeTemplate = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(createPersonalTemplate.rejected, createOrgTemplate.rejected),
        (state) => {
          state.isCreatingTemplate = false;
        }
      )
      .addMatcher(
        isAnyOf(updatePersonalTemplate.pending, updateOrgTemplate.pending),
        (state) => {
          state.isUpdatingTemplate = true;
        }
      )
      .addMatcher(
        isAnyOf(updatePersonalTemplate.fulfilled, updateOrgTemplate.fulfilled),
        (state, action) => {
          state.isUpdatingTemplate = false;
          state.activeTemplate = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(updatePersonalTemplate.rejected, updateOrgTemplate.rejected),
        (state) => {
          state.isUpdatingTemplate = false;
        }
      )
      .addMatcher(
        isAnyOf(deletePersonalTemplate.pending, deleteOrgTemplate.pending),
        (state) => {
          state.isDeletingTemplate = true;
        }
      )
      .addMatcher(
        isAnyOf(deletePersonalTemplate.fulfilled, deleteOrgTemplate.fulfilled),
        (state, action) => {
          const deletedTemplate = action?.meta.arg;
          const currentState = current(state);

          state.isDeletingTemplate = false;
          state.activeTemplate =
            currentState?.activeTemplate?.id === deletedTemplate?.id
              ? undefined
              : currentState?.activeTemplate;
        }
      )
      .addMatcher(
        isAnyOf(deletePersonalTemplate.rejected, deleteOrgTemplate.rejected),
        (state) => {
          state.isDeletingTemplate = false;
        }
      );
  },
});

export const { setActiveTemplate, setActiveAdminTemplate } =
  templatesManagementSlice.actions;

export default templatesManagementSlice.reducer;
