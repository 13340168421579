import styles from './AddPortfolioModel.module.scss'
import { useAppDispatch, useTranslation } from 'hooks';
import { useForm } from 'react-hook-form';
import { BUTTON_LOOK, Button, PortfolioModelForm } from 'components_old';
import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { IPortfolioModelFormValues, createPortfolioModels, selectIsCreatingModels } from 'store/portfolioModel';
import { v4 as uuidv4 } from 'uuid';
import { DefaultTFuncReturn } from 'i18next';

interface IProps {
  handleExit: Function;
  saveAndExitText?: string | DefaultTFuncReturn;
}

export const AddPortfolioModel = ({ handleExit, saveAndExitText }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const btnSaveAndExitLabel = saveAndExitText || t('portfolioModels.addPortfolioModel.saveAndExit')

  const portfolioModel: IPortfolioModelFormValues = useMemo(() => {
    return {
      models: [{ id: uuidv4(), name: '', tickers: [{ id: uuidv4(), ticker: '', allocation: '' }] }]
    }
  }, []);
  const isCreatingModels = useSelector(selectIsCreatingModels);

  const {
    control,
    watch,
    trigger,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    ...useFormProps
  } = useForm<IPortfolioModelFormValues>({
    defaultValues: portfolioModel,
    mode: 'onBlur'
  });


  useEffect(() => {
    const subscription = watch(() => {
      trigger();
    });
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, trigger]);


  useEffect(() => {
    trigger();
  }, [portfolioModel, trigger]);


  const onSaveModel = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, exit: boolean) => {
    e.preventDefault();
    const { models } = getValues();
    const response = await dispatch(createPortfolioModels(models));
    if (!response.hasOwnProperty('error')) {
      if (exit) {
        // mixpanel.track(EVENT.PORTFOLIO_MODELS.SAVE_MODEL_AND_EXIT);
        handleExit()
      } else {
        // mixpanel.track(EVENT.PORTFOLIO_MODELS.SAVE_MODEL_AND_ADD_NEW);
        reset();
      }
    }
  };


  return (
    <form
      className={styles.Wrapper}
      onSubmit={handleSubmit(() => { })}
    >
      <div className={styles.Title}>
        {t('portfolioModels.addAndSaveModel')}
      </div>
      <PortfolioModelForm
        control={control}
        getValues={getValues}
        watch={watch}
        trigger={trigger}
        errors={errors}
        isValid={isValid}
        modelIndex={0}
        model={portfolioModel.models[0]}
        isEditing={true}
        isProcessing={isCreatingModels}
        {...useFormProps}
      />

      <div className={classnames(styles.Buttons)}>
        <Button
          look={BUTTON_LOOK.SECONDARY}
          loading={isCreatingModels}
          disabled={!isValid}
          onClick={(e) => onSaveModel(e, false)}
        >
          {t('portfolioModels.addPortfolioModel.saveAndAdd')}
        </Button>
        <Button
          look={BUTTON_LOOK.SECONDARY}
          loading={isCreatingModels}
          disabled={!isValid}
          onClick={(e) => onSaveModel(e, true)}
        >
          {btnSaveAndExitLabel}
        </Button>
      </div>
    </form>
  );
}
