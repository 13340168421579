import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrganization, IOrganizationsState } from "./types";
import {
  createOrganization,
  deleteOrganization,
  getOrganization,
  getOrganizations,
  updateOrganization,
} from "./actions";

const initialState: IOrganizationsState = {
  isGettingOrganizationsList: false,
  isOrganizationActionProcessing: false,
  organizationsList: [],
  activeOrganization: undefined,
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setActiveOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.activeOrganization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizations.pending, (state) => {
        state.isGettingOrganizationsList = true;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.isGettingOrganizationsList = false;
        state.organizationsList = action.payload?.sort((a, b) =>
          a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
        );
      })
      .addCase(getOrganizations.rejected, (state) => {
        state.isGettingOrganizationsList = false;
      })
      .addCase(getOrganization.pending, (state) => {
        state.isOrganizationActionProcessing = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.isOrganizationActionProcessing = false;
      })
      .addCase(getOrganization.rejected, (state) => {
        state.isOrganizationActionProcessing = false;
      })
      .addCase(createOrganization.pending, (state) => {
        state.isOrganizationActionProcessing = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isOrganizationActionProcessing = false;
        state.activeOrganization = action.payload;
        state.organizationsList = [
          action.payload,
          ...currentState.organizationsList,
        ];
      })
      .addCase(createOrganization.rejected, (state) => {
        state.isOrganizationActionProcessing = false;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.isOrganizationActionProcessing = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isOrganizationActionProcessing = false;

        const updatedOrganization: IOrganization = action.payload;
        state.organizationsList = currentState?.organizationsList?.map(
          (organization) =>
            organization?.id !== updatedOrganization?.id
              ? organization
              : updatedOrganization
        );
        state.activeOrganization = updatedOrganization;
      })
      .addCase(updateOrganization.rejected, (state) => {
        state.isOrganizationActionProcessing = false;
      })
      .addCase(deleteOrganization.pending, (state) => {
        state.isOrganizationActionProcessing = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        const currentState = current(state);
        const deletedOrganizationId = action.meta.arg.id;

        state.isOrganizationActionProcessing = false;

        state.organizationsList = currentState?.organizationsList?.filter(
          (organization) => organization?.id !== deletedOrganizationId
        );
        state.activeOrganization = undefined;
      })
      .addCase(deleteOrganization.rejected, (state) => {
        state.isOrganizationActionProcessing = false;
      });
  },
});

export const { setActiveOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;
