import { createSlice } from "@reduxjs/toolkit";
import { IRedtailState } from "./types";
import { connectRedtail, disconnectRedtail } from "./actions";

const initialState: IRedtailState = {
  isConnectingToRedtail: false,
};

const redtailSlice = createSlice({
  name: "redtail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectRedtail.pending, (state) => {
        state.isConnectingToRedtail = true;
      })
      .addCase(connectRedtail.fulfilled, (state, action) => {
        state.isConnectingToRedtail = false;
      })
      .addCase(connectRedtail.rejected, (state) => {
        state.isConnectingToRedtail = false;
      })
      .addCase(disconnectRedtail.pending, (state) => {
        state.isConnectingToRedtail = true;
      })
      .addCase(disconnectRedtail.fulfilled, (state, action) => {
        state.isConnectingToRedtail = false;
      })
      .addCase(disconnectRedtail.rejected, (state) => {
        state.isConnectingToRedtail = false;
      });
  },
});

export const {} = redtailSlice.actions;

export default redtailSlice.reducer;
