import { Fragment } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IConnectRedtailPayload, connectRedtail, selectIsConnectingToRedtail } from 'store/redtail';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  onCreateCompleted?: (payload: void) => void
}

export const ConnectRedtailModal = ({ isVisible, setIsVisible, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isProcessing = useAppSelector(selectIsConnectingToRedtail);

  const formSchema = z.object({
    username: z.string().min(1, t('errors.required') as string),
    password: z.string().min(1, t('errors.required') as string),
    apiKey: z.string().min(1, t('errors.required') as string),
  });

  const form = useForm<Required<IConnectRedtailPayload>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      password: '',
      apiKey: ''
    }
  });

  const handleConnectToCRM = async (values: IConnectRedtailPayload) => {
    const response = await dispatch(connectRedtail(values));

    if (!response?.hasOwnProperty('error')) {
      form.reset();
      setIsVisible(false);
      onCreateCompleted && onCreateCompleted()
    }
  }

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t('component.ConnectRedtailModal.title')}</DialogTitle>
            <DialogDescription>
              {t('component.ConnectRedtailModal.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleConnectToCRM)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConnectRedtailModal.label.username')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConnectRedtailModal.label.password')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="apiKey"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConnectRedtailModal.label.apiKey')} *
                    </FormLabel>

                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  loading={isProcessing}
                >
                  {t('component.ConnectRedtailModal.btn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
