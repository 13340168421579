import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { Button } from "components/ui/button"
import { TasksTable } from './TasksTable';
import { ICrmTask, IDynamicListItem, IMeetingNotesSyncPicker, selectDataPoints, selectIsGetPostCallSummaryProcessing, selectOngoingCallBotId, selectPostCallSummary, selectQuestions } from 'store/chat-assistant';
import { useEffect, useRef, useState } from 'react';
import { Loading } from 'components/layout-atoms/Loading';
import { selectProfileDetailsFullName, selectProfileHasConnectedRedtail } from 'store/profile';
import { format } from 'date-fns';
import { DataPointsTable } from './DataPointsTable';
import { MeetingNotesTable } from './MeetingNotesTable';
import { toast } from 'react-toastify';
import { IClientDataRecord, ISalesforceMeetingNotes, ISyncSalesforcePayload, selectIsSyncingRedtail, syncRedtail } from 'store/clientManagement';
import { ConnectRedtail } from 'components/common/app/settings/connections/ConnectRedtail';


interface IProps {
  dataRecord: IClientDataRecord;
}

export const CrmData = ({ dataRecord }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const today = format(new Date(), 'yyyy-MM-dd')

  const [tableTasks, setTableTasks] = useState<ICrmTask[]>([])
  const syncTasks = useRef<ICrmTask[]>([])
  const [tableDataPoints, setTableDataPoints] = useState<IDynamicListItem[]>([])
  const syncDataPoints = useRef<IDynamicListItem[]>([])
  const [syncMeetingNotesPicker, setSyncMeetingNotesPicker] = useState<IMeetingNotesSyncPicker>()


  const [syncMeetingNotes, setSyncMeetingNotes] = useState<ISalesforceMeetingNotes>()

  const advisorFullName = useAppSelector(selectProfileDetailsFullName)

  const isGetPostCallSummaryProcessing = useAppSelector(selectIsGetPostCallSummaryProcessing)
  const postCallSummary = useAppSelector(selectPostCallSummary)

  const dataPoints = useAppSelector(selectDataPoints);
  const questions = useAppSelector(selectQuestions);

  const ongoingCallBotId = useAppSelector(selectOngoingCallBotId);
  const isSyncingRedtail = useAppSelector(selectIsSyncingRedtail);
  const hasConnectedRedtail = useAppSelector(selectProfileHasConnectedRedtail);


  useEffect(() => {
    if (!postCallSummary) {
      return;
    }

    const { action_items, summary } = postCallSummary
    const dataPointsString = dataPoints?.map(entry => `${entry?.text}: ${entry?.value || '-'}`)?.join('\n')
    const questionsString = questions?.map(entry => `Question: ${entry?.text}\nAnswer: ${entry?.value || '-'}`)?.join('\n\n')

    const meetingNotesObject: ISalesforceMeetingNotes = {
      data_points: {
        title: 'Data points',
        description: syncMeetingNotesPicker && syncMeetingNotesPicker?.dataPoints ? dataPointsString : '-'
      },
      questions: {
        title: "Questions/Answers",
        description: syncMeetingNotesPicker && syncMeetingNotesPicker?.questions ? questionsString : '-'
      },
      summary: {
        title: "Summary",
        description: syncMeetingNotesPicker && syncMeetingNotesPicker?.summary ? summary : '-'
      },
      action_items: {
        title: "Action items",
        description: syncMeetingNotesPicker && syncMeetingNotesPicker?.actionItems ? action_items : '-'
      },
    }

    setSyncMeetingNotes(meetingNotesObject)
  }, [postCallSummary, dataPoints, questions, syncMeetingNotesPicker])

  useEffect(() => {
    if (!postCallSummary) {
      return;
    }

    const { action_items: actionItems } = postCallSummary

    /* Extract tasks from action items */
    const step1 = actionItems?.split("Advisor To-Dos:")
    if (step1 && step1?.length > 1) {
      const rawTasks = step1?.[1]?.split("Next Meeting Date:")?.[0]?.trim() || '';

      const tasksStringArray = rawTasks?.split("\n")
        ?.map(line => line.trim())
        ?.filter(line => line !== '' && !line.startsWith('[')) // Remove empty lines and lines with brackets
        ?.map(line => line.replace(/^- /, '')); // Remove "- " from the start


      const crmTasks = tasksStringArray?.map(aiString => {
        const crmTask: ICrmTask = {
          due_date: today,
          title: aiString,
          description: '',
          assignee: advisorFullName,
          sync: true
        }
        return crmTask
      })

      setTableTasks(crmTasks)
    } else {
      setTableTasks([])
    }
  }, [postCallSummary, advisorFullName, today])

  useEffect(() => {
    if (!dataPoints) {
      return;
    }

    const dataPointsWithValue = dataPoints?.filter(entry => entry.value)?.map(entry => { return { ...entry, sync: true } })

    setTableDataPoints(dataPointsWithValue)
  }, [dataPoints])

  const handleSync = async () => {
    if (!syncMeetingNotes) {
      return;
    }

    const payload: ISyncSalesforcePayload = {
      client_id: dataRecord?.clientId,
      meeting_notes: syncMeetingNotes,
      tasks: syncTasks.current
    }

    const response = await dispatch(syncRedtail(payload))

    if (syncRedtail.fulfilled.match(response)) {
      toast("Synchronization was successful", { type: 'success' })
    }
  }


  return (
    <div className="w-full h-full min-h-20 p-px space-y-6">
      {isGetPostCallSummaryProcessing ? (
        <Loading variant="centered" />
      ) : (
        <>
          {!hasConnectedRedtail && (
            <ConnectRedtail
              title={t('component.CrmData.connectTitle') as string}
              descriptionConnect={t('component.CrmData.connectDescription') as string}
            />
          )}

          {hasConnectedRedtail && (
            <>
              <DataPointsTable
                dataPoints={tableDataPoints}
                onDataPointsChange={dataPoints => {
                  syncDataPoints.current = dataPoints?.filter(dataPoint => dataPoint.sync)
                }}
              />

              <TasksTable
                tasks={tableTasks}
                onTasksChange={tasks => {
                  syncTasks.current = tasks?.filter(task => task.sync)
                }}
              />

              <MeetingNotesTable
                onSyncObjectChange={syncObject => {
                  setSyncMeetingNotesPicker(syncObject)
                }}
              />

              <Button
                onClick={() => { handleSync() }}
                disabled={!!ongoingCallBotId}
                loading={isSyncingRedtail}
              >
                {t('component.CrmData.btn')}
              </Button>
            </>
          )}
        </>
      )}

    </div>
  )
}

