import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "services/api";
import { ACTION } from "const";
import { http, processApiError } from "services";
import {
  ICreateBotPayload,
  IDownloadMediaPayload,
  IGetAIAnswerPayload,
  IGetDataPointValuePayload,
  IGetDataPointsStatusPayload,
  IGetQuestionValuePayload,
  IGetChatAssistantCommonPayload,
  IGetTranscriptResponse,
  ITranscriptItem,
  ITranscriptWord,
} from "./types";
import { toast } from "react-toastify";
import { ClientDataRecordType } from "store/clientManagement";
import { API_ROUTES } from "services/api/constants";
import {
  UploadStatusEnum,
  setUploadProgressEvent,
  setUploadStatus,
} from "store/upload-management";
import { AxiosProgressEvent } from "axios";
import { omit } from "lodash";

export const createBot = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.CREATE_BOT,
  async (payload: ICreateBotPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.createBot(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getTranscript = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_TRANSCRIPT,
  async (payload: IGetChatAssistantCommonPayload, { rejectWithValue }) => {
    try {
      if (
        !payload?.dataRecord ||
        payload?.dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING
      ) {
        const response = await api.chatAssistant.getTranscript(payload);
        return response;
      } else {
        const rawResponse = await api.chatAssistant.getInPersonTranscript(
          payload
        );

        const response: IGetTranscriptResponse = {
          call_ended: true,
          transcript: [],
        };

        const dialogLines = rawResponse?.transcript
          ?.split("\n")
          .filter((item) => !!item);

        dialogLines?.forEach((line) => {
          const index = line?.indexOf(":");
          const speaker = index !== -1 ? line.substring(0, index) : line;
          const text = index !== -1 ? line.substring(index + 1) : "";
          const words: ITranscriptWord[] = text
            ?.split(" ")
            ?.map((stringWord) => {
              const transcriptWord: ITranscriptWord = {
                text: stringWord,
                end_time: 0,
                start_time: Math.random(),
              };

              return transcriptWord;
            });

          const dialogItem: ITranscriptItem = {
            speaker,
            source: null,
            words,
          };

          response?.transcript?.push(dialogItem);
        });

        return response;
      }
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getTranscriptAIAnswer = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_TRANSCRIPT_AI_ANSWER,
  async (payload: IGetAIAnswerPayload, { rejectWithValue }) => {
    try {
      if (
        payload?.dataRecord?.type === ClientDataRecordType.IN_PERSON_MEETING
      ) {
        const response = await api.chatAssistant.getInPersonTranscriptAIAnswer(
          payload
        );
        return response;
      } else {
        const response = await api.chatAssistant.getTranscriptAIAnswer(payload);
        return response;
      }
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getPastDataAIAnswer = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_PAST_DATA_AI_ANSWER,
  async (payload: IGetAIAnswerPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.getPastDataAIAnswer(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getDataPointValue = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_DATA_POINT_VALUE,
  async (payload: IGetDataPointValuePayload, { rejectWithValue }) => {
    try {
      if (
        !payload?.dataRecord ||
        payload?.dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING
      ) {
        const response = await api.chatAssistant.getDataPointValue(payload);
        return response;
      } else {
        const response = await api.chatAssistant.getInPersonDataPointValue(
          payload
        );
        return response;
      }
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getDataPointsStatus = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_DATA_POINTS_STATUS,
  async (payload: IGetDataPointsStatusPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.getDataPointsStatus(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getQuestionValue = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_QUESTION_VALUE,
  async (payload: IGetQuestionValuePayload, { rejectWithValue }) => {
    try {
      if (
        !payload?.dataRecord ||
        payload?.dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING
      ) {
        const response = await api.chatAssistant.getQuestionValue(payload);
        return response;
      } else {
        const response = await api.chatAssistant.getInPersonQuestionValue(
          payload
        );
        return response;
      }
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getQuestionsStatus = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_QUESTIONS_STATUS,
  async (payload: IGetChatAssistantCommonPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.getQuestionsStatus(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getTranscriptVideoUrl = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_TRANSCRIPT_VIDEO_URL,
  async (payload: IDownloadMediaPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.downloadVideo(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const downloadVideo = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.DOWNLOAD_VIDEO,
  async (payload: IDownloadMediaPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.downloadVideo(payload);
      const { video_url } = response;

      if (video_url) {
        const a = document.createElement("a");
        a.href = video_url;
        a.target = "_blank";
        a.rel = "noreferrer";

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        toast("Video is not available for this meeting", { type: "warning" });
      }
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const downloadAudio = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.DOWNLOAD_AUDIO,
  async (payload: IDownloadMediaPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.downloadAudio(payload);
      const { audio_url } = response;

      if (audio_url) {
        const a = document.createElement("a");
        a.href = audio_url;
        a.target = "_blank";
        a.rel = "noreferrer";

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        toast("Audio is not available for this meeting", { type: "warning" });
      }
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getTranscriptAudioUrl = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_TRANSCRIPT_AUDIO_URL,
  async (payload: IDownloadMediaPayload, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.downloadAudio(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const downloadTranscript = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.DOWNLOAD_TRANSCRIPT,
  async (payload: IDownloadMediaPayload, { rejectWithValue }) => {
    try {
      let response: Blob;
      if (
        !payload?.dataRecord ||
        payload?.dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING
      ) {
        response = await api.chatAssistant.downloadTranscript(payload);
      } else {
        response = await api.chatAssistant.downloadInPersonTranscript(payload);
      }

      const blob = new Blob([response], { type: "text/plain" });
      const blobObjectUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobObjectUrl;
      a.download = `transcript-${payload.botId}.txt`;
      a.target = "_blank";
      a.rel = "noreferrer";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getPostCallSummary = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_POST_CALL_SUMMARY,
  async (payload: IGetChatAssistantCommonPayload, { rejectWithValue }) => {
    try {
      if (
        !payload?.dataRecord ||
        payload?.dataRecord?.type !== ClientDataRecordType.IN_PERSON_MEETING
      ) {
        const response = await api.chatAssistant.getPostCallSummary(payload);
        return response;
      } else {
        const response = await api.chatAssistant.getInPersonPostCallSummary(
          payload
        );
        return response;
      }
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const getHeadsUpDisplay = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.GET_HEADS_UP_DISPLAY,
  async (botId: string, { rejectWithValue }) => {
    try {
      const response = await api.chatAssistant.getHeadsUpDisplay(botId);
      return response;
    } catch (error) {
      const msg = processApiError(error, false);
      return rejectWithValue(msg);
    }
  }
);

export const uploadInPersonRecording = createAsyncThunk(
  ACTION.CHAT_ASSISTANT.UPLOAD_IN_PERSON_RECORDING,
  async (payload: FormData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setUploadProgressEvent(null));
      dispatch(setUploadStatus(UploadStatusEnum.UPLOADING));

      let lastProgressTime = Date.now();
      let progressTimeout: NodeJS.Timeout;

      const updateProgress = (progressEvent: AxiosProgressEvent) => {
        lastProgressTime = Date.now();
        dispatch(setUploadProgressEvent(omit(progressEvent, "event")));

        // Clear any existing timeout
        if (progressTimeout) {
          clearTimeout(progressTimeout);
        }

        // Set a new timeout to check if progress has stalled
        progressTimeout = setTimeout(() => {
          if (Date.now() - lastProgressTime >= 1000) {
            // If no progress event for 1 second and progress is not complete, set it to 100%
            if (progressEvent.progress !== 1) {
              dispatch(
                setUploadProgressEvent({
                  loaded: progressEvent.total,
                  total: progressEvent.total,
                  progress: 1,
                } as AxiosProgressEvent)
              );
            }
          }
        }, 1000);
      };

      const response = await http
        .post(API_ROUTES.inPersonMeeting.uploadRecording, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          onUploadProgress: updateProgress,
        })
        .then((response) => {
          const { data, status } = response;
          clearTimeout(progressTimeout); // Clear the timeout when upload is completed
          // Ensure that progress is set to 100% on completion
          dispatch(
            setUploadProgressEvent({
              loaded: 10,
              total: 10,
              progress: 1,
            } as AxiosProgressEvent)
          );

          dispatch(setUploadStatus(UploadStatusEnum.COMPLETED));
          return { data, status };
        });

      return response;
    } catch (error) {
      dispatch(setUploadStatus(UploadStatusEnum.FAILED));
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
