import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from 'react-toastify';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IOrganization, OrgIndustryTypeEnum, createOrganization, selectIsOrganizationActionProcessing, updateOrganization } from 'store/organizations';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';


interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  data?: IOrganization | null;
  onCreateCompleted?: (org: IOrganization) => void
  onUpdateCompleted?: (org: IOrganization) => void
}

export const OrganizationModal = ({ isVisible, setIsVisible, data, onCreateCompleted, onUpdateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isOrganizationOperationProcessing = useAppSelector(selectIsOrganizationActionProcessing);

  const formSchema = z.object({
    name: z.string().min(1, t('errors.required') as string),
    domain: z.string().min(1, t('errors.required') as string),
    industryType: z.string().min(1, t('errors.required') as string),
  });

  const form = useForm<IOrganization>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name || '',
      domain: data?.domain || '',
      industryType: data?.industryType || undefined,
    }
  });

  useEffect(() => {
    form.setFocus('name');
  }, [form]);

  useEffect(() => {
    form.reset({
      name: data?.name || '',
      domain: data?.domain || '',
      industryType: data?.industryType || undefined,
    })
  }, [data, form]);

  const handleOnManageOrganization = async (values: IOrganization) => {
    if (!data) {
      const response = await dispatch(createOrganization(values));

      if (!response?.hasOwnProperty('error')) {
        const orgData = response?.payload as IOrganization;
        toast(t(`component.OrganizationModal.createSuccess`));
        setIsVisible(false);
        resetForm();
        onCreateCompleted && onCreateCompleted(orgData)
      }
    } else {
      const response = await dispatch(updateOrganization({
        ...data,
        ...values
      }));

      if (!response?.hasOwnProperty('error')) {
        const orgData = response?.payload as IOrganization;
        toast(t(`component.OrganizationModal.updateSuccess`));
        setIsVisible(false)
        resetForm();
        onUpdateCompleted && onUpdateCompleted(orgData)
      }
    }
  }

  const resetForm = () => {
    form.reset();
    setIsVisible(false);
  }

  return (
    <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {!data ? t(`component.OrganizationModal.createTitle`) : t(`component.OrganizationModal.editTitle`)}
          </DialogTitle>

          <DialogDescription>
            {!data ? t(`component.OrganizationModal.createDescription`) : t(`component.OrganizationModal.editDescription`)}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleOnManageOrganization)} className="flex flex-col gap-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(`component.OrganizationModal.name`)}
                  </FormLabel>

                  <FormControl>
                    <Input {...field} autoComplete="off" data-1p-ignore disabled={isOrganizationOperationProcessing} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="domain"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(`component.OrganizationModal.domain`)}
                  </FormLabel>

                  <FormControl>
                    <Input {...field} autoComplete="off" data-1p-ignore disabled={isOrganizationOperationProcessing} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="industryType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(`component.OrganizationModal.industryType`)}
                  </FormLabel>

                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    disabled={!!data?.industryType || isOrganizationOperationProcessing}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('component.UploadAudioDataRecordModal.templateSelect')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.keys(OrgIndustryTypeEnum)?.map(key => {
                        return (
                          <SelectItem
                            key={key}
                            value={OrgIndustryTypeEnum[key as keyof typeof OrgIndustryTypeEnum]}
                          >
                            {key}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                loading={isOrganizationOperationProcessing}
              >
                {!data ? t(`component.OrganizationModal.createBtn`) : t(`component.OrganizationModal.updateBtn`)}
              </Button>
            </DialogFooter>
          </form>
        </Form>

      </DialogContent>
    </Dialog>
  );
};

